var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filters__container"},[(_vm.options)?_c('div',[(_vm.options.back)?_c('Button',{staticClass:"page__edit_margin",attrs:{"block":"","text":_vm.options.back.text ? _vm.options.back.text : _vm.text.back[_vm.language]},on:{"click":_vm.goBack}}):_vm._e(),(_vm.options.title)?_c('div',{staticClass:"page__edit_margin page__edit_value"},[_vm._v(" "+_vm._s(_vm.options.title)+" ")]):_vm._e()],1):_vm._e(),_vm._l((_vm.filters),function(filter,index){return _c('div',{key:`filter_${index}`},[(filter && filter.type && filter.type === 'find')?_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"filters__title"},[_vm._v(" "+_vm._s(_vm.text.search[_vm.language])+" ")]),_c('Input',{attrs:{"search":""},on:{"change":function($event){return _vm.resetTimer(_vm.find, $event)}}})],1)]):_vm._e(),(filter && filter.type && filter.column && filter.type === 'checkbox_list')?_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px"}},[(filter.name)?_c('div',{staticClass:"filters__title block block__between",style:(`margin-bottom: 15px; ${filter.values.length > 5 ? 'cursor: pointer;' : ''}`),on:{"click":function($event){return _vm.setCbOpen(filter.column, filter.values.length)}}},[_c('div',[_vm._v(_vm._s(filter.name))]),(filter.values.length > 5)?_c('div',{class:`component__margin-left ${
              _vm.cb_opened && filter.column && _vm.cb_opened[filter.column]
                ? 'filters__arrow_open'
                : 'filters__arrow'
            }`},[_c('ArrowLogo')],1):_vm._e()]):_vm._e(),(filter.values && filter.column)?_c('div',_vm._l((_vm.setCBValues(filter.values, _vm.cb_opened[filter.column])),function(item,i){return _c('div',{key:`cbl_${i}`},[_c('div',{staticClass:"block block__between",staticStyle:{"margin-bottom":"10px","cursor":"pointer"},on:{"click":function($event){return _vm.setCheckedCb(item.value, filter.column)}}},[_c('div',[_c('span',[_vm._v(" "+_vm._s(item.name)+" "),_c('b',[_vm._v("("+_vm._s(_vm.getNumberWithSpaces(item.count))+")")])])]),_c('div',{staticClass:"component__margin-left"},[_c('CheckBoxI5',{attrs:{"disable-handle-click":"","value":_vm.cb_checked &&
                    _vm.cb_checked[filter.column] &&
                    _vm.cb_checked[filter.column][item.value]}})],1)])])}),0):_vm._e()])]):_vm._e(),(filter && filter.type && filter.column && filter.type === 'segment')?_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"filters__title",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(filter.name)+" ")]),(filter.values)?_c('div',{staticClass:"block block__align-center block__between"},[_c('div',[_vm._v(" "+_vm._s(_vm.segments_min_max[filter.column][0])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.segments_min_max[filter.column][1])+" ")])]):_vm._e(),(_vm.segments_values)?_c('Slider',{attrs:{"step":filter.tolerance,"min":_vm.segments_min_max[filter.column][0],"max":_vm.segments_min_max[filter.column][1],"value":_vm.segments_values[filter.column][0],"value2":_vm.segments_values[filter.column][1],"is-range":""},on:{"imput":function($event){return _vm.setSegmentV2($event, filter.column)},"change":function($event){return _vm.changeSegmentV2($event, filter.column)}}}):_vm._e(),(filter.values)?_c('div',{staticClass:"block block__align-center block__between"},[_c('Input',{attrs:{"type":"number","min":filter.values[0],"max":filter.values[1],"default-value":_vm.segments_values[filter.column][0],"max-width":70,"margin-bottom":0},on:{"change":function($event){return _vm.resetTimer(_vm.setSegmentByInput, $event, filter.column, 'left')}}}),_c('Input',{attrs:{"type":"number","min":filter.values[0],"max":filter.values[1],"default-value":_vm.segments_values[filter.column][1],"max-width":70,"margin-bottom":0},on:{"change":function($event){return _vm.resetTimer(_vm.setSegmentByInput, $event, filter.column, 'right')}}})],1):_vm._e()],1):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }