export const getNumberWithSpaces = (value) => {
  let parts = "";
  let result = "";
  if (typeof value === "number") {
    parts = value.toString().split(".");
  } else if (typeof value === "string") {
    parts = value.split(".");
  } else {
    parts = "";
  }
  if (parts !== "") {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // parts.join('.').toString();
    result = parts.join(".").toString();
  }
  return result;
};
export const getMoney = (value, currency, locale) => {
  const options = { style: "currency", currency };
  const temp = new Intl.NumberFormat(locale, options);
  return temp.format(value).toString();
};

export const getMb = (value) => +(+value / (1024 * 1024)).toFixed(2);
