<template>
  <Modal :show="show" @close="handleClose" :title="setTitle(type)">
    <template slot="body">
      <div class="blank__add_wrapper">
        <div class="blank__add_container">
          <div class="blank__add_left">
            <div class="blank__add_card" style="width: 100%">
              <div class="block">
                <div style="width: 100%">
                  <Search
                    :label="text.material[language]"
                    :query="{
                      method: 'post',
                      url: 'getMaterialsAsArray',
                      label: 'name',
                      value: 'id',
                    }"
                    :required="true"
                    :default-value="values && values.material ? values.material : ''"
                    clearable
                    @change="handleChange('id_material', $event.value)"
                    name="material"
                  />
                  <Search
                    :label="text.blank[language]"
                    :default-value="values && values.blank_type ? values.blank_type : ''"
                    :query="{
                      method: 'post',
                      url: 'getSearchBlanks',
                      label: 'blank_type',
                      value: 'id',
                    }"
                    clearable
                    @change="handleChange('id_blank_type', $event.value)"
                    name="blank_type"
                  />
                </div>
                <div style="width: 100%">
                  <Search
                    style="margin-left: 20px"
                    :label="text.material_standard[language]"
                    :query="{
                      method: 'post',
                      url: 'getSearchStandards',
                      label: 'standard_code',
                      value: 'id',
                    }"
                    clearable
                    @change="handleChange('id_gost_material', $event.value)"
                    name="gost_material"
                  />
                  <Search
                    style="margin-left: 20px"
                    :label="text.blank_standard[language]"
                    :query="{
                      method: 'post',
                      url: 'getSearchStandards',
                      label: 'standard_code',
                      value: 'id',
                    }"
                    :ti="11"
                    clearable
                    @change="handleChange('id_gost_blank', $event.value)"
                    name="gost_blank"
                  />
                </div>
              </div>
            </div>
            <div class="block">
              <div class="blank__add_card" style="margin-right: 10px">
                <div>
                  <div v-if="values && values.img">
                    <img :src="values.img" style="max-width: 100%" />
                  </div>
                  <div v-if="values && values.blank_sizes" style="width: 100%">
                    <div
                      style="width: 100%"
                      v-for="(size, i) in Object.keys(values.blank_sizes)"
                      v-bind:key="`sizes_${i}`"
                    >
                      <div style="margin-bottom: 10px" class="block__flex block__align-center">
                        <div
                          class="blank__add_letter"
                          style="margin-right: 10px; min-width: 30px; text-align: right"
                        >
                          {{ size }}
                        </div>
                        <Input
                          :max-width="80"
                          :default-value="values.blank_sizes[size]"
                          :margin-bottom="0"
                        />
                        <div class="blank__add_letter" style="margin-left: 10px">
                          {{ text.mm[language] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="blank__add_card" style="width: 100%">
                <div class="block">
                  <div>
                    <div class="blank__add_title">
                      {{ text.amount[language] }}
                    </div>
                    <Input
                      type="number"
                      :margin-bottom="0"
                      :default-value="
                        values && values.amount_blanks_value ? values.amount_blanks_value : ''
                      "
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="blank__add_right"></div>
        </div>
        <div class="blank__add_bottom">
          <Button type="default" :text="text.cancel[language]" />
          <Button style="margin-left: 20px" type="primary" :text="text.save[language]" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import Search from "@/components/search/Search.vue";
import text from "@/assets/text/blanks.json";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";

export default {
  name: "AddBlankModal",
  data() {
    return {
      text,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    values: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    setTitle(type) {
      let result = "";
      if (type === "add") {
        result = text.add_title[this.language];
      } else if (type === "edit") {
        result = text.edit_title[this.language];
      }
      return result;
    },
    handleChange(name, value) {
      console.log(name, value);
    },
  },
  mounted() {},
  components: {
    Modal,
    Search,
    Button,
    Input,
  },
};
</script>
