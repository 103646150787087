<template>
  <div>
    <Modal
      :title="text.edit_title[language]"
      :ok-text="text.save"
      @ok="changeUser"
      @cancel="setCompanyModal(false)"
      :show="showCompanyModal"
    >
      <template slot="body">
        <div style="padding: 20px" v-if="row">
          <Input
            @change="saveEditData($event, 'first_name')"
            :label="text.first_name[language]"
            :default-value="row.first_name ? row.first_name : ''"
          />
          <Input
            @change="saveEditData($event, 'last_name')"
            :label="text.last_name[language]"
            :default-value="row.last_name ? row.last_name : ''"
          />
          <!--<Input
            :label="text.post[language]"
            :default-value="row.position ? row.position : ''" />-->
          <Search
            :position-type="'fixed'"
            :label="text.company_name[language]"
            clearable
            :default-value="row.company_name"
            @change="saveEditData(+$event.value, 'id_company')"
            @clear="saveEditData(null, 'id_company')"
            :query="{
              method: 'post',
              url: 'getSearchCompaniesExpert',
              findName: 'find',
              label: 'name',
              value: 'id',
            }"
          />
          <Input
            @change="saveEditData($event, 'mobile')"
            :label="text.phone[language]"
            :default-value="row.mobile ? row.mobile : ''"
          />
          <Textarea
            @change="saveEditData($event, 'comment')"
            :default-value="row.comment ? row.comment : ''"
            :label="text.comment[language]"
          />
        </div>
      </template>
    </Modal>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <Button
        block
        :text="text.edit_user[language]"
        class="page__edit_margin"
        @click="setCompanyModal(true)"
      />
      <div v-if="row.login" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.login[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.login }}
        </div>
      </div>
      <div v-if="row.name" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.name[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.name }}
        </div>
      </div>
      <div v-if="row" class="page__edit_margin">
        <div class="page__edit_title">{{ text.license_date_limit[language] }}:</div>
        <div class="page__edit_value component__select">
          <div class="block__flex">
            <DropdownV3>
              <template slot="body">
                <div class="block__flex block__align-center">
                  <div style="text-decoration: underline; cursor: pointer">
                    {{
                      row.license_date_limit
                        ? moment(row.license_date_limit).format("DD.MM.YYYY")
                        : text.not_set[language]
                    }}
                  </div>
                </div>
              </template>
              <template slot="dropdown">
                <Calendar
                  @setDate="changeLicenseDate($event)"
                  :default-date="
                    row.license_date_limit
                      ? moment(row.license_date_limit).format('DD.MM.YYYY')
                      : null
                  "
                />
              </template>
            </DropdownV3>
            <div
              style="cursor: pointer"
              class="component__margin-left"
              v-if="row.license_date_limit"
              @click="changeLicenseDate(null)"
            >
              <DeleteLogo />
            </div>
          </div>
        </div>
      </div>
      <div v-if="row.position" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.post[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.position }}
        </div>
      </div>
      <div v-if="row.company_name" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.company_name[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.company_name }}
        </div>
      </div>
      <div v-if="row.mobile" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.phone[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.mobile }}
        </div>
      </div>
      <div v-if="row.last_login" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.last_login[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ moment(row.last_login).format("DD.MM.YYYY HH:ss") }}
        </div>
      </div>
      <div v-if="row.ip_last_login" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.ip_last_login[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.ip_last_login }}
        </div>
      </div>
      <div v-if="row.timestamp_registration" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.reg_date[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ moment(row.timestamp_registration).format("DD.MM.YYYY HH:ss") }}
        </div>
      </div>
      <div v-if="row.company_name_when_registering" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.company_name_when_reg[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.company_name_when_registering }}
        </div>
      </div>
      <div v-if="row.proof_status" class="page__edit_margin">
        <div class="page__edit_title">
          {{ text.proof_status[language] }}
        </div>
        <div class="page__edit_value component__select">
          {{ row.proof_status ? text.yes[language] : text.no[language] }}
        </div>
      </div>
      <div v-if="row && row.comment" class="page__edit_margin">
        <div class="page__edit_title">{{ text.comment[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ row.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/all_users.json";
import Button from "@/components/button/Button.vue";
import Modal from "@/components/modal/Modal.vue";
import Input from "@/components/input/Input.vue";
import Search from "@/components/search/Search.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import moment from "moment";
import { query } from "@/utilities/axios";
import Calendar from "@/components/calendar/Calendar.vue";
import DropdownV3 from "@/components/dropdownV3/DropdownV3.vue";
import DeleteLogo from "@/assets/images/parts/delete-file.svg";

export default {
  name: "Edit",
  data() {
    return {
      text,
      moment,
      showCompanyModal: false,
      editData: {},
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setCompanyModal(val) {
      this.showCompanyModal = val;
    },
    saveEditData(value, name) {
      this.editData[name] = value;
    },
    changeUser() {
      let data = {
        id_user: this.row.id,
      };
      data = { ...data, ...this.editData };
      query("post", "changeUserInfoByAdmin", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.setCompanyModal(false);
        this.$emit("update");
      });
    },
    changeLicenseDate(value) {
      let resultDate = null;
      if (value) {
        const splitDate = value.split(".");
        resultDate = `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`;
      }
      query(
        "post",
        "changeUserInfoByAdmin",
        {
          id_user: this.row.id,
          license_date_limit: resultDate,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
  },
  mounted() {},
  components: {
    Button,
    Modal,
    Input,
    Search,
    Textarea,
    Calendar,
    DropdownV3,
    DeleteLogo,
  },
};
</script>
