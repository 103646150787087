<template>
  <div>
    <CheckModal
      :show="showCheckModal"
      :order="order"
      @ok="sendOrderToManufacturer($event)"
      @close="setShowCheckModal(false)"
      :check-data="checkData"
    />
    <KpModal
      :show="showKpModal"
      :uuid="$route.params.id"
      :company-uuid="row.uuid_manufacturer"
      @close="setShowKpModal(false)"
    />
    <div class="page__edit_margin page__edit_large-title">{{ row.type }}</div>
    <div v-if="company">
      <div class="block block__between block__align-center page__edit_margin">
        <a-tooltip>
          <template slot="title">
            {{ text.approved[language] }}
          </template>
          <img
            src="@/assets/images/i5/approve.png"
            v-if="row.proof_by_i5"
            style="max-height: 60px"
          />
          <div v-if="!row.proof_by_i5"></div>
        </a-tooltip>
        <div v-if="company.company_name" class="block block__center">
          <!--<div class="page__edit_title">
            {{text.name[language]}}:
          </div>-->
          <div style="font-size: 17px; font-weight: 700" class="page__edit_value component__select">
            {{ company.company_name }}
          </div>
        </div>
        <div v-if="row.logo" class="component__margin-left">
          <img :src="row.logo" style="max-height: 40px; max-width: 100px; object-fit: scale-down" />
        </div>
      </div>
      <div v-if="company.id_order_manuf_status && company.id_order_manuf_status === 4">
        <Button
          block
          @click="setShowKpModal(true)"
          :text="text.view_kp[language]"
          class="page__edit_margin"
        />
        <a
          v-if="row.uuid_manufacturer"
          :href="`https://api.i5.solutions/getCommercialOfferPDFV2?t=${token}&uuid=${$route.params.id}&uuid_company=${row.uuid_manufacturer}`"
          download
        >
          <Button block :text="text.save_pdf[language]" class="page__edit_margin" />
        </a>
      </div>
      <div v-if="company.id_order_manuf_status && company.id_order_manuf_status === 1">
        <Button
          block
          @click="checkOrder"
          :text="text.send_req[language]"
          class="page__edit_margin"
        />
      </div>
      <div v-if="company.imgs" class="page__edit_margin">
        <Carousel :images="company.imgs" />
      </div>
      <div v-if="company.total_price" class="page__edit_margin">
        <div class="page__edit_title">{{ text.total_price[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ getMoney(company.total_price, currency, this.language) }}
        </div>
      </div>
      <div v-if="company.location" class="page__edit_margin">
        <div class="page__edit_title">{{ text.country[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ company.location }}
        </div>
      </div>
      <div v-if="company.description" class="page__edit_margin">
        <div class="page__edit_title">{{ text.desc[language] }}:</div>
        <div class="page__edit_value component__select" v-html="company.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/manufacturer_search.json";
import Button from "@/components/button/Button.vue";
import KpModal from "@/components/kpModal/KpModal.vue";
import CheckModal from "@/components/checkModal/CheckModal.vue";
import Carousel from "@/components/carousel/Carousel.vue";
import { query } from "@/utilities/axios";
import { getMoney } from "@/utilities/functions";
import moment from "moment";

export default {
  name: "Edit",
  data() {
    return {
      getMoney,
      text,
      moment,
      company: null,
      showKpModal: false,
      showCheckModal: false,
      checkData: null,
      manufacturerIdToOrder: null,
      order: null,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  watch: {
    row: function (val) {
      this.company = null;
      this.getCompany(val.uuid_manufacturer);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    currency() {
      return this.$store.state.user.user.currency;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    getCompany(uuid) {
      query(
        "post",
        "getKPinfoByOrderV2",
        {
          uuid_manufacturer: uuid,
          uuid: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.company = res;
      });
    },
    setShowKpModal(val) {
      this.showKpModal = val;
    },
    sendOrderToManufacturer() {
      query(
        "post",
        "sendOrderToManufacturers",
        {
          uuid: this.$route.params.id,
          manufacturers: [this.manufacturerIdToOrder],
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.setShowCheckModal(false);
        this.$emit("update");
      });
    },
    setShowCheckModal(val) {
      this.showCheckModal = val;
    },
    checkOrder() {
      const uuid = this.$route.params.id;
      this.manufacturerIdToOrder = this.row.id_manufacturer;
      query(
        "post",
        "checkOrder",
        {
          uuid: uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((checkData) => {
        this.checkData = checkData;
        this.setShowCheckModal(true);
      });
    },
    getOrder() {
      return new Promise((resolve, reject) => {
        query(
          "post",
          "getOrderInfoV2",
          {
            uuid: this.$route.params.id,
          },
          {
            token: localStorage.getItem("token"),
          },
        )
          .then((result) => {
            this.order = result.order_info;
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mounted() {
    this.getCompany(this.row.uuid_manufacturer);
    this.getOrder();
  },
  components: {
    Button,
    KpModal,
    Carousel,
    CheckModal,
  },
};
</script>
