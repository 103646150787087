import axios from "axios";
import store from "@/store";
import packageJson from "../../../package.json";

export function query(method, url, data, headers, onUploadProgress = () => {}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${packageJson.url}${url}`,
      data,
      headers,
      onUploadProgress,
    })
      .then((item) => {
        resolve(item.data);
      })
      .catch((err) => {
        if (err.response) {
          const status = err.response.status;
          if (status === 401) {
            store.commit("user/setShowAuthorizeModal", true);
            reject(err);
          }
        }
        reject(err);
      });
  });
}

export async function requestFile(method, url, data, headers) {
  const response = await axios({
    method: method,
    url: `${packageJson.url}${url}`,
    headers: headers,
    responseType: "blob",
    data: data,
  }).catch((error) => {
    console.log(error);
  });
  if (response.status && response.status === 200) {
    return { data: response };
  } else {
    return { error: response };
  }
}

export function queryWithout401(method, url, data, headers, onUploadProgress = () => {}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${packageJson.url}${url}`,
      data,
      headers,
      onUploadProgress,
    })
      .then((item) => {
        resolve(item.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default query;
