<template>
  <I5Modal :show="showModal" @close="handleClose" size="full-size" :title="title">
    <template slot="body">
      <div class="full-size__wrapper" v-if="type === 'image'">
        <img
          @mousemove="handleMouseMove"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @load="handleImageLoad"
          ref="image"
          :src="link"
          class="full-size__img"
          @error="setError"
        />
        <div
          class="full-size__zoomed-image"
          v-show="showZoomed"
          :style="zoomedImageCombinedStyle"
        ></div>
        <div class="full-size__error" v-if="showError">
          {{ text.not_available[language] }}
        </div>
      </div>
      <ModelViewer v-if="type === '3d'" :src="link" />
      <div v-if="type === 'none'">not image</div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import ModelViewer from "@/components/modelViewer/ModelViewer.vue";
import text from "@/assets/text/full_size.json";

export default {
  name: "FullSize",
  data() {
    return {
      text,
      showModal: false,
      imageContainer: null,
      zoomSection: null,
      image: null,
      showError: false,
      showZoomed: false,
      mouseX: 0,
      mouseY: 0,
      imageNaturalWidth: 0,
      imageNaturalHeight: 0,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "image",
    },
    link: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    previewLink: {
      type: String,
      default: "",
    },
  },
  watch: {
    show: function (val) {
      this.showError = false;
      this.showModal = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    zoomedImageStyle() {
      const x = this.mouseX + 20;
      const y = this.mouseY + 20;
      return {
        top: `${y}px`,
        left: `${x}px`,
      };
    },
    zoomedImageCombinedStyle() {
      const style = {
        ...this.zoomedImageStyle,
        backgroundImage: `url(${this.link})`,
      };

      if (this.$refs.image) {
        const imageContainer = this.$refs.image.parentNode;
        const imageWidth = this.$refs.image.clientWidth;
        const imageHeight = this.$refs.image.clientHeight;
        const scaleX = this.imageNaturalWidth / imageWidth;
        const scaleY = this.imageNaturalHeight / imageHeight;

        const x = -1 * (this.mouseX - imageContainer.offsetLeft) * 2 * scaleX;
        const y = -1 * (this.mouseY - imageContainer.offsetTop) * 2 * scaleY;
        style.backgroundPosition = `${x}px ${y}px`;
      }

      return style;
    },
  },
  methods: {
    handleClose() {
      this.showModal = false;
      this.$emit("close");
    },
    handleImageLoad(e) {
      this.imageNaturalWidth = e.target.naturalWidth;
      this.imageNaturalHeight = e.target.naturalHeight;
    },
    setError(e) {
      this.showError = true;
      e.target.src = this.previewLink;
    },
    setZoom() {
      const imageContainer = document.querySelector(".full-size__wrapper");
      const zoomSection = document.querySelector(".full-size__zoom-section");
      const image = document.querySelector(".full-size__img");
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      imageContainer.addEventListener("mousemove", (e) => {
        const x = e.offsetX;
        const y = e.offsetY;
        const zoomX = (x * 100) / image.offsetWidth;
        const zoomY = (y * 100) / image.offsetHeight;
        let top = e.pageY;
        let left = e.pageX;

        if (e.pageY + zoomSection.offsetHeight > windowHeight) {
          top = e.pageY - (zoomSection.offsetHeight + 100);
        }

        if (e.pageX + zoomSection.offsetWidth > windowWidth) {
          left = e.pageX - zoomSection.offsetWidth;
        }

        zoomSection.style.backgroundImage = `url('${image.src}')`;
        zoomSection.style.backgroundPosition = `${zoomX}% ${zoomY}%`;
        zoomSection.style.backgroundSize = "1000%";
        zoomSection.style.display = "block";
        zoomSection.style.left = `${left}px`;
        zoomSection.style.top = `${top}px`;
      });

      imageContainer.addEventListener("mouseleave", () => {
        zoomSection.style.display = "none";
      });
    },
    handleMouseMove(event) {
      const { clientX, clientY } = event;
      this.mouseX = clientX;
      this.mouseY = clientY;
    },
    handleMouseEnter() {
      // this.showZoomed = true;
    },
    handleMouseLeave() {
      // this.showZoomed = false;
    },
  },
  components: {
    I5Modal,
    ModelViewer,
  },
  mounted() {},
};
</script>
