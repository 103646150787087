<template>
  <div style="height: inherit">
    <Add :show="showAddModal" @close="setAddModal(false)" />
    <PageConstructor
      :query="query"
      :columns="columns"
      :buttons="buttons"
      show-filter
      @headerClick="headerClick($event)"
      @setUpdate="setUpdate($event)"
      @getFilter="getFilter($event)"
      id-name="uuid"
      :page-name="text.page_name[language]"
    >
      <template slot="edit" slot-scope="{ row, width }">
        <Edit :row="row" :width="width" @update="tableUpdate" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/tools.json";
import { requestFile } from "@/utilities/axios";
import fileSaver from "file-saver";
import Edit from "./components/edit/Edit.vue";
import Add from "./components/add/Add.vue";

export default {
  name: "Tools",
  data() {
    return {
      text,
      showAddModal: false,
      tableUpdate: () => {},
      fileSaver,
      filters: null,
      query: {
        method: "post",
        url: "getCurrentCompaniesToolsV2",
        headers: {},
        data: {},
      },
      buttons: [
        {
          type: "download",
        },
        {
          type: "main",
          text: text.add_tool[this.$store.state.index.language],
        },
      ],
      columns: [
        {
          name: "sn",
          label: text.number,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "code",
          label: text.tool_code,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "tool_type",
          label: text.tool_type,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "manufacturer",
          label: text.manufacturer,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "amount",
          label: text.amount,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "minimum_amount",
          label: text.a_min_amount,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "timestamp_change",
          label: text.timestamp_change,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
      ],
    };
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.setAddModal(true);
      } else if (type === "download") {
        this.downloadList();
      }
    },
    setAddModal(val) {
      this.showAddModal = val;
      this.tableUpdate();
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
    getFilter(val) {
      this.filters = val;
    },
    downloadList() {
      const data = {};
      if (this.filters) {
        data.filters = this.filters;
      }
      requestFile("post", "expToolsToExcel", data, {
        token: localStorage.getItem("token"),
      }).then((item) => {
        // const name = item.data.headers['content-disposition'];
        // console.log(name);
        // const VRegExp = new RegExp(/[^]*filename="([^]*)"/);
        // const VResult = name.replace(VRegExp, '$1');
        this.fileSaver.saveAs(item.data.data, "tool_list");
      });
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
    Add,
    Edit,
  },
};
</script>
