<template>
  <div :class="`${hideHeader ? 'page__wrapper_hide' : 'page__wrapper'}`">
    <Modal
      :show="showAdd"
      :hide-footer="hideAddFooter"
      @cancel="closeAdd"
      :title="setAddTitle()"
      :width="'800px'"
    >
      <template slot="body">
        <slot :columns="columns" name="add" />
      </template>
    </Modal>
    <Modal :show="showDel" :title="page.delete" @cancel="closeDel">
      <template slot="body">
        <div>
          <span class="form__header_title">
            <span v-for="name in formName" v-bind:key="`del_${name}`">
              <span v-if="activeRow && activeRow[name]">
                {{ activeRow[name] }}
              </span>
            </span>
          </span>
        </div>
      </template>
    </Modal>
    <template slot="component"> </template>
    <PageHeader
      v-if="!hideHeader"
      :name="tablePageName || ''"
      @edit="setEdit"
      :buttons="buttons"
      @click="pageHeaderClick($event)"
      :active-row="activeRow"
    />
    <div
      @mousemove="onMouseMove"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mouseleave="onMouseUp"
      class="block page__container"
    >
      <TableFilter
        v-if="showFilter"
        :style="setFilterStyle()"
        @setFilter="setFilter($event)"
        :options="filterOptions"
        :filters="filters"
      >
        <template slot="edit">
          <slot
            name="filter"
            :filters="filters"
            :row="activeRow || null"
            :idName="idName"
            :width="filterWidth"
          />
        </template>
      </TableFilter>
      <Delimiter
        v-if="showFilter"
        :move="moveFilterDelimiter"
        id="delimiter_filter"
        :style="setDelimiterFilterStyle()"
        name="delimiter_filter"
      />
      <div class="page__table_wrapper">
        <Table
          :table-name="tableName"
          v-if="query"
          :id-name="idName"
          :save-store="saveStore"
          @rowClick="handleClick"
          @setPageTitle="setPageTitle($event)"
          :hide-footer="hideFooter"
          :columns="tableColumns"
          :query="query"
          :queryFilter="queryFilter"
          :external-filters="externalFilters"
          :left="formWidth"
          @filters="setFilters($event)"
          :bottom="infoHeight"
          @setUpdate="setUpdate($event)"
          :style="setTableStyle()"
          :setPageName="setPageName"
        />
        <!--<div
          v-if="delimiterBottom > 0"
          :style="setInfoStyle()"
          class="page__info_container"></div>
        <Delimiter
          :style="setDelimiterInfoStyle()"
          :move="moveInfoDelimiter"
          type="horizontal"
          name="delimiter_info"
          id="delimiter_info" />-->
      </div>
      <Form
        v-if="edit"
        @headerClick="headerClick($event)"
        :form-name="formName"
        :style="setFormStyle()"
        :row="activeRow"
        :columns="columns"
      >
        <template slot="edit">
          <slot name="edit" :row="activeRow || null" :idName="idName" :width="formWidth" />
        </template>
      </Form>
      <Delimiter
        v-if="edit"
        :move="moveFormDelimiter"
        id="delimiter_form"
        :style="setDelimiterFormStyle()"
        name="delimiter_form"
      />
    </div>
  </div>
</template>

<script>
import Table from "@/components/table/Table.vue";
import Form from "@/components/form/Form.vue";
import TableFilter from "@/components/filter/TableFilter.vue";
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import Delimiter from "@/components/delimiter/Delimiter.vue";
import Modal from "@/components/modal/Modal.vue";
import page from "@/assets/text/page.json";

export default {
  name: "PageConstructor",
  props: {
    idName: {
      type: String,
    },
    pageName: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: () => null,
    },
    queryFilter: {
      type: Object,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    formName: {
      type: Array,
      default: () => ["id"],
    },
    addTitle: {
      type: [String, Array],
      default: "",
    },
    hideAddFooter: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    saveStore: {
      type: String,
    },
    showFilter: {
      type: Boolean,
    },
    filterOptions: {
      type: Object,
    },
  },
  watch: {
    pageName: {
      immediate: true,
      handler: function (val) {
        this.tablePageName = val;
      },
    },
  },
  data() {
    return {
      page,
      showAdd: false,
      showDel: false,
      tableName: "",
      edit: false,
      activeRow: null,
      formWidth: 365,
      delimiterRight: 365,
      filterWidth: 365,
      delimiterLeft: 365,
      moveFormDelimiter: false,
      moveFilterDelimiter: false,
      infoHeight: 0,
      delimiterBottom: 0,
      moveInfoDelimiter: false,
      tableColumns: [],
      totalValues: [],
      total: 1,
      filters: null,
      externalFilters: null,
      tableUpdate: () => {},
      tablePageName: "",
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleClick(data) {
      this.activeRow = data.row;
      if (data.row) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    closeForm() {
      this.edit = false;
      this.activeRow = null;
    },
    setEdit() {
      this.edit = !this.edit;
    },
    pageHeaderClick(type) {
      this.$emit("headerClick", type);
      if (type === "plus") {
        this.showAdd = true;
      }
    },
    headerClick(type) {
      if (type === "close") {
        this.closeForm();
      } else if (type === "delete") {
        this.openDel();
      }
    },
    openDel() {
      this.showDel = true;
    },
    setShowAdd() {
      this.showAdd = true;
    },
    closeAdd() {
      this.showAdd = false;
      this.tableUpdate();
    },
    setAddTitle() {
      let result = "";
      if (this.addTitle) {
        if (typeof this.addTitle === "string") {
          result = this.addTitle;
        } else if (typeof this.addTitle === "object") {
          this.addTitle.forEach((el) => {
            const index = this.columns.findIndex((column) => column.name === el);
            if (index >= 0) {
              result += `${this.columns[index].label} `;
            } else {
              result += `${el} `;
            }
          });
        }
      }
      return result;
    },
    closeDel() {
      this.showDel = false;
    },
    setFormStyle() {
      const result = {
        width: `${this.formWidth}px`,
        minWidth: `${this.formWidth}px`,
      };
      return result;
    },
    setFilterStyle() {
      const result = {
        width: `${this.filterWidth}px`,
        minWidth: `${this.filterWidth}px`,
      };
      return result;
    },
    setDelimiterFormStyle() {
      const result = {
        right: `${this.delimiterRight - 2.5}px`,
      };
      return result;
    },
    setDelimiterFilterStyle() {
      const result = {
        left: `${this.delimiterLeft - 2.5}px`,
      };
      return result;
    },
    setDelimiterInfoStyle() {
      const result = {
        bottom: `${this.delimiterBottom + 2.5}px`,
      };
      return result;
    },
    onMouseDown(e) {
      if (e.target.id === "delimiter_form") {
        this.moveFormDelimiter = true;
      }
      if (e.target.id === "delimiter_filter") {
        this.moveFilterDelimiter = true;
      }
      if (e.target.id === "delimiter_info") {
        this.moveInfoDelimiter = true;
      }
    },
    onMouseUp(e) {
      if (this.moveFormDelimiter) {
        this.moveFormDelimiter = false;
        const right = this.setDelimiterRight(window.innerWidth - e.clientX);
        this.delimiterRight = right;
        this.formWidth = right;
      }
      if (this.moveFilterDelimiter) {
        this.moveFilterDelimiter = false;
        const left = this.setDelimiterLeft(e.clientX - 55);
        this.delimiterLeft = left;
        this.filterWidth = left;
      }
      if (this.moveInfoDelimiter) {
        this.moveInfoDelimiter = false;
        const bottom = this.setDelimiterBottom(window.innerHeight - e.clientY);
        this.delimiterBottom = bottom;
        this.infoHeight = bottom;
      }
    },
    onMouseMove(e) {
      if (this.moveFormDelimiter) {
        const right = this.setDelimiterRight(window.innerWidth - e.clientX);
        this.delimiterRight = right;
      }
      if (this.moveFilterDelimiter) {
        const left = this.setDelimiterLeft(e.clientX - 55);
        this.delimiterLeft = left;
      }
      if (this.moveInfoDelimiter) {
        const bottom = this.setDelimiterBottom(window.innerHeight - e.clientY);
        this.delimiterBottom = bottom;
      }
    },
    setDelimiterBottom(bottom) {
      let result = bottom;
      if (bottom < window.innerHeight / 2 && bottom > 0) {
        result = bottom;
      } else if (bottom > window.innerHeight / 2) {
        result = window.innerHeight / 2;
      } else if (bottom <= 0) {
        result = 0;
      }
      return result;
    },
    setDelimiterRight(right) {
      let result = right;
      if (right < window.innerWidth / 2 && right > 5) {
        result = right;
      } else if (right > window.innerWidth / 2) {
        result = window.innerWidth / 2;
      } else if (right < 5) {
        result = 5;
      }
      return result;
    },
    setDelimiterLeft(left) {
      let result = left;
      if (left > (window.innerWidth - 55) / 3) {
        result = (window.innerWidth - 55) / 3;
      } else if (left < 55) {
        result = 55;
      }
      return result;
    },
    setTableStyle() {
      let result = {
        height: `calc(100% - ${this.infoHeight}px)`,
      };
      if (this.moveFormDelimiter || this.moveInfoDelimiter) {
        result = {
          ...result,
          ...{
            pointerEvents: "none",
            webkitTouchCallout: "none",
            webkitUserSelect: "none",
            khtmlUserSelect: "none",
            mozUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          },
        };
      }
      return result;
    },
    setInfoStyle() {
      const result = {
        height: `${this.infoHeight}px`,
        minHeight: `${this.infoHeight}px`,
      };
      return result;
    },
    setPageTitle(title) {
      this.$emit("setPageTitle", title);
    },
    setUpdate(func) {
      this.tableUpdate = func;
      this.$emit("setUpdate", func);
    },
    setFilters(val) {
      this.filters = val;
    },
    setFilter(data) {
      this.externalFilters = data;
      this.$emit("getFilter", data);
      this.$nextTick(() => {
        this.tableUpdate();
      });
    },
    setPageName(val) {
      this.tablePageName = val;
    },
  },
  created() {
    this.$emit("setFunctions", {
      showAdd: this.setShowAdd,
      closeAdd: this.closeAdd,
    });
    this.tableColumns = this.columns;
    this.formWidth = window.innerWidth / 3;
    this.delimiterRight = window.innerWidth / 3;
    const path = this.$route.path.split("/");
    path.splice(0, 1);

    this.tableName = path.join("/");
    console.log(this.tableName);
  },
  components: {
    Table,
    PageHeader,
    Form,
    TableFilter,
    Delimiter,
    Modal,
  },
};
</script>
