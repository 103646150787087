const state = () => ({
  info: null,
  name: null,
});

const mutations = {
  setData(state, data) {
    if (data.table_bottom) {
      state.info = data.table_bottom;
    }
    if (data.title) {
      state.name = data.title;
    }
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
