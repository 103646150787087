<template>
  <div>
    <div class="block" style="height: 100%">
      <div
        :class="`upload__wrapper ${isDrag ? 'upload__wrapper_drag' : ''} ${
          files.length > 0 ? 'upload__wrapper_half' : ''
        }`"
        ondragover="event.preventDefault()"
        @dragenter="handleMouseEnter"
        @dragleave="handleMouseLeave"
        @drop="handleDrop"
      >
        <div v-if="!isStart && !errorStatus">
          <div class="upload__container">
            Выберите или перетащите, документы, чертежи, спецификации. Доступные форматы png, jpg,
            pdf...
          </div>
        </div>
        <input
          id="file"
          type="file"
          class="upload__input"
          multiple
          @change="handleChange($event)"
        />
      </div>
      <div
        :class="`upload__files ${files.length > 0 ? 'upload__files_half' : ''}`"
        v-if="files.length > 0"
      >
        <div>Файлы:</div>
        <div class="upload__files_container">
          <div v-for="(file, index) in files" v-bind:key="`file_${index}`" class="upload__file">
            <div class="block">
              <div class="upload__files_name">{{ file.name }}</div>
              <div class="component__padding-left upload__files_size">
                {{ Math.round(file.size / 1000) + "Кб." }}
              </div>
            </div>
            <div>
              <a-progress :percent="file.percent" :status="file.status" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/assets/text/upload.json";
import { query } from "@/utilities/axios";

export default {
  name: "Upload",
  data() {
    return {
      upload,
      showUpload: true,
      unsubscribe: () => {},
      isDrag: false,
      files: [],
      componentHeight: 95,
      componentWidth: 456,
      isStart: false,
      percent: null,
      errorStatus: null,
      errorTitle: null,
      errorSubtitle: null,
      showFileLoad: false,
      showServerLoad: true,
      timeOut: false,
      formData: new FormData(),
      timer: null,
      partCount: null,
    };
  },
  props: {
    show: Boolean,
    parent: Object,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
  },
  watch: {
    percent: function (val) {
      if (this.timeOut && val) {
        this.isStart = true;
      }
    },
  },
  methods: {
    hideErrors() {
      this.errorStatus = null;
      this.errorTitle = null;
      this.errorSubtitle = null;
      clearTimeout(this.timer);
      this.percent = null;
      this.isStart = false;
      document.getElementById("file").value = "";
      this.formData = new FormData();
      this.partCount = null;
    },
    updateErrors() {
      if (this.errorStatus === "NE") {
        this.errorTitle = this.upload.error_network_title[this.language];
        this.errorSubtitle = this.upload.error_network_subtitle[this.language];
      } else if (this.errorStatus === 405) {
        this.errorTitle = this.upload.error_405_title[this.language];
        this.errorSubtitle = `${this.upload.error_405_subtitle_1[this.language]} ${
          this.partCount
        } ${this.upload.error_405_subtitle_2[this.language]}`;
      } else if (this.errorStatus === 415) {
        this.errorTitle = this.upload.error_415_title[this.language];
        this.errorSubtitle = this.upload.error_415_subtitle[this.language];
      } else if (this.errorStatus === 500) {
        this.errorTitle = this.upload.error_500_title[this.language];
        this.errorSubtitle = this.upload.error_500_subtitle[this.language];
      } else if (this.errorStatus === "SIZE") {
        this.errorTitle = this.upload.error_size_title[this.language];
        this.errorSubtitle = this.upload.error_size_subtitle[this.language];
      }
    },
    handleClose() {
      this.$emit("close");
    },
    handleChange(e) {
      let startIndex = 0;
      if (this.files.length > 0) {
        startIndex = this.files.length;
      }
      Array.from(e.target.files).forEach(async (file) => {
        this.files.push({
          id: this.files.length,
          name: file.name,
          size: file.size,
          type: file.type,
          percent: 0,
          status: "active",
          file: file,
        });
      });
      const fileUpload = (file, index) => {
        const formData = new FormData();
        formData.append("file", this.files[index].file);
        query(
          "put",
          "addFile",
          formData,
          {
            token: localStorage.getItem("token"),
          },
          (evt) => {
            const percent = Math.round((evt.loaded * 100) / evt.total);
            this.files[index].percent = percent;
            if (percent < 100) {
              this.files[index].status = "active";
            } else {
              this.files[index].status = "success";
            }
          },
        )
          .then((fileData) => {
            this.files[index] = { ...this.files[index], ...fileData };
            if (index + 1 < e.target.files.length) {
              fileUpload(e.target.files[index + 1], index + 1);
            }
          })
          .catch(() => {
            if (!this.files[index].percent) {
              this.files[index].percent = 1;
            }
            this.files[index].status = "exception";
            if (index + 1 < e.target.files.length) {
              fileUpload(e.target.files[index + 1], index + 1);
            }
          });
      };
      fileUpload(e.target.files[startIndex], startIndex);
    },
    handleMouseEnter() {
      this.isDrag = true;
    },
    handleMouseLeave() {
      this.isDrag = false;
    },
    handleDrop() {
      this.isDrag = false;
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "index/setWidth") {
        this.componentHeight = this.$el.clientHeight;
        this.componentWidth = this.$el.clientWidth;
      } else if (mutation.type === "index/setLanguage") {
        this.updateErrors();
      }
    });
    this.componentHeight = this.$el.clientHeight;
    this.componentWidth = this.$el.clientWidth;
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {},
};
</script>
