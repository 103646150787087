<template>
  <div style="width: 100%">
    <div v-if="edit">
      <div style="width: 100%">
        <Input
          style="width: 100%"
          :default-value="values.name ? values.name : ''"
          @change="resetTimer(changeLine, $event, 'name')"
          :label="text.installation_name[language]"
        />
        <Input
          style="width: 100%"
          :default-value="values.adjustment ? values.adjustment : ''"
          :label="text.main_fixture[language]"
        />
        <Input style="width: 100%" :label="text.accessories[language]" />
        <Input style="width: 100%" :label="text.removing_blank[language]" />
      </div>
    </div>
    <div v-else class="tech-proc__card" style="width: 100%">
      <div v-if="values.adjustment" class="component__margin-bottom">
        <div class="tech-proc__card_param-title">
          {{ text.main_fixture[language] }}
        </div>
        <div>
          <span class="tech-proc__card_label">{{ values.adjustment }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/technological_processes.json";
import Input from "@/components/input/Input.vue";
import { query } from "@/utilities/axios";

export default {
  name: "fixture",
  data() {
    return {
      text,
      timer: null,
    };
  },
  props: {
    edit: {
      type: Boolean,
    },
    values: {},
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    startTimer(callback, ...args) {
      this.timer = setTimeout(() => {
        if (typeof callback === "function") {
          callback(...args);
        }
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(callback, ...args) {
      this.stopTimer();
      this.startTimer(callback, ...args);
    },
    changeLine(value, name) {
      const data = {};
      data[name] = value;
      query(
        "post",
        "changeMPline",
        {
          uuid_mp_line: this.values.uuid,
          change_data: data,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update", this.values);
      });
    },
  },
  components: {
    Input,
  },
};
</script>
