<template>
  <div
    :style="disableHandleClick ? 'pointer-events: none;' : ''"
    :class="`checkbox-i5__wrapper ${checked ? 'checkbox-i5__wrapper_active' : ''}`"
  >
    <div class="checkbox-i5__container">
      <label class="checkbox-i5">
        <input type="checkbox" :id="name" :name="name" @change="onHandleClick" :checked="checked" />
        <span class="checkmark">
          <Check v-if="checked" width="12" height="10" viewBox="0 0 12 10" />
        </span>
      </label>
    </div>
  </div>
</template>

<script>
const Check = () => import("@/assets/images/checkbox/Path.svg");

export default {
  name: "CheckBoxI5",
  props: {
    value: {
      type: Boolean,
    },
    name: {
      type: [String, Number],
      default: "checkbox",
    },
    disableHandleClick: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.checked = val;
      },
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    onHandleClick() {
      if (!this.disableHandleClick) {
        this.checked = !this.checked;
        this.$emit("click", this.checked);
      }
    },
  },
  components: {
    Check,
  },
  mounted() {},
};
</script>
