<template>
  <div style="width: 100%">
    <div v-if="edit">
      <div style="width: 100%">
        <Textarea
          :symbols="symbols"
          style="width: 100%"
          @change="resetTimer(changeLine, $event, 'name')"
          :default-value="values.name ? values.name : ''"
          :label="text.transition_name[language]"
        />
        <div>
          <div class="tech-proc__label">
            {{ text.instrument[language] }}
          </div>
          <div style="width: 100%" v-if="tools">
            <div
              style="width: 100%"
              v-for="(instrument, index) in tools"
              v-bind:key="`tool_${instrument.id}`"
            >
              <div class="block block__align-center">
                <Search
                  style="width: 100%"
                  @change="saveTool($event, index)"
                  :default-value="instrument.name ? instrument.name : ''"
                  :dropdown-props="{
                    img: 'img',
                    sublabel: 'tool_type',
                    secondLine: 'manufacturer',
                    prelabel: 'vendor_code',
                  }"
                  :query="{
                    method: 'post',
                    url: 'getSearchAllTools',
                    findName: 'name',
                    label: 'name',
                    value: 'id',
                  }"
                />
                <InputNumber
                  :min="1"
                  @change="setToolAmount($event, index)"
                  class="component__margin-left"
                  :disabled="!instrument.name"
                  :default-value="instrument.amount ? +instrument.amount : 1"
                />
                <DeleteLogo
                  style="margin-bottom: 15px; min-width: 14px; cursor: pointer"
                  @click="deleteTool(index)"
                  class="component__margin-left"
                />
              </div>
            </div>
            <div
              class="block block__align-center component__margin-bottom"
              @click="addTool"
              style="cursor: pointer"
            >
              <AddLogo class="component__margin-right" />
              <div class="tech-proc__params_plus">
                {{ text.plus_tool[language] }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="tech-proc__label">
            {{ text.measure_tool[language] }}
          </div>
          <div style="width: 100%" v-if="measure_tools">
            <div
              style="width: 100%"
              v-for="(tool, index) in measure_tools"
              v-bind:key="`measure_tool_${tool.id}`"
            >
              <div class="block block__align-center">
                <Search
                  style="width: 100%"
                  @change="saveMeasureTool($event, index)"
                  :default-value="tool.name ? tool.name : ''"
                  :dropdown-props="{
                    img: 'img',
                    sublabel: 'tool_type',
                    secondLine: 'manufacturer',
                    prelabel: 'vendor_code',
                  }"
                  :query="{
                    method: 'post',
                    url: 'getSearchAllTools',
                    findName: 'name',
                    label: 'name',
                    value: 'id',
                  }"
                />
                <InputNumber
                  :min="1"
                  @change="setMeasureToolAmount($event, index)"
                  class="component__margin-left"
                  :disabled="!tool.name"
                  :default-value="tool.amount ? +tool.amount : 1"
                />
                <DeleteLogo
                  style="margin-bottom: 15px; min-width: 14px; cursor: pointer"
                  @click="deleteMeasureTool(index)"
                  class="component__margin-left"
                />
              </div>
            </div>
            <div
              class="block block__align-center component__margin-bottom"
              @click="addMeasureTool"
              style="cursor: pointer"
            >
              <AddLogo class="component__margin-right" />
              <div class="tech-proc__params_plus">
                {{ text.plus_measuring_tool[language] }}
              </div>
            </div>
          </div>
        </div>
        <div class="tech-proc__label">
          {{ text.processing_modes[language] }}
        </div>
        <div
          v-if="values.cutting_conditions && Array.isArray(values.cutting_conditions)"
          class="tech-proc__params-grid_container"
        >
          <div
            v-for="(item, index) in values.cutting_conditions"
            v-bind:key="`${item.name || index}`"
          >
            <div class="tech-proc__label">
              <span v-if="item.label">{{ item.label }}</span>
              <span class="tech-proc__index" v-if="item.index">{{ item.index }}</span>
              <span class="tech-proc__sublabel" v-if="item.dimensionality">
                ({{ item.dimensionality }})</span
              >
            </div>
            <Input
              @change="resetTimer(changeLine, +$event, item.name)"
              type="number"
              :default-value="item.value ? item.value : ''"
            />
          </div>
        </div>
        <div>
          <div class="tech-proc__label">
            {{ text.time_parameters[language] }}
          </div>
          <div class="tech-proc__params-grid_container">
            <div>
              <div class="tech-proc__label">
                <span>T</span>
                <span class="tech-proc__index">{{ text.time_to[language] }}</span>
                <span class="tech-proc__sublabel"> ({{ text.min[language] }})</span>
              </div>
              <Input
                @change="resetTimer(changeLine, +$event, 'to')"
                type="number"
                :default-value="values.to ? values.to : ''"
              />
            </div>
            <div>
              <div class="tech-proc__label">
                <span>T</span>
                <span class="tech-proc__index">{{ text.time_tv[language] }}</span>
                <span class="tech-proc__sublabel"> ({{ text.min[language] }})</span>
              </div>
              <Input
                @change="resetTimer(changeLine, +$event, 'tv')"
                type="number"
                :default-value="values.tv ? values.tv : ''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tech-proc__card" style="width: 100%">
      <div v-if="values.name" class="component__margin-bottom">
        <div class="tech-proc__card_param-title">
          {{ text.transition_name[language] }}
        </div>
        <div>
          <span class="tech-proc__card_label">{{ values.name }} </span>
        </div>
      </div>
      <div style="width: 100%" v-if="values.tools">
        <div v-for="(item, index) in values.tools" v-bind:key="`tool_type_${index}`">
          <div class="tech-proc__card_param-title">
            {{ item.name }}
          </div>
          <div style="width: 100%" v-for="(el, index) in item.data" v-bind:key="`tool_${index}`">
            <div class="tech-proc__card_item">
              <div class="component__margin-bottom">
                <div class="block">
                  <img :src="el.logo" v-if="el.logo" class="tech-proc__model_card_logo" />
                  <div>
                    <div class="tech-proc__model_card_label">
                      {{ text.manufacturer_tool[language] }}
                    </div>
                    <div class="tech-proc__model_card_value">
                      {{ el.manufacturer }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="block">
                <div class="tech-proc__model_card_icon" v-if="!el.img">
                  <AddTool v-if="item.type === 'fixture'" />
                  <MachineTool v-else-if="item.type === 'machine_tool'" />
                  <Tool v-else />
                </div>
                <img
                  :src="el.img"
                  v-if="el.img"
                  class="tech-proc__model_card_icon component__none-select"
                />
                <div>
                  <div class="component__margin-bottom">
                    <div class="tech-proc__model_card_label">
                      {{ text.sn[language] }}
                    </div>
                    <div class="tech-proc__model_card_value component__select">
                      {{ el.sn }}
                    </div>
                  </div>
                  <div class="component__margin-bottom">
                    <div class="tech-proc__model_card_label" v-if="item.type === 'machine_tool'">
                      {{ text.card_machine_model[language] }}
                    </div>
                    <div class="tech-proc__model_card_label" v-if="item.type !== 'machine_tool'">
                      {{ text.card_model[language] }}
                    </div>
                    <div class="tech-proc__model_card_value component__select">
                      {{ el.model }}
                    </div>
                  </div>
                  <div class="component__margin-bottom">
                    <div class="tech-proc__model_card_label" v-if="item.type === 'machine_tool'">
                      {{ text.type_mt[language] }}
                    </div>
                    <div class="tech-proc__model_card_label" v-if="item.type !== 'machine_tool'">
                      {{ text.type[language] }}
                    </div>
                    <div class="tech-proc__model_card_value component__select">
                      {{ el.type }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="values.cutting_conditions && Array.isArray(values.cutting_conditions)"
        class="tech-proc__params-grid_text-container"
      >
        <div
          class="component__margin-left component__margin-top"
          v-for="(item, index) in values.cutting_conditions"
          v-bind:key="`${item.name || index}`"
        >
          <div class="component__margin-bottom">
            {{ item.description }}
          </div>
          <div class="component__margin-bottom">
            <span class="tech-proc__card_label">{{ item.label }}</span>
            <span class="tech-proc__card_label">: </span>
            <span class="tech-proc__card_value">
              {{ item.value }}
            </span>
            <span class="tech-proc__card_label" v-if="item.dimensionality">
              {{ item.dimensionality }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/technological_processes.json";
import Input from "@/components/input/Input.vue";
import InputNumber from "@/components/inputNumber/InputNumber.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import Search from "@/components/search/Search.vue";
import AddLogo from "@/assets/images/tech-proc/add.svg";
import DeleteLogo from "@/assets/images/tech-proc/delete.svg";
import { query } from "@/utilities/axios";

export default {
  name: "Transition",
  data() {
    return {
      text,
      timer: null,
      tools: [],
      measure_tools: [],
      symbols: [
        "Ø",
        "˚",
        "≤",
        "±",
        "≥",
        "<",
        ">",
        "≠",
        "α",
        "β",
        "⟂",
        "↗",
        "⌰",
        "⏤",
        "⏥",
        "=",
        "◯",
        "⌭",
        "//",
        "◎",
        "⦟",
        "⌯",
        "⌒",
        "⌓",
        "⌖",
        "×",
      ],
    };
  },
  props: {
    edit: {
      type: Boolean,
    },
    values: {},
  },
  watch: {
    values: {
      immediate: true,
      handler: function (val) {
        this.setTools(val);
        this.setMeasureTools(val);
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    startTimer(callback, ...args) {
      this.timer = setTimeout(() => {
        if (typeof callback === "function") {
          callback(...args);
        }
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(callback, ...args) {
      this.stopTimer();
      this.startTimer(callback, ...args);
    },
    changeLine(value, name) {
      const data = {};
      data[name] = value;
      query(
        "post",
        "changeMPline",
        {
          uuid_mp_line: this.values.uuid,
          change_data: data,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update", this.values);
      });
    },
    setTools(values) {
      let result = [
        {
          id: 1,
          id_tool_catalog_type: null,
          name: "",
          uuid_tool: null,
          amount: 1,
        },
      ];
      if (values && values.tools) {
        const tools = values.tools.filter((el) => el.type === "cutting_tools");
        if (tools && tools[0] && tools[0].data) {
          result = tools[0].data.map((el, i) => {
            return {
              id: i + 1,
              id_tool_catalog_type: el.id_tool_catalog_type || null,
              name: el.type || "",
              uuid_tool: el.uuid_tool || null,
              amount: el.amount || 1,
            };
          });
        }
      }
      this.tools = result;
    },
    setMeasureTools() {
      const result = [
        {
          id: 1,
          id_tool_catalog_type: null,
          name: "",
          uuid_tool: null,
        },
      ];
      this.measure_tools = result;
    },
    addTool() {
      this.tools.push({
        id: this.tools.length + 1,
        id_tool_catalog_type: null,
        name: "",
        uuid_tool: null,
      });
    },
    addMeasureTool() {
      this.measure_tools.push({
        id: this.measure_tools.length + 1,
        id_tool_catalog_type: null,
        name: "",
        uuid_tool: null,
      });
    },
    saveTool(tool, index) {
      const tools = [...this.tools];
      tools[index] = {
        id: this.tools[index].id,
        uuid_tool: tool.uuid,
        id_tool_catalog_type: tool.id_tool_catalog_type,
        name: tool.name,
      };
      this.tools = tools;
      this.saveAllTools(this.tools);
    },
    saveMeasureTool(tool, index) {
      const tools = [...this.measure_tools];
      tools[index] = {
        id: this.measure_tools[index].id,
        uuid_tool: tool.uuid,
        id_tool_catalog_type: tool.id_tool_catalog_type,
        name: tool.name,
      };
      this.measure_tools = tools;
      // this.saveAllTools(this.tools);
    },
    setToolAmount(value, index) {
      const setValues = (value, index) => {
        this.tools[index] = {
          ...this.tools[index],
          ...{
            amount: value,
          },
        };
        this.saveAllTools(this.tools);
      };
      this.resetTimer(setValues, value, index);
    },
    setMeasureToolAmount(value, index) {
      const setValues = (value, index) => {
        this.measure_tools[index] = {
          ...this.measure_tools[index],
          ...{
            amount: value,
          },
        };
        // this.saveAllTools(this.measure_tools);
      };
      this.resetTimer(setValues, value, index);
    },
    deleteTool(index) {
      this.tools.splice(index, 1);
      this.saveAllTools(this.tools);
    },
    deleteMeasureTool(index) {
      this.measure_tools.splice(index, 1);
      // this.saveAllTools(this.measure_tools);
    },
    saveAllTools(tools) {
      const data = [];
      tools.forEach((el) => {
        if (el.uuid_tool && (el.id_tool_catalog_type || el.id_tool_catalog_type === 0)) {
          data.push({
            uuid_tool: el.uuid_tool,
            id_tool_catalog_type: el.id_tool_catalog_type,
            amount: el.amount ? el.amount : 1,
          });
        }
      });
      this.changeLine(data, "uuid_tools");
    },
  },
  components: {
    Input,
    InputNumber,
    Search,
    AddLogo,
    DeleteLogo,
    Textarea,
  },
};
</script>
