<template>
  <Modal
    @close="closeAddModal"
    :show="show"
    :title="text.add_tool_title[language]" >
    <template slot="body">
      <div style="height: 100%; padding: 10px" class="block block__column block__between">
        <Breadcrumbs
          @click="findToolNomenclatureType($event.tools || null)"
          :breadcrumbs="breadCrumbs"
          name="name"
        />
        <div v-if="pipelineType === 1" style="height: 100%; padding: 10px; overflow-y: auto">
          <div style="height: 100%">
            <div class="tools__add_type-container" v-if="nomenclatureTypes">
              <div
                @click="setType(type)"
                class="tools__add_type-card"
                v-for="type in nomenclatureTypes"
                v-bind:key="`type_${type.uuid}`"
              >
                <div class="block block__center">
                  <img v-if="type.img" :src="type.img" class="tools__add_image" />
                </div>
                <div class="tools__add_name">
                  {{ type.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="pipelineType === 2"
          class="block block__column block__between"
          style="height: 100%; padding: 10px"
        >
          <div style="height: 100%; overflow-y: auto">
            <div class="block">
              <Input
                v-if="resultType && resultType.name"
                :default-value="resultType.name"
                :label="text.tool_type[language]"
                style="width: 100%"
                disabled
              />
              <Search
                style="width: 100%"
                :position-type="'fixed'"
                :label="text.manufacturer[language]"
                class="component__margin-left"
                @change="saveAdd($event.value, 'id_manufacturer')"
                :query="{
                  method: 'post',
                  url: 'getSearchCompaniesExpert',
                  findName: 'find',
                  label: 'name',
                  value: 'id',
                }"
              />
            </div>
            <div class="block">
              <div style="width: 100%">
                <img
                  v-if="resultType && resultType.img"
                  style="max-height: 100px; width: auto; max-width: 300px; object-fit: scale-down"
                  :src="resultType.img"
                />
              </div>
              <Input
                style="width: 100%"
                @change="saveAdd($event, 'vendor_code')"
                :label="text.vendor_code[language]"
              />
            </div>
            <div class="block">
              <Textarea
                @change="saveAdd($event, 'comment')"
                :label="text.comment[language]"
                style="width: 100%"
              />
              <Input
                style="width: 100%"
                class="component__margin-left"
                @change="saveAdd($event, 'name')"
                :label="text.tool_name[language]"
              />
            </div>
            <div class="block" style="flex-wrap: wrap">
              <div
                v-for="item in nomenclatureTypeParameters"
                style="flex: 1 0 50%"
                v-bind:key="item.id"
              >
                <div class="block block__align-center block__between">
                  <div>{{ item.name_rus }} {{ item.iso302_01 }}</div>
                  <div class="block__flex">
                    <Input
                      v-if="item.i5_column_name"
                      type="number"
                      @change="saveAdd(+$event, item.i5_column_name)"
                      class="component__margin-right"
                    />
                    <div style="width: 30px">{{ item.dimension_rus }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 10px" class="block block__right">
            <Button type="default" @click="closeAddModal" :text="text.cancel[language]" />
            <Button
              type="primary"
              :text="text.save[language]"
              @click="addTool"
              style="margin-left: 10px"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import Input from "@/components/input/Input.vue";
import Search from "@/components/search/Search.vue";
import Button from "@/components/button/Button.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import text from "@/assets/text/tools.json";
import { query } from "@/utilities/axios";

export default {
  name: "Add",
  data() {
    return {
      text,
      pipelineType: 1,
      resultType: null,
      nomenclatureTypes: null,
      breadCrumbs: null,
      types: [
        {
          id: 1,
          label: {
            ru: "Каталожный",
            en: "From catalogue",
          },
        },
        {
          id: 2,
          label: {
            ru: "ГОСТ",
            en: "Standard",
          },
        },
      ],
      saveData: {},
      toolNomenclatures: null,
      nomenclatureTypeParameters: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.getToolNomenclatures();
        this.findToolNomenclatureType();
      }
    },
  },
  methods: {
    closeAddModal() {
      this.pipelineType = 1;
      this.nomenclatureTypes = null;
      this.saveData = {};
      this.toolNomenclatures = null;
      this.nomenclatureTypeParameters = null;
      this.$emit("close");
    },
    setTypesValues(lang) {
      const result = [];
      for (const type of this.types) {
        result.push({
          label: type.label[lang],
          id: type.id,
        });
      }
      return result;
    },
    addTool() {
      const queryData = {
        ...{
          id_tool_catalog_type: 1,
        },
        ...this.saveData,
      };
      query("put", "addNewTool", queryData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("update");
        this.closeAddModal();
      });
    },
    saveAdd(value, name) {
      this.saveData[name] = value;
    },
    getToolNomenclatures() {
      query(
        "post",
        "getToolNomenclatures",
        {
          page: 1,
          line_per_page: 100,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.toolNomenclatures = res.table;
      });
    },
    getToolNomenclatureTypeParameter(id) {
      query(
        "post",
        "getToolNomenclatureTypeParameter",
        {
          id: id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.nomenclatureTypeParameters = res;
      });
    },
    findToolNomenclatureType(value = null) {
      query(
        "post",
        "findToolNomenclatureType",
        {
          uuid: value,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.pipelineType = 1;
        this.nomenclatureTypes = res.tools;
        this.breadCrumbs = res.bread_crumbs;
      });
    },
    setType(type) {
      if (!type.final) {
        this.findToolNomenclatureType(type.uuid);
      } else {
        this.resultType = type;
        this.saveAdd(type.id, "id_tool_nomenclature_type");
        this.getToolNomenclatureTypeParameter(type.id);
        this.pipelineType = 2;
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  mounted() {},
  components: {
    Modal,
    Input,
    Search,
    Button,
    Textarea,
    Breadcrumbs,
  },
};
</script>
