<template>
  <div class="block block__right block__align-center" :style="{ marginTop: '20px' }">
    <div>
      <a-button @click="cancel" v-if="showCancel">
        {{ cancelText }}
      </a-button>
    </div>
    <div class="component__margin-left">
      <a-button type="primary" html-type="submit">
        {{ okText }}
      </a-button>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/modalFooter.json";

export default {
  name: "ModalFooter",
  data() {
    return {
      text,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    cancel: {
      type: Function,
      default: () => {},
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: [String, Number],
      default: function () {
        return this.language;
      },
    },
    okText: {
      type: [String, Number],
      default: function () {
        return text.ok[this.language];
      },
    },
  },
};
</script>
