<template>
  <div class="block__flex block__column calendar__container" v-if="!loading">
    <div class="calendar__header block__center">
      <div class="block__flex block__between calendar__title">
        <div>
          <span @click="setType('month')" class="calendar__month-name">
            {{ getMonthName(month, false) }}
          </span>
          <span @click="setType('year')" class="calendar__year-name">{{ year }}</span>
        </div>
        <div class="block__flex">
          <div @click="privYear()" class="calendar__arrow calendar__arrow_1">
            <Arrow1
              :fill="
                this.year > new Date().getFullYear() || !this.showDisabled
                  ? '#B92A21'
                  : 'rgba(60, 60, 67, 0.3)'
              "
            />
          </div>
          <div @click="privMonth()" class="calendar__arrow calendar__arrow_2">
            <Arrow2
              :fill="
                new Date(this.year, this.month - 1) > new Date() || !this.showDisabled
                  ? '#B92A21'
                  : 'rgba(60, 60, 67, 0.3)'
              "
            />
          </div>
          <!--<div @click="today()" style="width: 6px">
            <Arrow2 />
          </div>-->
          <div @click="nextMonth()" class="calendar__arrow calendar__arrow_3">
            <Arrow3 />
          </div>
          <div @click="nextYear()" class="calendar__arrow calendar__arrow_4">
            <Arrow4 />
          </div>
        </div>
      </div>
    </div>
    <div class="block__flex calendar__week-days" v-if="dateType === 'day'">
      <div
        v-for="(day, index) in days"
        v-bind:key="`${index}_day`"
        :class="`block__column calendar__week-day ${day > 5 ? 'calendar__week-day_weekend' : ''}`"
      >
        {{ getWeekDayName(day, true) }}
      </div>
    </div>
    <div class="block__column calendar__calendar-body" v-if="dateType === 'day'">
      <div
        v-for="(week, index) in weeks"
        v-bind:key="`${index}_week`"
        class="block__flex calendar__week-row"
      >
        <div v-for="(day, index) in days" v-bind:key="`${index}_day`">
          <Cell
            :value="getCalendarCellDay(week, day)"
            :active="setActive(day, week)"
            :month="month"
            :year="year"
            :show-disabled="showDisabled"
            :type="'day'"
            :fromStart="firstDayOfYear"
            :fromEnd="String(moment(new Date()).format('DD.MM.YYYY'))"
            :week="week"
            :day="day"
            @selectDay="selectDay($event.week, $event.day)"
          />
        </div>
      </div>
    </div>
    <div v-if="dateType === 'year'">
      <div class="calendar__years-wrapper">
        <div v-for="(y, index) in years" v-bind:key="`${index}_year`">
          <Cell
            :value="y"
            :show-disabled="showDisabled"
            :month="month"
            :year="year"
            :fromStart="String(new Date().getFullYear() - 1)"
            :fromEnd="years[years.length - 1]"
            :active="y === String(year)"
            :type="'year'"
            @selectYear="setYear($event)"
          />
        </div>
      </div>
    </div>
    <div v-if="dateType === 'month'">
      <div class="calendar__months-wrapper">
        <div v-for="(m, index) in shortMonths" v-bind:key="`${index}_month`">
          <Cell
            :value="m"
            :show-disabled="showDisabled"
            :month="month"
            :year="year"
            :fromStart="shortMonths[0]"
            :fromEnd="shortMonths[+new Date().getMonth() - 1]"
            :active="setActiveMonth(index + 1, year)"
            :type="'month'"
            @selectMonth="setMonth($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

const Arrow1 = () => import("@/assets/images/calendar/CalendarArrows1.svg");
const Arrow2 = () => import("@/assets/images/calendar/CalendarArrow2.svg");
const Arrow3 = () => import("@/assets/images/calendar/CalendarArrow3.svg");
const Arrow4 = () => import("@/assets/images/calendar/CalendarArrow4.svg");
const Cell = () => import("./Cell.vue");
// const Ellipse = () => import('@/assets/pictures/calendar/Ellipse_2.svg');

export default {
  name: "Calendar",
  data() {
    return {
      unsubscribe: () => {},
      activeDate: {
        value: "",
        day: null,
        week: null,
        month: null,
        year: null,
      },
      disabledFrom: {},
      yearDisabledFrom: {},
      loading: true,
      moment,
      calendar: null,
      localeData: null,
      intlWeekdays: null,
      dateType: "day",
      month: 9,
      year: 2000,
      day: 1,
      days: [1, 2, 3, 4, 5, 6, 7],
      daysNamesShort: [],
      firstDayOfWeek: null,
      firstDayOfYear: null,
      daysNames: [],
      weeks: [1, 2, 3, 4, 5, 6],
      months: [],
      shortMonths: [],
      years: [],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    defaultDate: {
      type: String,
    },
    showDisabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    defaultDate: function () {
      this.startCallendar();
    },
  },
  methods: {
    setType(type) {
      if (type === "month" && this.dateType === "month") {
        this.dateType = "day";
      } else if (type === "year" && this.dateType === "year") {
        this.dateType = "day";
      } else {
        this.dateType = type;
      }
    },
    setActive(day, week, value) {
      let result = false;
      if (
        this.activeDate.day &&
        this.activeDate.week &&
        this.activeDate.month &&
        this.activeDate.year
      ) {
        if (
          day === this.activeDate.day &&
          week === this.activeDate.week &&
          this.month === this.activeDate.month &&
          this.year === this.activeDate.year
        ) {
          result = true;
        }
      }
      this.day = value;
      return result;
    },
    getMonthName(month, short) {
      let result = "";
      if (short) {
        result = this.shortMonths[month - 1];
      } else {
        result = this.months[month - 1];
      }
      return result;
    },
    setDisabledDay(day, month, year) {
      let result = false;
      if (
        moment(new Date(year, month - 1, day)).format("DD.MM.YYYY") <
        moment(new Date()).format("DD.MM.YYYY")
      ) {
        if (!this.disabledFrom.start) {
          this.disabledFrom.start = `${day}.${month}.${year}`;
        }
        if (!this.disabledFrom.end) {
          this.disabledFrom.end = `${day}.${month}.${year}`;
        }
        if (moment(this.disabledFrom.start).format("D.M.YYYY") > moment(day).format("D.M.YYYY")) {
          this.disabledFrom.start = `${day}.${month}.${year}`;
        }
        if (moment(this.disabledFrom.start).format("D.M.YYYY") < moment(day).format("D.M.YYYY")) {
          this.disabledFrom.end = `${day}.${month}.${year}`;
        }
        result = true;
      }
      return result;
    },
    setActiveMonth(value, year) {
      if (year === this.year && value === this.month) {
        return true;
      } else {
        return false;
      }
    },
    setDisabledYear(year) {
      let result = false;
      const y = Number(year);
      if (y < new Date().getFullYear()) {
        if (!this.yearDisabledFrom.start) {
          this.yearDisabledFrom.start = year;
        }
        if (!this.yearDisabledFrom.end) {
          this.yearDisabledFrom.end = year;
        }
        if (Number(this.yearDisabledFrom.end) > y) {
          this.yearDisabledFrom.end = year;
        }
        if (Number(this.yearDisabledFrom.start) < y) {
          this.yearDisabledFrom.start = year;
        }
        result = true;
      }
      return result;
    },
    getWeekDayName(weekDay, short) {
      let result = "";
      if (short) {
        result = this.daysNamesShort[weekDay - 1];
      } else {
        result = this.daysNames[weekDay - 1];
      }
      return result;
    },
    privYear() {
      if (this.year > new Date().getFullYear() || !this.showDisabled) {
        this.year--;
        if (this.year === new Date().getFullYear()) {
          this.month = new Date().getMonth() + 1;
        }
        this.years = [];
        for (let item = 0; item < 9; item++) {
          const year = String(+this.year + 4 - item);
          this.years.push(year);
        }
      }
    },
    privMonth() {
      if (new Date(this.year, this.month - 1) > new Date() || !this.showDisabled) {
        if (this.month === 1) {
          this.month = 12;
          this.privYear();
        } else {
          this.month--;
        }
      }
    },
    nextYear() {
      this.year++;
      this.years = [];
      for (let item = 0; item < 9; item++) {
        const year = String(+this.year + 4 - item);
        this.years.push(year);
      }
    },
    today() {
      const today = new Date();
      this.year = today.getFullYear();
      this.month = today.getMonth() + 1;
    },
    nextMonth() {
      if (this.month === 12) {
        this.month = 1;
        this.nextYear();
      } else {
        this.month++;
      }
    },
    getCalendarCellDay(week, day) {
      const daysInMonth = 32 - new Date(this.year, this.month - 1, 32).getDate() + 1;
      const firstWeekDay = new Date(this.year, this.month - 1, 1).getUTCDay();
      // первый день недели в месяце, нумерация с "0"
      const dayNum = (week - 1) * 7 + day - firstWeekDay;
      return dayNum < daysInMonth && dayNum > 0 ? dayNum : null;
    },
    zeroFill(num, len) {
      let res = String(num);
      for (let i = 0; i < len - String(num).length; i++) {
        res = "0" + res;
      }
      return res;
    },
    selectDay(week, day) {
      const date =
        this.zeroFill(this.getCalendarCellDay(week, day), 2) +
        "." +
        this.zeroFill(this.month, 2) +
        "." +
        String(this.year);
      if (!this.disable) {
        this.activeDate.value = date;
        this.activeDate.day = day;
        this.activeDate.week = week;
        this.activeDate.month = this.month;
        this.activeDate.year = this.year;
      }
      this.$emit("setDate", this.activeDate.value);
      this.dropDownOpen = false;
    },
    setYear(year) {
      this.year = year;
      if (this.year === new Date().getFullYear()) {
        this.month = new Date().getMonth() + 1;
      }
    },
    setMonth(month) {
      this.month = month;
      this.dateType = "day";
    },
    onStart() {
      this.loading = true;
      this.calendar.locale(this.$store.state.index.language);
      this.localeData = this.calendar.localeData();
      this.firstDayOfWeek = this.localeData.firstDayOfWeek();
      this.months = this.calendar.months();
      this.daysNamesShort = this.calendar.weekdaysShort(true);
      this.daysNames = this.calendar.weekdays(true);
      this.shortMonths = this.calendar.monthsShort();
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    setFirstDayOfYear() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), 1, 1);
      this.firstDayOfYear = moment(firstDay).format("DD.MM.YYYY");
    },
    setDefaultDate() {
      if (this.defaultDate) {
        const splitDate = this.defaultDate.split(".");
        // const daysInMonth = 32 - new Date(this.year, this.month - 1, 32).getDate() + 1;
        const firstWeekDay = new Date(this.year, this.month - 1, 1).getUTCDay();
        this.activeDate.value = this.defaultDate;
        this.activeDate.week = Math.ceil((+splitDate[0] + firstWeekDay) / 7);
        this.activeDate.day = +splitDate[0] + firstWeekDay - (this.activeDate.week - 1) * 7;
        this.activeDate.month = +splitDate[1];
        this.activeDate.year = +splitDate[2];
      }
    },
    startCallendar() {
      this.setFirstDayOfYear();
      this.calendar = moment;
      this.onStart();
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "index/setLanguage") {
          this.onStart();
        }
      });
      let today = new Date();
      if (this.defaultDate) {
        const splitDate = this.defaultDate.split(".");
        today = new Date(+splitDate[2], +splitDate[1] - 1, +splitDate[0]);
      }
      this.year = today.getFullYear();
      for (let item = 0; item < 9; item++) {
        const year = String(+this.year + 4 - item);
        this.years.push(year);
      }
      this.month = today.getMonth() + 1;
      this.day = today.getUTCDay();
      this.setDefaultDate();
    },
  },
  mounted() {
    this.startCallendar();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    Arrow1,
    Arrow2,
    Arrow3,
    Arrow4,
    Cell,
    // Ellipse,
  },
};
</script>
