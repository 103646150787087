<template>
  <div class="order__wrapper">
    <KpModal
      :show="showKpModal"
      :uuid="offerData.uuid_order"
      :company-id="row.id_manufacturer"
      @close="setShowKpModal(false)"
    />
    <div v-if="offerData" style="width: 100%">
      <div class="page__edit_large-title component__margin-bottom">
        {{ text.order_offer[language] }}
      </div>
      <div class="page__edit_status component__margin-bottom" v-if="offerData.status">
        {{ offerData.status }}
      </div>
      <Button
        block
        @click="setShowKpModal(true)"
        :text="text.view_kp[language]"
        class="component__margin-bottom"
      />
      <a
        v-if="offerData.uuid_order && offerData.total_price"
        class="component__margin-bottom"
        :href="`https://api.i5.solutions/getCommercialOfferPDF?t=${token}&uuid=${offerData.uuid_order}&id=${row.id_manufacturer}`"
        download
      >
        <Button :text="orderText.save_pdf[language]" block />
      </a>
      <div class="component__margin-bottom" v-if="row.company_name">
        <div class="page__edit_value">
          {{ text.manufacturer[language] }}
        </div>
        <div class="page__edit_title">
          {{ row.company_name }}
        </div>
      </div>
      <div class="component__margin-bottom" v-if="offerData.total_price">
        <div class="page__edit_value">
          {{ text.total_price[language] }}
        </div>
        <div class="page__edit_title">
          {{ getMoney(offerData.total_price, "RUB", "ru") }}
        </div>
      </div>
      <div class="component__margin-bottom" v-if="row.deadline">
        <div class="page__edit_value">
          {{ text.delivery_time[language] }}
        </div>
        <div class="page__edit_title">
          {{ row.deadline }}
        </div>
      </div>
      <div
        class="component__margin-bottom"
        v-if="offerData.parts.filter((el) => el.cant_produce === true).length > 0"
      >
        <div class="page__edit_value">
          {{ text.cant_produce[language] }}
        </div>
        <div
          v-bind:key="`cant_produce_${index}`"
          v-for="(item, index) in offerData.parts.filter((el) => el.cant_produce === true)"
        >
          <div class="page__edit_title">{{ item.code }} {{ item.name }}</div>
        </div>
      </div>
      <div
        class="component__margin-bottom"
        v-if="offerData.parts.filter((el) => el.comment).length > 0"
      >
        <div class="page__edit_value">
          {{ text.parts_with_comment[language] }}
        </div>
        <div
          v-bind:key="`comment_${index}`"
          v-for="(item, index) in offerData.parts.filter((el) => el.comment)"
        >
          <div class="page__edit_title">{{ item.code }} {{ item.name }}</div>
          <div>{{ item.comment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/order_offers.json";
import orderText from "@/assets/text/order.json";
import { query } from "@/utilities/axios";
import Button from "@/components/button/Button.vue";
import { getMoney } from "@/utilities/functions";
import KpModal from "../kpModal/KpModal.vue";

export default {
  name: "OrderOfferEdit",
  data() {
    return {
      text,
      orderText,
      getMoney,
      offerData: null,
      showKpModal: false,
    };
  },
  props: {
    row: Object,
  },
  watch: {
    row: {
      deep: true,
      handler: function () {
        this.getKPInfo();
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    getKPInfo() {
      query(
        "post",
        "getKPInfobyOrder",
        {
          uuid: this.$route.params.id,
          id_manufacturer: this.row.id_manufacturer ? this.row.id_manufacturer : 16,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.offerData = result;
        console.log(result);
      });
    },
    setShowKpModal(val) {
      this.showKpModal = val;
    },
  },
  mounted() {
    this.getKPInfo();
  },
  components: {
    Button,
    KpModal,
  },
};
</script>
