<template>
  <div :class="`${hideHeader ? 'page__wrapper_hide' : 'page__wrapper'}`">
    <PageHeader
      v-if="!hideHeader"
      :name="pageName || ''"
      :buttons="buttons"
      @click="pageHeaderClick($event)"
    />
    <div
      @mousemove="onMouseMove"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mouseleave="onMouseUp"
      class="block page__container"
    >
      <TableFilter
        v-if="showFilter"
        :style="setFilterStyle()"
        @setFilter="setFilter($event)"
        :filters="filters"
      >
        <template slot="edit">
          <slot name="filter" :filters="filters" :idName="idName" :width="filterWidth" />
        </template>
      </TableFilter>
      <Delimiter
        v-if="showFilter"
        :move="moveFilterDelimiter"
        id="delimiter_filter"
        :style="setDelimiterFilterStyle()"
        name="delimiter_filter"
      />
      <div class="page__table_wrapper">
        <div class="page-with-cards__wrapper">
          <div
            style="height: 100%; padding-left: 10px; width: 29px"
            class="block__flex block__column block__center"
          >
            <div v-if="showLeftArrow" @click="goToPrevPage" style="cursor: pointer">
              <ArrowLeft />
            </div>
          </div>
          <div class="page-with-cards__container">
            <div v-if="selectedCard" style="width: 100%; padding: 0 10px">
              <Card
                :id-name="idName"
                :values="selectedCard"
                :parameters="card"
                :checked="true"
                @check="toggleCardSelection($event)"
              />
            </div>
            <div
              style="width: 100%; padding: 0 10px"
              v-for="item in values"
              v-bind:key="`card_${item[idName]}`"
            >
              <Card
                :id-name="idName"
                :values="item"
                @check="toggleCardSelection($event)"
                :parameters="card"
                :checked="isSelected(item)"
              />
            </div>
          </div>
          <div
            style="height: 100%; padding-right: 10px; width: 29px"
            class="block__flex block__column block__center"
          >
            <div v-if="showRightArrow" @click="goToNextPage" style="cursor: pointer">
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import TableFilter from "@/components/filter/TableFilter.vue";
import Delimiter from "@/components/delimiter/Delimiter.vue";
import ArrowLeft from "@/assets/images/i5/arrowLeft.svg";
import ArrowRight from "@/assets/images/i5/arrowRight.svg";
import { query as q } from "@/utilities/axios";
import Card from "./components/card/Card.vue";

export default {
  name: "PageWithCards",
  data() {
    return {
      filterWidth: 365,
      delimiterLeft: 365,
      externalFilters: null,
      filters: null,
      moveFilterDelimiter: false,
      values: null,
      totalValues: null,
      total: null,
      totalPages: null,
      activePage: 1,
      selectedCard: null,
    };
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    pageName: {
      type: String,
      default: "",
    },
    buttons: {
      type: Array,
    },
    showFilter: {
      type: Boolean,
    },
    idName: {
      type: String,
    },
    query: {
      type: Object,
      default: () => null,
    },
    card: {
      type: Object,
    },
  },
  computed: {
    showLeftArrow() {
      return this.activePage > 1;
    },
    showRightArrow() {
      return this.activePage < this.totalPages;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    pageHeaderClick(type) {
      this.$emit("headerClick", type);
    },
    setFilterStyle() {
      const result = {
        width: `${this.filterWidth}px`,
        minWidth: `${this.filterWidth}px`,
      };
      return result;
    },
    setFilter(data) {
      this.externalFilters = data;
      this.$nextTick(() => {
        this.cardsUpdate();
      });
    },
    cardsUpdate() {
      this.getData(this.activePage, this.selectedCard ? 2 : 3);
    },
    setDelimiterFilterStyle() {
      const result = {
        left: `${this.delimiterLeft - 2.5}px`,
      };
      return result;
    },
    onMouseMove(e) {
      if (this.moveFilterDelimiter) {
        const left = this.setDelimiterLeft(e.clientX - 55);
        this.delimiterLeft = left;
      }
    },
    setDelimiterLeft(left) {
      let result = left;
      if (left > (window.innerWidth - 55) / 3) {
        result = (window.innerWidth - 55) / 3;
      } else if (left < 55) {
        result = 55;
      }
      return result;
    },
    onMouseDown(e) {
      if (e.target.id === "delimiter_filter") {
        this.moveFilterDelimiter = true;
      }
    },
    onMouseUp(e) {
      if (this.moveFilterDelimiter) {
        this.moveFilterDelimiter = false;
        const left = this.setDelimiterLeft(e.clientX - 55);
        this.delimiterLeft = left;
        this.filterWidth = left;
      }
    },
    getData(page = 1, linePerPage = 3) {
      return new Promise((resolve) => {
        if (this.query.method && this.query.url) {
          const queryData = {
            page: page,
            line_per_page: linePerPage,
          };
          if (this.externalFilters) {
            queryData.filters = this.externalFilters;
          }
          const headers = {};
          const token = localStorage.getItem("token");
          if (token) {
            headers.token = token;
          }
          q(
            this.query.method,
            this.query.url,
            {
              ...this.query.data,
              ...queryData,
            },
            {
              ...this.query.headers,
              ...headers,
            },
          ).then((data) => {
            this.activePage = page;
            this.values = data.table;
            if (data.filters) {
              this.filters = data.filters;
            }
            this.totalValues = data.table_bottom ?? [
              {
                label: "Total machine tools:",
                count: data.total_lines,
                measure: "pcs.",
              },
            ];
            this.total = data.total_lines;
            this.totalPages = data.total_pages;
            resolve(data);
          });
        }
      });
    },
    async goToPrevPage() {
      if (this.activePage > 1) {
        await this.getData(this.activePage - 1, this.selectedCard ? 2 : 3);
      }
    },
    async goToNextPage() {
      if (this.activePage < this.totalPages) {
        await this.getData(this.activePage + 1, this.selectedCard ? 2 : 3);
      }
    },
    isSelected(card) {
      let result = false;
      if (
        this.selectedCard &&
        this.selectedCard[this.idName] &&
        card[this.idName] &&
        this.selectedCard[this.idName] === card[this.idName]
      ) {
        result = true;
      }
      return result;
    },
    toggleCardSelection(checkData) {
      if (checkData.isCheck) {
        this.getData(this.activePage, 2).then(() => {
          this.selectedCard = checkData.values;
        });
      } else {
        this.getData(this.activePage, 3).then(() => {
          this.selectedCard = null;
        });
      }
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    PageHeader,
    TableFilter,
    Delimiter,
    ArrowRight,
    ArrowLeft,
    Card,
  },
};
</script>
