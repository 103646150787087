const state = () => ({
  data: null,
  part: null,
});

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  delData(state) {
    state.data = null;
  },
  setPart(state, data) {
    state.part = data;
  },
  delPart(state) {
    state.part = null;
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
