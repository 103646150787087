<template>
  <div>
    <a-button @click="addPart" type="primary">Добавить деталь</a-button>
    <a-tabs
      :default-active-key="parts.length"
      tab-position="left"
      :style="{
        height: '500px',
        padding: '8px !important',
      }"
    >
      <a-tab-pane v-for="(item, index) in parts" :key="index + 1" :tab="item.name">
        <div style="overflow-y: auto">
          <a-button @click="choosePart(index)">Выбрать</a-button>
          <a-button class="component__margin-left" @click="createPart(index)">Создать</a-button>
          <div v-if="item.choose">
            <div v-for="part in createdParts" v-bind:key="part.uuid" class="component__margin">
              <div class="parts__add_file">{{ part.code }} {{ part.name }}</div>
            </div>
          </div>
          <div v-if="item.create">
            <AddPart />
          </div>
          <a-collapse accordion>
            <a-collapse-panel key="1" header="Дополнительные параметры">
              <div class="block">
                <div style="width: 100%">
                  <Input label="Материал" name="material" />
                </div>
                <div style="width: 100%" class="component__margin-left">
                  <Input label="Габариты" name="size" />
                </div>
              </div>
              <div class="block">
                <div style="width: 50%">
                  <Input label="Масса" name="mass" />
                </div>
              </div>
              <div class="block">
                <div>
                  <a-checkbox />
                </div>
                <div class="component__margin-left">
                  Материал заготовки предоставляется заказчиком
                </div>
              </div>
              <div class="block">
                <div>
                  <a-checkbox />
                </div>
                <div class="component__margin-left">Согласен с проверкой на стандартность</div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";
import AddPart from "@/pages/manufacture/parts/components/add/Add.vue";
import { query } from "@/utilities/axios";

export default {
  name: "OrdersAdd",
  data() {
    return {
      parts: [],
      createdParts: [],
    };
  },
  methods: {
    addPart() {
      this.parts.push({
        name: "Деталь",
      });
    },
    choosePart(index) {
      const result = this.parts.slice(0);
      result[index] = {
        ...result[index],
        ...{
          choose: true,
          create: false,
        },
      };
      this.parts = result;
    },
    createPart(index) {
      const result = this.parts.slice(0);
      result[index] = {
        ...result[index],
        ...{
          choose: false,
          create: true,
        },
      };
      this.parts = result;
    },
    getParts() {
      query(
        "post",
        "getPartsByCompanyV2",
        {
          filters: {},
          line_per_page: 10,
          page: 1,
          sorter: {},
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((parts) => {
        this.createdParts = parts.table;
      });
    },
  },
  created() {
    this.getParts();
  },
  components: {
    Input,
    AddPart,
  },
};
</script>
