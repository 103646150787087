<template>
  <div class="form__container">
    <!--<div class="form__header">
      <span class="form__header_title">
        <span v-for="name in formName" v-bind:key="`fn_${name}`">
        {{row[name]}}
      </span>
      </span>
      <div class="block__flex block__right">
        <IconButton
          class="component__margin-left"
          :text="page.copy[language]"
          placement="bottom"
          iconName="copy"/>
        <IconButton
          class="component__margin-left"
          @click="handleHeaderClick('delete')"
          :text="page.delete[language]"
          placement="bottom"
          iconName="delete"/>
        <div class="form__header_delimiter"></div>
        <IconButton
          @click="handleHeaderClick('close')"
          class="component__margin-left"
          :text="page.close[language]"
          placement="bottom"
          iconName="close"/>
      </div>
    </div> -->
    <div class="form__body">
      <slot name="edit" />
    </div>
  </div>
</template>

<script>
import page from "@/assets/text/page.json";

// const IconButton = () => import('@/components/iconButton/IconButton.vue');

export default {
  name: "Form",
  data() {
    return {
      page,
    };
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    formName: {
      type: Array,
      default: () => ["id"],
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleSubmit(evt) {
      evt.preventDefault();
      console.log(evt);
    },
    handleHeaderClick(type) {
      this.$emit("headerClick", type);
    },
  },
  components: {
    // IconButton,
  },
};
</script>
