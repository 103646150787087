<template>
  <div style="height: inherit">
    <AddModal
      v-if="showAddModal"
      @update="tableUpdate()"
      :show="showAddModal"
      @close="setAddModal(false)"
    />
    <PageConstructor
      show-filter
      :query="query"
      :columns="columns"
      id-name="id"
      @headerClick="headerClick($event)"
      @setUpdate="setUpdate($event)"
      :buttons="buttons"
      :page-name="text.page_name[language]"
    >
      <template slot="edit" slot-scope="{ row }">
        <Edit :row="row" @update="tableUpdate()" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/all_organizations.json";
import Edit from "./components/edit/Edit.vue";
import AddModal from "./components/addModal/AddModal.vue";

export default {
  name: "AllOrganizations",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getCompaniesAdminV2",
        headers: {},
        data: {},
      },
      tableUpdate: null,
      showAddModal: false,
      buttons: [
        {
          type: "main",
          text: text.add_button[this.$store.state.index.language],
        },
      ],
      columns: [
        {
          name: "id",
          label: text.id,
          options: {
            bold: true,
            sort: true,
            copy: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "logo",
          label: {
            ru: "",
            en: "",
          },
          type: "image",
          options: {},
          basis: 80,
          width: 80,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
            copy: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "inn",
          label: text.tin,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "email",
          label: text.email,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "receive_orders",
          label: text.get_orders,
          type: "status",
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "balance",
          label: text.balance,
          type: "money",
          options: {
            sort: true,
            right: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "license_date_limit",
          label: text.license_date_limit,
          type: "date",
          options: {
            sort: true,
            right: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "mt_on_manufacturer",
          label: text.mt_amount,
          options: {
            sort: true,
            right: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "success_orders",
          label: text.success_orders,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.setAddModal(true);
      }
    },
    setAddModal(val) {
      this.showAddModal = val;
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
  },
  components: {
    PageConstructor,
    Edit,
    AddModal,
  },
};
</script>
