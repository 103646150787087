<template>
  <button
    @click="handleClick"
    :type="htmlType"
    :class="`button__wrapper ${setSize()} ${setType()} ${setDisable()} ${setBlock()}`"
  >
    <span v-if="contentType === 'text'">{{ text }}</span>
    <div v-if="icon === 'calculator'">
      <Calc :class="`button__icon button__icon_${type}`" />
    </div>
  </button>
</template>

<script>
import Calc from "@/assets/images/button/Calc.svg";
// const Calc = () => import('@/assets/images/button/Calc.svg');

export default {
  name: "Button",
  data() {
    return {};
  },
  props: {
    text: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "default", // attention, whiteboard
    },
    size: {
      type: String,
      default: "medium", // smallest
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    contentType: {
      type: String,
      default: "text", // text, icon
    },
    htmlType: {
      type: String,
    },
    icon: {
      type: String, // calculator,
    },
  },
  methods: {
    setSize() {
      return `button__wrapper_${this.size}`;
    },
    setType() {
      return `button__wrapper_${this.type}`;
    },
    setDisable() {
      return this.disabled ? "button__wrapper_disabled" : "";
    },
    setBlock() {
      return this.block ? "button__wrapper_block" : "";
    },
    handleClick() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
  components: {
    Calc,
  },
};
</script>
