<template>
  <div style="height: inherit">
    <Modal
      name="addMachineTool"
      @cancel="closeAddModal"
      @ok="addShop"
      :show="showAddModal"
      :title="text.add_shop_title[language]"
    >
      <template slot="body">
        <Input
          :label="text.name[language]"
          :error="nameError"
          style="width: 100%"
          required
          @change="saveAdd($event, 'name')"
        />
        <Select
          :values="shopTypes"
          :error="shopTypesError"
          @change="saveAdd($event.value, 'shop_type')"
          required
          :label="text.shop_type[language]"
          :dropdown-props="{
            label: 'name',
            value: 'id',
          }"
          :position-type="'fixed'"
        />
        <Select
          :values="regions"
          :error="regionsError"
          @change="saveAdd($event.value, 'region')"
          required
          :label="text.place[language]"
          :dropdown-props="{
            label: 'name',
            value: 'id',
          }"
          :position-type="'fixed'"
        />
      </template>
    </Modal>
    <PageConstructor
      :buttons="buttons"
      :query="query"
      :columns="columns"
      @setUpdate="setUpdate($event)"
      id-name="id"
      @headerClick="headerClick($event)"
      :page-name="text.page_name[language]"
    >
      <template slot="edit" slot-scope="{ row }">
        <Edit :row="row" @update="updateTable" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/workshops.json";
import Modal from "@/components/modal/Modal.vue";
import Input from "@/components/input/Input.vue";
import Select from "@/components/select/Select.vue";
import { query } from "@/utilities/axios";
import Edit from "./components/edit/EditWorkshop.vue";

export default {
  name: "Workshops",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getCurrentCompanyWorkShopsV2",
        headers: {},
        data: {},
      },
      shopTypes: [],
      regions: [],
      showAddModal: false,
      nameError: null,
      shopName: null,
      tableUpdate: () => {},
      shopTypesError: null,
      shopType: null,
      regionsError: null,
      region: null,
      buttons: [
        {
          type: "main",
          text: text.add_shop[this.$store.state.index.language],
        },
      ],
      columns: [
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "address",
          label: text.place,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "shop_type",
          label: text.shop_type,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "machine_tools_count",
          label: text.mt_amount,
          options: {
            sort: true,
          },
          basis: 180,
          width: 180,
        },
        {
          name: "tools_count",
          label: text.tools_amount,
          options: {
            sort: true,
          },
          basis: 180,
          width: 180,
        },
        {
          name: "measure_tools_amount",
          label: text.measure_tools_amount,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "fixture_amount",
          label: text.fixture_amount,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "blanks_count",
          label: text.blanks_count,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.openAddModal();
      }
    },
    updateTable() {
      this.tableUpdate();
    },
    openAddModal() {
      if (this.shopTypes.length === 0 || this.regions.length) {
        this.getShopTypes();
        this.getRegions();
      }
      this.showAddModal = true;
    },
    getShopTypes() {
      query(
        "get",
        "getShopTypes",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((types) => {
        this.shopTypes = types;
      });
    },
    getRegions() {
      query(
        "get",
        "getRegions",
        {},
        {
          token: localStorage.getItem("token"),
          id_address_country: 1,
          lang: "rus",
        },
      ).then((regions) => {
        this.regions = regions;
      });
    },
    closeAddModal() {
      this.showAddModal = false;
    },
    addShop() {
      query(
        "put",
        "addShop",
        {
          id_region: this.region,
          id_shop_type: this.shopType,
          shop_name: this.shopName,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.closeAddModal();
        this.updateTable();
      });
    },
    saveAdd(value, name) {
      if (name === "name") {
        this.nameError = null;
        this.shopName = value;
      } else if (name === "shop_type") {
        this.shopTypesError = null;
        this.shopType = value;
      } else if (name === "region") {
        this.regionsError = null;
        this.region = value;
      }
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
  },
  components: {
    PageConstructor,
    Modal,
    Input,
    Select,
    Edit,
  },
};
</script>
