<template>
  <div style="height: calc(100% - 50px)">
    <FullSize
      :type="fsType"
      :link="fsLink"
      :show="fsShow"
      :preview-link="fsPreviewLink"
      :title="fsFileName"
      @close="closeFullSize"
    />
    <AddFiles
      @close="closeAddFileModal"
      :title="partText.add_files_modal[language]"
      :files="files"
      :part-uuid="partUuid"
      @deleteFile="updateWhenDelete"
      :show="showAddFileModal"
    ></AddFiles>
    <div class="edit-part__header">
      <div class="edit-part__header_title">
        <span>{{ code_order }} {{ name_order }}</span>
        <span class="edit-part__header_subtitle">({{ code }} {{ name }})</span>
      </div>
      <transition name="component">
        <div class="component__margin-left block__flex block__align-center" v-if="saved">
          <Save />
          <div class="component__margin-left edit-part__header_saved">
            {{ partText.saved[language] }}
          </div>
        </div>
      </transition>
    </div>
    <transition name="component">
      <div class="edit-part__wrapper" v-if="loading">
        <div class="edit-part__params">
          <div class="block">
            <div style="width: 100%; padding-right: 10px">
              <Input
                :ti="1"
                :label="partText.code_order[language]"
                :default-value="code_order"
                :required="true"
                @change="save('code_order', $event, partUuid)"
                name="code"
              />
              <Input
                :label="partText.name_order[language]"
                :ti="2"
                :required="true"
                :default-value="name_order"
                @change="save('name_order', $event, partUuid)"
                name="name"
              />
            </div>
            <div style="width: 100%; padding-left: 10px">
              <Input
                :label="partText.code_inner[language]"
                disabled
                :default-value="code"
                name="code"
              />
              <Input
                :label="partText.name_inner[language]"
                disabled
                :default-value="name"
                name="name"
              />
            </div>
          </div>
          <div class="edit-part__delimiter"></div>
          <div class="block">
            <div style="width: 100%; padding-right: 10px">
              <div class="block">
                <div style="width: 100%; padding-right: 10px">
                  <InputNumber
                    :label="partText.amount[language]"
                    :required="true"
                    :max="999999"
                    :ti="3"
                    :min="1"
                    :step="1"
                    @change="save('amount', +$event, partUuid)"
                    :default-value="+amount || null"
                    name="amount"
                  />
                </div>
                <div style="width: 100%; padding-left: 10px">
                  <Input
                    :label="partText.weight[language]"
                    :ti="4"
                    :step="1"
                    type="number"
                    :text-position="'center'"
                    :default-value="weight"
                    :sub-label="partText.weight_unit[language]"
                    @change="save('weight', +$event, partUuid)"
                    name="weight"
                  />
                </div>
              </div>
              <Search
                :label="partText.material[language]"
                :query="{
                  method: 'post',
                  url: 'getMaterialsAsArray',
                  label: 'name',
                  value: 'id',
                }"
                :ti="8"
                :required="true"
                :default-value="material"
                clearable
                @clear="save('id_material', null, partUuid)"
                @change="save('id_material', $event.value, partUuid)"
                name="material"
              />
              <Search
                :label="partText.blank[language]"
                :default-value="blankType"
                :ti="10"
                :query="{
                  method: 'post',
                  url: 'getSearchBlanks',
                  label: 'blank_type',
                  value: 'id',
                }"
                clearable
                @clear="save('id_blank_type', null, partUuid)"
                @change="save('id_blank_type', $event.value, partUuid)"
                name="blank_type"
              />
            </div>
            <div style="width: 100%; padding-left: 10px">
              <SizeInput
                :x-size="x"
                :y-size="y"
                :z-size="z"
                :tab-indexes="[5, 6, 7]"
                @change="saveSize($event, partUuid)"
              />
              <Search
                :label="partText.material_standard[language]"
                :query="{
                  method: 'post',
                  url: 'getSearchStandards',
                  label: 'standard_code',
                  value: 'id',
                }"
                :ti="9"
                :default-value="gostMaterial"
                clearable
                @clear="save('id_gost_material', null, partUuid)"
                @change="save('id_gost_material', $event.value, partUuid)"
                name="gost_material"
              />
              <Search
                :label="partText.blank_standard[language]"
                :query="{
                  method: 'post',
                  url: 'getSearchStandards',
                  label: 'standard_code',
                  value: 'id',
                }"
                :ti="11"
                clearable
                @clear="save('id_gost_blank', null, partUuid)"
                :default-value="gostBlank"
                @change="save('id_gost_blank', $event.value, partUuid)"
                name="gost_blank"
              />
            </div>
          </div>
          <TextArea
            :label="partText.comment[language]"
            :ti="12"
            :default-value="comment"
            @change="save('comment', $event, partUuid)"
            name="comment"
          />
        </div>
        <div class="edit-part__files">
          <div class="edit-part__files_container">
            <div class="edit-part__preview" v-if="files && files[0]">
              <div class="edit-part__preview">
                <div class="edit-part__preview_title">
                  <span class="edit-part__preview_title" v-if="files[activeFile].preview_3d">
                    {{ partText.preview_3d[language] }}
                  </span>
                  <span class="edit-part__preview_title" v-if="!files[activeFile].preview_3d">
                    {{ partText.preview_document[language] }}
                  </span>
                </div>
                <ModelViewer
                  v-if="files[activeFile].preview_3d && show3dModel"
                  style="height: 250px"
                  :src="
                    setLink(
                      files[activeFile].hash,
                      files[activeFile].time_stamp,
                      files[activeFile].file_name,
                    )
                  "
                />
                <div class="order__preview_buttons">
                  <FullSizeLogo @click="setFullSize" class="order__preview_button" />
                </div>
                <div class="edit-part__preview_img-container" v-if="!show3dModel">
                  <img
                    v-if="
                      files[activeFile] &&
                      files[activeFile].preview_img !== 'default' &&
                      !show3dModel
                    "
                    :src="files[activeFile].preview_img"
                    class="edit-part__preview_img"
                    @error="setError"
                  />
                  <div
                    style="height: 250px"
                    class="block block__center block__align-center"
                    v-if="!files[activeFile] || files[activeFile].preview_img === 'default'"
                  >
                    <DefaultSvg />
                  </div>
                  <div
                    class="edit-part__preview_3d-button"
                    @click="setShow3dModel"
                    v-if="files[activeFile].preview_3d"
                  >
                    {{ partText["3d-button"][language] }}
                  </div>
                </div>
                <Button
                  v-if="
                    files[activeFile].preview_3d &&
                    files[activeFile].hash &&
                    files[activeFile].time_stamp &&
                    files[activeFile].file_name &&
                    files[activeFile].ext &&
                    token
                  "
                  class="component__margin-top"
                  @click="
                    goToTechReq(
                      files[activeFile].hash,
                      files[activeFile].time_stamp,
                      files[activeFile].file_name,
                      files[activeFile].ext,
                    )
                  "
                  block
                  :text="text.set_tech_req[language]"
                />
              </div>
            </div>
            <div v-if="files && files[0]" class="edit-part__file-list">
              <div v-for="(file, index) in files" v-bind:key="`file_${index}`">
                <File
                  :deletable="true"
                  @delete="deleteAddedFile($event)"
                  :active="activeFile === index"
                  @click="setActiveFile(index)"
                  :file="file"
                />
              </div>
            </div>
            <Button
              class="edit-part__add-button"
              block
              @click="openAddFilesModal"
              :text="partText.add_files[language]"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="edit-part__wrapper" v-if="!loading">
      <div class="edit-part__loading">
        <a-spin />
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/create_order.json";
import packageJson from "@/../package.json";
import Input from "@/components/input/Input.vue";
import InputNumber from "@/components/inputNumber/InputNumber.vue";
import Search from "@/components/search/Search.vue";
import SizeInput from "@/components/sizeInput/SizeInput.vue";
import TextArea from "@/components/textarea/Textarea.vue";
import ModelViewer from "@/components/modelViewer/ModelViewer.vue";
import partText from "@/assets/text/parts.json";
import { query } from "@/utilities/axios";
import Button from "@/components/button/Button.vue";
import Default from "@/assets/images/parts/edit-deafult.png";
import FullSizeLogo from "@/assets/images/order/full_size.svg";
import DefaultSvg from "@/assets/images/parts/edit-default.svg";
import File from "@/components/file/File.vue";
import FullSize from "@/components/fullSize/FullSize.vue";
import Save from "@/assets/images/parts/save.svg";
import AddFiles from "./AddFilesV2.vue";

export default {
  name: "EditPart",
  data() {
    return {
      text,
      partText,
      savedData: {},
      showAddFileModal: false,
      code: "",
      name: "",
      code_order: "",
      name_order: "",
      amount: null,
      comment: "",
      material: "",
      blankType: "",
      idMaterial: null,
      idBlank: null,
      materialStandard: "",
      blankStandard: "",
      gostMaterial: "",
      gostBlank: "",
      idGostMaterial: null,
      idGostBlank: null,
      weight: "",
      x: "",
      y: "",
      z: "",
      parts: [],
      loading: false,
      files: null,
      timer: null,
      activeFile: 0,
      show3dModel: false,
      timerIsActive: false,
      showFullSize: false,
      fullLink: "",
      fsType: "image",
      fsLink: null,
      fsPreviewLink: null,
      fsFileName: "",
      fsShow: false,
      fsHash: null,
      fsTS: null,
      fsName: null,
      saved: false,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    treeParts: {
      type: Array,
    },
    partUuid: {
      type: String,
    },
  },
  watch: {
    partUuid: {
      immediate: true,
      handler: function (val, oldVal) {
        this.getPage(val);
        if (this.timerIsActive && oldVal) {
          this.stopTimer();
          this.saveData(oldVal);
          this.savedData = null;
          this.saved = false;
        }
      },
    },
  },
  methods: {
    getPage(uuid) {
      this.loading = false;
      if (uuid) {
        query(
          "post",
          "getPartOrderInfoV2",
          {
            uuid: uuid,
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((orderPart) => {
          this.loading = true;
          this.parts[this.index] = orderPart.part_order_info;
          this.setPart();
        });
      } else {
        this.loading = true;
      }
    },
    updateWhenDelete() {
      this.getPage(this.partUuid);
    },
    deleteAddedFile(file) {
      query(
        "delete",
        "deleteFileFromPartOrderV2",
        {
          uuid: this.partUuid,
          hash: file.hash,
          time_stamp: file.time_stamp,
          file_name: file.file_name,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.updateWhenDelete();
      });
    },
    setDefault() {},
    setError(e) {
      e.target.src = Default;
    },
    saveData(uuid) {
      let data = {
        uuid: uuid,
      };
      data = {
        ...data,
        ...this.savedData,
      };
      query("post", "changePartOrderInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.savedData = {};
        this.setSaved();
        this.$emit("save");
        this.timerIsActive = false;
      });
    },
    setSaved() {
      this.saved = true;
    },
    startTimer(uuid) {
      this.timer = setTimeout(() => {
        this.saveData(uuid);
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(name, value, uuid) {
      this.savedData[name] = value;
      this.timerIsActive = true;
      this.saved = false;
      this.stopTimer();
      this.startTimer(uuid);
    },
    setFullSize() {
      this.fsShow = true;
    },
    closeFullSize() {
      this.fsShow = false;
    },
    saveSize(values, uuid) {
      if (values.x) {
        this.x = +values.x;
        this.resetTimer("x_size", +values.x, uuid);
      } else {
        this.resetTimer("x_size", this.parts[this.index].x_size, uuid);
      }
      if (values.y) {
        this.y = +values.y;
        this.resetTimer("y_size", +values.y, uuid);
      } else {
        this.resetTimer("y_size", this.parts[this.index].y_size, uuid);
      }
      if (values.z) {
        this.z = +values.z;
        this.resetTimer("z_size", +values.z, uuid);
      } else {
        this.resetTimer("z_size", this.parts[this.index].z_size, uuid);
      }
    },
    save(name, value, uuid) {
      this.resetTimer(name, value, uuid);
    },
    setActiveFile(index) {
      this.show3dModel = false;
      this.activeFile = index;
      this.parts[this.index].activeFile = index;
      this.fullLink = this.files[index].download;
      this.setFS(this.activeFile);
    },
    setPart() {
      const part = this.parts[this.index];
      if (part) {
        this.code = part.code ? part.code : "";
        this.name = part.name ? part.name : "";
        this.amount = part.amount ? part.amount : null;
        this.code_order = part.code_order ? part.code_order : "";
        this.name_order = part.name_order ? part.name_order : "";
        this.comment = part.comment ? part.comment : "";
        this.material = part.material ? part.material : "";
        this.blankType = part.blank_type ? part.blank_type : "";
        this.materialStandard = part.material_gost ? part.material_gost : "";
        this.blankStandard = part.blank_gost ? part.blank_gost : "";
        this.weight = part.weight ? part.weight : "";
        this.gostMaterial = part.gost_material ? part.gost_material : "";
        this.gostBlank = part.gost_blank ? part.gost_blank : "";
        this.x = part.x_size ? part.x_size : "";
        this.y = part.y_size ? part.y_size : "";
        this.z = part.z_size ? part.z_size : "";
        this.files = part.files.length > 0 ? part.files : null;
        this.setFS(0);
        this.activeFile = part.activeFile ? part.activeFile : 0;
        this.show3dModel = false;
      }
    },
    openAddFilesModal() {
      this.showAddFileModal = true;
    },
    closeAddFileModal() {
      this.showAddFileModal = false;
      this.getPage(this.partUuid);
    },
    setLink(hash, timeStamp, fileName) {
      return `${packageJson.url}getFile?time_stamp=${timeStamp}&hash=${hash}&file_name=${fileName}&ext=stl`;
    },
    setShow3dModel() {
      this.show3dModel = true;
    },
    closeFull() {
      this.showFullSize = false;
    },
    setFS(index) {
      if (this.files && this.files[index] && this.files[index].preview_3d) {
        this.fsType = "3d";
        this.fsLink = this.files[index].preview_3d;
        this.fsFileName = this.files[index].filename;
      } else if (this.files && this.files[index] && this.files[index].preview_img) {
        this.fsType = "image";
        this.fsLink = this.files[index].download;
        this.fsPreviewLink = this.files[index].preview_img;
        this.fsFileName = this.files[index].filename;
      } else {
        this.fsType = "none";
        this.fsFileName =
          this.files && this.files[index] && this.files[index].filename
            ? this.files[index].filename
            : "";
      }
    },
    goToTechReq() {
      this.$router.push(`/order-tech-requirement/${this.partUuid}/roughness`);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    token() {
      return localStorage.getItem("token");
    },
    access() {
      return this.$store.state.user.access;
    },
  },
  mounted() {},
  beforeDestroy() {},
  beforeRouteLeave(to, from, next) {
    this.saveData(this.partUuid);
    this.savedData = {};
    next();
  },
  components: {
    ModelViewer,
    Button,
    Input,
    InputNumber,
    SizeInput,
    TextArea,
    DefaultSvg,
    File,
    AddFiles,
    Search,
    FullSize,
    FullSizeLogo,
    Save,
  },
};
</script>
