<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.page_name[language] }}</div>
    <div class="page__edit_title">{{ text.code[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.code }}
    </div>
    <div class="page__edit_title">{{ text.name[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.name }}
    </div>
    <div class="page__edit_title">{{ text.type[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.type }}
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/standards.json";

export default {
  name: "Edit",
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
