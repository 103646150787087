<template>
  <div style="height: inherit">
    <PageConstructor
      :query="queryTable"
      :columns="columns"
      :buttons="buttons"
      @headerClick="headerClick($event)"
      @setUpdate="setUpdate($event)"
      @getFilter="getFilter($event)"
      @setPageTitle="setTitle($event)"
      id-name="uuid"
      :page-name="pageName"
    >
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/tools.json";

export default {
  name: "ToolHistory",
  data() {
    return {
      text,
      showAddModal: false,
      tableUpdate: null,
      filters: null,
      buttons: [],
      pageName: "",
      columns: [
        {
          name: "shop_from",
          label: text.from,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "shop_to",
          label: text.to,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "amount",
          label: text.amount,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "comment",
          label: text.comment,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "date_transaction",
          label: text.date_transaction,
          type: "date-time",
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
    };
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.setAddModal(true);
      } else if (type === "download") {
        this.downloadList();
      }
    },
    setAddModal(val) {
      this.showAddModal = val;
      this.tableUpdate();
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
    getFilter(val) {
      this.filters = val;
    },
    setTitle(val) {
      this.pageName = `${text.tool_history[this.language]} - ${val}`;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    queryTable() {
      return {
        method: "post",
        url: "getCurrentCompaniesHistoryToolsV2",
        headers: {},
        data: {
          uuid: this.$route.params.id,
          id_tool_catalog_type: 1,
        },
      };
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
