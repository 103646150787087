<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    @setUpdate="setUpdate($event)"
    id-name="id_manufacturer"
    :page-name="text.page_name[language]"
    :filter-options="filterOptions"
    show-filter
  >
    <template slot="edit" slot-scope="{ row }">
      <Edit :row="row" @update="updateTable" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/manufacturer_search.json";
import { query } from "@/utilities/axios";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "ManufacturerSearch",
  data() {
    return {
      text,
      orderData: null,
      tableUpdate: null,
      query: {
        method: "post",
        url: "getAllKPbyOrderV2",
        headers: {},
        data: {
          uuid: this.$route.params.id,
        },
      },
      filterOptions: {},
      columns: [
        {
          name: "proof_by_i5",
          label: {
            ru: "",
            en: "",
          },
          type: "approve",
          options: {},
          basis: 40,
          width: 40,
        },
        {
          name: "company_name",
          label: text.manufacturer,
          options: {},
          basis: 0,
          width: 150,
        },
        {
          name: "logo",
          label: {
            ru: "",
            en: "",
          },
          type: "image",
          options: {},
          basis: 80,
          width: 80,
        },
        {
          name: "type",
          label: text.type,
          options: {},
          basis: 0,
          width: 150,
        },
        {
          name: "company_mark",
          label: text.rating,
          options: {
            right: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "location",
          label: text.country,
          options: {},
          basis: 0,
          width: 150,
        },
        {
          name: "order_percent",
          label: text.order_percent,
          options: {},
          basis: 150,
          width: 150,
        },
        {
          name: "total_price",
          label: text.sum,
          type: "number_with_spaces",
          options: {
            right: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  watch: {
    orderData: {
      immediate: true,
      handler(val) {
        if (val && val.name) {
          this.filterOptions.title = val.name;
          this.filterOptions.back = {
            path: `/order/${this.$route.params.id}`,
          };
        }
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getOrder() {
      query(
        "post",
        "getOrderInfoV2",
        {
          uuid: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((order) => {
        this.orderData = order.order_info;
      });
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
    updateTable() {
      this.tableUpdate();
    },
  },
  mounted() {
    this.getOrder();
  },
  components: {
    PageConstructor,
    Edit,
  },
};
</script>
