<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="number"
    :page-name="text.page_name[language]"
    @setUpdate="setUpdate($event)"
  >
    <template slot="edit" slot-scope="{ row }">
      <Edit :row="row" @update="updateTable" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/order_offers.json";
import Edit from "./components/edit/OrderOfferEdit.vue";

export default {
  name: "OrderOffers",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getAllKPbyOrder",
        headers: {},
        data: {
          uuid: this.$route.params.id,
        },
      },
      columns: [
        {
          name: "number",
          label: text.number,
          options: {
            bold: true,
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "company_name",
          label: text.manufacturer,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "status",
          label: text.status,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "total_price",
          label: text.sum,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "deadline",
          label: text.time,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "company_mark",
          label: text.rating,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "company_country",
          label: text.country,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "company_region",
          label: text.region,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "order_percent",
          label: text.percent,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
      tableUpdate: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setUpdate(func) {
      this.tableUpdate = func;
    },
    updateTable() {
      this.tableUpdate();
    },
  },
  components: {
    PageConstructor,
    Edit,
  },
};
</script>
