<template>
  <div class="rec__wrapper">
    <FullSize
      :type="'3d'"
      :link="preview3d"
      :show="fsShow"
      :title="fsFileName"
      @close="closeFullSize"
    />
    <div class="rec__container">
      <div style="padding: 10px">
        <Button block @click="closePage" :text="text.back[language]" />
        <Button
          class="component__margin-top"
          block
          @click="addMP"
          :text="text.create_empty[language]"
        />
      </div>
      <div style="width: 100%" class="rec__block" v-if="part && part.name_order && part.code_order">
        <div class="rec__label">{{ part.code_order }} - {{ part.name_order }}</div>
      </div>
      <div class="rec__files" v-if="preview3d">
        <div class="rec__files_container">
          <div class="rec__preview" v-if="preview3d">
            <div class="rec__preview">
              <ModelViewer v-if="preview3d && show3dModel" style="height: 150px" :src="preview3d" />
              <div class="rec__preview_buttons">
                <FullSizeLogo @click="setFullSize" class="rec__preview_button" />
              </div>
              <div class="rec__preview_img-container" v-if="!show3dModel">
                <img
                  v-if="preview !== 'default'"
                  :src="preview"
                  class="rec__preview_img"
                  @error="setError"
                />
                <div
                  style="height: 250px"
                  class="block block__center block__align-center"
                  v-if="preview === 'default'"
                >
                  <DefaultSvg />
                </div>
                <div class="rec__preview_3d-button" @click="setShow3dModel" v-if="preview3d">
                  {{ partText["3d-button"][language] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%" class="rec__block">
        <div class="rec__label">
          {{ orderText.part[language] }}
        </div>
        <div class="rec__values" v-if="part">
          <div class="component__margin-bottom" v-if="part.amount">
            <div class="rec__values_label">{{ orderText.amount[language] }}</div>
            <div class="rec__values_value">{{ part.amount }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.material">
            <div class="rec__values_label">{{ orderText.material[language] }}</div>
            <div class="rec__values_value">{{ part.material }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.gost_material">
            <div class="rec__values_label">{{ orderText.gost_material[language] }}</div>
            <div class="rec__values_value">{{ part.gost_material }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.blank">
            <div class="rec__values_label">{{ orderText.blank[language] }}</div>
            <div class="rec__values_value">{{ part.blank }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.gost_blank">
            <div class="rec__values_label">{{ orderText.gost_blank[language] }}</div>
            <div class="rec__values_value">{{ part.gost_blank }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.x_size && part.y_size && part.z_size">
            <div class="rec__values_label">{{ orderText.size[language] }}</div>
            <div class="rec__values_value">
              {{ part.x_size }} x {{ part.y_size }} x {{ part.z_size }}
            </div>
          </div>
          <div class="component__margin-bottom" v-if="part.weight">
            <div class="rec__values_label">{{ orderText.weight[language] }}</div>
            <div class="rec__values_value">{{ getNumberWithSpaces(part.weight) }}</div>
          </div>
        </div>
      </div>
      <div style="width: 100%" class="rec__block">
        <div class="rec__label">
          {{ text.manufacturer[language] }}
        </div>
        <div class="rec__values" v-if="part">
          <div class="block block__column">
            <div>
              <a-checkbox v-model="onlyOurMachineTools" @change="getTechProcesses">
                {{ text.only_m_tools[language] }}
              </a-checkbox>
            </div>
            <div>
              <a-checkbox @change="getTechProcesses" v-model="onlyOurTools">
                {{ text.only_tools[language] }}
              </a-checkbox>
            </div>
            <div>
              <a-checkbox @change="getTechProcesses" v-model="onlyOurBlanks">
                {{ text.only_blanks[language] }}
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rec__body">
      <div class="rec__body_container" v-if="loading && showTp">
        <Card
          v-for="(item, index) in tp"
          v-bind:key="`tp_${index}`"
          :index="index"
          :active="activeCardIndex === index"
          @setActive="setActiveCard($event.index, $event.val)"
          @click="goToTechProcess($event)"
          :item="item"
        />
      </div>
      <div v-if="loading && !showTp && errList" style="padding: 20px">
        <div v-for="item in errList" v-bind:key="item.code">
          <div class="rec__error_card">
            <div class="rec__error_title">{{ item.name }}</div>
            <div class="rec__error_text">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div v-if="loading && !showTp && !errList" class="block block__center">
        <div style="margin-top: 40px">
          {{ text.empty[language] }}
        </div>
      </div>
      <div class="rec__body_container" v-if="!loading">
        <div style="height: 100%; width: 100%" class="block block__center block__align-center">
          <a-spin />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from "@/utilities/axios";
import { getNumberWithSpaces, getMoney } from "@/utilities/functions";
import ModelViewer from "@/components/modelViewer/ModelViewer.vue";
import Button from "@/components/button/Button.vue";
import FullSizeLogo from "@/assets/images/order/full_size.svg";
import Default from "@/assets/images/parts/edit-deafult.png";
import FullSize from "@/components/fullSize/FullSize.vue";
import partText from "@/assets/text/parts.json";
import orderText from "@/assets/text/order.json";
import text from "@/assets/text/recommendations.json";
import Card from "./components/card/Card.vue";

export default {
  name: "Recommendations",
  data() {
    return {
      text,
      partText,
      orderText,
      getMoney,
      getNumberWithSpaces,
      errList: null,
      preview: "",
      preview3d: "",
      hash: "",
      timeStamp: "",
      fileName: "",
      amount: 1,
      tp: null,
      show3dModel: false,
      fsShow: false,
      fsFileName: "",
      part: null,
      activeCardIndex: 1,
      timer: null,
      loading: false,
      showTp: true,
      onlyOurBlanks: true,
      onlyOurMachineTools: true,
      onlyOurTools: true,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    currency() {
      return this.$store.state.index.currency;
    },
  },
  methods: {
    getPart(uuid) {
      const headers = {};
      const token = localStorage.getItem("token");
      if (token) {
        headers.token = token;
      }
      query(
        "post",
        "getPartOrderInfoV2",
        {
          uuid,
        },
        headers,
      ).then((part) => {
        const files = part.part_order_info.files;
        this.part = part.part_order_info;
        this.amount = part.part_order_info.amount;
        files.forEach((file) => {
          if (file.preview_3d) {
            this.preview = file.preview_img;
            this.preview3d = file.preview_3d;
            this.hash = file.hash;
            this.timeStamp = file.time_stamp;
            this.fileName = file.file_name;
            this.fsFileName = file.filename;
          }
        });
        this.getTechProcesses();
      });
    },
    getTechProcesses() {
      this.loading = false;
      query(
        "post",
        "scanMP",
        {
          uuid_part_order: this.$route.params.id,
          only_our_blanks: this.onlyOurBlanks,
          only_our_machine_tools: this.onlyOurMachineTools,
          only_our_tools: this.onlyOurTools,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((tp) => {
        let result = false;
        if (tp.err_list) {
          this.errList = tp.err_list;
        }
        for (const item of tp.mp) {
          if (item) {
            result = true;
          }
        }
        this.showTp = result;
        this.tp = tp.mp;
        this.loading = true;
      });
    },
    setFullSize() {
      this.fsShow = true;
    },
    closePage() {
      this.$router.back();
    },
    closeFullSize() {
      this.fsShow = false;
    },
    setShow3dModel() {
      this.show3dModel = true;
    },
    setError(e) {
      e.target.src = Default;
    },
    setActiveCard(index, val) {
      if (val) {
        this.activeCardIndex = index;
        this.stopTimer();
      } else {
        this.resetTimer();
      }
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.activeCardIndex = 1;
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer() {
      this.stopTimer();
      this.startTimer();
    },
    goToTechProcess(uuid) {
      this.$router.push(`/tech-processes/${uuid}`);
    },
    addMP() {
      query(
        "put",
        "addMP",
        {
          uuid_part_order: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.goToTechProcess(res.uuid);
      });
    },
  },
  mounted() {
    this.getPart(this.$route.params.id);
  },
  components: {
    ModelViewer,
    FullSizeLogo,
    FullSize,
    Card,
    Button,
  },
};
</script>
