<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <div v-if="row.img" class="block block__center page__edit_margin">
        <img :src="row.img" class="page__edit_img" />
      </div>

      <div class="page__edit_margin" v-if="row.feature_code">
        <div class="page__edit_title">{{ text.code[language] }}:</div>
        <div class="page__edit_value">
          {{ row.feature_code }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.machine_tool">
        <div class="page__edit_title">{{ text.machine_tool[language] }}:</div>
        <div class="page__edit_value">
          {{ row.machine_tool }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/mto.json";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  components: { Button },
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
