<template>
  <Modal @close="closeAddModal" :show="show" :title="text.add_tool_title[language]">
    <template slot="body">
      <div class="block block__column block__between" style="height: 100%; padding: 10px">
        <div style="height: 100%; overflow-y: auto">
          <div class="block">
            <Input
              :label="text.serial_number[language]"
              :error="snError"
              style="width: 50%"
              required
              @change="saveAdd($event, 'serial_number')"
            />
            <Search
              show-custom
              style="width: 100%"
              :error="toolError"
              :position-type="'fixed'"
              :label="text.tool_name[language]"
              required
              class="component__margin-left"
              @change="saveAdd($event, 'uuid_tool')"
              :dropdown-props="{
                img: 'logo',
                sublabel: 'vendor_code',
                secondLine: 'tool_type',
              }"
              :query="{
                method: 'post',
                url: 'getSearchAllTools',
                findName: 'name',
                label: 'name',
                value: 'uuid',
              }"
            />
          </div>
          <div class="block">
            <Input
              v-if="toolData && toolData.vendor_code"
              disabled
              style="width: 100%"
              :label="text.vendor_code[language]"
              :default-value="toolData.vendor_code"
            />
            <Input
              :class="toolData.vendor_code ? 'component__margin-left' : ''"
              v-if="toolData && toolData.tool_type"
              disabled
              style="width: 100%"
              :label="text.tool_type[language]"
              :default-value="toolData.tool_type"
            />
            <Input
              :class="toolData.vendor_code || toolData.tool_type ? 'component__margin-left' : ''"
              v-if="toolData && toolData.manufacturer"
              disabled
              style="width: 100%"
              :label="text.manufacturer[language]"
              :default-value="toolData.manufacturer"
            />
          </div>
          <div class="block">
            <img
              style="max-height: 40px; max-width: 100px; object-fit: scale-down"
              v-if="toolData && toolData.logo"
              :src="toolData.logo"
            />
            <img
              :class="toolData.logo ? 'component__margin-left' : ''"
              style="max-height: 100px; max-width: 200px; object-fit: scale-down"
              v-if="toolData && toolData.img"
              :src="toolData.img"
            />
          </div>
          <div class="block">
            <Input
              :label="text.minimal_amount[language]"
              :error="maError"
              type="number"
              style="width: 50%"
              required
              @change="saveAdd($event, 'minimal_amount')"
            />
            <Textarea
              @change="saveAdd($event, 'comment')"
              class="component__margin-left"
              style="width: 100%"
              :label="text.comment[language]"
            />
          </div>
          <!--<div class="block">
            <Input
              disabled
              style="width: 100%;"
              :label="text.manufacturing[language]"
              :default-value="addCompanyName" />
            <Input
              class="component__margin-left"
              style="width: 100%;"
              disabled
              :label="text.machine_tool_method[language]"
              :default-value="addMethod" />
          </div> -->
          <!--<div class="block">
            <Select :values="shops"
                    :error="shError"
                    style="width: 100%;"
                    @change="saveAdd($event.value, 'id_shop')"
                    required
                    :label="text.shop_name[language]"
                    :dropdown-props="{
          label: 'name',
          value: 'id',
        }" :position-type="'fixed'" />
            <Select
              class="component__margin-left"
              style="width: 100%;"
              position-type="fixed"
              :error="acError"
              required
              :label="text.accuracy_class[language]"
              :values="accuracyClasses"
              @change="saveAdd(+$event.value, 'id_current_accuracy_class')"
              :dropdown-props="{
                    label: 'name',
                    value: 'id',
                  }"
            />
          </div> -->
        </div>
        <div style="padding: 10px" class="block block__right">
          <Button type="default" @click="closeAddModal" :text="text.cancel[language]" />
          <Button
            type="primary"
            :text="text.save[language]"
            @click="addTool"
            style="margin-left: 10px"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import Input from "@/components/input/Input.vue";
import Search from "@/components/search/Search.vue";
import Button from "@/components/button/Button.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import text from "@/assets/text/tools.json";
import { query } from "@/utilities/axios";

export default {
  name: "Add",
  data() {
    return {
      text,
      snError: null,
      maError: null,
      toolError: null,
      serialNumber: null,
      idTool: null,
      idShop: null,
      toolData: null,
      comment: null,
      minAmount: null,
      shops: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.getShops();
      }
    },
  },
  methods: {
    closeAddModal() {
      this.$emit("close");
      this.snError = null;
      this.maError = null;
      this.toolError = null;
      this.toolData = null;
      this.idTool = null;
      this.serialNumber = null;
      this.minAmount = null;
    },
    saveAdd(value, name) {
      if (name === "serial_number") {
        this.snError = null;
        this.serialNumber = value;
      } else if (name === "uuid_tool") {
        this.toolError = null;
        if (value.value !== -1) {
          this.toolData = value;
        }
        this.idTool = value.value;
      } else if (name === "minimal_amount") {
        this.maError = null;
        this.minAmount = value;
      } else if (name === "comment") {
        this.comment = value;
      }
    },
    addTool() {
      if (!this.idTool && this.idTool !== 0) {
        this.toolError = text.require[this.language];
      }
      if (!this.serialNumber) {
        this.snError = text.require[this.language];
      }
      if (!this.minAmount) {
        this.maError = text.require[this.language];
      }
      if (!this.snError && !this.toolError && !this.maError) {
        const data = {
          id_tool_catalog_type: 1,
          uuid_tool: this.idTool,
          minimum_amount: +this.minAmount,
          serial_number: this.serialNumber,
        };
        if (this.comment) {
          data.comment = this.comment;
        }
        query("put", "addToolToCompany", data, {
          token: localStorage.getItem("token"),
        }).then(() => {
          this.closeAddModal();
        });
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    Modal,
    Input,
    Search,
    Button,
    // Select,
    Textarea,
  },
};
</script>
