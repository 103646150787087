<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.z[language] }}</div>
    <div v-if="row && row.blank_type" style="margin-bottom: 15px">
      <div class="page__edit_title">{{ text.blank_type[language] }}:</div>
      <div class="page__edit_value">
        {{ row.blank_type }}
      </div>
    </div>
    <Button :text="text.edit[language]" block @click="edit" />
  </div>
</template>

<script>
import text from "@/assets/text/blanks.json";
import Button from "@/components/button/Button.vue";

export default {
  name: "EditBlank",
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    edit() {
      this.$emit("edit", this.row);
    },
  },
  components: {
    Button,
  },
};
</script>
