<template>
  <div class="toggle-container">
    <label v-if="label" class="toggle-label" @click="onClickToggleHandle">{{ label }}</label>

    <div
      class="toggle-element"
      :class="`toggle-element, ${isChecked && 'is-active'}`"
      @click="onClickToggleHandle"
    >
      <div class="disabled-block" v-if="disabled" @click.stop></div>

      <input
        class="toggle-input"
        type="checkbox"
        :disabled="disabled"
        :name="name"
        :value="value"
        role="switch"
        aria-hidden="true"
      />

      <span class="toggle-control"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    name: { type: String, required: true },
    value: { type: Boolean, required: true },
    checked: { type: Boolean, default: false },
    disabled: Boolean,
  },
  data() {
    return {
      isActive: false,
      isChecked: this.checked,
    };
  },
  watch: {
    checked(newValue) {
      this.isChecked = newValue;
    },
  },
  methods: {
    onClickToggleHandle() {
      this.isActive = true;
      this.isChecked = !this.isChecked;
      this.$emit("change", this.isChecked);
      setTimeout(() => {
        this.isActive = false;
      }, 200);
    },
  },
};
</script>
