<template>
  <div class="page-header__wrapper">
    <div class="page-header__name">{{ name }}</div>
    <div class="block__flex">
      <div v-for="(button, index) in buttons" v-bind:key="`button_${index}`">
        <Button
          class="component__margin-left"
          type="attention"
          @click="handleClick('main')"
          size="small"
          :text="button.text ? button.text : ''"
          v-if="button.type === 'main'"
        >
        </Button>
        <IconButton
          v-if="button.type === 'download'"
          class="component__margin-left"
          :text="page.download[language]"
          @click="handleClick('download')"
          placement="bottom"
          iconName="cloud-download"
        />
        <!-- <IconButton
          class="component__margin-left"
          :disabled="activeRow ? false : true"
          :text="page.copy[language]"
          placement="bottom"
          iconName="copy"/>
        <IconButton
          class="component__margin-left"
          :disabled="activeRow ? false : true"
          :text="page.delete[language]"
          placement="bottom"
          iconName="delete"/>
        <IconButton
          class="component__margin-left"
          :disabled="activeRow ? false : true"
          :text="page.edit[language]"
          placement="bottom"
          @click="openEditForm"
          iconName="edit"/> -->
        <IconButton
          class="component__margin-left"
          v-if="button.type === 'plus'"
          :text="page.add[language]"
          @click="handleClick('plus')"
          placement="bottomRight"
          iconName="plus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import page from "@/assets/text/page_constructor.json";
import Button from "@/components/button/Button.vue";

const IconButton = () => import("@/components/iconButton/IconButton.vue");

export default {
  name: "PageHeader",
  data() {
    return {
      page,
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    activeRow: {
      type: Object,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    openEditForm() {
      this.$emit("edit");
    },
    handleClick(type) {
      this.$emit("click", type);
    },
  },
  components: {
    IconButton,
    Button,
  },
};
</script>
