<template>
  <div :class="checked ? 'page-with-cards__active-card' : 'page-with-cards__card'">
    <div style="width: 100%">
      <div class="block block__align-center">
        <div style="max-width: 14px">
          <CheckBoxI5 :value="checked" @click="handleCheck(values, $event)" />
        </div>
        <div class="page-with-cards__card_value component__margin-left">
          {{ compare[language] }}
        </div>
      </div>
      <div
        v-if="parameters && parameters.title"
        class="page-with-cards__card_title page-with-cards__card_el"
      >
        <span v-if="parameters.title.text" class="component__select">
          {{ parameters.title.text[language] }}
        </span>
        <span
          v-if="parameters.title.value && values[parameters.title.value]"
          class="component__select"
        >
          {{ values[parameters.title.value] }}
        </span>
      </div>
      <div v-if="parameters && parameters.values">
        <div
          v-for="(value, index) in parameters.values"
          v-bind:key="`value_${values[idName]}_${index}`"
        >
          <div
            class="page-with-cards__card_el"
            v-if="value && value.type && value.type === 'value'"
          >
            <div class="block__flex">
              <div v-if="value.logo && values[value.logo]" class="component__margin-right">
                <img
                  class="page-with-cards__card_logo"
                  v-if="values[value.logo]"
                  :src="values[value.logo]"
                />
              </div>
              <div>
                <div
                  v-if="value && value.label"
                  class="page-with-cards__card_label component__select"
                >
                  {{ value.label[language] }}
                </div>
                <div
                  v-if="value && value.value && values[value.value]"
                  class="page-with-cards__card_value component__select"
                >
                  {{ values[value.value] }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="page-with-cards__card_el"
            v-if="value && value.type && value.type === 'image'"
          >
            <div class="block block__center">
              <img
                @error="setError"
                class="page-with-cards__card_img"
                v-if="values[value.value]"
                :src="values[value.value]"
              />
              <img src="@/assets/images/i5/default.png" class="page-with-cards__card_img" v-else />
            </div>
          </div>
          <div
            class="page-with-cards__card_el"
            v-if="value && value.type && value.type === 'html-list'"
          >
            <div v-if="value && value.label" class="page-with-cards__card_label component__select">
              {{ value.label[language] }}
            </div>
            <div class="page-with-cards__card_list" v-if="values[value.value]">
              <div
                v-for="(el, i) in values[value.value]"
                v-bind:key="`hlist_${values[idName]}_${index}_${i}`"
              >
                <div style="margin-bottom: 10px" class="component__select" v-html="el"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxI5 from "@/components/checkbox/CheckBoxI5.vue";
import Default from "@/assets/images/i5/default.png";

export default {
  name: "Card",
  data() {
    return {
      compare: {
        ru: "Сравнить",
        en: "Compare",
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    values: {
      type: Object,
    },
    parameters: {
      type: Object,
    },
    idName: {
      type: String,
      default: "uuid",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCheck(values, isCheck) {
      this.$emit("check", {
        values,
        isCheck,
      });
    },
    setError(e) {
      e.target.src = Default;
    },
  },
  components: {
    CheckBoxI5,
  },
};
</script>
