<template>
  <div style="width: 100%">
    <div v-if="param.type === 'string'">
      <Input :label="param.name" :sub-label="param.dimension" :default-value="param.value" />
    </div>
    <div v-else-if="param.type === 'integer'">
      <Input
        :step="1"
        type="number"
        :label="param.name"
        :sub-label="param.dimension"
        :default-value="param.value"
      />
    </div>
    <div v-else-if="param.type === 'number'">
      <Input
        type="number"
        :label="param.name"
        :sub-label="param.dimension"
        :default-value="param.value"
      />
    </div>
    <div v-else-if="param.type === 'dropdown'">{{ param.name }}</div>
    <div v-else-if="param.type === 'int_positive'">
      <Input
        :min="0"
        :step="1"
        type="number"
        :label="param.name"
        :sub-label="param.dimension"
        :default-value="param.value"
      />
    </div>
    <div v-else-if="param.type === 'number_positive'">
      <Input
        :min="0"
        type="number"
        :label="param.name"
        :sub-label="param.dimension"
        :default-value="param.value"
      />
    </div>
    <div v-else-if="param.type === 'number_positive_array'"></div>
    <div v-else-if="param.type === 'integer_array'"></div>
    <div v-else>{{ param.name }}</div>
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";

export default {
  name: "EditTableParameters",
  components: { Input },
  data() {
    return {
      parameters: [
        "string",
        "integer",
        "dropdown",
        "int_positive",
        "number_positive",
        "number_positive_array",
        "integer_array",
      ],
    };
  },
  props: {
    param: {},
  },
};
</script>
