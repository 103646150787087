<template>
  <div style="width: 100%">
    <div v-if="edit">
      <div class="block">
        <div style="width: 100%; padding-right: 10px">
          <Search
            :label="text.material[language]"
            :query="{
              method: 'post',
              url: 'getMaterialsAsArray',
              label: 'name',
              value: 'id',
            }"
            :required="true"
            :default-value="values.blank && values.blank.material ? values.blank.material : ''"
            clearable
            name="material"
          />
        </div>
        <div style="width: 100%; padding-left: 10px">
          <Search
            :label="text.blank[language]"
            :default-value="values.blank && values.blank.blank_type ? values.blank.blank_type : ''"
            @change="setBlankType($event.value)"
            :query="{
              method: 'post',
              url: 'getSearchBlanks',
              label: 'blank_type',
              value: 'id',
            }"
            clearable
            name="blank_type"
          />
        </div>
      </div>
      <div class="block">
        <div style="width: 100%; padding-right: 10px">
          <Search
            :label="text.material_standard[language]"
            :query="{
              method: 'post',
              url: 'getSearchStandards',
              label: 'standard_code',
              value: 'id',
            }"
            :default-value="
              values.blank && values.blank.material_standard ? values.blank.material_standard : ''
            "
            clearable
            name="gost_material"
          />
        </div>
        <div style="width: 100%; padding-left: 10px">
          <Search
            :label="text.blank_standard[language]"
            :query="{
              method: 'post',
              url: 'getSearchStandards',
              label: 'standard_code',
              value: 'id',
            }"
            clearable
            :default-value="
              values.blank && values.blank.blank_standard ? values.blank.blank_standard : ''
            "
            name="gost_blank"
          />
        </div>
      </div>
      <div class="block">
        <div style="width: 100%; padding-right: 10px">
          <InputNumber
            :label="text.amount[language]"
            :required="true"
            :max="999999"
            :min="1"
            :step="1"
            :default-value="+values.amount || null"
            name="amount"
          />
        </div>
        <div style="width: 100%; padding-left: 10px">
          <Input
            :label="text.weight[language]"
            :step="1"
            type="number"
            :text-position="'center'"
            :default-value="values.weight ? values.weight : ''"
            :sub-label="text.g[language]"
            name="weight"
          />
        </div>
      </div>
      <div class="block">
        <div style="width: 100%; padding-right: 10px">
          <Input
            :label="text.usage[language]"
            type="number"
            :text-position="'center'"
            :default-value="values.useful ? values.useful : ''"
            name="weight"
          />
        </div>
        <div style="width: 100%; padding-left: 10px"></div>
      </div>
      <div class="block" v-if="blankParams" style="margin-top: 20px">
        <div style="padding-right: 10px">
          <img
            class="tech-proc__blank_img"
            v-if="blankParams && blankParams.img"
            :src="blankParams.img"
          />
        </div>
        <div style="width: 100%; padding-left: 10px">
          <div class="tech-proc__blank_size-edit-container">
            <div
              class="tech-proc__blank_size-item"
              v-for="size in blankParams.sizes"
              v-bind:key="`size_${size}`"
            >
              <div class="block__flex block__align-center">
                <div class="tech-proc__blank_letter">{{ size }}</div>
                <Input
                  :default-value="
                    values && values.size && values.size[size] ? values.size[size] : ''
                  "
                  class="component__margin-left"
                  :margin-bottom="0"
                />
                <div class="tech-proc__blank_letter component__margin-left">
                  {{ text.mm[language] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tech-proc__card" style="width: 100%">
      <div class="block__flex">
        <div style="margin-right: 20px">
          <div v-if="values.blank.material" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.material[language] }}
            </div>
            <div>
              <span class="tech-proc__card_value">{{ values.blank.material }} </span>
            </div>
          </div>
          <div v-if="values.blank.material_standard" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.material_standard[language] }}
            </div>
            <div>
              <span class="tech-proc__card_value">{{ values.blank.material_standard }}</span>
            </div>
          </div>
          <div v-if="values.blank.blank_type" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.blank[language] }}
            </div>
            <div>
              <span class="tech-proc__card_value">{{ values.blank.blank_type }} </span>
            </div>
          </div>
          <div v-if="values.blank.blank_standard" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.blank_standard[language] }}
            </div>
            <div>
              <span class="tech-proc__card_value">{{ values.blank.blank_standard }} </span>
            </div>
          </div>
        </div>
        <div>
          <div v-if="values.amount" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.amount[language] }}
            </div>
            <div>
              <span class="tech-proc__card_value">{{ values.amount }} </span>
              <span class="tech-proc__card_label">{{ text.pcs[language] }}</span>
            </div>
          </div>
          <div v-if="values.useful" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.usage[language] }}
            </div>
            <div class="tech-proc__card_value">
              {{ values.useful }}
            </div>
          </div>
          <div v-if="values.weight" class="component__margin-bottom">
            <div class="tech-proc__card_param-title">
              {{ text.weight[language] }}
            </div>
            <div>
              <span class="tech-proc__card_value">{{ values.weight }} </span>
              <span class="tech-proc__card_label">{{ text.g[language] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block" v-if="blankParams" style="margin-top: 20px">
        <div style="padding-right: 10px">
          <img
            class="tech-proc__blank_img"
            v-if="blankParams && blankParams.img"
            :src="blankParams.img"
          />
        </div>
        <div style="width: 100%; padding-left: 10px">
          <div class="tech-proc__blank_size-container">
            <div
              class="tech-proc__blank_size-item"
              v-for="size in blankParams.sizes"
              v-bind:key="`size_${size}`"
            >
              <div class="block__flex block__align-center">
                <div class="tech-proc__blank_text">{{ size }}</div>
                <div class="tech-proc__blank_letter component__margin-left">
                  {{ values && values.size && values.size[size] ? values.size[size] : "-" }}
                </div>
                <div class="tech-proc__blank_text component__margin-left">
                  {{ text.mm[language] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/technological_processes.json";
import Input from "@/components/input/Input.vue";
import InputNumber from "@/components/inputNumber/InputNumber.vue";
import Search from "@/components/search/Search.vue";
import { query } from "@/utilities/axios";
// import SizeInput from '@/components/sizeInput/SizeInput.vue';

export default {
  name: "blank",
  data() {
    return {
      text,
      label: {
        ru: "Габариты",
        en: "Size",
      },
      sublabel: {
        ru: "X Y Z, мм.",
        en: "X Y Z, mm.",
      },
      blankParams: null,
    };
  },
  props: {
    edit: {
      type: Boolean,
    },
    values: {},
  },
  watch: {
    values: function (val) {
      if (val && val.blank && val.blank.id_blank) {
        this.getBlankParams(val.blank.id_blank);
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setBlankType(val) {
      if (val) {
        this.getBlankParams(val);
      }
    },
    getBlankParams(id) {
      query(
        "post",
        "getBlankParams",
        {
          id_blank_type: id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.blankParams = res[0];
      });
    },
  },
  mounted() {
    if (this.values && this.values.blank && this.values.blank.id_blank) {
      this.getBlankParams(this.values.blank.id_blank);
    }
  },
  components: {
    Input,
    // SizeInput,
    InputNumber,
    Search,
  },
};
</script>
