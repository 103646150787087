<template>
  <PageConstructor
    :query="query"
    show-filter
    :columns="columns"
    :id-name="'uuid'"
    :page-name="text.page_name[language]"
    @headerClick="headerClick($event)"
    :buttons="buttons"
    @setUpdate="setUpdate($event)"
    :query-filter="queryFilter"
    :addTitle="addTitle[language]"
  >
    <template slot="edit" slot-scope="{ row, idName }">
      <Edit :row="row" :id-name="idName" @update="updateTable" />
    </template>
    <template slot="add">
      <Add />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/all_orders.json";
import { query } from "@/utilities/axios";
import Edit from "./components/edit/OrdersEdit.vue";
import Add from "./components/add/OrdersAdd.vue";

export default {
  name: "MachineTools",
  data() {
    return {
      text,
      tableUpdate: null,
      addTitle: {
        ru: "Создать новый заказ",
        en: "Create new order",
      },
      query: {
        method: "post",
        url: "getOrdersAdminV2",
        headers: {},
        data: {},
      },
      queryFilter: {
        method: "post",
        url: "getFilterMachineTools",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "name",
          label: text.name,
          options: {
            bold: true,
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "customer_company_name",
          label: text.customer_company_name,
          options: {
            bold: true,
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "user",
          label: text.user,
          options: {
            bold: true,
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "status",
          label: text.status,
          options: {
            sort: true,
            filter: true,
            getFilter: this.getFilterMachineToolsStatus,
            filterName: "id_status",
          },
          basis: 150,
          width: 150,
        },
        {
          name: "parts_type_number",
          label: text.part_types_number,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "parts_number",
          label: text.parts_number,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "total_price",
          label: text.total_price,
          type: "money",
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
      buttons: [
        {
          type: "main",
          text: text.add_button[this.$store.state.index.language],
        },
        {
          type: "download",
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.$router.push("/create_order/draft");
      }
    },
    updateTable() {
      this.tableUpdate();
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
    getFilterMachineToolsStatus() {
      return new Promise((resolve) => {
        query(
          "post",
          "getOrdersFiltersAdminV2",
          {
            column: "status",
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((values) => {
          const result = [];
          for (const item of values) {
            result.push({
              value: item.id_status,
              label: item.status,
              subLabel: item.count,
            });
          }
          resolve(result);
        });
      });
    },
  },
  components: {
    PageConstructor,
    Edit,
    Add,
  },
};
</script>
