<template>
  <div
    :class="`file ${active && 'file__active'}`"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click="handleClick"
  >
    <div>
      <img
        @mousedown="handleMouseDown($event)"
        v-if="file && file[previewName] && file[previewName] !== 'default'"
        @error="setError"
        :src="file[previewName]"
        class="file__img"
      />
      <div
        v-if="!file || !file[previewName] || file[previewName] === 'default'"
        class="file__preview"
      >
        <EmptyFile />
        <div class="file__preview_format">{{ fileFormat }}</div>
      </div>
    </div>
    <div class="block block__column block__align-center file__name">
      <div class="file__name_text" v-if="nameTop">{{ nameTop }}</div>
      <div class="file__name_text" v-if="nameBottom">{{ nameBottom }}</div>
    </div>
    <div class="file__delete" v-if="showDelete">
      <Delete @click="deleteFile" />
    </div>
  </div>
</template>

<script>
import EmptyFile from "@/assets/images/parts/empty-file 2.svg";
import Delete from "@/assets/images/parts/delete-file.svg";
import Default from "@/assets/images/parts/empty-file.png";

export default {
  name: "file",
  data() {
    return {
      nameTop: "",
      nameBottom: "",
      fileFormat: "",
      showDelete: false,
    };
  },
  props: {
    file: {
      type: Object,
    },
    active: {
      type: Boolean,
    },
    previewName: {
      type: String,
      default: "preview_img",
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
    handleMouseEnter() {
      if (this.deletable) {
        this.showDelete = true;
      }
    },
    handleMouseLeave() {
      if (this.deletable) {
        this.showDelete = false;
      }
    },
    setName(name) {
      const splitByDot = name.split(".");
      const ellipsis = "…";
      this.fileFormat = splitByDot[splitByDot.length - 1];
      const fileFormatLength = this.fileFormat.length;
      const nameWithoutFormat = name.split("");
      nameWithoutFormat.splice(
        nameWithoutFormat.length - (fileFormatLength + 1),
        fileFormatLength + 1,
      );
      let nameTop = "";
      let nameBottom = "";
      if (name.length <= 12) {
        nameTop = name;
      } else if (name.length <= 24) {
        for (const [index, letter] of nameWithoutFormat.entries()) {
          if (index <= 11) {
            nameTop += letter;
          } else {
            nameBottom += letter;
          }
        }
        nameBottom += ".";
        nameBottom += this.fileFormat;
      } else {
        for (const [index, letter] of nameWithoutFormat.entries()) {
          if (index <= 11) {
            nameTop += letter;
          } else if (index > 11 && index <= 23 - (fileFormatLength + 2)) {
            nameBottom += letter;
          }
        }
        nameBottom += ellipsis;
        nameBottom += ".";
        nameBottom += this.fileFormat;
      }
      this.nameTop = nameTop;
      this.nameBottom = nameBottom;
    },
    setError(e) {
      e.target.src = Default;
    },
    deleteFile() {
      this.$emit("delete", this.file);
    },
    handleMouseDown(e) {
      e.preventDefault();
    },
  },
  components: {
    EmptyFile,
    Delete,
  },
  mounted() {
    this.setName(this.file.filename);
  },
};
</script>
