<template>
  <div>
    <FullSize
      :type="fsType"
      :link="fsLink"
      :preview-link="fsPreviewLink"
      :show="fsShow"
      :title="fsFileName"
      @close="closeFullSize"
    />
    <div class="order__files" v-if="files">
      <div :class="width < 600 ? 'order__files_container' : 'order__files_container-2'">
        <div class="order__preview" v-if="files && files[0]">
          <div :class="width < 600 ? 'order__preview' : 'order__preview_big'">
            <ModelViewer
              v-if="files[activeFile].preview_3d && show3dModel"
              style="height: 150px"
              :src="
                setLink(
                  files[activeFile].hash,
                  files[activeFile].time_stamp,
                  files[activeFile].file_name,
                )
              "
            />
            <div class="order__preview_buttons">
              <FullSizeLogo @click="setFullSize" class="order__preview_button" />
            </div>
            <div class="order__preview_img-container" v-if="!show3dModel">
              <img
                v-if="files[activeFile].preview_img !== 'default'"
                :src="files[activeFile].preview_img"
                class="order__preview_img"
                @error="setError"
              />
              <div
                style="height: 250px"
                class="block block__center block__align-center"
                v-if="!files[activeFile] || files[activeFile].preview_img === 'default'"
              >
                <DefaultSvg />
              </div>
              <div class="order__preview_360" v-if="files[activeFile].preview_3d">
                <Logo360 @click="setShow3dModel" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="files" :class="width <= 600 ? 'order__block' : 'order__files_block'">
          <div
            @click="setShowFiles"
            class="block block__between block__align-center order__files_label"
          >
            <div class="order__label">{{ text.total_files[language] }}: ({{ files.length }})</div>
            <div class="component__margin-right">
              <Arrow :class="!showFiles ? 'order__arrow' : ' order__arroworder__arrow_active'" />
            </div>
          </div>
          <div v-if="showFiles">
            <div
              v-for="(file, index) in files"
              @click="setActiveFile(index)"
              :class="`block__flex order__files_item ${
                activeFile === index ? 'order__files_active' : ''
              }`"
              v-bind:key="`file_${index}`"
            >
              <div style="width: 50px">
                <img :src="file.preview_img" v-if="file.preview_img" class="order__files_image" />
              </div>
              <div class="component__margin-left">{{ file.filename }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullSize from "@/components/fullSize/FullSize.vue";
import ModelViewer from "@/components/modelViewer/ModelViewer.vue";
import DefaultSvg from "@/assets/images/order/full_size_medium.svg";
import FullSizeLogo from "@/assets/images/order/full_size.svg";
import Logo360 from "@/assets/images/order/3d360.svg";
import Arrow from "@/assets/images/order/Arrow.svg";
import packageJson from "../../../package.json";
import Default from "@/assets/images/parts/edit-deafult.png";
import text from "@/assets/text/order.json";

export default {
  name: "FilePreview",
  data() {
    return {
      text,
      fsType: "image",
      fsLink: null,
      fsPreviewLink: null,
      fsFileName: "",
      fsShow: false,
      fsHash: null,
      fsTS: null,
      fsName: null,
      activeFile: 0,
      show3dModel: false,
      showFiles: true,
    };
  },
  props: {
    files: {
      type: Array,
    },
    width: {
      type: [Number, String],
    },
  },
  watch: {
    files: {
      immediate: true,
      handler: function () {
        this.activeFile = 0;
        this.setFS(0);
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    closeFullSize() {
      this.fsShow = false;
    },
    setLink(hash, timeStamp, fileName) {
      return `${packageJson.url}getFile?time_stamp=${timeStamp}&hash=${hash}&file_name=${fileName}&ext=stl`;
    },
    setFullSize() {
      this.fsShow = true;
    },
    setError(e) {
      e.target.src = Default;
    },
    setShow3dModel() {
      this.show3dModel = true;
    },
    setShowFiles() {
      this.showFiles = !this.showFiles;
    },
    setActiveFile(index) {
      this.show3dModel = false;
      this.activeFile = index;
      this.setFS(this.activeFile);
    },
    setFS(index) {
      if (this.files && this.files[index].preview_3d) {
        this.fsType = "3d";
        this.fsLink = this.files[index].preview_3d;
        this.fsFileName = this.files[index].filename;
      } else if (this.files && this.files[index].preview_img) {
        this.fsType = "image";
        this.fsLink = this.files[index].download || this.files[index].preview_img;
        this.fsFileName = this.files[index].filename || "";
        this.fsPreviewLink = this.files[index].preview_img || "";
      } else {
        this.fsType = "none";
        this.fsFileName = this.files[index].filename;
      }
    },
  },
  components: {
    Arrow,
    FullSize,
    ModelViewer,
    DefaultSvg,
    FullSizeLogo,
    Logo360,
  },
};
</script>
