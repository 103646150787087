var render = function render(){var _vm=this,_c=_vm._self._c;return _c('I5Modal',{attrs:{"show":_vm.show,"title":_vm.text.edit_modal_name[_vm.language]},on:{"close":_vm.onHandlerClose}},[_c('template',{slot:"body"},[_c('div',{staticClass:"block block__column",staticStyle:{"padding":"20px","overflow-y":"scroll","height":"calc(100% - 80px)"}},[_c('div',{staticClass:"block",staticStyle:{"gap":"10px"}},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":"Наименование на русском","name":"name_ru","default-value":_vm.editData.name_ru},on:{"change":(e) => _vm.onHandleChange(e, 'name_ru')}}),_c('div',{staticStyle:{"width":"180px"}},[_c('Toggle',{attrs:{"label":"Режимы обработки","name":"cutting_conditions","checked":_vm.editData.cutting_conditions,"value":_vm.editData.cutting_conditions},on:{"change":function($event){return _vm.onHandleChange($event, 'cutting_conditions')}}})],1)],1),_c('div',{staticClass:"block",staticStyle:{"gap":"10px"}},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":"Наименование (Eng)","name":"name_en","default-value":_vm.editData.name_en},on:{"change":(e) => _vm.onHandleChange(e, 'name_en')}}),_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":"Наименование (De)","name":"name_de","default-value":_vm.editData.name_de},on:{"change":(e) => _vm.onHandleChange(e, 'name_de')}})],1),_c('div',{staticClass:"block",staticStyle:{"gap":"10px"}},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":"Наименование (Tr)","name":"name_tr","default-value":_vm.editData.name_tr},on:{"change":(e) => _vm.onHandleChange(e, 'name_tr')}}),_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":"Наименование (Zh)","name":"name_zh","default-value":_vm.editData.name_zh},on:{"change":(e) => _vm.onHandleChange(e, 'name_zh')}})],1),_c('h3',[_vm._v("Параметры")]),_c('Search',{attrs:{"query":{
          method: 'post',
          url: 'getAllToolNomenclatureTypeParameters',
          findName: 'find',
          label: 'name',
          value: 'id',
        }},on:{"change":function($event){return _vm.addParam($event)}}}),(_vm.params && _vm.params.length > 0)?_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.params),function(el,index){return _c('div',{key:`${index}_param`,staticClass:"block block__between block__align-center",staticStyle:{"margin-bottom":"10px","border":"2px solid #f1f1f1","border-radius":"8px","padding":"5px"}},[_c('b',[_vm._v(_vm._s(el.name))]),_c('div',{staticClass:"tech-req__block_delete",on:{"click":function($event){return _vm.deleteParam(index)}}},[_c('TrashIcon')],1)])}),0):_vm._e()],1),_c('div',{staticClass:"block block__align-center block__right",staticStyle:{"height":"80px","gap":"12px","padding":"0 20px"}},[_c('Button',{attrs:{"type":"default","text":"Отмена"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('Button',{attrs:{"type":"primary","text":"Сохранить"},on:{"click":_vm.saveEdit}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }