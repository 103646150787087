<template>
  <div class="home__wrapper">
    <div class="home__container">
      <div class="home__card">
        <div class="home__calculator_title component__margin-bottom" v-if="mainTitle">
          {{ mainTitle }}
        </div>
        <div class="block" style="gap: 20px">
          <div v-if="mainInfo && mainInfo[0]" style="width: 100%">
            <div v-for="(column, index) in mainInfo[0]" v-bind:key="`main_info_1_${index}`">
              <div>
                <div class="home__card_title">
                  {{ column.name }}
                </div>
                <div class="home__card_text">
                  <b>{{ column.value }}</b>
                </div>
              </div>
            </div>
          </div>
          <div v-if="mainInfo && mainInfo[1]" style="width: 100%">
            <div v-for="(column, index) in mainInfo[1]" v-bind:key="`main_info_2_${index}`">
              <div>
                <div class="home__card_title">
                  {{ column.name }}
                </div>
                <div class="home__card_text">
                  <b>{{ column.value }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home__card">
        <div class="home__calculator_title component__margin-bottom">
          {{ text.calc_title[language] }}
        </div>
        <div class="home__calculator_text component__margin-bottom">
          {{ text.calc_text[language] }}
        </div>
        <div>{{ text.calc_point_1[language] }}</div>
        <div>{{ text.calc_point_2[language] }}</div>
        <div>{{ text.calc_point_3[language] }}</div>
        <Button
          class="component__margin-top"
          :text="text.calc_button[language]"
          type="attention"
          @click="goToOrder"
          icon="calculator"
          block
        />
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/home.json";
import { query } from "@/utilities/axios";
import Button from "@/components/button/Button.vue";

export default {
  name: "Home",
  data() {
    return {
      text,
      orders: null,
      companyInfo: {},
      mainInfo: null,
      mainTitle: "",
      ordersLoading: false,
      orderYears: [],
      ordersChart: {
        labels: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        datasets: [
          {
            label: "",
            backgroundColor: "#c32844",
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getOrdersChartByYear(data, year) {
      const dateOrders = {};
      for (const order of data) {
        const arrayDate = order.date.split(".");
        const date = new Date(arrayDate[2], arrayDate[1]);
        let value = 1;
        if (dateOrders[date.getFullYear()] && dateOrders[date.getFullYear()][date.getMonth()]) {
          value = dateOrders[date.getFullYear()][date.getMonth()] + 1;
        }
        dateOrders[date.getFullYear()] = {
          ...dateOrders[date.getFullYear()],
          ...{ [date.getMonth()]: value },
        };
      }
      const result = [];
      for (const [index] of this.ordersChart.labels.entries()) {
        if (dateOrders[year][index + 1]) {
          result.push(dateOrders[year][index + 1]);
        }
      }
      this.orderYears = Object.keys(dateOrders);
      this.ordersChart.datasets[0].data = result;
      this.ordersChart.datasets[0].label = `Заказы за ${year} г.`;
      this.ordersLoading = true;
    },
    getOrders() {
      query(
        "get",
        "getOrdersAsManufacturer",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((orders) => {
        this.orders = orders;
        this.getOrdersChartByYear(orders, +new Date().getFullYear() - 1);
      });
    },
    getCompanyFigures() {
      query(
        "get",
        "getCompanyFigures",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((figures) => {
        this.companyInfo = figures.company_info;
        this.mainInfo = figures.main_info;
        this.mainTitle = figures.title;
      });
    },
    goToOrder() {
      this.$router.push("/create_order/draft");
    },
  },
  created() {
    this.getOrders();
    this.getCompanyFigures();
  },
  components: {
    Button,
  },
};
</script>
