<template>
  <PageConstructor :query="query" :columns="columns" id-name="uuid"></PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/all_tools.json";
export default {
  name: "Tools/CuttingConditions",
  data() {
    return {
      query: {
        method: "post",
        url: "getToolCuttingConditions",
        headers: {},
        data: {
          id_tool_catalog_type: 1,
          uuid: this.$route.params.id,
        },
      },
      columns: [
        {
          name: "group",
          label: text.cc_group,
          options: {
            bold: true,
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "material_subgroup",
          label: text.cc_material_subgroup,
          options: {
            sort: true,
          },
          basis: 0,
          width: 400,
        },
        {
          name: "speed",
          label: text.cc_speed,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "depth",
          label: text.cc_depth,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "supply",
          label: text.cc_supply,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "min_tool_life",
          label: text.cc_min_tool_life,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
    };
  },
  components: {
    PageConstructor,
  },
};
</script>
