<template>
  <div class="user-profile__wrapper">
    <div class="user-profile__header">
      <div class="user-profile__header_title">{{ text.user_profile[language] }}</div>
    </div>
    <div class="user-profile__body">
      <div class="user-profile__block">
        <img :src="user.company_logo" class="component__margin-right user-profile__logo" />
        <div class="user-profile__value component__margin-top">{{ user.company_name }}</div>
      </div>
      <div class="user-profile__block">
        <div class="user-profile__title">{{ text.first_name[language] }}:</div>
        <div class="user-profile__value" v-if="user.first_name">{{ user.first_name }}</div>
      </div>
      <div class="user-profile__block">
        <div class="user-profile__title">{{ text.last_name[language] }}:</div>
        <div class="user-profile__value" v-if="user.last_name">{{ user.last_name }}</div>
      </div>
      <div class="user-profile__block">
        <div class="user-profile__title">{{ text.email[language] }}:</div>
        <div class="user-profile__value" v-if="user.email">{{ user.email }}</div>
      </div>
      <div class="user-profile__block">
        <div class="user-profile__title">{{ text.phone[language] }}:</div>
        <div class="user-profile__value" v-if="user.mobile">{{ user.mobile }}</div>
      </div>
      <div class="user-profile__block">
        <div>
          <Select
            style="max-width: 200px"
            :label="text.currency[language]"
            :values="currencyList"
            :default-value="user && user.currency ? user.currency : ''"
            @change="setCurrency($event)"
            :dropdown-props="{
              label: 'name',
              value: 'id',
            }"
          />
        </div>
      </div>
      <div class="user-profile__block">
        <div>
          <Select
            style="max-width: 200px"
            :label="text.language[language]"
            :values="languages"
            :default-value="user && user.lang ? user.lang : ''"
            @change="setLanguage($event)"
            :dropdown-props="{
              label: 'name',
              value: 'id',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/select/Select.vue";
import { query } from "@/utilities/axios";
import text from "@/assets/text/user_profile.json";

export default {
  name: "User",
  data() {
    return {
      text,
      languages: [],
      currencyList: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getLanguages() {
      query("post", "getLanguages", {}, {}).then((res) => {
        this.languages = res;
      });
    },
    getCurrency() {
      query("post", "getCurrency", {}, {}).then((res) => {
        this.currencyList = res;
      });
    },
    setLanguage(e) {
      const index = this.languages.findIndex((el) => el.id === e.value);
      if (index >= 0) {
        const token = localStorage.getItem("token");
        if (token) {
          query(
            "post",
            "changeUserInfoByUser",
            {
              lang: e.value,
            },
            {
              token: token,
            },
          ).then(() => {
            this.$store.dispatch("user/getUser").then(() => {
              if (this.user && this.user.lang) {
                this.$store.commit("index/setLanguage", this.user.lang);
              }
            });
          });
        }
      }
    },
    setCurrency(e) {
      const index = this.currencyList.findIndex((el) => el.id === e.value);
      if (index >= 0) {
        const token = localStorage.getItem("token");
        if (token) {
          query(
            "post",
            "changeUserInfoByUser",
            {
              currency: e.value,
            },
            {
              token: token,
            },
          ).then(() => {
            this.$store.dispatch("user/getUser");
          });
        }
      }
    },
  },
  mounted() {
    this.getLanguages();
    this.getCurrency();
  },
  components: {
    Select,
  },
};
</script>
