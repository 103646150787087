<template>
  <div style="height: calc(100% - 50px)">
    <div class="find__header">
      <div class="find__header_title">
        {{ text["find-title"][language] }}
      </div>
      <div></div>
    </div>
    <div class="find__wrapper">
      <div class="find__form">
        <Input
          :label="partText.code[language]"
          required
          :default-value="code"
          :error="codeError"
          @change="searchPart($event, 'code')"
          name="code"
        />
        <Input
          :label="partText.name[language]"
          required
          :error="nameError"
          :default-value="name"
          @change="searchPart($event, 'name')"
          name="name"
        />
        <Button
          block
          @click="createNewPart"
          :text="text.save_new_part[language]"
          style="margin-top: 20px"
        />
      </div>
      <div class="find__parts">
        <div style="padding-left: 25px">
          <div class="find__parts_title">{{ text.find_part_title[language] }}</div>
          <div class="find__parts_text">{{ text.find_part_text[language] }}</div>
        </div>
        <div class="find__parts_container">
          <div v-if="showParts && showParts.length > 0" class="find__parts_list">
            <div
              v-for="part in showParts"
              v-bind:key="part.uuid"
              class="find__parts_card"
              @click="setFindedPart(part.uuid)"
            >
              <div class="find__parts_img-container">
                <PartWithBack v-if="!part.preview || part.preview === 'default'" />
                <img
                  class="find__parts_img"
                  v-if="part.preview && part.preview !== 'default'"
                  :src="part.preview"
                />
              </div>
              <div class="find__parts_params">
                <div>
                  <div class="find__parts_card-title">{{ text.code[language] }}:</div>
                  <div class="find__parts_card-value">{{ part.code }}</div>
                </div>
                <div>
                  <div class="find__parts_card-title">{{ text.name[language] }}:</div>
                  <div class="find__parts_card-value">{{ part.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="find__parts_empty" v-if="!parts[index] || parts[index].length === 0">
            <PartBackground />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";
import partText from "@/assets/text/parts.json";
import text from "@/assets/text/create_order.json";
import { query } from "@/utilities/axios";
import PartBackground from "@/assets/images/parts/part_background_big.svg";
import PartWithBack from "@/assets/images/parts/part_with_back.svg";
import Button from "@/components/button/Button.vue";

export default {
  name: "FindOrCreate",
  data() {
    return {
      partText,
      text,
      code: "",
      name: "",
      codeError: null,
      nameError: null,
      showPartsList: false,
      parts: [],
      showParts: [],
      formsParts: [],
    };
  },
  methods: {
    searchPart(value, name) {
      this.codeError = null;
      this.nameError = null;
      this[name] = value;
      this.formsParts[this.index][name] = value;
      const filters = {
        code: [""],
        name: [""],
      };
      filters.code = this.code;
      filters.name = this.name;
      query(
        "post",
        "getPartsByCompanyV2",
        {
          page: 1,
          line_per_page: 10,
          filters: filters,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((parts) => {
        // this.showPartsList = true;
        this.parts[this.index] = parts.table;
        this.showParts = parts.table;
      });
    },
    setFindedPart(uuid) {
      this.$emit("setPart", uuid);
    },
    createNewPart() {
      if (this.code && this.name) {
        query(
          "post",
          "addNewPartV2",
          {
            code: this.code,
            name: this.name,
            files: [],
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((part) => {
          this.$emit("setPart", part.uuid);
        });
      }
      if (!this.code) {
        this.codeError = this.text.required[this.language];
      }
      if (!this.name) {
        this.nameError = this.text.required[this.language];
      }
    },
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    index: {
      immediate: true,
      handler: function (val) {
        const result = this.parts.slice();
        const resultForm = this.formsParts.slice();
        if (val || val === 0) {
          for (const el of [...Array(val + 1).keys()]) {
            if (!result[el]) {
              result.push([]);
            }
            if (!resultForm[el]) {
              resultForm.push({
                code: "",
                name: "",
              });
            }
          }
        }
        this.parts = result;
        this.formsParts = resultForm;
        this.showParts = this.parts[val];
        this.code = this.formsParts[val].code;
        this.name = this.formsParts[val].name;
        this.searchPart("", "name");
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    Input,
    PartBackground,
    PartWithBack,
    Button,
  },
};
</script>
