import Vue from "vue";
import Vuex from "vuex";
import index from "./modules/index";
import user from "./modules/user";
import specification from "./modules/specification";
import order from "./modules/order";
import model from "./modules/model";
import modelV2 from "./modules/modelV2";
import techProc from "./modules/tech-proc";
import tech from "./modules/tech";
import machineTools from "./modules/machineTools";
import techReq from "./modules/techReq";
import tables from "./modules/tables";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    index,
    user,
    specification,
    order,
    model,
    modelV2,
    techProc,
    tech,
    machineTools,
    techReq,
    tables,
  },
});
