<template>
  <div class="component__select">
    <EditModal
      :uuid="row.uuid"
      :sn="row.sn || ''"
      :commentValue="row.comment || ''"
      :minimumAmount="row.minimum_amount || ''"
      :code="row.code || ''"
      :show="isShowEditModal"
      @close="setShowEditModal(false)"
    />
    <div class="page__edit_margin page__edit_large-title">{{ label }}</div>
    <div>
      <div class="page__edit_title">{{ text.name[language] }}:</div>
      <div class="page__edit_margin page__edit_value component__select">
        {{ row.code }}
      </div>
    </div>
    <div>
      <div class="page__edit_title">{{ text.tool_type[language] }}:</div>
      <div class="page__edit_margin page__edit_value component__select">
        {{ row.tool_type }}
      </div>
    </div>
    <div
      v-if="row.img && !(toolData && toolData.files && toolData.files.length > 0)"
      class="block block__center"
    >
      <img :src="row.img" class="page__edit_img" />
    </div>
    <FilePreview
      v-if="toolData && toolData.files && toolData.files.length > 0"
      :width="width"
      :files="toolData.files"
    />
    <Button
      class="page__edit_margin"
      block
      :text="text.edit_button[language]"
      @click="setShowEditModal(true)"
    />
    <Button
      @click="goToHistory"
      class="page__edit_margin"
      block
      :text="text.tool_history[language]"
    />
    <Button
      class="page__edit_margin"
      block
      :text="text.assembly_button[language]"
      @click="openAssemblyNewWindow"
    />
    <Button
      v-if="this.row.cutting_conditions"
      class="page__edit_margin"
      block
      @click="
        () => {
          $router.push(`/all_tools/cutting_conditions/${row.uuid_tool}`);
        }
      "
      :text="text.cutting_conditions_button[language]"
    />
    <div>
      <div class="page__edit_title">{{ text.edit_amount[language] }}:</div>
      <div class="page__edit_margin page__edit_value component__select">
        {{ row.amount }}
      </div>
    </div>
    <div>
      <div class="page__edit_title">{{ text.edit_min_amount[language] }}:</div>
      <div class="page__edit_margin page__edit_value component__select">
        {{ row.minimum_amount }}
      </div>
    </div>
    <div class="page__edit_margin" v-if="toolData && toolData.parameters">
      <div class="page__edit_title">{{ text.params[language] }}</div>
      <div class="page__edit_line">
        <div
          v-for="({ name, value, dimension }, index) in toolData.parameters"
          v-bind:key="`${index}_param`"
        >
          <span class="component__select"
            >{{ name && name }} <b>{{ value && value }}</b> {{ dimension && dimension }}</span
          >
        </div>
      </div>
    </div>
    <div class="page__edit_title">{{ text.manufacturer[language] }}:</div>
    <div class="page__edit_margin page__edit_value component__select">
      {{ row.manufacturer }}
    </div>
    <div class="page__edit_title">{{ text.comment[language] }}:</div>
    <div class="page__edit_margin page__edit_value component__select">
      {{ row.comment }}
    </div>
    <div class="page__edit_margin" v-if="toolData && toolData.description">
      <div class="page__edit_title">{{ text.description[language] }}:</div>
      <div class="page__edit_value component__select">
        {{ toolData.description }}
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/tools.json";
import FilePreview from "@/components/filePreview/FilePreview.vue";
import Button from "@/components/button/Button.vue";
import EditModal from "../editModal/EditModal.vue";
import query from "@/utilities/axios";

export default {
  name: "Edit",
  data() {
    return {
      text,
      toolData: null,
      isShowEditModal: false,
    };
  },
  props: {
    row: {
      type: Object,
    },
    width: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
  },
  watch: {
    row: {
      immediate: true,
      handler: function (val) {
        if (val && val.uuid_tool) {
          this.getToolData(val.uuid_tool);
        }
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getToolData(uuid) {
      query(
        "post",
        "getOpenToolInfo",
        {
          uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.toolData = res;
      });
    },
    openAssemblyNewWindow() {
      const routeData = this.$router.resolve({
        path: `/all_tools/assembly/${this.row.uuid_tool}`,
      });
      window.open(routeData.href, "_blank");
    },
    goToHistory() {
      const routeData = this.$router.resolve({
        path: `/tool_history/${this.row.uuid}`,
      });
      window.open(routeData.href, "_blank");
    },
    setShowEditModal(val) {
      this.isShowEditModal = val;
      if (!val) {
        this.getToolData(this.row.uuid);
        this.$emit("update");
      }
    },
  },
  components: {
    Button,
    FilePreview,
    EditModal,
  },
};
</script>
