<template>
  <div class="layout__personal_wrapper">
    <div class="layout__personal_header">
      <Header />
    </div>
    <div class="block block__left">
      <div class="layout__personal_container">
        <slot v-if="!show404" name="page" />
        <Page404 v-if="show404" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/HeaderStandard.vue";
import Page404 from "@/components/404/Page404.vue";

export default {
  name: "StandardLayout",
  data() {
    return {};
  },
  computed: {
    show404() {
      return this.$store.state.index.show404;
    },
  },
  mounted() {},
  components: {
    Header,
    Page404,
  },
};
</script>
