<template>
  <div class="table table__wrapper">
    <div class="table__container">
      <div class="table__header" id="table_header">
        <div
          v-for="(column, index) in tableColumns.filter((col) => !col.options.formOnly)"
          v-bind:key="column.name"
          class="table__header_column"
          :style="setColumnStyle(column)"
        >
          <div class="block block__between block__align-center">
            <a-tooltip placement="top">
              <template slot="title">
                {{ column.label[language] }}
              </template>
              <div class="table__header_text">
                {{ column.label[language] }}
              </div>
            </a-tooltip>
            <div class="block__flex">
              <div
                v-if="column.options.sort"
                class="table__icon_container"
                @click="setSort(column)"
              >
                <a-icon type="caret-up" :style="setIconStyle('sort_up', column)" />
                <a-icon type="caret-down" :style="setIconStyle('sort_down', column)" />
              </div>
              <div
                @click="setFilter(column, index)"
                v-if="column.options.filter || column.options.searchFilter"
                class="table__icon_container"
              >
                <a-icon :style="setIconStyle('filter', column)" type="filter" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table__body_wrapper" :style="setBodyWrapperStyle()" id="table_body">
        <div :style="setBodyStyle()">
          <div
            class="table__filters_body table__filters_wrapper"
            id="table_filters"
            v-show="showFilter"
          >
            <div
              v-for="(column, index) in tableColumns"
              v-bind:key="`filter_${column.name}`"
              class="table__filters_column"
              :style="setColumnStyle(column)"
            >
              <div
                v-if="activeFilterName === column.name && column.options.searchFilter"
                class="table__filters_container"
              >
                <a-input-search :placeholder="column.label[language]" @change="filterSearch" />
                <div v-if="activeFilter[column.name]" class="component__margin-top">
                  <div
                    v-for="value in tableFilter[column.name]"
                    v-bind:key="`active_${column.name}_${value}`"
                  >
                    <div class="block">
                      <div>
                        <a-checkbox @change="removeTableSearchFilter(value)" :checked="true" />
                      </div>
                      <div class="component__margin-left">
                        {{ value }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="table__filters_delimiter"
                    v-if="
                      tableFilter[column.name] &&
                      tableFilter[column.name].length > 0 &&
                      activeFilter[column.name].length > 0
                    "
                  ></div>
                  <div
                    v-for="value in activeFilter[column.name]"
                    v-bind:key="`filter_${column.name}_${value}`"
                  >
                    <div class="block">
                      <div>
                        <a-checkbox @change="addTableSearchFilter(value)" />
                      </div>
                      <div class="component__margin-left">
                        {{ value }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="tableFilter[column.name] && tableFilter[column.name].length > 0"
                    @click="filterReset"
                    class="table__filters_clear"
                  >
                    {{ pageText.filterClear[language] }}
                  </div>
                </div>
              </div>
              <div
                v-if="activeFilterName === column.name && column.options.filter"
                class="table__filters_container"
              >
                <div v-if="activeFilter[column.name]" class="component__margin-top">
                  <div
                    v-for="item in column.options.filterValues"
                    v-bind:key="`active_${column.name}_${item.value}`"
                  >
                    <div class="block">
                      <div>
                        <a-checkbox
                          :checked="item.checked ? item.checked : false"
                          :default-checked="item.checked ? item.checked : false"
                          @change="setTableFilter(item.value, column, index)"
                        />
                      </div>
                      <div class="component__margin-left">
                        <span
                          >{{ item.label }} <b>({{ item.subLabel }})</b></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="table__filters_delimiter"
                    v-if="
                      tableFilter[column.name] &&
                      tableFilter[column.name].length > 0 &&
                      activeFilter[column.name].length > 0
                    "
                  ></div>
                  <div
                    v-if="tableFilter[column.name] && tableFilter[column.name].length > 0"
                    @click="filterReset"
                    class="table__filters_clear"
                  >
                    {{ pageText.filterClear[language] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :id="'table_rows'">
            <div
              v-for="(item, index) in values"
              v-bind:key="index"
              :class="`table__body ${item[idName] === activeIndex ? 'table__body_active' : ''}`"
              @click="handleRowClick(item, index)"
            >
              <div
                v-for="column in tableColumns.filter((col) => !col.options.formOnly)"
                v-bind:key="`cell_${column.name}`"
                class="table__header_column"
                :style="setColumnStyle(column)"
              >
                <TableCell
                  :item="item[column.name] && item[column.name] ? item[column.name] : ''"
                  :type="column.type && column.type"
                  :options="column.options && column.options"
                />
              </div>
            </div>
            <div v-if="values.length === 0 && loading" class="table__empty">
              <img src="@/assets/images/table/box.png" class="table__empty_image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table__footer">
      <div v-if="hideFooter"></div>
      <span class="block block__align-center component__margin-right" v-if="!hideFooter">
        <span v-for="(item, index) in totalValues" v-bind:key="`total_${index}`">
          <span class="block block__align-center" v-if="item.count">
            <span v-if="item.label" class="component__margin-right">
              {{ item.label }}
            </span>
            <b v-if="item.count" class="component__margin-right">
              {{ getNumberWithSpaces(item.count) }}
            </b>
            <b v-if="item.measure" class="component__margin-right">
              {{ item.measure }}
            </b>
          </span>
        </span>
      </span>
      <div class="table__footer_pagination">
        <a-pagination :current="page" @change="pageChange" :pageSize="pageSize" :total="total" />
      </div>
    </div>
  </div>
</template>

<script>
import { query as q } from "@/utilities/axios";
import { getNumberWithSpaces } from "@/utilities/functions/index";
import pageText from "@/assets/text/page_constructor.json";
import TableCell from "./TableCell.vue";

export default {
  name: "Table",
  data() {
    return {
      pageText,
      loading: false,
      q, // Функция работы с API
      getNumberWithSpaces,
      unsubscribe: () => {}, // Функция отписки от обновлений vuex
      pages: null, // Хранит DOM элемент всей таблицы
      body: null, // Хранит DOM элемент тела таблицы
      header: null, // Хранит DOM элемент хэдера таблицы
      rows: null, // Хранит DOM элемент строк таблицы
      filters: null, // Хранит DOM элемент фильтров таблицы
      showFilter: false, // скрывает или показывет фильтры
      values: [], // Данные таблицы
      totalValues: [], // Данные которые отображаем в футере таблицы
      total: null, // Количество строк в таблице всего
      totalPages: 1, // Количество страниц
      activeRow: null, // Объект выделенной строки
      activeIndex: null, // Id Активной строки
      liveLoad: true, // Если загрузка строк идет постранично при прокрутке
      scrollTimer: null, // Таймаут, нужен если слишком быстрая прокрутка
      topPage: 1, // Верхняя загруженная страница
      page: 1, // Текущая страница
      bottomPage: 2, // Нижняя загруженная страница
      pageSize: 10, // Количество строк на страницу
      pageHeight: null, // Высота страницы в пикселях
      sorter: {}, // Объект формируемый для сортировки
      activeFilter: {}, // Объект с фильтрами
      tableFilter: {}, // Результирующие фильтры для таблицы (не используется)
      activeFilterName: null, // Имя фильтра с которым работаем
      scrollTop: 0, // скролл по оси X
      scrollLeft: 0, // скролл по оси Y
      elY: 0, // Расстояние от таблицы до верха экрана
      loadedPages: [],
      bodyHeight: null,
      pageTitle: null,
      x: 55,
      tableColumns: [],
    };
  },
  props: {
    // Колонки таблицы
    tableName: {
      type: String,
    },
    idName: {
      type: String,
      default: "id",
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    // Данные для запроса строк таблицы
    query: {
      type: Object,
      default() {
        return {};
      },
    },
    // Данные для запроса фильтров
    queryFilter: {
      type: Object,
      default() {
        return {};
      },
    },
    externalFilters: {
      type: [Object, Array],
    },
    // Изменение размера по оси Y
    left: {
      type: Number,
      default: 0,
    },
    // Изменение размера по оси X
    bottom: {
      type: Number,
      default: 0,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    saveStore: {
      type: String,
    },
    setPageName: {
      type: Function,
    },
  },
  watch: {
    columns: function (val) {
      this.tableColumns = val;
    },
  },
  computed: {
    // Текущий язык пользователя
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    // Первоначальное получение данных текущей страницы
    getData(page, linePerPage, activeItem) {
      return new Promise((resolve) => {
        if (this.query.method && this.query.url) {
          const queryData = {
            page: page,
            line_per_page: linePerPage,
          };
          if (this.sorter) {
            queryData.sorter = this.sorter;
          }
          queryData.filters = this.tableFilter;
          if (this.externalFilters) {
            queryData.filters = this.externalFilters;
          }
          const headers = {};
          const token = localStorage.getItem("token");
          if (token) {
            headers.token = token;
          }
          q(
            this.query.method,
            this.query.url,
            {
              ...this.query.data,
              ...queryData,
            },
            {
              ...this.query.headers,
              ...headers,
            },
          ).then((data) => {
            this.loadedPages = [page];
            this.values = data.table;
            if (data && data.title) {
              this.setPageName(data.title);
            }
            if (!data.table || data.table.length === 0) {
              this.stopScrollTimer();
            }
            this.setRowPosition();
            if (!this.activeIndex && activeItem) {
              const index = data.table.findIndex((el) => el[this.idName] === activeItem);
              if (index >= 0) {
                this.activeRow = data.table[index];
                this.activeIndex = activeItem;
                this.$emit("rowClick", {
                  row: this.activeRow,
                  index: this.activeIndex,
                });
              }
            }
            if (data.title && data.title !== this.pageTitle) {
              this.pageTitle = data.title;
              this.$emit("setPageTitle", data.title);
            }
            if (data.filters) {
              this.$emit("filters", data.filters);
            }
            this.totalValues = data.table_bottom ?? [
              {
                label: "Total machine tools:",
                count: data.total_lines,
                measure: "pcs.",
              },
            ];
            this.total = data.total_lines;
            this.totalPages = data.total_pages;
            if (this.saveStore) {
              this.$store.commit(this.saveStore, data);
            }
            this.x = this.body.getBoundingClientRect().x;
            resolve(data);
          });
        }
      });
    },
    // Добавление страниц сверху или снизу от основной
    addData(page, linePerPage, direction, activeItem) {
      return new Promise((resolve) => {
        if (this.query.method && this.query.url) {
          const queryData = {
            page: page,
            line_per_page: linePerPage,
          };
          if (this.sorter) {
            queryData.sorter = this.sorter;
          }
          queryData.filters = this.tableFilter;
          if (this.externalFilters) {
            queryData.filters = this.externalFilters;
          }
          const headers = {};
          const token = localStorage.getItem("token");
          if (token) {
            headers.token = token;
          }
          q(
            this.query.method,
            this.query.url,
            {
              ...this.query.data,
              ...queryData,
            },
            {
              ...this.query.headers,
              ...headers,
            },
          ).then((data) => {
            this.loadedPages.push(page);
            this.loadedPages = this.loadedPages.sort((a, b) => a - b);
            if (!data.table || data.table.length === 0) {
              this.stopScrollTimer();
            }
            if (direction === "bottom") {
              const result = this.values.concat(data.table);
              this.values = result;
            } else {
              const result = data.table.concat(this.values);
              this.values = result;
              this.setRowPosition();
            }
            if (!this.activeIndex && activeItem) {
              const index = data.table.findIndex((el) => el[this.idName] === activeItem);
              if (index >= 0) {
                this.activeRow = data.table[index];
                this.activeIndex = activeItem;
                this.$emit("rowClick", {
                  row: this.activeRow,
                  index: this.activeIndex,
                });
              }
            }
            resolve(data);
          });
        }
      });
    },
    // Устанавливаем стили колонки
    setColumnStyle(column) {
      const result = {};
      if (column.basis === 0) {
        result.flexGrow = 1;
        result.flexBasis = "auto";
        result.width = `${column.width}px`;
      } else {
        result.flexBasis = `${column.basis}px`;
        result.width = `${column.basis}px`;
        result.minWidth = `${column.width}px`;
      }
      if (column.width) {
        result.flexShrink = "0";
      }
      return result;
    },
    // Устанавливаем ширину таблицы
    setBodyWrapperStyle() {
      return {
        width: `calc(100vw - ${this.x + this.left}px)`,
      };
    },
    // Устанавливаем высоту таблицы
    setBodyStyle() {
      this.bodyHeight = this.total * 35;
      return {
        height: `${this.bodyHeight}px`,
      };
    },
    // Событие нажатия на строку таблицы
    handleRowClick(item, index) {
      if (item[this.idName] !== this.activeIndex) {
        const realIndex = (this.loadedPages[0] - 1) * this.pageSize + index + 1;
        this.activeRow = item;
        this.activeIndex = item[this.idName];
        this.$store.commit("tables/setTable", {
          name: this.tableName,
          data: {
            index: realIndex,
            id: this.activeIndex,
          },
        });
      } else {
        this.activeRow = null;
        this.activeIndex = null;
        this.$store.commit("tables/delTable", {
          name: this.tableName,
        });
      }
      this.$emit("rowClick", {
        row: this.activeRow,
        index: this.activeIndex,
      });
    },
    // Устанавливаем расположение строк в зависимости от верхней загруженной страницы
    setRowPosition() {
      let scrollTop = 0;
      const pageHeight = this.pageSize * 35;
      if (this.topPage > 1 && !this.pageSize) {
        scrollTop = this.scrollTop - this.pageHeight;
      } else if (this.topPage > 1 && this.pageSize) {
        scrollTop = (this.topPage - 1) * pageHeight;
      }
      this.rows.setAttribute("style", `transform : translate3d(0, ${scrollTop}px, 0);`);
    },
    // Изменение страницы из пагинации
    pageChange(page, pageSize) {
      this.pageSize = pageSize;
      this.page = page;
      if (page > 1) {
        this.topPage = page - 1;
      } else {
        this.topPage = 1;
      }
      if (this.page < this.totalPages) {
        this.bottomPage = page + 1;
      } else {
        this.bottomPage = page;
      }
      this.body.scrollTop = this.setTableScrollTop();
      // this.body.scrollTop = (page - 1) * this.pageHeight;
      this.getData(this.page, this.pageSize).then(() => {
        if (this.page < this.totalPages) {
          this.addData(this.bottomPage, this.pageSize, "bottom");
        }
        if (page > 1) {
          this.addData(this.topPage, this.pageSize, "top");
        }
      });
    },
    // Событие выбора сортировки
    setSort(column) {
      let result = {};
      if (this.sorter.column !== column.name) {
        result.ASC = true;
        result.column = column.name;
      } else if (this.sorter.column === column.name && !this.sorter.ASC) {
        result = false;
      } else {
        result.ASC = false;
        result.column = column.name;
      }
      this.sorter = result;
      if (this.page > 1) {
        this.topPage = this.page - 1;
      } else {
        this.topPage = 1;
      }
      if (this.page < this.totalPages) {
        this.bottomPage = this.page + 1;
      } else {
        this.bottomPage = this.page;
      }
      this.getData(this.page, this.pageSize).then(() => {
        if (this.page < this.totalPages) {
          this.addData(this.bottomPage, this.pageSize, "bottom");
        }
        if (this.page > 1) {
          this.addData(this.topPage, this.pageSize, "top");
        }
      });
    },
    // Логика управления фильтрами
    setFilter(column, index) {
      if (column.options.filter) {
        if (!this.tableColumns[index].options.filterValues) {
          column.options.getFilter().then((result) => {
            const columns = this.tableColumns.slice();
            columns[index].options.filterValues = result;
            this.tableColumns = columns;
          });
        }
      }
      if (this.activeFilterName !== column.name) {
        this.activeFilterName = column.name;
      } else {
        this.activeFilterName = null;
      }
      this.showFilter = true;
      this.filters.setAttribute("style", `transform : translate3d(-${this.scrollLeft}px, 0, 0);`);
    },
    // Раскрашиваем иконки в хэдере таблицы
    setIconStyle(icon, column) {
      const result = {
        transition: "all .3s linear",
      };
      if (icon === "sort_up") {
        if (this.sorter.column && this.sorter.column === column.name && this.sorter.ASC === true) {
          result.color = "rgba(185, 42, 33, .9)";
        } else {
          result.color = "rgba(0, 0, 0, .5)";
        }
      } else if (icon === "sort_down") {
        if (this.sorter.column && this.sorter.column === column.name && this.sorter.ASC === false) {
          result.color = "rgba(185, 42, 33, .9)";
        } else {
          result.color = "rgba(0, 0, 0, .5)";
        }
      } else if (icon === "filter") {
        if (this.tableFilter[column.name] && this.tableFilter[column.name].length > 0) {
          result.color = "rgba(185, 42, 33, .9)";
        } else {
          result.color = "rgba(0, 0, 0, .5)";
        }
      }
      return result;
    },
    // Прокрутка строк таблицы по оси X и по оси Y
    onHandleScroll(val) {
      if (val.target.id === "table_body") {
        this.header.setAttribute(
          "style",
          `transform : translate3d(-${val.target.scrollLeft}px, 0, 0);`,
        );
        if (this.showFilter) {
          this.filters.setAttribute(
            "style",
            `transform : translate3d(-${val.target.scrollLeft}px, 0, 0);`,
          );
        }
        let page = 1;
        const pageHeight = this.pageSize * 35;
        const pageScroll = +(val.target.scrollTop / pageHeight).toFixed(1);
        const wholePart = +Math.floor(pageScroll);
        const checkValue =
          this.page === this.totalPages || this.page === this.totalPages - 1 ? 0.01 : 0.25;
        if (+(pageScroll - wholePart).toFixed(2) < checkValue) {
          page = wholePart + 1;
        } else {
          page = wholePart + 2;
        }
        /*
        if (
          val.target.scrollTop > this.scrollTop &&
          val.target.scrollTop < val.target.scrollHeight
        ) {
          page = Math.ceil(val.target.scrollTop / pageHeight) + 1;
        } else if (val.target.scrollTop < this.scrollTop && val.target.scrollTop > 0) {
          page = Math.round(val.target.scrollTop / pageHeight) + 1;
        } else if (val.target.scrollTop + pageHeight >= val.target.scrollHeight) {
          page = this.totalPages;
        } else {
          page = 1;
        }
         */
        this.scrollTop = val.target.scrollTop;
        this.scrollLeft = val.target.scrollLeft;
        if (page > this.page) {
          if (page < 1) {
            this.page = 1;
          } else if (page >= this.totalPages) {
            this.page = this.totalPages;
          } else {
            this.page = page;
          }
          if (
            this.page > this.topPage &&
            this.page < this.bottomPage &&
            this.page < this.totalPages
          ) {
            this.stopScrollTimer();
            this.liveLoad = true;
          } else if (
            this.page > this.topPage &&
            this.page === this.bottomPage &&
            this.page < this.totalPages
          ) {
            this.stopScrollTimer();
            this.liveLoad = true;
            this.bottomPage = this.page + 1;
            this.addData(this.bottomPage, this.pageSize, "bottom");
          } else if (this.page === this.totalPages && this.bottomPage === this.totalPages) {
            this.stopScrollTimer();
            this.liveLoad = true;
          } else {
            this.resetScrollTimer();
            this.liveLoad = false;
          }
        } else if (page < this.page) {
          this.page = page;
          if (this.page > 1 && this.page === this.topPage) {
            if (page > 1) {
              this.topPage = this.page - 1;
            } else {
              this.topPage = 1;
            }
            this.liveLoad = true;
            this.stopScrollTimer();
            this.addData(this.topPage, this.pageSize, "top");
          } else if (this.topPage <= this.page) {
            this.liveLoad = true;
            this.stopScrollTimer();
          } else {
            this.resetScrollTimer();
            this.liveLoad = false;
          }
        }
      }
    },
    // Изменение размера
    resize(val) {
      console.log(val);
      this.setTableSize();
      this.updateTable();
    },
    // Запускаем таймер на прокрутку
    startScrollTimer() {
      this.scrollTimer = setTimeout(() => {
        if (this.page > 1) {
          this.topPage = this.page - 1;
        } else {
          this.topPage = 1;
        }
        if (this.page < this.totalPages) {
          this.bottomPage = this.page + 1;
        } else {
          this.bottomPage = this.page;
        }
        this.getData(this.page, this.pageSize).then(() => {
          if (this.page < this.totalPages) {
            this.addData(this.bottomPage, this.pageSize, "bottom");
          }
          if (this.page > 1) {
            this.addData(this.topPage, this.pageSize, "top");
          }
        });
      }, 500);
    },
    // Остановка таймера прокрутки
    stopScrollTimer() {
      clearTimeout(this.scrollTimer);
      this.scrollTimer = null;
    },
    // Сброс таймера прокрутки
    resetScrollTimer() {
      this.stopScrollTimer();
      this.startScrollTimer();
    },
    // Поиск внутри фильтра
    filterSearch(evt) {
      const result = {
        filters: this.tableFilter,
        search: {
          column: this.activeFilterName,
          find: evt.target.value,
        },
      };
      q(
        this.queryFilter.method,
        this.queryFilter.url,
        {
          ...this.queryFilter.data,
          ...result,
        },
        {
          ...this.queryFilter.headers,
          ...{
            token: localStorage.getItem("token"),
          },
        },
      ).then((values) => {
        this.activeFilter = {
          ...this.activeFilter,
          ...{
            [this.activeFilterName]: values.table,
          },
        };
      });
    },
    addTableSearchFilter(val) {
      const result = [];
      result.push(val);
      const searchArr = [];
      this.activeFilter[this.activeFilterName].forEach((el) => {
        if (el !== val) {
          searchArr.push(el);
        }
      });
      this.activeFilter[this.activeFilterName] = searchArr;
      this.tableFilter = {
        ...this.tableFilter,
        ...{
          [this.activeFilterName]: [...(this.tableFilter[this.activeFilterName] || []), ...result],
        },
      };
      this.updateFilterTable();
    },
    setTableFilter(val, column, i) {
      const filterName = column.options.filterName
        ? column.options.filterName
        : this.activeFilterName;
      if (!this.activeFilter[filterName]) {
        this.activeFilter[filterName] = [];
      }
      let checked = false;
      const index = this.activeFilter[filterName].findIndex((el) => el === val);
      if (index < 0) {
        this.activeFilter[filterName].push(val);
        checked = true;
      } else {
        this.activeFilter[filterName].splice(index, 1);
        checked = false;
      }
      this.tableFilter = {
        ...this.tableFilter,
        ...{
          [filterName]: this.activeFilter[filterName] || [],
        },
      };
      const columns = this.tableColumns.slice();
      const valueIndex = columns[i].options.filterValues.findIndex((el) => el.value === val);
      if (valueIndex >= 0) {
        columns[i].options.filterValues[valueIndex].checked = checked;
      }
      this.tableColumns = columns;
      this.updateFilterTable();
    },
    removeTableSearchFilter(val) {
      const result = [];
      result.push(val);
      const searchArr = [];
      this.tableFilter[this.activeFilterName].forEach((el) => {
        if (el !== val) {
          searchArr.push(el);
        }
      });
      this.tableFilter[this.activeFilterName] = searchArr;
      this.activeFilter[this.activeFilterName] = [
        ...(this.activeFilter[this.activeFilterName] || []),
        ...result,
      ];
      this.updateFilterTable();
    },
    filterReset() {
      this.activeFilter[this.activeFilterName] = [
        ...(this.activeFilter[this.activeFilterName] || []),
        ...this.tableFilter[this.activeFilterName],
      ];
      this.tableFilter[this.activeFilterName] = [];
      this.updateFilterTable();
    },
    updateFilterTable() {
      this.scrollTop = 0;
      this.body.scrollTop = 0;
      this.page = 1;
      this.updateTable();
    },
    updateTable() {
      if (this.page > 1) {
        this.topPage = this.page - 1;
      } else {
        this.topPage = 1;
      }
      if (this.page < this.totalPages) {
        this.bottomPage = this.page + 1;
      } else {
        this.bottomPage = this.page;
      }
      // this.body.scrollTop = (this.page - 1) * this.pageHeight;
      this.scrollTop = this.setTableScrollTop();
      this.getData(this.page, this.pageSize).then(() => {
        if (this.page < this.totalPages) {
          this.addData(this.bottomPage, this.pageSize, "bottom");
        }
        if (this.page > 1) {
          this.addData(this.topPage, this.pageSize, "top");
        }
        if (this.activeIndex) {
          const index = this.values.findIndex((el) => el[this.idName] === this.activeIndex);
          if (index >= 0) {
            this.activeRow = this.values[index];
          }
          this.$emit("rowClick", {
            row: this.activeRow,
            index: this.activeIndex,
          });
        }
      });
    },
    setTableSize() {
      this.elY = this.$el.getBoundingClientRect().y;
      // Устанавливаем количество строк на страницу и высоту страницы в пикселях
      this.pageSize = Math.round((this.$el.clientHeight - this.elY) / 35);
      // this.pageSize = +((this.$el.clientHeight - this.elY) / 35).toFixed(0);
      this.pageHeight = this.$el.clientHeight - this.elY;
    },
    setTableScrollTop() {
      const pageHeight = this.pageSize * 35;
      const scrollTop = this.page > 1 ? (this.page - 1) * pageHeight : 0;
      return scrollTop;
    },
  },
  created() {
    // Подписка на изменение высоты окна
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "index/setHeight") {
        this.pageSize = Math.round((this.$el.clientHeight - 102) / 35);
      } else if (mutation.type === "user/setUser") {
        this.getData(this.page, this.pageSize);
      }
    });
  },
  mounted() {
    // Сохраняем все нужные элементы
    this.body = document.getElementById("table_body");
    this.header = document.getElementById("table_header");
    this.rows = document.getElementById("table_rows");
    this.filters = document.getElementById("table_filters");
    this.setTableSize();
    // Подписываемся на скролл
    this.body.addEventListener(
      "scroll",
      (val) => {
        this.onHandleScroll(val);
      },
      true,
    );
    this.tableColumns = this.columns;
    this.$emit("setUpdate", this.updateTable);
    // Получаем первую и вторую страницу
    const saved = this.$store.state.tables.tables[this.tableName] || null;
    if (saved && (saved.index || saved.index === 0) && (saved.id || saved.id === 0)) {
      const realPage = +Math.ceil(saved.index / this.pageSize);
      this.page = realPage;
      this.getData(this.page, this.pageSize, saved.id).then((res) => {
        this.loading = true;
        this.scrollTop = this.setTableScrollTop();
        // this.scrollTop = (this.page - 1) * this.pageHeight;
        if (this.page > 1) {
          this.topPage = this.page - 1;
        } else {
          this.topPage = 1;
        }
        if (this.page < this.totalPages) {
          this.bottomPage = this.page + 1;
        } else {
          this.bottomPage = this.page;
        }
        this.setRowPosition();
        this.body.scrollTop = this.setTableScrollTop();
        // this.body.scrollTop = (this.page - 1) * this.pageHeight;
        this.totalPages = res.total_pages;
        if (this.page < this.totalPages) {
          this.addData(this.bottomPage, this.pageSize, "bottom", saved.id);
        }
        if (this.page > 1) {
          this.addData(this.topPage, this.pageSize, "top", saved.id);
        }
      });
    } else {
      this.getData(this.page, this.pageSize)
        .then(() => {
          this.loading = true;
          this.addData(this.bottomPage, this.pageSize, "bottom");
        })
        .catch(() => {
          this.loading = true;
        });
    }
    // Подписываемся на изменение размера блока
    this.$el.addEventListener("resize", this.resize, false);
    this.tableColumns.forEach((column) => {
      if (column.options && column.options.filter) {
        this.activeFilter[column.name] = [];
      }
    });
  },
  beforeDestroy() {
    // Отписываемся от всего
    this.unsubscribe();
    this.body.removeEventListener(
      "scroll",
      (val) => {
        this.onHandleScroll(val);
      },
      true,
    );
    this.$el.removeEventListener("resize", this.resize, false);
  },
  components: {
    TableCell,
  },
};
</script>
