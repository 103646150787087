<template>
  <I5Modal
    :show="showCalcModal"
    :title="text.calc_standard_hour_title[language]"
    @close="closeCalcModal"
  >
    <template slot="body">
      <div class="block block__column block__between" style="height: 100%">
        <div style="overflow-y: auto; padding: 30px">
          <div class="block" style="margin-bottom: 15px">
            <div style="width: 100%">
              <div class="machine-tools__calc_title">{{ text.initial_data[language] }}:</div>
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="machine-tools__calc_title">{{ text.calc_res[language] }}:</div>
            </div>
          </div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :type="'number'"
                :min="0"
                :max="1000000000"
                :step="1"
                :ti="1"
                :label="`1. ${text.machine_price[language]}`"
                @change="checkStandardHour('mt_price', $event)"
                :default-value="mt_price ? mt_price : ''"
                :sub-label="text.rub[language]"
                required
              />
              <Input
                label-col
                :label="`2. ${text.fixture_price[language]}`"
                :type="'number'"
                :ti="2"
                :min="0"
                @change="checkStandardHour('add_price', $event)"
                :default-value="add_price ? add_price : ''"
                :max="1000000000"
                :step="1"
                :sub-label="text.rub[language]"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.total_purchase_price[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.total_price
                      ? getMoney(standardResult.total_price, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`3. ${text.number_shift[language]}`"
                required
                :type="'number'"
                :min="1"
                :max="24"
                :step="1"
                :ti="3"
                @change="checkStandardHour('working_hours_per_day', $event)"
                :default-value="working_hours_per_day ? working_hours_per_day : ''"
                :sub-label="text.hour[language]"
              />
              <Input
                label-col
                :label="`4. ${text.working_days_per_year[language]}`"
                @change="checkStandardHour('working_days_per_year', $event)"
                :default-value="working_days_per_year ? working_days_per_year : ''"
                :type="'number'"
                :min="1"
                :max="365"
                :step="1"
                :ti="4"
                required
                :sub-label="text.day[language]"
              />
              <Input
                label-col
                required
                :type="'number'"
                :min="1"
                :max="3"
                :step="1"
                :ti="5"
                @change="checkStandardHour('work_shifts', $event)"
                :default-value="work_shifts ? work_shifts : ''"
                sub-label=""
                :label="`5. ${text.number_shifts[language]}`"
              />
              <Input
                label-col
                required
                :type="'number'"
                :min="0"
                :max="100"
                :step="1"
                :ti="6"
                @change="checkStandardHour('utilization_rate', $event)"
                :default-value="utilization_rate ? utilization_rate : ''"
                sub-label="%"
                :label="`6. ${text.utilization_rate[language]}`"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">{{ text.hours_per_year[language] }}</div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.time_per_year
                      ? getNumberWithSpaces(standardResult.time_per_year)
                      : "0"
                  }}
                  {{ text.hour[language] }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                required
                :type="'number'"
                :min="1"
                :max="100"
                :step="1"
                :ti="7"
                @change="checkStandardHour('period_amortization', $event)"
                :default-value="period_amortization ? period_amortization : ''"
                :sub-label="text.years[language]"
                :label="`7. ${text.depreciation_period[language]}`"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between component__margin-bottom">
                <div class="machine-tools__params_title">
                  {{ text.amortization_percent[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.amortization_percent
                      ? getNumberWithSpaces(standardResult.amortization_percent)
                      : "0"
                  }}
                  %
                </div>
              </div>
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.amortization_per_year[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.amortization_per_year
                      ? getMoney(standardResult.amortization_per_year, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`8. ${text.credit_rate[language]}`"
                :type="'number'"
                :min="0"
                :max="1000"
                :ti="8"
                @change="checkStandardHour('credit_percent', $event)"
                :default-value="credit_percent ? credit_percent : ''"
                sub-label="%"
                :step="0.1"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.payments_per_year[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.pay_by_credit_per_year
                      ? getMoney(standardResult.pay_by_credit_per_year, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`9. ${text.equipment_area[language]}`"
                required
                :sub-label="text.m2[language]"
                :type="'number'"
                :min="1"
                :max="1000"
                :step="0.1"
                :ti="9"
                @change="checkStandardHour('square_for_mt', $event)"
                :default-value="square_for_mt ? square_for_mt : ''"
              />
              <Input
                label-col
                :label="`10. ${text.cost_of_renting[language]}`"
                required
                :type="'number'"
                :min="0"
                :max="10000000"
                @change="checkStandardHour('price_for_square_1m2', $event)"
                :default-value="price_for_square_1m2 ? price_for_square_1m2 : ''"
                :step="0.01"
                :ti="10"
                :sub-label="text.rub[language]"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.rent_for_prod_rent[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.rent_per_area
                      ? getMoney(standardResult.rent_per_area, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`11. ${text.installed_capacity[language]}`"
                required
                :type="'number'"
                :min="0"
                :max="1000"
                :step="0.1"
                :ti="11"
                :sub-label="text.kw[language]"
                @change="checkStandardHour('mt_power', $event)"
                :default-value="mt_power ? mt_power : ''"
              />
              <Input
                label-col
                :label="`12. ${text.cost_electricity[language]}`"
                required
                :type="'number'"
                :min="0"
                :max="10000"
                :step="0.01"
                :ti="12"
                :sub-label="text.rub[language]"
                @change="checkStandardHour('price_electricity', $event)"
                :default-value="price_electricity ? price_electricity : ''"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.energy_cost_year[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.pay_electricity_per_year
                      ? getMoney(standardResult.pay_electricity_per_year, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`13. ${text.staff_salary[language]}`"
                :type="'number'"
                :min="0"
                :max="10000000"
                :step="1"
                required
                :ti="13"
                @change="checkStandardHour('salary', $event)"
                :default-value="salary ? salary : ''"
                :sub-label="text.rub_month[language]"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.labor_cost[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.pay_staff_per_year
                      ? getMoney(standardResult.pay_staff_per_year, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`14. ${text.social_contributions[language]}`"
                required
                :type="'number'"
                :min="0"
                @change="checkStandardHour('social_tax', $event)"
                :default-value="social_tax ? social_tax : ''"
                :max="100"
                :ti="14"
                :step="1"
                sub-label="%"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.social_payments[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.social_payments
                      ? getMoney(standardResult.social_payments, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`15. ${text.administrative_contributions[language]}`"
                @change="checkStandardHour('admin_tax', $event)"
                :default-value="admin_tax ? admin_tax : ''"
                required
                :type="'number'"
                :min="0"
                :max="100"
                :step="1"
                :ti="15"
                sub-label="%"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.administrative_costs[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.admin_costs
                      ? getMoney(standardResult.admin_costs, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="machine-tools__delimiter"></div>
          <div class="block">
            <div style="width: 100%">
              <Input
                label-col
                :label="`16. ${text.other_contributions[language]}`"
                @change="checkStandardHour('other_tax', $event)"
                :default-value="other_tax ? other_tax : ''"
                required
                :type="'number'"
                :min="0"
                :max="100"
                :step="1"
                :ti="16"
                sub-label="%"
              />
            </div>
            <div style="width: 100%; margin-left: 40px">
              <div class="block block__between">
                <div class="machine-tools__params_title">
                  {{ text.other_costs[language] }}
                </div>
                <div class="machine-tools__params_value">
                  {{
                    standardResult && standardResult.other_costs
                      ? getMoney(standardResult.other_costs, "RUB", "ru")
                      : "0 ₽"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="machine-tools__bottom">
          <div class="block block__between">
            <div>
              <div class="machine-tools__params_title">{{ text.total_year[language] }}</div>
              <div class="machine-tools__params_value component__margin-bottom">
                {{
                  standardResult && standardResult.total_per_year
                    ? getMoney(standardResult.total_per_year, "RUB", "ru")
                    : "0 ₽"
                }}
              </div>
              <div class="machine-tools__total_title">
                {{ text.mt_standard_hour[language] }}
              </div>
              <div class="machine-tools__total_value">
                {{
                  standardResult && standardResult.mt_time_price
                    ? getMoney(standardResult.mt_time_price, "RUB", "ru")
                    : "0 ₽"
                }}
              </div>
            </div>
            <div class="component__margin-left">
              <Button
                class="component__margin-bottom"
                @click="duplicate"
                :text="text.duplicate[language]"
              />
              <Button
                @click="saveStandardHour"
                :text="text.save[language]"
                type="attention"
                block
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/machine_tools.json";
import { query } from "@/utilities/axios";
import { getMoney, getNumberWithSpaces } from "@/utilities/functions";

export default {
  name: "SetStandardHour",
  data() {
    return {
      text,
      getMoney,
      getNumberWithSpaces,
      showCalcModal: false,
      add_price: 0,
      admin_tax: 0,
      credit_percent: 0,
      mt_power: 0,
      mt_price: 0,
      other_tax: 0,
      period_amortization: 0,
      price_electricity: 0,
      price_for_square_1m2: 0,
      salary: 0,
      social_tax: 0,
      square_for_mt: 0,
      utilization_rate: 0,
      work_shifts: 0,
      working_days_per_year: 0,
      working_hours_per_day: 0,
      standardResult: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.openCalcModal();
      } else {
        this.closeCalcModal();
      }
    },
    row: function (val) {
      this.getMtPrice(val.id);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    closeCalcModal() {
      this.showCalcModal = false;
      this.$emit("cancel");
    },
    openCalcModal() {
      this.showCalcModal = true;
    },
    checkStandardHour(name, value) {
      this[name] = +value;
      const data = {
        id: +this.row.id,
        id_machine_tool: +this.row.id,
        save: false,
        add_price: +this.add_price,
        admin_tax: +this.admin_tax,
        credit_percent: +this.credit_percent,
        mt_power: +this.mt_power,
        mt_price: +this.mt_price,
        other_tax: +this.other_tax,
        period_amortization: +this.period_amortization,
        price_electricity: +this.price_electricity,
        price_for_square_1m2: +this.price_for_square_1m2,
        salary: +this.salary,
        social_tax: +this.social_tax,
        square_for_mt: +this.square_for_mt,
        utilization_rate: +(this.utilization_rate / 100).toFixed(2),
        work_shifts: +this.work_shifts,
        working_days_per_year: +this.working_days_per_year,
        working_hours_per_day: +this.working_hours_per_day,
      };
      query("post", "saveMtPricePerHour", data, {
        token: localStorage.getItem("token"),
      }).then((result) => {
        this.standardResult = result;
      });
    },
    saveStandardHour() {
      const data = {
        id: this.row.id,
        id_machine_tool: this.row.id,
        save: true,
        add_price: +this.add_price,
        admin_tax: +this.admin_tax,
        credit_percent: +this.credit_percent,
        mt_power: +this.mt_power,
        mt_price: +this.mt_price,
        other_tax: +this.other_tax,
        period_amortization: +this.period_amortization,
        price_electricity: +this.price_electricity,
        price_for_square_1m2: +this.price_for_square_1m2,
        salary: +this.salary,
        social_tax: +this.social_tax,
        square_for_mt: +this.square_for_mt,
        utilization_rate: +(this.utilization_rate / 100).toFixed(2),
        work_shifts: +this.work_shifts,
        working_days_per_year: +this.working_days_per_year,
        working_hours_per_day: +this.working_hours_per_day,
      };
      query("post", "saveMtPricePerHour", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("cancel");
      });
    },
    duplicate() {
      query(
        "post",
        "saveForAllMtPricePerHour",
        {
          id_machine_tool: this.row.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("cancel");
      });
    },
    getMtPrice(id) {
      query(
        "post",
        "getMtPricePerHour",
        {
          id_machine_tool: id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.add_price = +result.add_price;
        this.admin_tax = +result.admin_tax;
        this.credit_percent = +result.credit_percent;
        this.mt_power = +result.mt_power;
        this.mt_price = +result.mt_price;
        this.other_tax = +result.other_tax;
        this.period_amortization = +result.period_amortization;
        this.price_electricity = +result.period_amortization;
        this.price_for_square_1m2 = +result.price_for_square_1m2;
        this.salary = +result.salary;
        this.social_tax = +result.social_tax;
        this.square_for_mt = +result.square_for_mt;
        this.utilization_rate = +result.utilization_rate * 100;
        this.work_shifts = +result.work_shifts;
        this.working_days_per_year = +result.working_days_per_year;
        this.working_hours_per_day = +result.working_hours_per_day;
        this.checkStandardHour("add_price", this.add_price);
      });
    },
  },
  mounted() {
    this.getMtPrice(this.row.id);
  },
  components: {
    I5Modal,
    Input,
    Button,
  },
};
</script>
