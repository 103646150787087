import Vue from "vue";
import Antd from "ant-design-vue";
import { Timeline } from "vue2vis";
import App from "./App.vue";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
import "ant-design-vue/dist/antd.less";
import "chartjs-plugin-colorschemes";

Vue.use(Antd);
Vue.component("timeline", Timeline);

Vue.config.productionTip = false;

// Checking the user permission before going to the page
router.beforeEach((to, from, next) => {
  store.commit("index/setShow404", false);
  let access = true;
  const pushPage = () => {
    if (access) {
      next();
    } else if (store.state.user.user && !access && to.meta.auth) {
      next("/");
    } else if (store.state.user.user && !access && !to.meta.auth) {
      next("/");
    } else if (!store.state.user.user && !access && to.meta.auth) {
      next("/login");
    } else if (!store.state.user.user && !access && !to.meta.auth) {
      next("/404");
    } else {
      next("/404");
    }
  };
  const check = () => {
    // const user = store.state.user.user;
    const acc = store.state.user.access;
    const meta = to.meta;
    if (!(acc && acc.admin) && meta.prod === false) {
      access = false;
    } else if (
      !(
        (meta.admin && acc.admin) ||
        (meta.expert && acc.expert) ||
        (meta.manufacturer && acc.manufacturing) ||
        (!acc.admin && !acc.expert && !acc.manufacturing && meta.user)
      )
    ) {
      access = false;
    }
  };
  if (to.meta.auth) {
    store.commit("user/setClosableModal", false);
    store.commit("user/setAfterAuthorize", () => {});
    if (!store.state.user.user || !store.state.user.access) {
      store
        .dispatch("user/getUser")
        .then(() => {
          if (store.state.user.user && store.state.user.user.lang) {
            store.commit("index/setLanguage", store.state.user.user.lang);
          }
          check();
          pushPage();
        })
        .catch(() => {
          const afterAuthorize = () => {
            next();
          };
          store.commit("user/setAfterAuthorize", afterAuthorize);
          store.commit("user/setShowAuthorizeModal", true);
        });
    } else {
      store.dispatch("user/getUser").then(() => {
        if (store.state.user.user && store.state.user.user.lang) {
          store.commit("index/setLanguage", store.state.user.user.lang);
        }
      });
      check();
      pushPage();
    }
  } else {
    pushPage();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // set user language
    const savedLanguage = localStorage.getItem("language");
    let resultLang = "en";
    if (!savedLanguage) {
      const language = window.navigator.userLanguage || window.navigator.language;
      resultLang = language.split("-")[0];
    } else {
      resultLang = savedLanguage;
    }
    if (resultLang === "ru") {
      this.$store.commit("index/setLanguage", "ru");
    } else if (resultLang === "en") {
      this.$store.commit("index/setLanguage", "en");
    } else {
      this.$store.commit("index/setLanguage", "en");
    }
  },
}).$mount("#app");
