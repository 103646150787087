<template>
  <div>
    <!--<h3>Принимать заказы</h3>
    <a-switch :default-checked="row.get_new_orders" />
    <h3>Наладчик</h3>
    <div v-if="row && row.last_name">{{row.last_name}}</div>
    <h3>Стоимость нормочаса</h3>
    <div class="block">
      <Input :default-value="row.labor_hour_price" />
      <IconButton class="component__margin-left" icon-name="calculator" />
    </div>-->
    <SetStandardHour :row="row" :show="showStandardModal" @cancel="closeCalcModal" />
    <Modal
      :ok-text="text.save"
      name="editMachineTool"
      @cancel="closeEditModal"
      @ok="editMachineToolFromModal"
      :show="showEditModal"
      :title="text.edit_mt_title[language]"
    >
      <template slot="body">
        <span class="machine-tools__margin" style="font-size: 18px; font-weight: 600">
          {{ row.machine_tool_name }} ({{ row.manufacturing }})
        </span>
        <Input
          :label="text.serial_number[language]"
          style="width: 100%"
          :default-value="row.serial_number"
          @change="saveEdit($event, 'serial_number')"
        />
        <Select
          :values="shops"
          @change="saveEdit($event.value, 'id_shop')"
          style="width: 100%"
          :default-value="row.shop_name"
          :label="text.shop_name[language]"
          :dropdown-props="{
            label: 'name',
            value: 'id',
          }"
          :position-type="'fixed'"
        />
        <Select
          :label="text.accuracy_class[language]"
          :defaultValue="row && row.accuracy_class ? row.accuracy_class : ''"
          :values="accuracyClasses"
          @change="saveEdit(+$event.value, 'id_current_accuracy_class')"
          :position-type="'fixed'"
          :dropdown-props="{
            label: 'name',
            value: 'id',
          }"
        />
      </template>
    </Modal>
    <Modal
      name="addMachineTool"
      @cancel="closeDeleteModal"
      @ok="deleteMachineTool"
      :okText="text.delete"
      :show="showDeleteModal"
      :title="text.delete_title[language]"
    >
      <template slot="body">
        <div class="component__margin">
          <span style="font-size: 18px"
            >{{ text.delete_text[language] }}
            <b>{{ row.serial_number }} {{ row.machine_tool_name }}</b
            >?
          </span>
        </div>
      </template>
    </Modal>
    <Button
      block
      size="small"
      @click="changeStatus()"
      class="machine-tools__margin"
      :text="row.get_new_orders ? text.disable[language] : text.enable[language]"
    />
    <Button
      class="machine-tools__margin"
      size="small"
      block
      @click="openCalcModal"
      :text="text.calc_standard_hour[language]"
    />
    <Button
      class="machine-tools__margin"
      size="small"
      block
      @click="openEditModal"
      :text="text.edit[language]"
    />
    <Button
      class="machine-tools__margin"
      size="small"
      block
      @click="openDeleteModal"
      :text="text.delete[language]"
    />
    <div class="machine-tools__margin" style="width: 100%">
      <span class="machine-tools__mt"
        >{{ text.mt[language] }}
        <span
          :class="`machine-tools__status machine-tools__status_${
            row.get_new_orders ? 'success' : 'danger'
          }`"
        >
          {{
            row.get_new_orders ? text.get_new_orders[language] : text.dont_get_new_orders[language]
          }}
        </span>
      </span>
    </div>
    <div class="machine-tools__params_title">{{ text.serial_number[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.serial_number }}
    </div>
    <div class="block block__center">
      <img v-if="row.img" :src="row.img" class="machine-tools__image" />
    </div>
    <div class="machine-tools__params_title">{{ text.machine_tool_name[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.machine_tool_name }}
    </div>
    <div class="machine-tools__params_title">{{ text.manufacturing[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.manufacturing }}
    </div>
    <div class="machine-tools__params_title">{{ text.machine_tool_method[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.machine_tool_method }}
    </div>
    <div class="component__margin-bottom">
      <div class="machine-tools__params_title component__margin-bottom">
        {{ text.accuracy_class[language] }}
      </div>
      <div class="machine-tools__margin machine-tools__params_value">
        {{ row && row.accuracy_class ? row.accuracy_class : "-" }}
      </div>
    </div>
    <div class="machine-tools__params_title">{{ text.shop_name[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.shop_name }}
    </div>
    <div class="machine-tools__title component__margin-bottom">
      {{ text.labor_hour_price[language] }}:
    </div>
    <Input
      :default-value="row.labor_hour_price || ''"
      style="width: 100%"
      @change="setLaborHour(+$event)"
    />
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";
import Modal from "@/components/modal/Modal.vue";
import Select from "@/components/select/Select.vue";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/machine_tools.json";
import { query } from "@/utilities/axios";
import SetStandardHour from "../setStandardHour/SetStandardHour.vue";

export default {
  name: "MachineEdit",
  data() {
    return {
      text,
      timer: null,
      showAddModal: false,
      showStandardModal: false,
      showEditModal: false,
      showDeleteModal: false,
      serialNumber: null,
      laborHourPrice: null,
      idShop: null,
      idAccClass: null,
      shops: [],
      accuracyClasses: [],
    };
  },
  props: {
    row: Object,
  },
  watch: {
    row: {
      handler: () => {
        if (this.$store.state.machineTools.hourPriceData) {
          this.editMachineTool();
        }
      },
      deep: true,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    openCalcModal() {
      this.showStandardModal = true;
    },
    openEditModal() {
      if (this.shops.length === 0) {
        this.getShops();
      }
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.$emit("update");
    },
    closeCalcModal() {
      this.showStandardModal = false;
      this.$emit("update");
    },
    openDeleteModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.$emit("update");
    },
    changeStatus() {
      query(
        "post",
        "useMachineToolForOrders",
        {
          get_new_orders: !this.row.get_new_orders,
          id: this.row.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
    editMachineTool() {
      const data = this.$store.state.machineTools.hourPriceData;
      query("post", "changeCompanyMachineToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        // this.serialNumber = null;
        this.$store.commit("machineTools/clearHourPriceData");
        this.$emit("update");
        // this.closeEditModal();
      });
    },
    editMachineToolFromModal() {
      const data = {
        id: this.row.id,
      };
      if (this.serialNumber) {
        data.serial_number = this.serialNumber;
      }
      if (this.idShop) {
        data.id_shop = this.idShop;
      }
      if (this.laborHourPrice) {
        data.labor_hour_price = this.laborHourPrice;
      }
      if (this.idAccClass) {
        data.id_current_accuracy_class = this.idAccClass;
      }
      query("post", "changeCompanyMachineToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.serialNumber = null;
        this.$emit("update");
        this.closeEditModal();
      });
    },
    getShops() {
      query(
        "get",
        "getCurrentCompaniesShops",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((shops) => {
        this.shops = shops;
      });
    },
    saveEdit(value, name) {
      if (name === "serial_number") {
        this.serialNumber = value;
      } else if (name === "id_shop") {
        this.idShop = value;
      } else if (name === "id_current_accuracy_class") {
        this.idAccClass = value;
      }
    },
    setLaborHour(val) {
      this.laborHourPrice = val;
      this.resetTimer();
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.editMachineTool();
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer() {
      const data = {
        id: this.row.id,
      };
      if (this.serialNumber) {
        data.serial_number = this.serialNumber;
      }
      if (this.idShop) {
        data.id_shop = this.idShop;
      }
      if (this.laborHourPrice) {
        data.labor_hour_price = this.laborHourPrice;
      }
      this.$store.commit("machineTools/setHourPriceData", data);
      this.stopTimer();
      this.startTimer();
    },
    deleteMachineTool() {
      query(
        "delete",
        "deleteMachineToolFromCompany",
        {},
        {
          token: localStorage.getItem("token"),
          id: this.row.id,
        },
      ).then(() => {
        this.$emit("update");
        this.closeDeleteModal();
      });
    },
    getAccuracyClasses() {
      query(
        "get",
        "getAccuracyClasses",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.accuracyClasses = res;
      });
    },
  },
  created() {
    this.getAccuracyClasses();
  },
  components: {
    Input,
    // IconButton,
    Button,
    SetStandardHour,
    Modal,
    Select,
    // Search,
  },
};
</script>
