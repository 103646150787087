<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :page-name="text.page_name[language]"
  >
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/billing.json";

export default {
  name: "Billing",
  data() {
    return {
      text,
      query: {},
      columns: [
        {
          name: "date",
          label: text.date,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "incomes",
          label: text.incomes,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "expenses",
          label: text.expenses,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
