<template>
  <div class="create-order__wrapper">
    <Modal
      :show="showDeletePartModal"
      :ok-text="partText.delete_part_ok"
      @cancel="
        () => {
          showDeletePartModal = false;
        }
      "
      @ok="deletePart"
      :title="partText.delete_part_title[language]"
    >
      <template slot="body">
        <div class="create-order__del-modal" v-if="deletePartModalData">
          <span>
            {{ partText.delete_part_text[language] }}:
            <b>{{ deletePartModalData.code }}-{{ deletePartModalData.name }}</b> ?
          </span>
        </div>
      </template>
    </Modal>
    <div class="create-order__tree">
      <div style="padding-right: 20px; margin-bottom: 20px">
        <Button
          @click="goToSpec()"
          :disabled="disableSpecButton"
          block
          :text="text.edit_cancel[language]"
        />
      </div>
      <div>
        <span class="create-order__status create-order__status_title">
          {{ text.order_status[language] }}:
        </span>
        <span class="create-order__status create-order__status_value component__margin-left">
          {{ orderStatus }}
        </span>
      </div>
      <!--<tree-component
        class="create-order__tree-comp"
        id-name="uuid"
        id="tree"
        :width="308"
        :active-tree-id="activePartUuid"
        @click="treeClick($event)"
        @del-part="setShowDeletePart($event)"
        @loading="treeLoading()"
        @setChangeActive="saveChangeActiveTree($event)"
        :values="treeParts" />-->
      <PartList
        :parts="parts"
        :order-name="order && order.name ? order.name : ''"
        :active-uuid="activePartUuid"
        @click="handleSelect($event)"
        @delete="setShowDeletePart($event)"
        class="create-order__tree-comp"
      />
      <div style="margin-top: 15px; padding: 0 20px 0 0">
        <Button
          :text="text.add_part[language]"
          :disabled="disableAddDraftButton"
          block
          @click="addDraftPart"
        />
      </div>
    </div>
    <div class="create-order__body">
      <EditPart
        @save="updateTree"
        :part-uuid="activePart && activePart.uuid ? activePart.uuid : null"
        v-if="activePart && activePart.status === 'added'"
      />
      <FindOrCreate
        @setPart="addNewPart($event)"
        v-if="activePart && activePart.status === 'draft'"
      />
    </div>
  </div>
</template>

<script>
// import TreeComponent from '@/components/tree/TreeComponent.vue';
import partText from "@/assets/text/parts.json";
import text from "@/assets/text/create_order.json";
import Button from "@/components/button/Button.vue";
import { query } from "@/utilities/axios";
import Modal from "@/components/modal/Modal.vue";
import FindOrCreate from "./components/FindOrCreate.vue";
import EditPart from "./components/EditPart.vue";
import PartList from "./components/PartList.vue";

export default {
  name: "CreateOrder",
  data() {
    return {
      partText,
      text,
      tree: null,
      loading: false,
      order: null,
      activePartIndex: 0,
      activePartUuid: null,
      activePart: null,
      orderStatus: "",
      treeParts: [
        {
          id: 0,
          type: "order",
          status: "draft",
          name: "-",
          children: [
            {
              id: "0-0",
              type: "part",
              status: "draft",
              name: text.draft[this.$store.state.index.language],
            },
          ],
        },
      ],
      parts: [],
      partData: null,
      findParts: [],
      code: "",
      name: "",
      showPartsList: false,
      changeActiveTree: null,
      disableAddDraftButton: false,
      showDeletePartModal: false,
      deletePartModalData: null,
      disableSpecButton: false,
    };
  },
  watch: {
    "$route.params.id": function () {
      this.startOrder();
    },
    disableSpecButton: function (val) {
      console.log(val);
    },
  },
  methods: {
    setShowDeletePart(val) {
      this.deletePartModalData = val;
      this.showDeletePartModal = true;
    },
    handleSelect(value) {
      // this.activePartIndex = +value[0].split('-')[1];
      this.activePart = value;
      this.activePartUuid = value.uuid;
    },
    searchPart(value, name) {
      this[name] = value;
      const filters = {
        code: [""],
        name: [""],
      };
      filters[name] = [value];
      query(
        "post",
        "getPartsByCompanyV2",
        {
          page: 1,
          line_per_page: 10,
          filters: filters,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((parts) => {
        this.showPartsList = true;
        this.findParts = parts.table;
      });
    },
    createOrder(part) {
      const parts = [];
      parts.push(part);
      query(
        "post",
        "addNewOrderV2",
        {
          parts: parts,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((order) => {
        this.$router.push(`/create_order/${order.uuid}`);
      });
    },
    changeParent(data, index) {
      const result = this.treeParts.slice();
      result[index] = { ...this.treeParts[index], ...data };
      this.treeParts = result;
    },
    setChildren(data, index) {
      const result = this.treeParts.slice();
      result[index].children = data;
      this.treeParts = result;
      let draftCount = 0;
      this.treeParts[0].children.forEach((part) => {
        if (part.status === "draft") {
          draftCount += 1;
        }
      });
      if (draftCount === 0) {
        this.disableAddDraftButton = false;
      } else {
        this.disableAddDraftButton = true;
      }
      setTimeout(() => {
        this.tree.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 1000);
    },
    addChildren(data, index) {
      // const activeIndex = data.findIndex((el) => el.uuid = uuid);
      const result = this.treeParts.slice();
      result[index].children = [...result[index].children, ...data];
      this.treeParts = result;
      // this.changeActiveTree(1, data[0].uuid);
      this.activePartUuid = data[0].uuid;
      let draftCount = 0;
      this.treeParts[0].children.forEach((part) => {
        if (part.status === "draft") {
          draftCount += 1;
        }
      });
      if (draftCount === 0) {
        this.disableAddDraftButton = false;
      } else {
        this.disableAddDraftButton = true;
      }
      // this.tree.scrollTop = this.tree.scrollHeight;
    },
    checkDraft() {
      let result;
      let draftCount = 0;
      this.parts.forEach((part) => {
        if (part.status === "draft") {
          draftCount += 1;
        }
      });
      if (draftCount) {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    addDraftPart() {
      const result = [];
      if (this.checkDraft()) {
        this.disableAddDraftButton = true;
        result.push({
          type: "part",
          status: "draft",
          name: `${text.draft[this.language]}`,
          index: this.parts.length,
          uuid: "draft",
        });
        this.parts = [...this.parts, ...result];
        this.activePart = result[0];
        this.activePartUuid = result[0].uuid;
      }
    },
    addNewPart(uuid) {
      if (this.$route.params.id !== "draft") {
        query(
          "post",
          "addNewPartToOrderV2",
          {
            order_uuid: this.$route.params.id,
            parts: [uuid],
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((part) => {
          this.activePartUuid = part.order_part;
          this.getSpec(this.$route.params.id, 1);
          this.disableAddDraftButton = false;
        });
      } else {
        this.createOrder(uuid);
      }
    },
    treeClick(leaf) {
      if (leaf.layer > 0) {
        this.activePartIndex = leaf.index;
        this.activePartUuid = leaf.uuid;
      }
    },
    saveChangeActiveTree(func) {
      this.changeActiveTree = func;
    },
    treeLoading() {
      if (typeof this.changeActiveTree === "function") {
        // this.changeActiveTree(1, 0);
      }
    },
    getSpec(uuid, page) {
      query(
        "post",
        "getCurrentCompaniesOrderV2",
        {
          uuid: uuid,
          page: page,
          line_per_page: 100,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((parts) => {
        /* this.changeParent({
          name: parts.title,
        }, 0); */
        const result = [];
        parts.table.forEach((item, index) => {
          result.push({
            type: "part",
            status: "added",
            code: item.code,
            name: item.name,
            preview: item.preview,
            uuid: item.uuid,
            index: index,
          });
        });
        // this.setChildren(result, 0);
        // this.changeActiveTree(0, 1);
        if (page === 1) {
          this.parts = result;
          if (!this.activePart) {
            this.activePart = this.parts[0];
          }
        } else {
          this.parts = [...this.parts, ...result];
        }
        if (parts.total_pages > page) {
          this.getSpec(uuid, page + 1);
        }
        if (!this.activePartUuid) {
          this.activePartUuid = this.parts[0].uuid;
          const index = this.parts.findIndex((part) => part.uuid === this.activePartUuid);
          if (index >= 0) {
            this.activePart = this.parts[index];
          }
        } else {
          const index = this.parts.findIndex((part) => part.uuid === this.activePartUuid);
          if (index >= 0) {
            this.activePart = this.parts[index];
          }
        }
        this.disableAddDraftButton = !this.checkDraft();
      });
    },
    getOrder(uuid) {
      query(
        "post",
        "getOrderInfoV2",
        {
          uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((order) => {
        this.order = order.order_info;
        this.orderStatus = order.order_info.status;
      });
    },
    deletePart() {
      query(
        "delete",
        "deletePartFromOrderV2",
        {
          uuid: this.deletePartModalData.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.deletePartModalData = null;
        this.activePartUuid = null;
        this.activePart = null;
        this.getSpec(this.$route.params.id, 1);
        this.showDeletePartModal = false;
      });
    },
    goToSpec() {
      this.$router.push(`/order/${this.$route.params.id}`);
    },
    updateTree() {
      this.getSpec(this.$route.params.id, 1);
    },
    startOrder() {
      this.tree = document.getElementById("tree");
      if (!this.loading) {
        if (this.$route.params.id !== "draft") {
          this.loading = true;
          this.activePart = null;
          this.activePartUuid = null;
          this.getSpec(this.$route.params.id, 1);
          this.getOrder(this.$route.params.id);
          this.disableSpecButton = this.checkDraft();
        } else {
          this.disableSpecButton = true;
          this.orderStatus = this.text.draft[this.language];
          this.disableAddDraftButton = true;
          const result = [];
          result.push({
            type: "part",
            status: "draft",
            name: `${text.draft[this.language]}`,
            index: this.parts.length,
            uuid: "draft",
          });
          this.parts = result;
          this.activePart = result[0];
          this.activePartUuid = result[0].uuid;
        }
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.id !== "draft") {
        vm.loading = true;
        vm.getSpec(to.params.id, 1);
        vm.getOrder(to.params.id);
        vm.disableSpecButton = false;
      } else {
        vm.orderStatus = vm.text.draft[vm.language];
        vm.disableSpecButton = true;
      }
    });
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  mounted() {
    this.startOrder();
  },
  components: {
    // TreeComponent,
    Button,
    FindOrCreate,
    EditPart,
    Modal,
    PartList,
  },
};
</script>
