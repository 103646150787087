<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <a :href="`https://market.i5.solutions/measure_tool/${row.uuid}`" target="_blank">
        <Button block :text="text.view[language]" class="page__edit_margin" />
      </a>

      <div class="page__edit_margin" v-if="row.logo">
        <img :src="row.logo" class="page__edit_img" />
      </div>

      <div class="page__edit_margin" v-if="row.name">
        <div class="page__edit_title">{{ text.name[language] }}:</div>
        <div class="page__edit_value">
          {{ row.name }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.company_name">
        <div class="page__edit_title">{{ text.company_name[language] }}:</div>
        <div class="page__edit_value">
          {{ row.company_name }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.done">
        <div class="page__edit_title">{{ text.done[language] }}:</div>
        <div class="page__edit_value">
          {{ row.done }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/measuring_tools_catalogue.json";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  components: { Button },
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
