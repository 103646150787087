<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :page-name="text.page_name[language]"
  >
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/locale_standards.json";

export default {
  name: "LocaleStandards",
  data() {
    return {
      text,
      query: {},
      columns: [
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "upload_date",
          label: text.upload_date,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "user",
          label: text.user,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
