var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"inherit"}},[_c('Modal',{attrs:{"name":"addMachineTool","show":_vm.showAddModal,"title":_vm.text.add_shop_title[_vm.language]},on:{"cancel":_vm.closeAddModal,"ok":_vm.addShop}},[_c('template',{slot:"body"},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":_vm.text.name[_vm.language],"error":_vm.nameError,"required":""},on:{"change":function($event){return _vm.saveAdd($event, 'name')}}}),_c('Select',{attrs:{"values":_vm.shopTypes,"error":_vm.shopTypesError,"required":"","label":_vm.text.shop_type[_vm.language],"dropdown-props":{
          label: 'name',
          value: 'id',
        },"position-type":'fixed'},on:{"change":function($event){return _vm.saveAdd($event.value, 'shop_type')}}}),_c('Select',{attrs:{"values":_vm.regions,"error":_vm.regionsError,"required":"","label":_vm.text.place[_vm.language],"dropdown-props":{
          label: 'name',
          value: 'id',
        },"position-type":'fixed'},on:{"change":function($event){return _vm.saveAdd($event.value, 'region')}}})],1)],2),_c('PageConstructor',{attrs:{"buttons":_vm.buttons,"query":_vm.query,"columns":_vm.columns,"id-name":"id","page-name":_vm.text.page_name[_vm.language]},on:{"setUpdate":function($event){return _vm.setUpdate($event)},"headerClick":function($event){return _vm.headerClick($event)}},scopedSlots:_vm._u([{key:"edit",fn:function({ row }){return [_c('Edit',{attrs:{"row":row},on:{"update":_vm.updateTable}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }