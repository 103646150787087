<template>
  <Modal :show="show" @close="handleClose" :title="text.add_title[language]">
    <template slot="body">
      <div class="block block__column block__between" style="height: 100%; padding: 10px">
        <div style="height: 100%; overflow-y: auto">
          <div v-if="activeStep === 0">
            <div class="block">
              <Select
                required
                :label="text.country[language]"
                :default-value="country && country.label ? country.label : ''"
                :position-type="'fixed'"
                :dropdown-props="{
                  label: 'name',
                  value: 'id',
                }"
                @change="setCountry($event)"
                :values="countries"
                style="width: 100%"
              />
              <Select
                v-if="country.value === 1"
                required
                :label="text.forms_of_business[language]"
                :default-value="type && type.label ? type.label : ''"
                :position-type="'fixed'"
                :dropdown-props="{
                  label: 'company_forma_type',
                  value: 'id',
                }"
                @change="setType($event)"
                :values="types"
                style="width: 100%; margin-left: 10px"
              />
            </div>
          </div>
          <div
            v-if="activeStep === 1 && country.value === 1 && (type.value === 1 || type.value === 2)"
          >
            <Input :label="text.tin[language]" @change="saveData('inn', $event)" />
          </div>
          <div v-if="activeStep === 2 && country.value === 1 && type.value === 1">
            <div
              style="margin-bottom: 20px"
              :class="
                savedData && savedData.active
                  ? 'organizations__active'
                  : 'organizations__not-active'
              "
            >
              {{
                savedData && savedData.active ? text.active[language] : text.not_active[language]
              }}
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.name_short[language]"
                :default-value="savedData && savedData.name ? savedData.name : ''"
                @change="saveData('name', $event)"
              />
              <Input
                :label="text.address[language]"
                style="width: 100%; margin-left: 10px"
                :default-value="savedData && savedData.address ? savedData.address : ''"
                @change="saveData('address', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.tin[language]"
                :default-value="savedData && savedData.inn ? savedData.inn : ''"
                @change="saveData('inn', $event)"
              />
              <Input
                :label="text.fact_address[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('fact_address', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.kpp[language]"
                :default-value="savedData && savedData.kpp ? savedData.kpp : ''"
                @change="saveData('kpp', $event)"
              />
              <Input
                :label="text.ceo_name[language]"
                style="width: 100%; margin-left: 10px"
                :default-value="savedData && savedData.ceo_name ? savedData.ceo_name : ''"
                @change="saveData('ceo_name', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.reg_number[language]"
                :default-value="savedData && savedData.ogrn ? savedData.ogrn : ''"
                @change="saveData('ogrn', $event)"
              />
              <Input
                :label="text.ceo_post[language]"
                style="width: 100%; margin-left: 10px"
                :default-value="savedData && savedData.ceo_post ? savedData.ceo_post : ''"
                @change="saveData('ceo_post', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.okpo[language]"
                :default-value="savedData && savedData.okpo ? savedData.okpo : ''"
                @change="saveData('okpo', $event)"
              />
              <Input
                :label="text.form_org_short[language]"
                style="width: 100%; margin-left: 10px"
                :default-value="savedData && savedData.small_type ? savedData.small_type : ''"
                @change="saveData('small_type', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.name_full[language]"
                :default-value="savedData && savedData.full_name ? savedData.full_name : ''"
                @change="saveData('full_name', $event)"
              />
              <Input
                :label="text.form_org[language]"
                style="width: 100%; margin-left: 10px"
                :default-value="savedData && savedData.full_type ? savedData.full_type : ''"
                @change="saveData('full_type', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.date[language]"
                :default-value="savedData && savedData.date ? savedData.date : ''"
                @change="saveData('date', $event)"
              />
              <Input
                :label="text.email_for_orders[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('email', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.phone_number[language]"
                @change="saveData('phone', $event)"
              />
              <Input
                :label="text.name_contact[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('kont_fio', $event)"
              />
            </div>
          </div>
          <div v-if="activeStep === 2 && country.value === 1 && type.value === 2">
            <div
              style="margin-bottom: 20px"
              :class="
                savedData && savedData.active
                  ? 'organizations__active'
                  : 'organizations__not-active'
              "
            >
              {{
                savedData && savedData.active ? text.active[language] : text.not_active[language]
              }}
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.tin[language]"
                :default-value="savedData && savedData.inn ? savedData.inn : ''"
                @change="saveData('inn', $event)"
              />
              <Input
                style="width: 100%; margin-left: 10px"
                :label="text.name2_short[language]"
                :default-value="savedData && savedData.name ? savedData.name : ''"
                @change="saveData('name', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.reg_number[language]"
                :default-value="savedData && savedData.ogrn ? savedData.ogrn : ''"
                @change="saveData('ogrn', $event)"
              />
              <Input
                style="width: 100%; margin-left: 10px"
                :label="text.name2_full[language]"
                :default-value="savedData && savedData.full_name ? savedData.full_name : ''"
                @change="saveData('full_name', $event)"
              />
            </div>
            <div class="block">
              <Input
                :label="text.address[language]"
                style="width: 100%"
                :default-value="savedData && savedData.address ? savedData.address : ''"
                @change="saveData('address', $event)"
              />
              <Input
                style="width: 100%; margin-left: 10px"
                :label="text.date[language]"
                :default-value="savedData && savedData.date ? savedData.date : ''"
                @change="saveData('date', $event)"
              />
            </div>
            <div class="block">
              <Input
                :label="text.fact_address[language]"
                style="width: 100%"
                @change="saveData('fact_address', $event)"
              />
              <Input
                :label="text.email[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('email', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.phone_number[language]"
                @change="saveData('phone', $event)"
              />
              <Input
                :label="text.name_contact[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('kont_fio', $event)"
              />
            </div>
          </div>
          <div v-if="activeStep === 1 && country.value === 1 && type.value === 3">
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.tin[language]"
                @change="saveData('inn', $event)"
              />
              <Input
                style="width: 100%; margin-left: 10px"
                :label="text.name3[language]"
                @change="saveData('name', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.phone_number[language]"
                @change="saveData('phone', $event)"
              />
              <Input
                :label="text.address[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('address', $event)"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.email_for_orders[language]"
                @change="saveData('email', $event)"
              />
              <Input
                :label="text.fact_address[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('fact_address', $event)"
              />
            </div>
          </div>
          <div v-if="activeStep === 1 && country.value !== 1">
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.tin[language]"
                @change="saveData('inn', $event)"
              />
              <Select
                :label="text.forms_of_business[language]"
                :default-value="type && type.label ? type.label : ''"
                :position-type="'fixed'"
                style="width: 100%; margin-left: 10px"
                :dropdown-props="{
                  label: 'company_forma_type',
                  value: 'id',
                }"
                @change="setType($event)"
                :values="types"
              />
            </div>
            <div class="block">
              <Input
                style="width: 100%"
                :label="text.name[language]"
                @change="saveData('name', $event)"
              />
              <Input
                :label="text.web_address[language]"
                style="width: 100%; margin-left: 10px"
                @change="saveData('web_address', $event)"
              />
            </div>
          </div>
        </div>
        <div>
          <div style="padding: 10px" class="block block__right" v-if="activeStep === 0">
            <Button type="default" @click="handleClose" :text="text.cancel[language]" />
            <Button
              type="primary"
              :text="text.next[language]"
              @click="setStep(1)"
              style="margin-left: 10px"
            />
          </div>
          <div
            style="padding: 10px"
            class="block block__right"
            v-if="activeStep === 1 && country.value === 1 && (type.value === 1 || type.value === 2)"
          >
            <Button type="default" @click="setStep(0)" :text="text.back[language]" />
            <Button
              type="primary"
              :text="text.next[language]"
              @click="setStep(2)"
              style="margin-left: 10px"
            />
          </div>
          <div
            style="padding: 10px"
            class="block block__right"
            v-if="activeStep === 2 && country.value === 1 && (type.value === 1 || type.value === 2)"
          >
            <Button type="default" @click="setStep(1)" :text="text.back[language]" />
            <Button
              type="primary"
              :text="text.save[language]"
              @click="save"
              style="margin-left: 10px"
            />
          </div>
          <div
            style="padding: 10px"
            class="block block__right"
            v-if="
              (activeStep === 1 && country.value === 1 && type.value === 3) ||
              (country.value !== 1 && activeStep === 1)
            "
          >
            <Button type="default" @click="setStep(0)" :text="text.back[language]" />
            <Button
              type="primary"
              :text="text.save[language]"
              @click="save"
              style="margin-left: 10px"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/all_organizations.json";
import { query } from "@/utilities/axios";
import Select from "@/components/select/Select.vue";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";

export default {
  name: "AddBlankModal",
  data() {
    return {
      text,
      activeStep: 0,
      countries: [],
      types: [],
      country: null,
      type: null,
      savedData: {},
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    setStep(val) {
      if (val < this.activeStep) {
        this.savedData = {};
      }
      this.activeStep = val;
      if (
        val === 2 &&
        this.country.value === 1 &&
        (this.type.value === 1 || this.type.value === 2) &&
        this.savedData.inn
      ) {
        this.findCompany(this.savedData.inn);
      }
    },
    getCountries() {
      query(
        "get",
        "getCountries",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.countries = res;
        const index = this.countries.findIndex((el) => el.id === 1);
        this.country = {
          label: this.countries[index].name,
          value: this.countries[index].id,
        };
      });
    },
    getTypes() {
      query(
        "get",
        "getCompanyFormaTypes",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.types = res;
        this.type = {
          label: this.types[0].company_forma_type,
          value: this.types[0].id,
        };
      });
    },
    setCountry(val) {
      this.country = val;
      if (val.value !== 1) {
        this.type = null;
      } else {
        this.type = {
          label: this.types[0].company_forma_type,
          value: this.types[0].id,
        };
      }
    },
    setType(val) {
      this.type = val;
    },
    saveData(name, val) {
      this.savedData[name] = val;
    },
    findCompany(inn) {
      query(
        "post",
        "findCompany",
        {
          query: inn,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        if (res) {
          this.savedData = { ...this.savedData, ...res };
        }
      });
    },
    save() {
      let data = {
        country: this.country.value,
      };
      data = { ...data, ...this.savedData };
      query("put", "addNewCompany", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("update");
        this.handleClose();
      });
    },
  },
  mounted() {
    this.getCountries();
    this.getTypes();
  },
  components: {
    Modal,
    Select,
    Button,
    Input,
  },
};
</script>
