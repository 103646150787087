<template>
  <div class="input-i5">
    <label :for="name"
      >{{ label }}
      <span v-if="required">*</span>
      <span v-if="subLabel" class="input-i5__sublabel">
        {{ subLabel }}
      </span>
    </label>
    <div class="textarea__symbols_wrapper" v-if="symbols">
      <SmallButton
        style="margin: 0 3px 3px 0"
        v-for="(symbol, index) in symbols"
        v-bind:key="`symbol_${index}`"
        :text="symbol"
        @click="addSymbol(symbol)"
      />
    </div>
    <textarea
      ref="textarea"
      :tabindex="ti"
      :value="value"
      :id="name"
      :rows="rows"
      :name="name"
      autocomplete="off"
      :disabled="disabled === true"
      @input="handleChange"
    />
  </div>
</template>

<script>
import SmallButton from "@/components/smallButton/SmallButton.vue";

export default {
  name: "Textarea",
  data() {
    return {
      value: "",
    };
  },
  props: {
    defaultValue: {
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
    symbols: {
      type: Array,
    },
  },
  watch: {
    defaultValue: function (val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(evt) {
      this.$emit("change", evt.target.value);
    },
    addSymbol(symbol) {
      if (document.queryCommandSupported("insertText")) {
        this.$refs.textarea.focus(); // Сначала фокусируемся на textarea
        document.execCommand("insertText", false, symbol); // Вставляем символ
      } else {
        const end = this.$refs.textarea.selectionEnd;
        this.value += symbol;
        this.$nextTick(() => {
          this.$refs.textarea.focus();
          this.$refs.textarea.selectionStart = end + 1;
          this.$refs.textarea.selectionEnd = end + 1;
        });
      }
    },
  },
  mounted() {
    this.value = this.defaultValue;
  },
  components: {
    SmallButton,
  },
};
</script>
