var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FullSize',{attrs:{"type":_vm.fsType,"link":_vm.fsLink,"preview-link":_vm.fsPreviewLink,"show":_vm.fsShow,"title":_vm.fsFileName},on:{"close":_vm.closeFullSize}}),(_vm.files)?_c('div',{staticClass:"order__files"},[_c('div',{class:_vm.width < 600 ? 'order__files_container' : 'order__files_container-2'},[(_vm.files && _vm.files[0])?_c('div',{staticClass:"order__preview"},[_c('div',{class:_vm.width < 600 ? 'order__preview' : 'order__preview_big'},[(_vm.files[_vm.activeFile].preview_3d && _vm.show3dModel)?_c('ModelViewer',{staticStyle:{"height":"150px"},attrs:{"src":_vm.setLink(
                _vm.files[_vm.activeFile].hash,
                _vm.files[_vm.activeFile].time_stamp,
                _vm.files[_vm.activeFile].file_name,
              )}}):_vm._e(),_c('div',{staticClass:"order__preview_buttons"},[_c('FullSizeLogo',{staticClass:"order__preview_button",on:{"click":_vm.setFullSize}})],1),(!_vm.show3dModel)?_c('div',{staticClass:"order__preview_img-container"},[(_vm.files[_vm.activeFile].preview_img !== 'default')?_c('img',{staticClass:"order__preview_img",attrs:{"src":_vm.files[_vm.activeFile].preview_img},on:{"error":_vm.setError}}):_vm._e(),(!_vm.files[_vm.activeFile] || _vm.files[_vm.activeFile].preview_img === 'default')?_c('div',{staticClass:"block block__center block__align-center",staticStyle:{"height":"250px"}},[_c('DefaultSvg')],1):_vm._e(),(_vm.files[_vm.activeFile].preview_3d)?_c('div',{staticClass:"order__preview_360"},[_c('Logo360',{on:{"click":_vm.setShow3dModel}})],1):_vm._e()]):_vm._e()],1)]):_vm._e(),(_vm.files)?_c('div',{class:_vm.width <= 600 ? 'order__block' : 'order__files_block'},[_c('div',{staticClass:"block block__between block__align-center order__files_label",on:{"click":_vm.setShowFiles}},[_c('div',{staticClass:"order__label"},[_vm._v(_vm._s(_vm.text.total_files[_vm.language])+": ("+_vm._s(_vm.files.length)+")")]),_c('div',{staticClass:"component__margin-right"},[_c('Arrow',{class:!_vm.showFiles ? 'order__arrow' : ' order__arroworder__arrow_active'})],1)]),(_vm.showFiles)?_c('div',_vm._l((_vm.files),function(file,index){return _c('div',{key:`file_${index}`,class:`block__flex order__files_item ${
              _vm.activeFile === index ? 'order__files_active' : ''
            }`,on:{"click":function($event){return _vm.setActiveFile(index)}}},[_c('div',{staticStyle:{"width":"50px"}},[(file.preview_img)?_c('img',{staticClass:"order__files_image",attrs:{"src":file.preview_img}}):_vm._e()]),_c('div',{staticClass:"component__margin-left"},[_vm._v(_vm._s(file.filename))])])}),0):_vm._e()]):_vm._e()])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }