<template>
  <div
    :class="`delimiter__container ${
      move ? 'delimiter__container_move' : ''
    } delimiter__container_${type}`"
  ></div>
</template>

<script>
export default {
  name: "Delimiter",
  props: {
    move: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "vertical",
      validator: function (value) {
        return ["vertical", "horizontal"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
