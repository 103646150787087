<template>
  <div style="overflow-y: auto; height: 100%" @scroll="handleScroll($event)">
    <div v-for="(file, i) in list" v-bind:key="`file_${file.hash}`">
      <div class="add-files__list_container">
        <div class="block__flex block__align-center">
          <img
            v-if="file.preview"
            :src="file.preview"
            @error="setError"
            class="add-files__list_image component__margin-right"
          />
          <div>{{ file.filename }}</div>
        </div>
        <div>
          <Button
            type="attention"
            size="smallest"
            @click="addFile(file, i)"
            :text="text.add_to_part[language]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Default from "@/assets/images/i5/default.png";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/parts.json";

export default {
  name: "FileList",
  data() {
    return {
      text,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    startDrag(evt, item, index) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("hash", item.hash);
      evt.dataTransfer.setData("ext", item.ext);
      evt.dataTransfer.setData("time_stamp", item.time_stamp);
      evt.dataTransfer.setData("file_name", item.file_name);
      evt.dataTransfer.setData("index", index);
    },
    handleScroll(e) {
      this.$emit("scroll", e.target.scrollTop);
    },
    setError(e) {
      e.target.src = Default;
    },
    addFile(item, index) {
      this.$emit("click", {
        hash: item.hash,
        ext: item.ext,
        time_stamp: item.time_stamp,
        file_name: item.file_name,
        index: index,
      });
    },
  },
  components: {
    Button,
  },
  mounted() {
    this.$emit("mounted", this.$el);
  },
};
</script>
