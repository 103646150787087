<template>
  <I5Modal :show="show" :title="text.edit_modal_name[language]" @close="onHandlerClose">
    <template slot="body">
      <div
        style="padding: 20px; overflow-y: scroll; height: calc(100% - 80px)"
        class="block block__column"
      >
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование на русском"
            name="name_ru"
            :default-value="editData.name_ru"
            @change="(e) => onHandleChange(e, 'name_ru')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Eng)"
            name="name_eng"
            :default-value="editData.name_en"
            @change="(e) => onHandleChange(e, 'name_en')"
            style="width: 100%"
          />
          <Input
            label="Наименование (De)"
            name="name_de"
            :default-value="editData.name_de"
            @change="(e) => onHandleChange(e, 'name_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Tr)"
            name="name_tr"
            :default-value="editData.name_tr"
            @change="(e) => onHandleChange(e, 'name_tr')"
            style="width: 100%"
          />
          <Input
            label="Наименование (Zh)"
            name="name_zh"
            :default-value="editData.name_zh"
            @change="(e) => onHandleChange(e, 'name_zh')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание на русском"
            name="description_ru"
            :default-value="editData.description_ru"
            @change="(e) => onHandleChange(e, 'description_ru')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание (Eng)"
            :default-value="editData.description_en"
            name="description_en"
            @change="(e) => onHandleChange(e, 'description_en')"
            style="width: 100%"
          />
          <Textarea
            label="Описание (De)"
            name="description_de"
            :default-value="editData.description_de"
            @change="(e) => onHandleChange(e, 'description_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание (Tr)"
            name="description_tr"
            :default-value="editData.description_tr"
            @change="(e) => onHandleChange(e, 'description_tr')"
            style="width: 100%"
          />
          <Textarea
            label="Описание (Zh)"
            name="description_zh"
            :default-value="editData.description_zh"
            @change="(e) => onHandleChange(e, 'description_zh')"
            style="width: 100%"
          />
        </div>
        <Input
          label="Тип станка"
          name="machine_tool_method"
          disabled
          :default-value="editData.machine_tool_method"
          @change="(e) => onHandleChange(e, 'machine_tool_method')"
          style="width: 100%"
        />
        <h2>Характеристики станка</h2>

        <div class="edit-modal_wrapper">
          <div
            v-for="(item, index) in infoData.mt.data"
            v-bind:key="index"
            class="edit-model_param"
          >
            <EditTableParameters :param="item" />
          </div>
        </div>

        <h2>Рабочие органы станка</h2>

        <h2>Способы установки и закрепления заготовок</h2>
      </div>
      <div
        style="height: 80px; gap: 12px; padding: 0 20px"
        class="block block__align-center block__right"
      >
        <Button type="default" @click="$emit('close')" text="Отмена" />
        <Button type="primary" text="Сохранить" @click="saveEdit" />
      </div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/all_machine_tools.json";
import Input from "@/components/input/Input.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import TrashIcon from "@/assets/images/tech/trash.svg";
import Button from "@/components/button/Button.vue";
import Search from "@/components/search/Search.vue";
import Toggle from "@/components/toggle/Toggle.vue";
import { query } from "@/utilities/axios";
import EditTableParameters from "@/components/editTableParameters/EditTableParameters.vue";

export default {
  name: "EditWBType",
  data() {
    return {
      text,
      editData: {
        name_ru: "",
        name_en: "",
        name_de: "",
        name_tr: "",
        name_zh: "",
        description_ru: "",
        description_en: "",
        description_de: "",
        description_tr: "",
        description_zh: "",
        machine_tool_method: "",
      },
    };
  },
  components: {
    I5Modal,
    Input,
    TrashIcon,
    Button,
    Search,
    Textarea,
    Toggle,
    EditTableParameters,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    show: {
      type: Boolean,
    },
    parameters: {
      type: Array,
      default: () => [],
    },
    infoData: {
      type: Object,
    },
  },
  watch: {
    parameters: {
      immediate: true,
      handler: function (val) {
        this.params = val;
      },
    },
    infoData: {
      immediate: true,
      handler: function (val) {
        console.log(val);
        if (val) {
          this.editData.name_ru = val.name_ru || "";
          this.editData.name_en = val.name_en || "";
          this.editData.name_de = val.name_de || "";
          this.editData.name_tr = val.name_tr || "";
          this.editData.name_zh = val.name_zh || "";
          this.editData.description_ru = val.description_ru || "";
          this.editData.description_en = val.description_en || "";
          this.editData.description_de = val.description_de || "";
          this.editData.description_tr = val.description_tr || "";
          this.editData.description_zh = val.description_zh || "";
          this.editData.machine_tool_method = val.machine_tool_method || "";
        }
      },
    },
  },
  methods: {
    onHandlerClose() {
      this.$emit("close");
    },
    onHandleChange(e, name) {
      this.editData[name] = e;
    },
    saveEdit() {
      const editData = {
        uuid: this.infoData.uuid,
        ...this.editData,
      };
      query("post", "editMachineToolWBTypeInfo", editData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("update");
        this.$emit("close");
      });
    },
  },
};
</script>
