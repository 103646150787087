<template>
  <div>
    <PersonalLayout v-if="this.$route.meta.layout && this.$route.meta.layout === 'personal'">
      <template slot="page">
        <router-view></router-view>
      </template>
    </PersonalLayout>
    <EmptyLayout v-else-if="this.$route.meta.layout && this.$route.meta.layout === 'empty'">
      <template slot="page">
        <router-view></router-view>
      </template>
    </EmptyLayout>
    <StandardLayout v-else>
      <template slot="page">
        <router-view></router-view>
      </template>
    </StandardLayout>
    <AuthModal :show="isShowAuth" :close="closeAuth" @ok="successAuth" />
    <Cookies v-if="isShowCookies" />
  </div>
</template>

<script>
import AuthModal from "@/components/auth/AuthModal.vue";
import Cookies from "@/components/cookies/Cookies.vue";
import StandardLayout from "@/layouts/StandardLayout.vue";
import PersonalLayout from "@/layouts/PersonalLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";

export default {
  name: "App",
  data() {
    return {
      unsubscribe: () => {},
      isShowAuth: false,
    };
  },
  computed: {
    isShowCookies() {
      return this.$store.state.user.showCookies;
    },
  },
  methods: {
    closeAuth() {
      this.$store.commit("user/setShowAuthorizeModal", false);
    },
    successAuth() {
      this.$store.commit("user/setShowAuthorizeModal", false);
    },
    updateWidth() {
      this.$store.commit("index/setWidth", window.innerWidth);
      this.$store.commit("index/setHeight", window.innerHeight);
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "user/setShowAuthorizeModal") {
        this.isShowAuth = mutation.payload;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    AuthModal,
    Cookies,
    StandardLayout,
    PersonalLayout,
    EmptyLayout,
  },
};
</script>
