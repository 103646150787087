<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    :page-name="text.page_name[language]"
    id-name="uuid"
    add-title="Добавить позицию спецификации"
  >
  </PageConstructor>
</template>

<script>
import text from "@/assets/text/reports.json";
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";

export default {
  name: "Reports",
  data() {
    return {
      text,
      query: {},
      columns: [
        {
          name: "name",
          label: text.name,
          options: {
            bold: true,
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "date",
          label: text.date,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "contract",
          label: text.contract,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "contractor",
          label: text.contractor,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "sum",
          label: text.sum,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "status",
          label: text.status,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
