<template>
  <PageConstructor
    :query="query"
    :page-name="$store.state.specification.name"
    :columns="columns"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
    id-name="uuid"
    :form-name="['name']"
    add-title="Добавить позицию спецификации"
    @setPageTitle="setPageTitle($event)"
    :save-store="saveStore"
    :query-filter="queryFilter"
  >
    <template slot="edit" slot-scope="{ row, width }">
      <Edit
        :row="row"
        :width="width"
        :order-data="orderData"
        :order-price-type="orderPriceType"
        @changePrice="changePrice()"
      />
    </template>
    <template slot="add">
      <Add />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/specification.json";
import Edit from "./components/edit/SpecEdit.vue";
import Add from "./components/add/SpecAdd.vue";

export default {
  name: "Specification",
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    saveStore: {
      type: String,
    },
    orderPriceType: {
      type: Number,
    },
    orderData: {
      type: Object,
    },
  },
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getCurrentCompaniesOrderV2",
        headers: {},
        data: {
          uuid: this.$route.params.id,
        },
      },
      queryFilter: {
        method: "post",
        url: "getFilterMachineTools",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "number",
          label: text.number,
          options: {
            bold: true,
          },
          basis: 50,
          width: 50,
        },
        {
          name: "code",
          label: text.code,
          options: {
            bold: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "name",
          label: text.name,
          options: {
            bold: true,
          },
          basis: 250,
          width: 250,
        },
        {
          name: "amount",
          label: text.amount,
          options: {
            right: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "weight",
          label: text.weight,
          type: "number_with_spaces",
          options: {
            right: true,
          },
          basis: 110,
          width: 110,
        },
        {
          name: "material",
          label: text.material,
          options: {
            bold: true,
          },
          basis: 0,
          width: 100,
        },
        {
          name: "files_count",
          label: text.files_count,
          options: {
            right: true,
          },
          basis: 130,
          width: 130,
        },
        {
          name: "size",
          label: text.size,
          options: {
            right: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "total_weight",
          label: text.total_weight,
          type: "number_with_spaces",
          options: {
            right: true,
          },
          basis: 150,
          width: 150,
        },
        /*
        {
          name: 'part_standard_value',
          label: text.part_standard_value,
          options: {
            sort: true,
            right: true,
          },
          basis: 150,
          width: 150,
        },
         */
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setPageTitle(title) {
      this.$store.commit(
        "specification/setName",
        `${this.text.page_header[this.language]}${title}`,
      );
    },
    changePrice() {
      this.$emit("changePrice");
    },
  },
  components: {
    PageConstructor,
    Edit,
    Add,
  },
  mounted() {},
};
</script>
