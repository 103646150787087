<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <div class="page__edit_margin" v-if="row.img">
        <img :src="row.img" class="page__edit_img" />
      </div>

      <div class="page__edit_margin" v-if="row.name">
        <div class="page__edit_title">{{ text.name[language] }}:</div>
        <div class="page__edit_value">
          {{ row.name }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.sizes">
        <div class="page__edit_title">{{ text.sizes[language] }}:</div>
        <div class="page__edit_value">
          {{ row.sizes.join(", ") }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.special">
        <div class="page__edit_title">{{ text.special[language] }}:</div>
        <div class="page__edit_value">
          {{ !row.special ? "Да" : "Нет" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/blank_types.json";

export default {
  name: "Edit",
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
