<template>
  <div style="height: inherit">
    <Add :show="showAddModal" @close="setAddModal(false)" @update="tableUpdate" />
    <PageConstructor
      :query="query"
      :columns="columns"
      @setUpdate="setUpdate($event)"
      id-name="id"
      :hide-add-footer="true"
      show-filter
      :buttons="buttons"
      @headerClick="headerClick($event)"
      :page-name="text.page_name[language]"
      :query-filter="queryFilter"
    >
      <template slot="edit" slot-scope="{ row, width }">
        <Edit :row="row" :width="width" :label="text.edit_name[language]" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/all_tools.json";
import Add from "./components/add/Add.vue";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "Tools",
  data() {
    return {
      text,
      showAddModal: false,
      buttons: [
        {
          type: "main",
          text: text.add_tool[this.$store.state.index.language],
        },
      ],
      query: {
        method: "post",
        url: "getTools",
        headers: {},
        data: {},
      },
      queryFilter: {},
      columns: [
        {
          name: "id",
          label: {
            ru: "№",
            en: "№",
            de: "№",
            tr: "№",
            zh: "№",
          },
          options: {
            bold: true,
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "logo",
          label: {
            ru: "",
            en: "",
          },
          type: "image",
          options: {},
          basis: 80,
          width: 80,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "tool_type",
          label: text.tool_type,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "manufacturer",
          label: text.manufacturer,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
      tableUpdate: () => {},
    };
  },
  methods: {
    test() {
      console.log("");
    },
    setAddModal(val) {
      this.showAddModal = val;
    },
    headerClick(type) {
      if (type === "main") {
        this.setAddModal(true);
      } else if (type === "download") {
        this.downloadList();
      }
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
    Add,
    Edit,
  },
};
</script>
