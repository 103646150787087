<template>
  <I5Modal :show="modalShow" @close="close">
    <template slot="body">
      <div class="add-files__wrapper">
        <div class="add-files__all-files">
          <div style="height: 100%" class="block block__column block__between">
            <div style="margin-bottom: 20px" class="add-files__title">
              {{ text.download_new_files[language] }}
            </div>
            <UniversalUpload
              :upload-percent="percent"
              style="margin-bottom: 20px"
              :index="0"
              :uploadFunction="uploadFileV2"
              @leave="handleMouseLeave"
              @enter="handleMouseEnter"
              @dLeave="handleDragLeave"
              @dEnter="handleDragEnter"
              @dDrop="handleDrop"
              :border-color="activeBorder ? '#B92A21' : '#F1F1F1'"
              :formats="`${
                '.stp,.STP,.STEP,.step,.stl,.STL,.pdf,.txt,.dwg,.dxf,.emf,.dwt' +
                ',.jpeg,.jpg,.png,.cdw,.heif,.raw,.docx,.xlsx,.sldprt,.dxf,.ipt'
              }`"
            >
              <template slot="body">
                <div
                  :style="activeGear ? 'background-color: rgba(185, 42, 33, .3)' : ''"
                  class="add-files__upload_wrapper"
                >
                  <GearLeft
                    :fill="activeGear ? '#B92A21' : '#b8b8b8'"
                    class="add-files__upload_gear-left"
                  />
                  <GearRight
                    :fill="activeGear ? '#B92A21' : '#b8b8b8'"
                    class="add-files__upload_gear-right"
                  />
                  <div class="add-files__upload_container">
                    <div
                      style="height: 100%"
                      class="block block__column block__center block__align-center"
                    >
                      <div class="add-files__upload_title" style="margin-bottom: 10px">
                        {{ text.upload_title[language] }}
                      </div>
                      <div class="add-files__upload_sub-title" style="margin-bottom: 10px">
                        {{ text.upload_auto_title[language] }}
                      </div>
                      <div class="add-files__upload_title" style="margin-bottom: 20px">
                        STP | STEP | STL
                      </div>
                      <div class="add-files__upload_sub-title" style="margin-bottom: 10px">
                        {{ text.upload_formats_doc_title[language] }}
                      </div>
                      <div class="add-files__upload_format">
                        PDF | DWG | JPEG | PNG | CDW | HEIF | RAW | DOCX | XLSX
                      </div>
                      <div class="add-files__upload_format" style="margin-bottom: 20px">
                        SLDPRT | DXF | STL | IPT | 3DXML | PRT | M3D…
                      </div>
                      <div class="add-files__upload_sub-title" style="margin-bottom: 10px">
                        {{ text.upload_first[language] }}
                      </div>
                      <div class="add-files__upload_sub-title" style="margin-bottom: 20px">
                        <span>{{ text.upload_size[language] }} </span>
                        <b>
                          <span>50 </span>
                          <span>{{ text.upload_mb[language] }}</span>
                        </b>
                      </div>
                    </div>
                    <div class="component__margin class block__flex block__align-center">
                      <Protect class="component__margin-right" style="min-width: 22px" />
                      <div class="add-files__upload_protect">
                        {{ text.upload_protect[language] }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </UniversalUpload>
          </div>
          <div style="height: 100%; overflow: hidden" class="block block__column block__between">
            <div>
              <div class="add-files__title">{{ text.all_files[language] }}</div>
              <Input style="margin-top: 15px" block @change="find($event)" />
            </div>
            <FileList
              @click="addExistFile($event)"
              @scroll="onScroll($event)"
              :list="allFilesList"
              @mounted="setListSize($event)"
            />
          </div>
        </div>
        <div class="add-files__part-files">
          <div style="margin-bottom: 20px" class="add-files__title">
            {{ text.part_files[language] }}
          </div>
          <div
            class="add-files__part-files_list"
            @dragover.prevent
            @dragenter.prevent
            @drop="dropFile($event)"
          >
            <!--<input type="file" />-->
            <div class="add-files__part-files_body" v-if="addingFiles">
              <div v-for="(addFile, index) in addingFiles" v-bind:key="`add_file_${index}`">
                <File :deletable="true" @delete="deleteAddedFile($event)" :file="addFile" />
              </div>
            </div>
            <div class="add-files__part-files_delimiter" v-if="addingFiles"></div>
            <div class="add-files__part-files_body">
              <div v-for="(newFile, index) in newFiles" v-bind:key="`new_file_${index}`">
                <File
                  :preview-name="'preview'"
                  @delete="deleteNewFile($event)"
                  :deletable="true"
                  :file="newFile"
                />
              </div>
            </div>
          </div>
          <div class="block block__column">
            <Button
              block
              type="attention"
              @click="addNewFiles"
              :text="text.save[language]"
              style="margin-top: 10px"
            />
          </div>
        </div>
      </div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/parts.json";
import { query } from "@/utilities/axios";
import File from "@/components/file/File.vue";
import UniversalUpload from "@/components/universalUpload/UniversalUploadV2.vue";
import GearLeft from "@/assets/images/parts/gearLeft.svg";
import GearRight from "@/assets/images/parts/gearRight.svg";
import Protect from "@/assets/images/parts/protect.svg";
import FileList from "./FileList.vue";

export default {
  name: "AddFiles",
  data() {
    return {
      text,
      modalShow: false,
      linePerPage: 0,
      allFiles: null,
      allFilesList: null,
      activePage: 1,
      totalPages: null,
      loading: false,
      addingFiles: null,
      newFiles: [],
      timer: null,
      downloadFiles: [],
      percent: 0,
      activeGear: false,
      activeBorder: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    files: {
      type: Array,
    },
    partUuid: {
      type: String,
    },
  },
  watch: {
    show: {
      handler: function (val) {
        this.modalShow = val;
        this.modalShow = this.show;
      },
    },
    files: {
      immediate: true,
      handler: function (val) {
        this.addingFiles = val;
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    close() {
      this.modalShow = false;
      this.$emit("close");
    },
    find(val) {
      this.resetTimer(val);
    },
    deleteAddedFile(file) {
      query(
        "delete",
        "deleteFileFromPartOrderV2",
        {
          uuid: this.partUuid,
          hash: file.hash,
          time_stamp: file.time_stamp,
          file_name: file.file_name,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("deleteFile");
      });
    },
    deleteNewFile(file) {
      const result = this.newFiles.slice();
      const index = this.newFiles.findIndex((el) => el.hash === file.hash);
      result.splice(index, 1);
      this.newFiles = result;
    },
    uploadFewFiles(files) {
      files.forEach((file) => {
        this.uploadFile(file);
      });
    },
    resize() {
      if (this.allFiles) {
        const col = Math.round(this.allFiles.clientWidth / 120);
        const row = Math.round(this.allFiles.clientHeight / 122);
        this.linePerPage = col * row;
      }
    },
    getFiles(linePerPage, page, find = null) {
      return new Promise((resolve, reject) => {
        const data = {
          page: page,
          line_per_page: linePerPage,
        };
        if (find) {
          data.find = find;
        }
        this.loading = true;
        this.activePage = 1;
        query("post", "getFilesByCompanyV2", data, {
          token: localStorage.getItem("token"),
        })
          .then((files) => {
            resolve();
            this.allFilesList = files.table;
            this.totalPages = files.total_pages;
            this.addFiles(linePerPage, page + 1, find);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            reject();
          });
      });
    },
    addFiles(linePerPage, page, find = null) {
      this.activePage = page;
      const data = {
        page: page,
        line_per_page: linePerPage,
      };
      if (find) {
        data.find = find;
      }
      this.loading = true;
      query("post", "getFilesByCompanyV2", data, {
        token: localStorage.getItem("token"),
      })
        .then((files) => {
          this.allFilesList = [...this.allFilesList, ...files.table];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onScroll(top) {
      const height = this.linePerPage * 55;
      const page = Math.ceil((top + height / 2) / height);
      if (page === this.activePage) {
        this.activePage += 1;
        this.addFiles(this.linePerPage, this.activePage);
      }
    },
    dropFile(evt) {
      evt.preventDefault();
      if (evt.dataTransfer.items[0].kind === "file") {
        this.downloadFiles = [];
        if (evt.dataTransfer.items[0].kind === "file") {
          const file = evt.dataTransfer.items[0].getAsFile();
          this.downloadFiles.push(file);
        }
        if (this.downloadFiles.length > 0) {
          this.uploadFile(this.downloadFiles[0], 0);
        }
      } else {
        const result = {};
        result.hash = evt.dataTransfer.getData("hash");
        result.ext = evt.dataTransfer.getData("ext");
        result.time_stamp = evt.dataTransfer.getData("time_stamp");
        result.file_name = evt.dataTransfer.getData("file_name");
        result.index = evt.dataTransfer.getData("index");
        this.newFiles.push(this.allFilesList[result.index]);
      }
    },
    addExistFile(data) {
      this.newFiles.push(this.allFilesList[data.index]);
    },
    uploadFileV2(formData, index, name) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token) {
          query(
            "put",
            "addFile",
            formData,
            {
              token,
            },
            (e) => {
              const percent = String(Math.round((e.loaded * 100) / e.total));
              this.percent = percent;
            },
          )
            .then((item) => {
              resolve();
              const file = {
                ...item,
                ...{
                  _i: index,
                  filename: name,
                },
              };
              this.newFiles.push(file);
              this.getFiles(this.linePerPage, 1);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          this.isShowAuth = true;
          reject();
        }
      });
    },
    uploadFile(file, index) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        const recDownload = () => {
          if (this.downloadFiles.length > index + 1) {
            this.uploadFile(this.downloadFiles[index + 1], index + 1);
          } else {
            this.getFiles(this.linePerPage, 1);
          }
        };
        query(
          "post",
          "addFile",
          formData,
          {
            token: localStorage.getItem("token"),
          },
          (evt) => {
            this.percent = Math.round((evt.loaded * 100) / evt.total);
          },
        )
          .then((fileData) => {
            resolve();
            this.newFiles.push(fileData);
            recDownload();
          })
          .catch((err) => {
            recDownload();
            reject(err);
          });
      });
    },
    setListSize(el) {
      this.linePerPage = Math.round(el.clientHeight / 55);
      // this.allFiles.addEventListener('scroll', this.onScroll);
      this.getFiles(this.linePerPage, 1);
    },
    startTimer(value) {
      this.timer = setTimeout(() => {
        this.getFiles(this.linePerPage, 1, value);
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(value) {
      this.stopTimer();
      this.startTimer(value);
    },
    addNewFiles() {
      const data = [];
      this.newFiles.forEach((file) => {
        data.push({
          hash: file.hash,
          time_stamp: file.time_stamp || file.timeStamp,
          file_name: file.file_name || file.fileName,
        });
      });
      query(
        "post",
        "addFilesToOrderPartV2",
        {
          uuid: this.partUuid,
          files: data,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.newFiles = [];
        this.close();
      });
    },
    handleMouseEnter() {
      this.activeBorder = true;
    },
    handleMouseLeave() {
      this.activeBorder = false;
    },
    handleDragLeave() {
      this.activeGear = false;
    },
    handleDragEnter() {
      this.activeGear = true;
    },
    handleDrop() {
      this.activeGear = false;
    },
  },
  mounted() {
    this.$el.addEventListener("resize", this.resize, false);
  },
  beforeDestroy() {
    // this.$el.removeEventListener('resize', this.resize, false);
    // this.allFiles.removeEventListener('scroll', this.onScroll);
  },
  components: {
    I5Modal,
    Input,
    FileList,
    Button,
    File,
    UniversalUpload,
    GearRight,
    GearLeft,
    Protect,
  },
};
</script>
