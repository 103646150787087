<template>
  <div>
    <Button
      class="component__margin-bottom"
      :text="text.go_to_tech[language]"
      block
      @click="open"
    />
    <Button
      class="component__margin-bottom"
      :text="text.delete[language]"
      block
      @click="deleteMP"
    />
    <div class="block block__between block__align-center component__margin-bottom">
      <div>
        <div class="block__flex block__align-center">
          <div class="page__edit_large-title">
            {{ text.edit_title[language] }}
          </div>
          <div
            class="page__edit_status component__margin-left"
            v-if="processPlan && processPlan.status"
          >
            {{ processPlan.status }}
          </div>
        </div>
      </div>
      <div v-if="processPlan && processPlan.automated">
        <a-tooltip placement="left">
          <template slot="title" v-if="processPlan.automated && !processPlan.change_automated">
            {{ text.automated[language] }}
          </template>
          <template slot="title" v-if="processPlan.automated && processPlan.change_automated">
            {{ text.change_automated[language] }}
          </template>
          <div
            class="page__edit_large-title"
            :style="
              processPlan.automated && !processPlan.change_automated
                ? 'color: #BD3122'
                : 'color: #BABABA'
            "
            v-if="processPlan.automated"
          >
            А
          </div>
        </a-tooltip>
      </div>
    </div>
    <div class="component__margin-bottom page__edit_value" v-if="processPlan">
      <span>
        <span v-if="processPlan.code">
          {{ processPlan.code }}
        </span>
        <span class="component__margin-left" v-if="processPlan.name">
          {{ processPlan.name }}
        </span>
      </span>
    </div>
    <div class="component__margin-bottom" v-if="processPlan && processPlan.time_stamp">
      <div class="page__edit_value">
        {{ text.creation_date[language] }}
      </div>
      <div class="page__edit_title block block__align-center component__margin-top">
        <CalendarImage class="component__margin-right" />
        <div>{{ moment(processPlan.time_stamp).format("DD.MM.YYYY") }}</div>
      </div>
    </div>
    <div class="component__margin-bottom" v-if="processPlan && processPlan.user">
      <div class="page__edit_value">
        {{ text.developer[language] }}
      </div>
      <div class="page__edit_title component__margin-top">
        <div>{{ processPlan.user }}</div>
      </div>
    </div>
    <div v-if="blank" class="component__margin-bottom">
      <div class="page__edit_value component__margin-bottom">
        {{ text.blank[language] }}
      </div>
      <div class="page__edit_title">
        {{ blank.name }}
      </div>
    </div>
    <div v-if="operations">
      <div class="page__edit_value component__margin-bottom">
        {{ text.operations[language] }}
      </div>
      <div class="page__edit_line">
        <div v-for="(item, index) in operations" v-bind:key="`${index}_operations`">
          <span>
            <span class="page__edit_line_value">{{ item.number }}</span>
            <span class="component__margin-left page__edit_line_label">
              {{ item.name }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/technological_processes.json";
import { query } from "@/utilities/axios";
import CalendarImage from "@/assets/images/order/calendar.svg";
import moment from "moment";

export default {
  data() {
    return {
      text,
      processPlan: null,
      operations: null,
      blank: null,
      moment,
    };
  },
  props: {
    row: Object,
    width: {
      type: [Number, String],
    },
  },
  watch: {
    row: {
      deep: true,
      handler: function () {
        this.getProcessPlan();
      },
    },
  },
  computed: {
    currency() {
      return this.$store.state.index.currency;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getProcessPlan() {
      query(
        "post",
        "getMP",
        {
          uuid_mp: this.row.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.processPlan = result.mp;
        this.operations = result.lines.filter(
          (el) => el.level[1] === 0 && el.level[2] === 0 && el.type === 2,
        );
        this.blank = result.lines.filter(
          (el) => el.level[1] === 0 && el.level[2] === 0 && el.type === 1,
        )[0];
      });
    },
    open() {
      this.$router.push(`/tech-processes/${this.row.uuid}`);
    },
    deleteMP() {
      query(
        "delete",
        "deleteMP",
        {
          uuid_mp: this.row.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
  },
  created() {
    this.getProcessPlan();
  },
  components: {
    Button,
    CalendarImage,
  },
};
</script>
