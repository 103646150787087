<template>
  <a-modal
    v-model="modalShow"
    :footer="null"
    centered
    width="800px"
    class="modal__title"
    @cancel="cancel"
    :title="setTitle()"
  >
    <div class="modal__container">
      <slot name="body" />
    </div>
    <div
      v-if="!hideFooter"
      class="block block__right block__align-center"
      :style="{ marginTop: '20px' }"
    >
      <div>
        <Button @click="cancel" v-if="showCancel" :text="cancelText[language]" size="small" />
      </div>
      <div class="component__margin-left">
        <Button
          type="primary"
          v-if="showOk"
          @click="setOk"
          :disabled="okDisabled"
          :text="okText[language]"
          size="small"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import Button from "@/components/button/Button.vue";

export default {
  name: "Modal",
  data() {
    return {
      modalShow: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: [String, Object],
    name: {
      type: String,
      default: "modal",
    },
    width: {
      type: String,
      default: "600px",
    },
    okText: {
      type: Object,
      default() {
        return {
          ru: "Добавить",
          en: "Add",
        };
      },
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    ok: {
      type: Function,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showOk: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: Object,
      default() {
        return {
          ru: "Отменить",
          en: "Cancel",
        };
      },
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      if (typeof val === "boolean") {
        this.modalShow = val;
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setTitle() {
      let result = "";
      if (this.title) {
        if (typeof this.title === "string") {
          result = this.title;
        } else {
          result = this.title[this.language];
        }
      }
      return result;
    },
    cancel() {
      this.$emit("cancel");
      // this.modalShow = false;
    },
    setOk() {
      this.$emit("ok");
      // this.cancel();
    },
  },
  mounted() {
    this.modalShow = this.show;
  },
  components: {
    Button,
  },
};
</script>
