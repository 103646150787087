<template>
  <div>
    <div v-if="row && row.preview">
      <img
        :src="row.preview"
        :style="{
          height: 'auto',
          width: '100%',
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  props: {
    row: {
      type: Object,
    },
  },
};
</script>
