<template>
  <Modal :show="show" @close="handleClose" :title="text.cost_calculator[language]">
    <template slot="body">
      <div class="order__calculator_wrapper" v-if="calculatorCosts">
        <div class="order__calculator_left">
          <div class="order__preview_img-container" v-if="part && part.files">
            <img
              v-if="part.files[0] && part.files[0].preview_img !== 'default'"
              :src="part.files[0].preview_img"
              class="order__preview_img"
              @error="setError"
            />
            <div
              style="height: 250px"
              class="block block__center block__align-center"
              v-if="!part.files[0] || part.files[0].preview_img === 'default'"
            >
              <DefaultSvg />
            </div>
          </div>
          <div style="width: 100%" class="order__block">
            <div class="order__label">
              {{ text.part[language] }}
            </div>
            <div class="order__values" v-if="part">
              <div class="component__margin-bottom" v-if="part.code">
                <div class="order__values_label">{{ text.code[language] }}</div>
                <div class="order__values_value">{{ part.code }}</div>
              </div>
              <div class="component__margin-bottom" v-if="part.name">
                <div class="order__values_label">{{ text.name[language] }}</div>
                <div class="order__values_value">{{ part.name_order }}</div>
              </div>
              <div class="component__margin-bottom" v-if="part.amount">
                <div class="order__values_label">{{ text.amount[language] }}</div>
                <div class="order__values_value">{{ part.amount }}</div>
              </div>
              <div class="component__margin-bottom" v-if="part.material">
                <div class="order__values_label">{{ text.material[language] }}</div>
                <div class="order__values_value">{{ part.material }}</div>
              </div>
              <div class="component__margin-bottom" v-if="part.gost_material">
                <div class="order__values_label">{{ text.gost_material[language] }}</div>
                <div class="order__values_value">{{ part.gost_material }}</div>
              </div>
              <div class="component__margin-bottom" v-if="part.blank_type">
                <div class="order__values_label">{{ text.blank[language] }}</div>
                <div class="order__values_value">{{ part.blank_type }}</div>
              </div>
              <div class="component__margin-bottom" v-if="part.gost_blank">
                <div class="order__values_label">{{ text.gost_blank[language] }}</div>
                <div class="order__values_value">{{ part.gost_blank }}</div>
              </div>
              <div
                class="component__margin-bottom"
                v-if="part.x_size && part.y_size && part.z_size"
              >
                <div class="order__values_label">{{ text.size[language] }}</div>
                <div class="order__values_value">
                  {{ part.x_size }} x {{ part.y_size }} x {{ part.z_size }}
                </div>
              </div>
              <div class="component__margin-bottom" v-if="part.weight">
                <div class="order__values_label">{{ text.weight[language] }}</div>
                <div class="order__values_value">{{ getNumberWithSpaces(part.weight) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block block__column block__between">
          <div class="order__calculator_center">
            <div class="order__calculator_card">
              <div class="block block__between block__align-center" style="margin-bottom: 10px">
                <div class="order__calculator_card-title">1. {{ text.blank_cost[language] }}</div>
                <div>
                  <a-checkbox
                    class="order__calculator_card-title"
                    @change="setCalculatorEdit($event, 'blank')"
                    :checked="editCalculator.blank"
                  >
                    {{ text.set_manually[language] }}
                  </a-checkbox>
                </div>
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  :margin-bottom="0"
                  type="number"
                  @change="setCalcCost($event, 'blank_cost', 'one')"
                  :default-value="
                    calculatorCosts && calculatorCosts.blank_cost ? calculatorCosts.blank_cost : 0
                  "
                  :disabled="!editCalculator.blank"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
                <div class="component__margin-left order__calculator_card-text">x</div>
                <div
                  v-if="row && row.amount"
                  class="component__margin-left order__calculator_amount"
                >
                  {{ row.amount }} {{ text.pcs[language] }} =
                </div>
                <Input
                  type="number"
                  @change="setCalcCost($event, 'blank_cost', 'all')"
                  :default-value="
                    calculatorCosts && calculatorCosts.blank_cost
                      ? calculatorCosts.blank_cost_all
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.blank"
                  class="component__margin-left"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="order__calculator_card">
              <div class="block block__between block__align-center" style="margin-bottom: 20px">
                <div class="order__calculator_card-title">
                  2. {{ text.treatment_cost[language] }}
                </div>
                <div>
                  <a-checkbox
                    class="order__calculator_card-title"
                    @change="setCalculatorEdit($event, 'treatment')"
                    :checked="editCalculator.treatment"
                  >
                    {{ text.set_manually[language] }}
                  </a-checkbox>
                </div>
              </div>
              <div class="order__calculator_card-subtitle" style="margin-bottom: 10px">
                2.1 {{ text.direct_processing_cost[language] }}
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  :margin-bottom="0"
                  type="number"
                  @change="setCalcCost($event, 'processing_cost', 'one')"
                  :default-value="
                    calculatorCosts && calculatorCosts.processing_cost
                      ? calculatorCosts.processing_cost
                      : 0
                  "
                  :disabled="!editCalculator.treatment"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
                <div class="component__margin-left order__calculator_card-text">x</div>
                <div
                  v-if="row && row.amount"
                  class="component__margin-left order__calculator_amount"
                >
                  {{ row.amount }} {{ text.pcs[language] }} =
                </div>
                <Input
                  type="number"
                  @change="setCalcCost($event, 'processing_cost', 'all')"
                  :default-value="
                    calculatorCosts && calculatorCosts.processing_cost_all
                      ? calculatorCosts.processing_cost_all
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.treatment"
                  class="component__margin-left"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
              </div>
              <div class="order__calculator_card-subtitle" style="margin-bottom: 10px">
                2.2 {{ text.overhead_cost[language] }}
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  type="number"
                  @change="setCalcCost($event, 'overhead_delta')"
                  :default-value="
                    calculatorCosts && calculatorCosts.overhead_delta
                      ? calculatorCosts.overhead_delta
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.treatment"
                />
                <div class="component__margin-left order__calculator_card-text">%</div>
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  :margin-bottom="0"
                  type="number"
                  @change="setCalcCost($event, 'overhead_cost', 'one')"
                  :default-value="
                    calculatorCosts && calculatorCosts.overhead_cost
                      ? calculatorCosts.overhead_cost
                      : 0
                  "
                  :disabled="!editCalculator.treatment"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
                <div class="component__margin-left order__calculator_card-text">x</div>
                <div
                  v-if="row && row.amount"
                  class="component__margin-left order__calculator_amount"
                >
                  {{ row.amount }} {{ text.pcs[language] }} =
                </div>
                <Input
                  type="number"
                  @change="setCalcCost($event, 'overhead_cost', 'all')"
                  :default-value="
                    calculatorCosts && calculatorCosts.overhead_cost_all
                      ? calculatorCosts.overhead_cost_all
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.treatment"
                  class="component__margin-left"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="order__calculator_card">
              <div class="block block__between block__align-center" style="margin-bottom: 10px">
                <div class="order__calculator_card-title">3. {{ text.profit[language] }}</div>
                <div>
                  <a-checkbox
                    class="order__calculator_card-title"
                    @change="setCalculatorEdit($event, 'profit')"
                    :checked="editCalculator.profit"
                  >
                    {{ text.set_manually[language] }}
                  </a-checkbox>
                </div>
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  type="number"
                  @change="setCalcCost($event, 'profit_delta')"
                  :default-value="
                    calculatorCosts && calculatorCosts.profit_delta
                      ? calculatorCosts.profit_delta
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.profit"
                />
                <div class="component__margin-left order__calculator_card-text">%</div>
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  :margin-bottom="0"
                  type="number"
                  @change="setCalcCost($event, 'profit', 'one')"
                  :default-value="
                    calculatorCosts && calculatorCosts.profit ? calculatorCosts.profit : 0
                  "
                  :disabled="!editCalculator.profit"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
                <div class="component__margin-left order__calculator_card-text">x</div>
                <div
                  v-if="row && row.amount"
                  class="component__margin-left order__calculator_amount"
                >
                  {{ row.amount }} {{ text.pcs[language] }} =
                </div>
                <Input
                  type="number"
                  @change="setCalcCost($event, 'profit', 'all')"
                  :default-value="
                    calculatorCosts && calculatorCosts.profit_all ? calculatorCosts.profit_all : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.profit"
                  class="component__margin-left"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="order__calculator_card">
              <div class="block block__between block__align-center" style="margin-bottom: 10px">
                <div class="order__calculator_card-title">4. {{ text.discount[language] }}</div>
                <div>
                  <a-checkbox
                    class="order__calculator_card-title"
                    @change="setCalculatorEdit($event, 'discount')"
                    :checked="editCalculator.discount"
                  >
                    {{ text.set_manually[language] }}
                  </a-checkbox>
                </div>
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  type="number"
                  @change="setCalcCost($event, 'discount_delta')"
                  :default-value="
                    calculatorCosts && calculatorCosts.discount_delta
                      ? calculatorCosts.discount_delta
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.discount"
                />
                <div class="component__margin-left order__calculator_card-text">%</div>
              </div>
              <div class="block block__align-center" style="margin-bottom: 10px">
                <Input
                  type="number"
                  @change="setCalcCost($event, 'discount_cost', 'one')"
                  :default-value="
                    calculatorCosts && calculatorCosts.discount_cost
                      ? calculatorCosts.discount_cost
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.discount"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
                <div class="component__margin-left order__calculator_card-text">x</div>
                <div
                  v-if="row && row.amount"
                  class="component__margin-left order__calculator_amount"
                >
                  {{ row.amount }} {{ text.pcs[language] }} =
                </div>
                <Input
                  type="number"
                  @change="setCalcCost($event, 'discount_cost', 'all')"
                  :default-value="
                    calculatorCosts && calculatorCosts.discount_cost_all
                      ? calculatorCosts.discount_cost_all
                      : 0
                  "
                  :margin-bottom="0"
                  :disabled="!editCalculator.discount"
                  class="component__margin-left"
                />
                <div class="component__margin-left order__calculator_card-text">
                  <div v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </div>
                  <div v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order__calculator_bottom">
            <Button type="default" @click="handleClose" :text="text.cancel[language]" />
            <Button
              class="component__margin-left"
              @click="saveCalculateData"
              type="primary"
              :text="text.save[language]"
            />
          </div>
        </div>
        <div class="order__calculator_right">
          <div class="order__calculator_results">
            <div>
              <div class="order__calculator_card-title" style="margin-bottom: 10px">
                {{ text.cost[language] }}
              </div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.blank_cost[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span v-if="calculatorCosts && calculatorCosts.blank_cost">
                    {{ getNumberWithSpaces(+calculatorCosts.blank_cost) }}
                  </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.processing_cost[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span>{{ getNumberWithSpaces(+calculatorCosts.processing_cost) }} </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.overhead_cost[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span v-if="calculatorCosts && calculatorCosts.overhead_cost">
                    {{ getNumberWithSpaces(+calculatorCosts.overhead_cost) }}
                  </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                  <span v-if="calculatorCosts && calculatorCosts.overhead_delta">
                    ({{ getNumberWithSpaces(+calculatorCosts.overhead_delta) }} %)
                  </span>
                </div>
              </div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.profit2[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span v-if="calculatorCosts && calculatorCosts.profit">
                    {{ getNumberWithSpaces(+calculatorCosts.profit) }}
                  </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                  <span v-if="calculatorCosts && calculatorCosts.profit_delta">
                    ({{ getNumberWithSpaces(+calculatorCosts.profit_delta) }} %)
                  </span>
                </div>
              </div>
              <div class="order__calculator_delimiter" style="margin-bottom: 20px"></div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.total_cost[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span v-if="calculatorCosts && calculatorCosts.net_cost_all">
                    {{ getNumberWithSpaces(+calculatorCosts.net_cost_all) }}
                  </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                </div>
              </div>
              <div
                class="order__calculator_card-text"
                style="text-align: center; margin-bottom: 10px"
              >
                -
              </div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.discount[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span v-if="calculatorCosts && calculatorCosts.discount_cost">
                    {{ getNumberWithSpaces(+calculatorCosts.discount_cost) }}
                  </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                  <span v-if="calculatorCosts && calculatorCosts.discount_delta">
                    ({{ getNumberWithSpaces(+calculatorCosts.discount_delta) }} %)
                  </span>
                </div>
              </div>
              <div
                class="order__calculator_card-text"
                style="text-align: center; margin-bottom: 10px"
              >
                +
              </div>
              <div class="order__calculator_card-text block block__between">
                <div>{{ text.agency[language] }}</div>
                <div style="margin-bottom: 10px">
                  <span v-if="calculatorCosts && calculatorCosts.agency_all">
                    {{ getNumberWithSpaces(+calculatorCosts.agency_all) }}
                  </span>
                  <span class="order__calculator_card-text">
                    <span v-if="user && user.currency && user.currency === 'RUB'">
                      {{ text.rub[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'EUR'">
                      {{ text.eur[language] }}
                    </span>
                    <span v-else-if="user && user.currency && user.currency === 'USD'">
                      {{ text.usd[language] }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="order__calculator_total">
            <div class="order__calculator_delimiter" style="margin-bottom: 2px"></div>
            <div class="order__calculator_delimiter" style="margin-bottom: 20px"></div>
            <div class="order__calculator_card-big-title" style="margin-bottom: 20px">
              {{ text.total[language] }}
            </div>
            <div class="order__calculator_card-text block block__between">
              <div>{{ text.per_part[language] }}</div>
              <div style="margin-bottom: 10px">
                <span v-if="calculatorCosts && calculatorCosts.price_per_one">
                  {{ getNumberWithSpaces(+calculatorCosts.price_per_one) }}
                </span>
                <span class="order__calculator_card-text">
                  <span v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </span>
                  <span v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </span>
                  <span v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </span>
                </span>
              </div>
            </div>
            <div class="order__calculator_card-text block block__between">
              <div>{{ text.total_parts[language] }}</div>
              <div style="margin-bottom: 10px" v-if="row && row.amount">
                <span>x {{ row.amount }} {{ text.pcs[language] }}</span>
              </div>
            </div>
            <div class="order__calculator_card-title block block__between">
              <div>{{ text.per_batch_parts[language] }}</div>
              <div style="margin-bottom: 10px">
                <span v-if="calculatorCosts && calculatorCosts.total_price">
                  {{ getNumberWithSpaces(+calculatorCosts.total_price) }}
                </span>
                <span class="order__calculator_card-title">
                  <span v-if="user && user.currency && user.currency === 'RUB'">
                    {{ text.rub[language] }}
                  </span>
                  <span v-else-if="user && user.currency && user.currency === 'EUR'">
                    {{ text.eur[language] }}
                  </span>
                  <span v-else-if="user && user.currency && user.currency === 'USD'">
                    {{ text.usd[language] }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/order.json";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";
import Default from "@/assets/images/parts/edit-deafult.png";
import { getMoney, getNumberWithSpaces } from "@/utilities/functions";
import { query } from "@/utilities/axios";

export default {
  name: "AddBlankModal",
  data() {
    return {
      text,
      calculatorCosts: null,
      getMoney,
      getNumberWithSpaces,
      Default,
      editCalculator: {
        blank: false,
        treatment: false,
        profit: false,
        discount: false,
      },
      timer: null,
      savedBeforeCalc: {},
      saveData: {},
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
    row: {
      type: Object,
    },
    part: {
      type: Object,
    },
  },
  watch: {
    values: function (val) {
      if (val) {
        this.calculatorCosts = val;
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    setError(e) {
      e.target.src = Default;
    },
    setCalculatorEdit(val, name) {
      if (!val.target.checked) {
        if (name === "blank") {
          if (this.savedBeforeCalc.blank_cost) {
            delete this.savedBeforeCalc.blank_cost;
          }
          if (this.saveData.blank_cost_edit) {
            delete this.saveData.blank_cost_edit;
          }
        } else if (name === "treatment") {
          if (this.savedBeforeCalc.processing_cost) {
            delete this.savedBeforeCalc.processing_cost;
          }
          if (this.savedBeforeCalc.overhead_cost) {
            delete this.savedBeforeCalc.overhead_cost;
          }
          if (this.savedBeforeCalc.overhead_delta) {
            delete this.savedBeforeCalc.overhead_delta;
          }
          if (this.saveData.processing_cost_edit) {
            delete this.saveData.processing_cost_edit;
          }
          if (this.saveData.overhead_cost_edit) {
            delete this.saveData.overhead_cost_edit;
          }
          if (this.saveData.overhead_delta_edit) {
            delete this.saveData.overhead_delta_edit;
          }
        } else if (name === "profit") {
          if (this.savedBeforeCalc.profit_delta) {
            delete this.savedBeforeCalc.profit_delta;
          }
          if (this.savedBeforeCalc.profit) {
            delete this.savedBeforeCalc.profit;
          }
          if (this.saveData.profit_delta_edit) {
            delete this.saveData.profit_delta_edit;
          }
          if (this.saveData.profit_edit) {
            delete this.saveData.profit_edit;
          }
        } else if (name === "discount") {
          if (this.savedBeforeCalc.discount_cost) {
            delete this.savedBeforeCalc.discount_cost;
          }
          if (this.savedBeforeCalc.discount_delta) {
            delete this.savedBeforeCalc.discount_delta;
          }
          if (this.saveData.discount_cost_edit) {
            delete this.saveData.discount_cost_edit;
          }
          if (this.saveData.discount_delta_edit) {
            delete this.saveData.discount_delta_edit;
          }
        }
        this.preCalculate();
      }
      this.editCalculator[name] = val.target.checked;
    },
    setCalcCost(val, name, type) {
      const calculatorCosts = { ...this.calculatorCosts };
      if (name === "blank_cost") {
        if (type === "one") {
          calculatorCosts.blank_cost = +val;
          calculatorCosts.blank_cost_all = (+val * this.row.amount).toFixed(2);
        } else if (type === "all") {
          calculatorCosts.blank_cost = (+val / this.row.amount).toFixed(2);
          calculatorCosts.blank_cost_all = +val;
        }
      } else if (name === "processing_cost") {
        if (type === "one") {
          calculatorCosts.processing_cost = +val;
          calculatorCosts.processing_cost_all = (+val * this.row.amount).toFixed(2);
        } else if (type === "all") {
          calculatorCosts.processing_cost = (+val / this.row.amount).toFixed(2);
          calculatorCosts.processing_cost_all = +val;
        }
      } else if (name === "overhead_cost") {
        if (type === "one") {
          calculatorCosts.overhead_cost = +val;
          calculatorCosts.overhead_cost_all = (+val * this.row.amount).toFixed(2);
        } else if (type === "all") {
          calculatorCosts.overhead_cost = (+val / this.row.amount).toFixed(2);
          calculatorCosts.overhead_cost_all = +val;
        }
      } else if (name === "profit") {
        if (type === "one") {
          calculatorCosts.profit = +val;
          calculatorCosts.profit_all = (+val * this.row.amount).toFixed(2);
        } else if (type === "all") {
          calculatorCosts.profit = (+val / this.row.amount).toFixed(2);
          calculatorCosts.profit_all = +val;
        }
      } else if (name === "discount_cost") {
        if (type === "one") {
          calculatorCosts.discount_cost = +val;
          calculatorCosts.discount_cost_all = (+val * this.row.amount).toFixed(2);
        } else if (type === "all") {
          calculatorCosts.discount_cost = (+val / this.row.amount).toFixed(2);
          calculatorCosts.discount_cost_all = +val;
        }
      } else {
        if (calculatorCosts[name]) {
          calculatorCosts[name] = val;
        }
      }
      this.calculatorCosts = calculatorCosts;
      this.resetTimer(val, name, type);
    },
    preCalculate() {
      if (!this.saveData.uuid) {
        this.saveData.uuid = this.part.uuid;
      }
      for (const i of Object.keys(this.savedBeforeCalc)) {
        const val = this.savedBeforeCalc[i].val;
        const type = this.savedBeforeCalc[i].type;
        const name = this.savedBeforeCalc[i].name;
        const value = +val;
        if (name === "blank_cost") {
          this.saveData.blank_cost_edit = {};
          this.saveData.blank_cost_edit[type] = value;
        }
        if (name === "processing_cost") {
          this.saveData.processing_cost_edit = {};
          this.saveData.processing_cost_edit[type] = value;
        }
        if (name === "overhead_delta") {
          this.saveData.overhead_delta_edit = value;
        }
        if (name === "overhead_cost") {
          this.saveData.overhead_cost_edit = {};
          this.saveData.overhead_cost_edit[type] = value;
        }
        if (name === "profit_delta") {
          this.saveData.profit_delta_edit = value;
        }
        if (name === "profit") {
          this.saveData.profit_edit = {};
          this.saveData.profit_edit[type] = value;
        }
        if (name === "discount_delta") {
          this.saveData.discount_delta_edit = value;
        }
        if (name === "discount_cost") {
          this.saveData.discount_cost_edit = {};
          this.saveData.discount_cost_edit[type] = value;
        }
      }
      query("post", "preCalculate", this.saveData, {
        token: localStorage.getItem("token"),
      }).then((res) => {
        this.calculatorCosts = res.technology_analysis;
      });
    },
    saveCalculateData() {
      if (this.saveData && this.saveData.uuid) {
        query("post", "saveCalculateData", this.saveData, {
          token: localStorage.getItem("token"),
        }).then((res) => {
          this.calculatorCosts = res.technology_analysis;
          this.handleClose();
        });
      }
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.preCalculate();
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(val, name, type) {
      this.savedBeforeCalc[`${name}`] = {
        val,
        name,
        type,
      };
      this.stopTimer();
      this.startTimer();
    },
  },
  mounted() {
    if (this.values) {
      this.calculatorCosts = this.values;
    }
  },
  components: {
    Modal,
    Button,
    Input,
  },
};
</script>
