<template>
  <div>
    <EditModal
      :info-data="editData"
      :parameters="editData.params"
      @close="setShowEditModal(false)"
      :show="showEditModal"
      @update="getWBInfo(row.uuid)"
    />
    <ViewTableParameters :parameters="params" :width="width" @buttonClick="onHandleButtonClick" />
  </div>
</template>

<script>
import text from "@/assets/text/wb_types.json";
import Button from "@/components/button/Button.vue";
import ViewTableParameters from "@/components/viewTableParameters/ViewTableParameters.vue";
import EditModal from "../modals/EditModal.vue";
import query from "@/utilities/axios";

export default {
  name: "Edit",
  components: { Button, ViewTableParameters, EditModal },
  data() {
    return {
      text,
      params: [],
      editData: null,
      showEditModal: false,
    };
  },
  props: {
    row: {
      type: Object,
    },
    width: {
      type: Number,
    },
  },
  watch: {
    row: {
      immediate: true,
      handler: function (val) {
        if (val && val.uuid) {
          this.getWBInfo(val.uuid);
        }
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setShowEditModal(val) {
      if (!val) {
        this.$emit("update");
      }
      this.showEditModal = val;
    },
    onHandleButtonClick(e) {
      switch (e.button_type) {
        case "edit_working_body_expert":
          this.setShowEditModal(true);
          break;
      }
    },
    getWBInfo(uuid) {
      query(
        "post",
        "getMachineToolTypeWBInfo",
        { uuid },
        {
          token: localStorage.getItem("token"),
        },
      ).then((params) => {
        if (params && params.cards) {
          this.params = params.cards;
        }
        if (params && params.data_edit) {
          this.editData = params.data_edit;
        }
      });
    },
  },
};
</script>
