<template>
  <header class="header__container">
    <div class="block__flex block__left block__align-center">
      <Logo @click="goHome" style="cursor: pointer" />
    </div>
    <div>
      <Dropdown v-if="user">
        <template slot="body">
          <div class="header__user_logo">
            <div>
              <div class="header__user_name">
                <span v-if="user && user.first_name">{{ user.first_name }} </span>
                <span v-if="user && user.last_name">{{ user.last_name }}</span>
                <span v-if="user && !user.first_name && !user.last_name && user.company_name">
                  {{ user.company_name }}
                </span>
              </div>
              <div v-if="user && user.position" class="header__user_position">
                {{ user.position }}
              </div>
            </div>
            <div
              class="header__user_container component__margin-left"
              v-if="user && user.first_name"
            >
              {{ user.first_name[0] }}
            </div>
            <div
              class="header__user_container component__margin-left"
              v-else-if="user && !user.first_name && user.company_name"
            >
              {{ getCompanyName(user.company_name)[0] }}
            </div>
            <div v-else class="header__user_container component__margin-left">U</div>
          </div>
        </template>
        <template slot="dropdown">
          <div @click="pushTo('user_profile')" class="header__user_el">
            {{ text.user_profile[language] }}
          </div>
          <div class="header__user_el" @click="exit">
            {{ text.logout[language] }}
          </div>
        </template>
      </Dropdown>
    </div>
  </header>
</template>

<script>
import Logo from "@/assets/images/i5/wide_logo.svg";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import text from "@/assets/text/header.json";

export default {
  name: "Header",
  data() {
    return {
      text,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    exit() {
      localStorage.removeItem("token");
      this.$store.commit("user/removeUser");
      this.$store.commit("user/setShowAuthorizeModal", true);
    },
    goHome() {
      this.$router.push("/");
    },
    getCompanyName(word) {
      // const re = new RegExp(/(?<=["'“])[^"'”]+/gm);
      return word[0];
    },
    pushTo(link) {
      this.$router.push(`/${link}`);
    },
  },
  components: {
    Logo,
    Dropdown,
  },
};
</script>
