<template>
  <PageConstructor show-filter :query="query" :columns="columns" id-name="uuid">
    <template slot="edit" slot-scope="{ row }">
      <Edit :row="row" :label="text.edit_name[language]" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import Edit from "../edit/Edit.vue";
import text from "@/assets/text/all_tools.json";
export default {
  name: "Tools/Assembly",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getToolAssembly",
        headers: {},
        data: {
          uuid: this.$route.params.id,
        },
      },
      columns: [
        {
          name: "logo",
          label: {
            ru: "",
            en: "",
          },
          type: "image",
          options: {},
          basis: 80,
          width: 80,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "tool_type",
          label: text.tool_type,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "manufacturer",
          label: text.manufacturer,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
    Edit,
  },
};
</script>
