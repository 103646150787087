const state = () => ({
  alphabetRu: [
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Ж",
    "З",
    "И",
    "К",
    "Л",
    "М",
    "Н",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Ш",
    "Э",
    "Ю",
    "Я",
  ],
  alphabetEn: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
  gearTypes: {
    1: {
      id: 1,
      label: {
        ru: "Цилиндрическая",
        en: "",
      },
      tooth_types: [1, 2, 3],
      profile_types: {
        1: [1, 2],
        2: [1],
        3: [1],
      },
      parameters: {
        1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      },
    },
    2: {
      id: 2,
      label: {
        ru: "Коническая",
        en: "",
      },
      tooth_types: [1, 4, 5, 6],
      profile_types: {
        1: [1, 2],
        4: [1],
        5: [1],
        6: [1],
      },
      parameters: {
        1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17],
        4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17],
        5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17],
        6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17],
      },
    },
    3: {
      id: 3,
      label: {
        ru: "Винтовая",
        en: "",
      },
      tooth_types: [6],
      profile_types: {
        6: [1],
      },
    },
    4: {
      id: 4,
      label: {
        ru: "Цилиндрическое червячное колесо",
        en: "",
      },
      tooth_types: [1, 2, 6],
      profile_types: {
        1: [1, 3, 4, 5],
        2: [1, 3, 4, 5],
        6: [1, 3, 4, 5],
      },
    },
    5: {
      id: 5,
      label: {
        ru: "Глобоидное червячное колесо",
        en: "",
      },
      tooth_types: [1, 2, 6],
      profile_types: {
        1: [1, 3, 4, 5],
        2: [1, 3, 4, 5],
        6: [1, 3, 4, 5],
      },
    },
    6: {
      id: 6,
      label: {
        ru: "Червяк цилиндрический",
        en: "",
      },
      tooth_types: [6],
      profile_types: {
        6: [1, 3, 4, 5],
      },
    },
    7: {
      id: 7,
      label: {
        ru: "Червяк глобоидный",
        en: "",
      },
      tooth_types: [6],
      profile_types: {
        6: [1, 3, 4, 5],
      },
    },
    8: {
      id: 8,
      label: {
        ru: "Рейка",
        en: "",
      },
      tooth_types: [1, 2],
      profile_types: {
        1: [1, 2],
        2: [1, 2],
      },
    },
    9: {
      id: 9,
      label: {
        ru: "Звездочка",
        en: "",
      },
      tooth_types: [],
    },
    10: {
      id: 10,
      label: {
        ru: "Коронная шестерня",
        en: "",
      },
      tooth_types: [],
    },
  },
  gearToothTypes: {
    1: {
      id: 1,
      label: {
        ru: "Прямозубые",
        en: "",
      },
    },
    2: {
      id: 2,
      label: {
        ru: "Косозубые",
        en: "",
      },
    },
    3: {
      id: 3,
      label: {
        ru: "Шевронные",
        en: "",
      },
    },
    4: {
      id: 4,
      label: {
        ru: "Тангенциальные",
        en: "",
      },
    },
    5: {
      id: 5,
      label: {
        ru: "Круговые",
        en: "",
      },
    },
    6: {
      id: 6,
      label: {
        ru: "Криволинейные",
        en: "",
      },
    },
  },
  gearProfileTypes: {
    1: {
      id: 1,
      label: {
        ru: "Эвольвентные",
        en: "",
      },
    },
    2: {
      id: 2,
      label: {
        ru: "Круговые (Новиков)",
        en: "",
      },
    },
    3: {
      id: 3,
      label: {
        ru: "Архимедовые",
        en: "",
      },
    },
    4: {
      id: 4,
      label: {
        ru: "Конволюнтные",
        en: "",
      },
    },
    5: {
      id: 5,
      label: {
        ru: "Трапецеидальные",
        en: "",
      },
    },
  },
  gearParams: {
    1: {
      id: 1,
      label: {
        ru: "Число зубьев",
        en: "",
      },
      title: "Z",
      type: "input",
      name: "number_of_tooth",
    },
    2: {
      id: 2,
      label: {
        ru: "Модуль",
        en: "",
      },
      title: "m",
      type: "input",
      name: "module",
    },
    3: {
      id: 3,
      label: {
        ru: "Высота делительной головки зуба",
        en: "",
      },
      title: "h",
      index: "a",
      type: "input",
      name: "height_dividing_head",
    },
    4: {
      id: 4,
      label: {
        ru: "Высота делительной ножки зуба",
        en: "",
      },
      title: "h",
      index: "f",
      type: "input",
      name: "height_dividing_leg",
    },
    5: {
      id: 5,
      label: {
        ru: "Длина общей нормали",
        en: "",
      },
      title: "W",
      type: "input",
      name: "length_common_normal",
    },
    6: {
      id: 6,
      label: {
        ru: "Угол профиля зуба исходного контура",
        en: "",
      },
      title: "α",
      type: "input",
      name: "angle_tooth_profile",
    },
    7: {
      id: 7,
      label: {
        ru: "Делительные диаметр",
        en: "",
      },
      title: "d",
      type: "input",
      name: "dividing_diameter",
    },
    8: {
      id: 8,
      label: {
        ru: "Диаметр впадин",
        en: "",
      },
      title: "d",
      index: "f",
      type: "input",
      name: "hollows_diameter",
    },
    9: {
      id: 9,
      label: {
        ru: "Диаметр вершин",
        en: "",
      },
      title: "d",
      index: "a",
      type: "input",
      name: "vertices_diameter",
    },
    10: {
      id: 10,
      label: {
        ru: "Угол наклона зуба",
        en: "",
      },
      title: "β",
      type: "input",
      name: "tooth_angle",
    },
    11: {
      id: 11,
      label: {
        ru: "Ширина венца",
        en: "",
      },
      title: "b",
      type: "input",
      name: "crown_width",
    },
    12: {
      id: 12,
      label: {
        ru: "Нормальная толщина зуба",
        en: "",
      },
      title: "S",
      index: "t",
      type: "input",
      name: "tooth_thickness",
    },
    13: {
      id: 13,
      label: {
        ru: "Коэффициент смещения исходного контура",
        en: "",
      },
      title: "x",
      type: "input",
      name: "offset_coeff_original_contour",
    },
    14: {
      id: 14,
      label: {
        ru: "Шероховатость впадин",
        en: "",
      },
      title: "1",
      type: "roughness",
      name: "offset_coeff_original_contour",
    },
    15: {
      id: 15,
      label: {
        ru: "Шероховатость рабочей поверхности",
        en: "",
      },
      title: "2",
      type: "roughness",
      name: "hollows_roughness",
    },
    16: {
      id: 16,
      label: {
        ru: "Угол наклона линии зуба",
        en: "",
      },
      title: "β",
      index: "1",
      type: "input",
      name: "vertices_roughness",
    },
    17: {
      id: 17,
      label: {
        ru: "Угол делительного конуса",
        en: "",
      },
      title: "δ",
      type: "input",
      name: "angle_deviding_cone",
    },
  },
});

const mutations = {
  setOrder(state, data) {
    state.order = data;
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
