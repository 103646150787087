<template>
  <div
    class="expandable-container"
    ref="container"
    tabindex="0"
    @focus="handleFocus"
    @blur="handleBlur"
  >
    <div class="toggle" @mousedown="handleClick">
      <slot name="toggle"></slot>
    </div>
    <transition name="expand">
      <div
        class="expandable"
        v-if="expanded"
        ref="expandable"
        :style="setExpandStyle(maxHeight, position)"
      >
        <slot name="expand"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
      position: {},
      maxHeight: "0px",
      focus: false,
    };
  },
  props: {
    componentScroll: {
      type: Number,
    },
  },
  watch: {
    scrollTop: function () {
      if (!this.componentScroll) {
        this.setPosition();
      }
    },
    componentScroll: function () {
      this.setPosition();
    },
  },
  computed: {
    scrollTop() {
      return this.$store.state.index.scrollTop;
    },
  },
  methods: {
    handleFocus() {
      this.toggleExpanded();
      this.focus = true;
    },
    handleBlur() {
      this.expanded = false;
      this.focus = false;
      this.$emit("close");
    },
    toggleExpanded() {
      this.expanded = true;
      this.setPosition();
    },
    handleClick() {
      if (this.focus) {
        this.expanded = !this.expanded;
        if (!this.expanded) {
          this.$emit("close");
        }
      }
    },
    closeExpanded() {
      this.expanded = false;
      this.$emit("close");
    },
    setPosition() {
      if (this.expanded) {
        const expandable = this.$refs.expandable;
        const calculate = () => {
          const containerRect = this.$refs.container.getBoundingClientRect();
          const expandableRect = this.$refs.expandable.getBoundingClientRect();

          // Calculate the maximum height of the expandable element based on the available space
          const maxHeight = Math.max(
            window.innerHeight - containerRect.bottom - 10,
            containerRect.top - 10,
          );

          // Calculate the position of the expandable element
          let top = containerRect.top + window.pageYOffset + containerRect.height;
          let left = containerRect.left + window.pageXOffset;
          //  const right = left + expandableRect.width;
          let bottom = top + expandableRect.height;
          let useTop = true;

          // Adjust the position of the expandable element if it overflows the screen
          /* if (right > window.innerWidth) {
            left -= right - window.innerWidth;
          } */
          if (left < 0) {
            left = 0;
          }
          if (bottom > window.innerHeight) {
            useTop = false;
            bottom = 0;
          } else {
            useTop = true;
          }
          if (top < 51) {
            top = 51;
          }

          // Adjust the height of the expandable element if it exceeds the maximum height
          const height = maxHeight;
          /* if (top + height > window.innerHeight) {
            top = window.innerHeight - height;
          } */

          // Set the position and height of the expandable element
          const position = {
            left,
          };
          if (useTop) {
            position.top = top;
          } else {
            position.bottom = bottom;
          }
          this.position = position;
          this.maxHeight = `${height}px`;
        };
        if (expandable) {
          calculate();
        } else {
          this.$nextTick(() => {
            calculate();
          });
        }
      }
    },
    setExpandStyle(height, position) {
      let result = "";
      result += `max-height: ${height}; `;
      if (position.top || position.top === 0) {
        result += `top: ${position.top}px; `;
      }
      if (position.right || position.right === 0) {
        result += `right: ${position.right}px; `;
      }
      if (position.bottom || position.bottom === 0) {
        result += `bottom: ${position.bottom}px; `;
      }
      if (position.left || position.left === 0) {
        result += `left: ${position.left}px; `;
      }
      return result;
    },
    setDraggable(val, index = null) {
      this.draggable = val;
      this.dragIndex = index;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setPosition);
    this.$emit("setClose", this.closeExpanded);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setPosition);
  },
};
</script>
