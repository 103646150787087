<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :page-name="text.page_name[language]"
  >
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/users.json";

export default {
  name: "Users",
  data() {
    return {
      text,
      query: {},
      columns: [
        {
          name: "login",
          label: text.login,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "surname",
          label: text.surname,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "email",
          label: text.email,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "phone",
          label: text.phone,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "work_phone",
          label: text.work_phone,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "post",
          label: text.post,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "department",
          label: text.department,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "role",
          label: text.role,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
