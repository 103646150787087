var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`input-i5 ${_vm.labelCol ? 'input-i5__col' : ''}`,style:(`text-align: ${_vm.labelPosition}; ${
    _vm.showError ? 'background-color: rgba(185, 42, 33, .3) !important' : ''
  }`)},[_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.subLabel && !_vm.error)?_c('span',{staticClass:"input-i5__sublabel"},[_vm._v(" "+_vm._s(_vm.subLabel)+" ")]):_vm._e(),(_vm.error)?_c('span',{staticClass:"input-i5__error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('div',{staticStyle:{"position":"relative"}},[_c('input',{class:`${_vm.error ? 'input-i5_error' : ''}`,style:(`text-align: ${_vm.textPosition}; ${
        _vm.search ? 'padding-right: 20px;' : ''
      } height: ${_vm.height}px !important; margin-bottom: ${_vm.marginBottom}px !important; ${
        _vm.maxWidth ? `max-width: ${_vm.maxWidth}px;` : ''
      }`),attrs:{"tabindex":_vm.ti,"id":_vm.name,"step":_vm.step,"type":_vm.type,"name":_vm.name,"autocomplete":"off","readonly":_vm.readonly === true,"disabled":_vm.disabled === true},domProps:{"value":_vm.value},on:{"input":_vm.handleChange}}),(_vm.search)?_c('Search',{staticClass:"input-i5__search"}):_vm._e(),(_vm.currency && _vm.user && _vm.user.currency)?_c('div',{staticClass:"input-i5__search"},[_c('div',{staticStyle:{"font-size":"16px","line-height":"20px","font-weight":"600"}},[(_vm.user.currency === 'RUB')?_c('div',[_vm._v("₽")]):_vm._e(),(_vm.user.currency === 'USD')?_c('div',[_vm._v("$")]):_vm._e(),(_vm.user.currency === 'EUR')?_c('div',[_vm._v("$")]):_vm._e()])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }