<template>
  <div>
    <div class="block block__left block__align-center">
      <div class="tree__order_icon">
        <Order />
      </div>
      <div class="tree__order_name">№ {{ orderName }}</div>
    </div>
    <div v-for="(el, index) in parts" v-bind:key="`tree_${el.uuid}`">
      <div
        :id="`part_${el.uuid}`"
        @mouseenter="setActiveEnter(index, true)"
        @mouseleave="setActiveEnter(index, false)"
        @click="handleClick(el)"
        class="block__flex block__align-center block__stretch"
      >
        <div style="padding-left: 15px" class="block__flex block__column">
          <div class="tree__branch_top"></div>
          <div :class="index < parts.length - 1 ? 'tree__branch_bot' : 'tree__branch_hide'"></div>
        </div>
        <div
          :class="`tree__part_wrapper ${
            activePart === el.uuid ? 'tree__part_active' : 'tree__part_not-active'
          }`"
        >
          <div class="tree__part_container">
            <div class="tree__part_image-cont">
              <img
                :src="el.preview"
                v-if="el.preview && el.preview !== 'default'"
                @error="setError"
                class="tree__preview"
              />
              <img
                src="@/assets/images/parts/partError.png"
                v-if="!el.preview || el.preview === 'default'"
                @error="setError"
                class="tree__preview"
              />
            </div>
            <div style="overflow-x: hidden">
              <div class="tree__part_code">
                {{ el.code && el.code }}
              </div>
              <div class="tree__part_name">{{ el.name && el.name }}</div>
            </div>
          </div>
          <div class="tree__close">
            <transition name="component">
              <Close v-if="index === enterLeaf" @click="deletePart(el)" class="tree__close_icon" />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Default from "@/assets/images/parts/partError.png";
import Close from "@/assets/images/parts/delete-part.svg";

const Order = () => import("@/assets/images/tree/order.svg");

export default {
  name: "PartList",
  data() {
    return {
      activePart: null,
      enterLeaf: null,
    };
  },
  props: {
    activeUuid: null,
    orderName: {
      type: String,
      default: "",
    },
    parts: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    activeUuid: function (val) {
      this.activePart = val;
    },
    parts: function (val) {
      if (this.activeUuid) {
        this.activePart = this.activeUuid;
      } else {
        this.activePart = val[0].uuid;
      }
    },
  },
  methods: {
    handleClick(el) {
      this.activePart = el.uuid;
      this.$emit("click", el);
    },
    deletePart(el) {
      this.$emit("delete", el);
    },
    setError(e) {
      e.target.src = Default;
    },
    setActiveEnter(index, value) {
      if (value) {
        this.enterLeaf = index;
      } else {
        this.enterLeaf = null;
      }
    },
  },
  mounted() {},
  components: {
    Order,
    Close,
  },
};
</script>
