<template>
  <Modal :title="text.select_company_title[language]" :show="modalShow" @close="close">
    <template slot="body">
      <div class="select-manuf__container">
        <div class="select-manuf__filters_container">
          <div class="select-manuf__filters_title">
            {{ text.sc_filter_title[language] }}
          </div>
          <div>
            <a-checkbox disabled value="1">
              {{ text.accept_orders[language] }}
            </a-checkbox>
          </div>
          <div>
            <a-checkbox
              value="2"
              @change="setFilter('manufacturers_has_email', $event)"
              :defaultChecked="filters.manufacturers_has_email"
            >
              {{ text.have_an_email[language] }}
            </a-checkbox>
          </div>
          <div>
            <a-checkbox
              value="3"
              :defaultChecked="filters.manufacturers_has_mt"
              @change="setFilter('manufacturers_has_mt', $event)"
            >
              {{ text.has_a_mt[language] }}
            </a-checkbox>
          </div>
          <div>
            <a-checkbox value="4" disabled>
              {{ text.mech_proc[language] }}
            </a-checkbox>
          </div>
          <div>
            <a-checkbox value="5" disabled>
              {{ text.printing[language] }}
            </a-checkbox>
          </div>
          <div>
            <a-checkbox value="6" disabled>
              {{ text.molding[language] }}
            </a-checkbox>
          </div>
        </div>
        <div class="select-manuf__search_container">
          <div style="padding-right: 10px">
            <Input style="margin-top: 15px" search block @change="find($event)" />
          </div>
          <div class="select-manuf__search_list" id="companies_list">
            <div
              class="select-manuf__card"
              v-for="(item, index) in allCompaniesList"
              v-bind:key="`company_${index}`"
            >
              <div class="block block__align-center block__between">
                <div class="block__flex block__align-center component__margin-bottom">
                  <div class="component__margin-right">
                    <a-checkbox
                      :value="item.id"
                      :name="`${item.id}`"
                      @change="setCompany(item, $event, index)"
                      :checked="item.checked"
                    ></a-checkbox>
                  </div>
                  <a-tooltip
                    :placement="'top'"
                    :mouseEnterDelay="1"
                    overlayClassName="tooltip-i5__wrapper"
                  >
                    <template slot="title">
                      {{ item.name }}
                    </template>
                    <div class="select-manuf__card_name component__select">
                      {{ item.name }}
                    </div>
                  </a-tooltip>
                </div>
                <div class="select-manuf__card_mark">{{ item.mark }}</div>
              </div>
              <div class="component__margin-bottom" v-if="item.inn">
                {{ text.sc_card_inn[language] }}
                <span class="component__select">
                  {{ item.inn }}
                </span>
              </div>
              <div class="block block__align-center block__between">
                <div class="block__flex block__align-center">
                  <div>{{ text.accept_orders[language] }}:</div>
                  <div v-if="item.receive_orders" class="component__margin-left">
                    <Success />
                  </div>
                  <div v-if="!item.receive_orders" class="component__margin-left">
                    <Error />
                  </div>
                </div>
                <div v-if="item.email" class="component__select">
                  {{ item.email }}
                </div>
              </div>
              <div v-if="item.mt_on_manufacturers">
                {{ text.number_of_mt[language] }}: {{ item.mt_on_manufacturers }}
              </div>
            </div>
          </div>
        </div>
        <div class="select-manuf__result_container">
          <div class="select-manuf__result_list">
            <div
              class="select-manuf__card"
              v-for="(item, index) in companiesList"
              v-bind:key="`company_${index}`"
            >
              <div class="block block__align-center block__between">
                <div class="block__flex block__align-center component__margin-bottom">
                  <a-tooltip
                    :placement="'top'"
                    :mouseEnterDelay="1"
                    overlayClassName="tooltip-i5__wrapper"
                  >
                    <template slot="title">
                      {{ item.name }}
                    </template>
                    <div class="select-manuf__card_name component__select">
                      {{ item.name }}
                    </div>
                  </a-tooltip>
                </div>
                <div class="block__flex block__align-center">
                  <div class="select-manuf__card_mark">{{ item.mark }}</div>
                  <Delete
                    class="component__margin-left"
                    @click="deleteCompany(index, item)"
                    style="cursor: pointer"
                  />
                </div>
              </div>
              <div class="component__margin-bottom" v-if="item.inn">
                {{ text.sc_card_inn[language] }}
                <span class="component__select">
                  {{ item.inn }}
                </span>
              </div>
              <div class="block block__align-center block__between">
                <div class="block__flex block__align-center">
                  <div>{{ text.accept_orders[language] }}:</div>
                  <div v-if="item.receive_orders" class="component__margin-left">
                    <Success />
                  </div>
                  <div v-if="!item.receive_orders" class="component__margin-left">
                    <Error />
                  </div>
                </div>
                <div v-if="item.email" class="component__select">
                  {{ item.email }}
                </div>
              </div>
              <div v-if="item.mt_on_manufacturers">
                {{ text.number_of_mt[language] }}: {{ item.mt_on_manufacturers }}
              </div>
            </div>
          </div>
          <div style="padding-right: 10px">
            <Button
              @click="sendOrder"
              :text="text.send_to_companies[language]"
              type="attention"
              block
              :disabled="companiesList.length === 0"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import Input from "@/components/input/Input.vue";
import text from "@/assets/text/order.json";
import Success from "@/assets/images/order/success2.svg";
import Error from "@/assets/images/order/error.svg";
import Delete from "@/assets/images/parts/delete-part.svg";
import Button from "@/components/button/Button.vue";
import { query } from "@/utilities/axios";

export default {
  name: "SelectCompany",
  data() {
    return {
      text,
      modalShow: false,
      timer: null,
      linePerPage: 30,
      loading: false,
      activePage: 1,
      allCompanies: null,
      allCompaniesList: null,
      totalPages: null,
      companiesList: [],
      activeIds: {},
      filters: {
        manufacturers_has_mt: true,
        manufacturers_has_email: true,
      },
      findWord: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: [String, Number],
      default: "",
    },
  },
  watch: {
    show: {
      handler: function (val) {
        this.modalShow = val;
        this.modalShow = this.show;
        this.$nextTick(() => {
          const list = document.getElementById("companies_list");
          this.setListSize(list);
        });
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    close() {
      this.modalShow = false;
      this.$emit("close");
    },
    find(val) {
      this.findWord = val;
      this.resetTimer(val);
    },
    startTimer(value) {
      this.timer = setTimeout(() => {
        this.getCompanies(this.linePerPage, 1, value);
      }, 600);
    },
    getCompanies(linePerPage, page, find = null) {
      const data = {
        page: page,
        line_per_page: linePerPage,
      };
      if (find) {
        data.search = {
          find: find,
        };
      }
      data.filters = this.filters;
      this.loading = true;
      this.activePage = 1;
      query("post", "getCompaniesAdminV2old", data, {
        token: localStorage.getItem("token"),
      })
        .then((files) => {
          const result = files.table;
          result.forEach((item) => {
            if (this.activeIds[item.id]) {
              item.checked = true;
            }
          });
          this.allCompaniesList = result;
          this.totalPages = files.total_pages;
          this.addCompanies(linePerPage, page + 1, find);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onScroll(e) {
      const top = e.target.scrollTop;
      let page = 1;
      if (top === 0) {
        page = 2;
      } else if (top > 0 && top <= this.allCompanies.clientHeight) {
        page = 3;
      } else {
        page = Math.ceil(top / this.allCompanies.clientHeight) + 2;
      }
      if (page > this.activePage && page <= this.totalPages && page > 2) {
        this.activePage = page;
        if (!this.findWord) {
          this.addCompanies(this.linePerPage, page);
        } else {
          this.addCompanies(this.linePerPage, page, this.findWord);
        }
      }
    },
    addCompanies(linePerPage, page, find = null) {
      const data = {
        page: page,
        line_per_page: linePerPage,
      };
      if (find) {
        data.search = {
          find: find,
        };
      }
      data.filters = this.filters;
      this.loading = true;
      query("post", "getCompaniesAdminV2old", data, {
        token: localStorage.getItem("token"),
      })
        .then((files) => {
          const result = files.table;
          result.forEach((item) => {
            if (this.activeIds[item.id]) {
              item.checked = true;
            }
          });
          this.allCompaniesList = [...this.allCompaniesList, ...result];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(value) {
      this.stopTimer();
      this.startTimer(value);
    },
    setListSize(el) {
      this.allCompanies = el;
      this.linePerPage = 30;
      this.allCompanies.addEventListener("scroll", this.onScroll);
      this.getCompanies(this.linePerPage, 1);
    },
    setCompany(item, evt, index) {
      const result = this.allCompaniesList.slice();
      result[index].checked = evt.target.checked;
      this.allCompaniesList = result;
      if (evt.target.checked) {
        this.companiesList.push(item);
        this.activeIds[item.id] = true;
      } else {
        const index = this.companiesList.findIndex((el) => el.id === item.id);
        this.companiesList.splice(index, 1);
        this.activeIds[item.id] = false;
      }
    },
    deleteCompany(index, item) {
      this.companiesList.splice(index, 1);
      this.activeIds[item.id] = false;
      const result = this.allCompaniesList.slice();
      const i = result.findIndex((el) => el.id === item.id);
      result[i].checked = false;
      this.allCompaniesList = result;
    },
    setFilter(name, val) {
      this.filters[name] = val.target.checked;
      if (!this.findWord) {
        this.getCompanies(this.linePerPage, 1);
      } else {
        this.getCompanies(this.linePerPage, 1, this.findWord);
      }
    },
    sendOrder() {
      const result = [];
      this.companiesList.forEach((item) => {
        result.push(item.id);
      });
      query(
        "post",
        "sendOrderToManufacturers",
        {
          uuid: this.uuid,
          manufacturers: result,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.close();
        this.$emit("update");
      });
    },
  },
  mounted() {},
  beforeDestroy() {},
  components: {
    Modal,
    Input,
    Success,
    Error,
    Delete,
    Button,
  },
};
</script>
