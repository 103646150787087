<template>
  <div class="block__flex block__align-center block__column" style="position: relative">
    <div style="width: 100%" :class="`input-i5 ${setFocus()}`">
      <label :for="name" @click="handleFocusOut"
        >{{ label }}
        <span v-if="required">*</span>
        <span v-if="subLabel && !error" class="input-i5__sublabel">
          {{ subLabel }}
        </span>
        <span v-if="error" class="input-i5__error">
          {{ error }}
        </span>
      </label>
      <div style="position: relative">
        <input
          :tabindex="ti"
          @focus="handleFocus"
          @blur="handleFocusOut"
          :value="value"
          :id="name"
          :name="name"
          autocomplete="off"
          :disabled="disabled === true"
          :class="`${error ? 'input-i5_error' : ''}`"
          :style="`${clearable ? 'padding-right: 20px;' : ''}`"
          @input="handleChange"
        />
        <ClearImage v-if="clearable && value" class="input-i5__clear" @click="clear" />
      </div>
    </div>
    <div
      :id="`${name}_dropdown`"
      :style="{
        width: `${elWidth}px`,
        maxHeight: `${dropDownMaxHeight}px`,
        marginTop: `${elHeight + 2}px`,
        position: setPosition(),
      }"
      :class="`search__container ${setOpen()} ${setDirection()}`"
    >
      <div
        v-for="(val, index) in dropdownValues"
        v-bind:key="`dd_${index}`"
        @mousedown="(e) => e.preventDefault()"
      >
        <div
          @click="setElement(val)"
          :class="`${
            dropdownProps && dropdownProps.secondLine && val[dropdownProps.secondLine]
              ? 'search__item_double'
              : 'search__item'
          }`"
        >
          <div class="block block__align-center">
            <img
              class="component__margin-right search__image"
              v-if="dropdownProps && dropdownProps.img && val[dropdownProps.img]"
              :src="val[dropdownProps.img]"
            />
            <div>
              <div class="block block__align-center">
                <div
                  v-if="dropdownProps && dropdownProps.prelabel && val[dropdownProps.prelabel]"
                  class="component__margin-right"
                >
                  <b>{{ val[dropdownProps.prelabel] }}</b>
                </div>
                <div>{{ val.label }}</div>
                <div
                  v-if="dropdownProps && dropdownProps.sublabel && val[dropdownProps.sublabel]"
                  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                  class="component__margin-left"
                >
                  ({{ val[dropdownProps.sublabel] }})
                </div>
              </div>
              <div
                v-if="dropdownProps && dropdownProps.secondLine && val[dropdownProps.secondLine]"
              >
                {{ val[dropdownProps.secondLine] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showCustom && value && isChanged"
        @mousedown="(e) => e.preventDefault()"
        @click="
          () => {
            this.isChoose = true;
            $emit('change', {
              label: value,
              value: -1,
            });
            this.open = false;
          }
        "
        class="search__custom-item"
      >
        <span
          >{{ text.custom_text[language] }}: <b>{{ value }}</b></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { query as q } from "@/utilities/axios";
import ClearImage from "@/assets/images/form/clear.svg";
import text from "@/assets/text/search.json";

export default {
  name: "Search",
  data() {
    return {
      text,
      focus: false,
      open: false,
      elWidth: null,
      elHeight: 70,
      dropDownMaxHeight: 100,
      dropDownDirection: "bottom", // top
      dropdownTransform: 0,
      value: "",
      tempValue: "",
      dropdownValues: [],
      isChanged: false,
      isChoose: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "search",
    },
    defaultValue: {
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
    },
    query: {
      type: Object, // method, url, label, value, findName, byObject, objectName
    },
    placement: {
      type: String,
      validator: function (value) {
        return ["bottomLeft", "bottom", "bottomRight"].indexOf(value) !== -1;
      },
    },
    positionType: {
      type: String,
      default: "scrollable", // fixed
    },
    active: [Number, String, Object],
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dropdownProps: {
      type: Object, // img, sublabel, prelabel, label,
    },
    showCustom: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Object, String],
      default: null,
    },
  },
  watch: {
    active: function () {
      this.open = false;
    },
    defaultValue: function (val) {
      this.value = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    handleFocus() {
      this.open = true;
      this.tempValue = this.value;
      this.value = "";
    },
    handleFocusOut() {
      this.open = false;
      if (!this.isChoose && this.tempValue) {
        this.value = this.tempValue;
      }
      this.isChoose = false;
      this.isChanged = false;
    },
    setTempValue() {
      if (this.tempValue) {
        this.value = this.tempValue;
      }
    },
    setFocus() {
      if (this.focus) {
        return "search__focus";
      }
      return "";
    },
    setOpen() {
      let result = "";
      if (this.open) {
        result = "search__open";
        this.$nextTick(() => {
          const dropdown = document.getElementById(`${this.name}_dropdown`);
          const dropdownWidth = dropdown.offsetWidth;
          const x = dropdown.getBoundingClientRect().x;
          const width = window.innerWidth;
          if (width / 2 < x && x + dropdownWidth > width) {
            this.dropdownTransform = `-${Math.round(x + dropdownWidth - width + 8)}px`;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          } else if (this.placement === "bottomLeft") {
            this.dropdownTransform = this.$el.getBoundingClientRect().x - x;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          }
          this.dropdownToggle(dropdown);
        });
      }
      return result;
    },
    dropdownToggle(el) {
      const y = el.getBoundingClientRect().y;
      this.dropDownDirection = "bottom";
      this.dropDownMaxHeight = window.innerHeight - y - 30;
    },
    clear() {
      this.value = "";
      this.$emit("clear");
    },
    setDirection() {
      return `search_${this.dropDownDirection}`;
    },
    setPosition() {
      let result = "";
      if (this.positionType === "scrollable") {
        result = "absolute !important";
      } else if (this.positionType === "fixed") {
        result = "fixed !important";
      }
      return result;
    },
    handleChange(evt) {
      this.value = evt.target.value;
      this.isChanged = true;
      if (this.query) {
        let findName = "find";
        if (this.query.findName) {
          findName = this.query.findName;
        }
        const data = {};
        data[findName] = evt.target.value;
        q(this.query.method, this.query.url, data, {
          token: localStorage.getItem("token"),
        }).then((values) => {
          const result = [];
          values.forEach((val) => {
            result.push({
              label: val[this.query.label],
              value: val[this.query.value],
              ...val,
            });
          });
          this.dropdownValues = result;
        });
      }
    },
    setElement(val) {
      this.isChoose = true;
      this.value = val.label;
      this.$emit("change", val);
      this.open = false;
      document.getElementById(`${this.name}`).blur();
    },
  },
  mounted() {
    this.elWidth = this.$el.scrollWidth;
    if (!this.label) {
      this.elHeight = 35;
    }
    this.value = this.defaultValue;
    this.$emit("setTempValue", this.setTempValue);
  },
  components: {
    ClearImage,
  },
};
</script>
