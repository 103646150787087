<template>
  <a-tooltip :placement="placement">
    <template slot="title">
      {{ text }}
    </template>
    <button
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @click="handleClick"
      :class="`icon-button__container ${clicked ? 'icon-button__container_clicked' : ''} ${
        disabled ? 'icon-button__container_disabled' : `icon-button__container_${type}`
      }`"
    >
      <a-icon :type="iconName" :style="setAntStyle()" v-if="iconType === 'ant'"> </a-icon>
      <Icon v-if="iconType === 'svg'" size="small" :iconStyle="svgStyle" :icon="iconName" />
    </button>
  </a-tooltip>
</template>

<script>
const Icon = () => import("@/components/icons/Icons.vue");

export default {
  name: "IconButton",
  data() {
    return {
      hovered: false,
      clicked: false,
      svgStyle: {},
    };
  },
  props: {
    type: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "primary"].indexOf(value) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: "ant",
      validator: function (value) {
        return ["ant", "svg"].indexOf(value) !== -1;
      },
    },
    iconName: {
      type: String,
      default: "question-circle",
    },
    text: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
      default: "top",
      validator: function (value) {
        return (
          [
            "topLeft",
            "top",
            "topRight",
            "leftTop",
            "left",
            "leftBottom",
            "rightTop",
            "right",
            "rightBottom",
            "bottomLeft",
            "bottom",
            "bottomRight",
          ].indexOf(value) !== -1
        );
      },
    },
  },
  methods: {
    handleMouseEnter() {
      if (!this.disabled) {
        this.hovered = true;
        if (this.iconType === "svg") {
          this.setSvgStyle();
        }
      }
    },
    handleMouseLeave() {
      this.hovered = false;
      if (this.iconType === "svg") {
        this.setSvgStyle();
      }
    },
    handleClick() {
      if (!this.disabled) {
        this.$emit("click");
        this.clicked = true;
        setTimeout(() => {
          this.clicked = false;
        }, 500);
      }
    },
    setAntStyle() {
      const color = this.disabled ? "rgba(0,0,0, .3)" : "#314659";
      const result = {
        transition: "all .3s linear",
        color: color,
      };
      if (this.type === "default") {
        if (this.hovered) {
          result.color = this.$store.state.index.primaryColor;
        } else {
          result.color = color;
        }
      } else if (this.type === "primary") {
        result.color = "#fff";
      }
      return result;
    },
    setSvgStyle() {
      const color = this.disabled ? "rgba(0,0,0, .3)" : "#314659";
      const result = {
        transition: "all .3s linear",
        fill: color,
      };
      if (this.type === "default") {
        if (this.hovered) {
          result.fill = this.$store.state.index.primaryColor;
        } else {
          result.fill = color;
        }
      } else if (this.type === "primary") {
        result.fill = "#fff";
      }
      return result;
    },
  },
  components: {
    Icon,
  },
};
</script>
