<template>
  <div class="tech-proc__wrapper">
    <div class="tech-proc__container" @scroll="setLeftScroll($event)">
      <Button
        block
        @click="closePage"
        class="component__margin-bottom"
        :text="text.back[language]"
      />
      <Button
        block
        @click="setEdit"
        class="component__margin-bottom"
        :text="edit ? text.finish_editing[language] : text.edit[language]"
      />
      <TechTree
        v-if="lines"
        :values="lines"
        :mp="mp"
        @update="updateTree($event)"
        :parentScroll="leftScroll"
        :active-leaf="activeIndex"
        @handleClick="treeHandleClick"
        @updateTreeValues="setUpdateTreeValues($event)"
        :edit="edit"
        @click="setItem($event)"
      />
      <!--<TechTree
        v-if="values"
        @flat="setFlatData($event)"
        :values="[values]"
        @click="setItem($event)"
        :levels="['route', 'sets', 'passes']" />-->
      <Button
        v-if="edit"
        class="component__margin-top"
        block
        @click="setShowAddModal(true)"
        :text="text.add[language]"
      />
    </div>
    <div class="tech-proc__body_3d">
      <a-tabs default-active-key="1" @change="setActiveTab($event)">
        <a-tab-pane key="1" :tab="text.params[language]">
          <div class="tech-proc__tab">
            <div v-if="activeItem">
              <div v-if="activeIndex === 'tp'" style="width: 100%">
                <div class="tech-proc__name component__margin-bottom" v-if="activeItem.name">
                  <span>
                    <span v-if="activeItem.code">
                      {{ activeItem.code }}
                    </span>
                    <span>{{ activeItem.name }}</span>
                  </span>
                </div>
                <div v-if="activeItem.status" class="page__edit_status component__margin-bottom">
                  {{ activeItem.status }}
                </div>
                <Tp @update="updateTp()" :values="activeItem" :edit="edit" />
              </div>
              <div v-if="activeItem.type === 1" style="width: 100%">
                <div class="tech-proc__name component__margin-bottom" v-if="activeItem.name">
                  <span>
                    <span>{{ activeItem.name }}</span>
                  </span>
                </div>
                <div class="block">
                  <Blank @update="updateTp()" :values="activeItem" :edit="edit" />
                  <!--<div style="width: 100%">
                    <div class="tech-proc__machine-tool_title component__margin-left">
                      {{activeItem['machine_tool']}}
                    </div>
                  </div>-->
                </div>
              </div>
              <div v-if="activeItem.type === 3" style="width: 100%">
                <div class="component__margin-bottom" v-if="activeItem.name">
                  <span>
                    <span
                      v-if="activeItem.number"
                      style="font-weight: 700"
                      class="tech-proc__number"
                      >{{ text.fix[language] }} {{ activeItem.number }}
                    </span>
                    <span class="tech-proc__name">{{ activeItem.name }}</span>
                  </span>
                </div>
                <div class="block">
                  <Fixture @update="updateTp()" :values="activeItem" :edit="edit" />
                  <div style="margin-left: 20px; min-width: 300px">
                    <div class="tech-proc__card">
                      <div v-if="activeItem.tv" class="component__margin-bottom">
                        {{ text.time_tv_title[language] }}
                      </div>
                      <div v-if="activeItem.tv" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tv[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value">{{ activeItem.tv }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.t_fix" class="component__margin-bottom">
                        {{ text.time_tfix_title[language] }}
                      </div>
                      <div v-if="activeItem.t_fix" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tfix[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value">{{ activeItem.t_fix }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.t_defix" class="component__margin-bottom">
                        {{ text.time_tdefix_title[language] }}
                      </div>
                      <div v-if="activeItem.t_defix" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tdefix[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value">{{ activeItem.t_defix }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeItem.type === 4" style="width: 100%">
                <div class="component__margin-bottom" v-if="activeItem.name">
                  <span>
                    <span v-if="activeItem.number" class="tech-proc__number"
                      >{{ activeItem.number }}
                    </span>
                    <span class="tech-proc__name">{{ activeItem.name }}</span>
                  </span>
                </div>
                <div class="block">
                  <Transition @update="updateTp()" :values="activeItem" :edit="edit" />
                  <div style="margin-left: 20px; min-width: 300px">
                    <div class="tech-proc__card">
                      <div v-if="activeItem.top" class="component__margin-bottom">
                        {{ text.time_top_title[language] }}
                      </div>
                      <div v-if="activeItem.top" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_top[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.top }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.to" class="component__margin-bottom">
                        {{ text.time_to_title[language] }}
                      </div>
                      <div v-if="activeItem.to" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_to[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.to }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.tv" class="component__margin-bottom">
                        {{ text.time_tv_transition_title[language] }}
                      </div>
                      <div v-if="activeItem.tv" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tv[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.tv }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeItem.type === 2" style="width: 100%">
                <div class="component__margin-bottom" v-if="activeItem.name">
                  <span>
                    <span
                      v-if="activeItem.number"
                      style="font-weight: 700"
                      class="tech-proc__number"
                      >{{ activeItem.number }}
                    </span>
                    <span class="tech-proc__name">{{ activeItem.name }}</span>
                  </span>
                </div>
                <div class="block">
                  <Operation :values="activeItem" @update="updateTp()" :edit="edit" />
                  <div style="margin-left: 20px; min-width: 300px">
                    <div class="tech-proc__card component__margin-bottom">
                      <div v-if="activeItem.tk" class="component__margin-bottom">
                        {{ text.time_tk_title[language] }}
                      </div>
                      <div v-if="activeItem.tk" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tk[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.tk }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.to" class="component__margin-bottom">
                        {{ text.time_to_title[language] }}
                      </div>
                      <div v-if="activeItem.to" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_to[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.to }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.tpz" class="component__margin-bottom">
                        {{ text.time_tpz_title[language] }}
                      </div>
                      <div v-if="activeItem.tpz" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tpz[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.tpz }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                      <div v-if="activeItem.tv" class="component__margin-bottom">
                        {{ text.time_tv_title[language] }}
                      </div>
                      <div v-if="activeItem.tv" class="component__margin-bottom">
                        <span class="tech-proc__card_label">T</span>
                        <span class="tech-proc__card_index">{{ text.time_tv[language] }}</span>
                        <span class="tech-proc__card_label">: </span>
                        <span class="tech-proc__card_value com">{{ activeItem.tv }}</span>
                        <span class="tech-proc__card_label"> {{ text.min[language] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="text['3d_model'][language]">
          <div class="tech-proc__tab_3d">
            <div class="tech-proc__model_container">
              <ModelViewer v-if="model3d" :src="model3d" type="gltf" :background-color="0xfafafa" />
              <div class="tech-proc__model_right" v-if="activeItem && activeItem.tools">
                <div v-for="(item, index) in activeItem.tools" v-bind:key="`tool_type_${index}`">
                  <div class="block block__right">
                    <div style="width: 100%">
                      <div class="block block__between">
                        <div class="tech-proc__model_card_title">
                          {{ item.name }}
                        </div>
                        <div
                          class="block block__column block__align-center block__right"
                          style="margin-left: 15px; max-width: 15px; padding-left: 5px"
                        >
                          <div
                            :class="
                              index === 0
                                ? 'tech-proc__model_card_empty'
                                : 'tech-proc__model_card_line'
                            "
                          ></div>
                        </div>
                      </div>
                      <div v-for="(el, i) in item.data" v-bind:key="`tool_${i}`">
                        <div class="block">
                          <div class="tech-proc__model_card">
                            <div class="component__margin-bottom">
                              <div class="block">
                                <img
                                  :src="el.logo"
                                  v-if="el.logo"
                                  class="tech-proc__model_card_logo"
                                />
                                <div>
                                  <div class="tech-proc__model_card_label">
                                    {{ text.manufacturer_tool[language] }}
                                  </div>
                                  <div class="tech-proc__model_card_value">
                                    {{ el.manufacturer }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="block">
                              <div class="tech-proc__model_card_icon" v-if="!el.img">
                                <AddTool v-if="item.type === 'fixture'" />
                                <MachineTool v-else-if="item.type === 'machine_tool'" />
                                <Tool v-else />
                              </div>
                              <img
                                :src="el.img"
                                v-if="el.img"
                                class="tech-proc__model_card_icon component__none-select"
                              />
                              <div>
                                <div class="component__margin-bottom">
                                  <div class="tech-proc__model_card_label">
                                    {{ text.sn[language] }}
                                  </div>
                                  <div class="tech-proc__model_card_value component__select">
                                    {{ el.sn }}
                                  </div>
                                </div>
                                <div class="component__margin-bottom">
                                  <div
                                    class="tech-proc__model_card_label"
                                    v-if="item.type === 'machine_tool'"
                                  >
                                    {{ text.card_machine_model[language] }}
                                  </div>
                                  <div
                                    class="tech-proc__model_card_label"
                                    v-if="item.type !== 'machine_tool'"
                                  >
                                    {{ text.card_model[language] }}
                                  </div>
                                  <div class="tech-proc__model_card_value component__select">
                                    {{ el.model }}
                                  </div>
                                </div>
                                <div class="component__margin-bottom">
                                  <div
                                    class="tech-proc__model_card_label"
                                    v-if="item.type === 'machine_tool'"
                                  >
                                    {{ text.type_mt[language] }}
                                  </div>
                                  <div
                                    class="tech-proc__model_card_label"
                                    v-if="item.type !== 'machine_tool'"
                                  >
                                    {{ text.type[language] }}
                                  </div>
                                  <div class="tech-proc__model_card_value component__select">
                                    {{ el.type }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="block block__column block__align-center block__right"
                            style="margin-left: 15px; max-width: 15px"
                          >
                            <div
                              :class="
                                index === 0 && i === 0
                                  ? 'tech-proc__model_card_empty'
                                  : 'tech-proc__model_card_line'
                              "
                            ></div>
                            <div class="tech-proc__model_card_circle"></div>
                            <div
                              :class="
                                index === activeItem.tools.length - 1 && i === item.data.length - 1
                                  ? 'tech-proc__model_card_empty'
                                  : 'tech-proc__model_card_line'
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="text.tooling_list[language]">
          <div class="tech-proc__tab">
            <ToolingList :uuid="$route.params.id" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" style="position: relative" :tab="text.documentation_set[language]">
          <div class="tech-proc__tab">
            <Pdf v-for="i in pageCount" :key="i" :src="pdfSrc" :page="i"></Pdf>
            <div class="tech-proc__tab_buttons">
              <Dropdown>
                <template slot="body">
                  <Button :text="text.download[language]" type="attention" />
                </template>
                <template slot="dropdown" v-if="exportList">
                  <div v-for="(exportItem, i) in exportList" v-bind:key="`exportItem_${i}`">
                    <a
                      @mousedown="
                        (e) => {
                          e.preventDefault();
                        }
                      "
                      style="color: black"
                      :href="exportItem.link"
                      download
                    >
                      <div class="tech-proc__tab_buttons-text" style="padding: 5px">
                        <div>{{ exportItem.name }}</div>
                        <b class="component__margin-left">{{ exportItem.format }}</b>
                      </div>
                    </a>
                  </div>
                </template>
              </Dropdown>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/technological_processes.json";
import AddTool from "@/assets/images/tech-proc/additional_tool.svg";
import Tool from "@/assets/images/tech-proc/tool.svg";
import MachineTool from "@/assets/images/tech-proc/machine_tool.svg";
import TechTree from "@/components/techTree/TechTreeV3.vue";
import Button from "@/components/button/Button.vue";
import { query } from "@/utilities/axios";
import Pdf from "vue-pdf";
import ModelViewer from "@/components/modelViewer/ModelViewer.vue";
import Dropdown from "@/components/dropdownV3/DropdownV3.vue";
import moment from "moment";
import Tp from "./components/params/tp/Tp.vue";
import Blank from "./components/params/blank/Blank.vue";
import Operation from "./components/params/operation/Operation.vue";
import Fixture from "./components/params/fixture/Fixture.vue";
import Transition from "./components/params/transition/Transition.vue";
import ToolingList from "./components/toolingList/ToolingList.vue";

export default {
  name: "TechProcesses",
  data() {
    return {
      text,
      moment,
      pdfSrc: null,
      pageCount: null,
      currentPage: 0,
      activeItem: null,
      activeIndex: null,
      controlData: null,
      flatData: null,
      values: null,
      models: null,
      lines: null,
      mp: null,
      model3d: null,
      modelFaces: null,
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      unsubscribe: () => {},
      toolList: [],
      activeTab: null,
      edit: false,
      showAddModal: false,
      leftScroll: 0,
      exportList: null,
      updateTreeValues: () => {},
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    tp() {
      return this.$store.state.techProc.data;
    },
    part() {
      return this.$store.state.techProc.part;
    },
    access() {
      return this.$store.state.user.access;
    },
  },
  methods: {
    getDocumentations() {
      this.pdfSrc = Pdf.createLoadingTask(
        `https://api.i5.solutions/getManufProcessCards?t=${localStorage.getItem("token")}&uuid=${
          this.$route.params.id
        }`,
      );
      this.pdfSrc.promise.then((pdf) => {
        this.pageCount = pdf.numPages;
      });
    },
    setNumPages(num) {
      this.pageCount = num;
    },
    closePage() {
      this.$router.back();
    },
    setCurPage(num) {
      this.currentPage = num;
    },
    setItem(data) {
      this.activeIndex = data.index;
      if (data.index !== "tp") {
        this.getMpLine(data.data);
      } else {
        this.activeItem = data.data;
      }
    },
    getMpLine(data) {
      query(
        "post",
        "getMPline",
        {
          uuid_mp_line: data.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      )
        .then((result) => {
          this.activeItem = { ...data, ...result.line.data };
          if (result.tools) {
            this.activeItem.tools = result.tools;
          }
          if (result.blank) {
            this.activeItem.blank = result.blank;
          }
          this.model3d = result.model3d.fileEnd;
          const tempList = [];
          if (this.activeItem.type === 2) {
            tempList.push({
              type: "machine_tool",
              name: this.activeItem.machine_tool,
            });
          } else if (this.activeItem.type === 3) {
            tempList.push({
              type: "additional_tool",
              name: this.activeItem.adjustment,
            });
          } else if (this.activeItem.type === 4) {
            if (this.activeItem.additional_tool && this.activeItem.additional_tool !== " - ") {
              tempList.push({
                type: "additional_tool",
                name: this.activeItem.additional_tool,
              });
            }
            if (this.activeItem.tool && this.activeItem.tool !== " - ") {
              tempList.push({
                type: "tool",
                name: this.activeItem.tool,
              });
            }
          }
          this.toolList = tempList;
          if (result.model3d.faces && result.model3d.faces.length > 0) {
            const faces = [];
            result.model3d.faces.forEach((el) => {
              faces.push(`Face${el}`);
            });
            this.modelFaces = faces;
            const groups = [
              {
                surfaces: this.modelFaces,
                color: this.clickColor,
              },
            ];
            this.$store.state.modelV2.decorateSurfaces(groups);
          } else {
            this.$store.state.modelV2.clearSurfaces();
          }
        })
        .catch(() => {
          this.activeItem = data;
        });
    },
    setFlatData(data) {
      this.controlData = data.controlData;
      this.flatData = data.data;
    },
    getTp() {
      query(
        "post",
        "getTechProcessData",
        {
          uuid_mp: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((tp) => {
        this.values = tp.data;
      });
    },
    getMpLines() {
      return new Promise((resolve, reject) => {
        query(
          "post",
          "getMP",
          {
            uuid_mp: this.$route.params.id,
          },
          {
            token: localStorage.getItem("token"),
          },
        )
          .then((result) => {
            this.lines = result.lines;
            this.mp = result.mp;
            resolve(result);
          })
          .catch(() => {
            reject();
          });
      });
    },
    updateMpLines() {
      query(
        "post",
        "getMP",
        {
          uuid_mp: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.updateTreeValues(result.lines);
        this.mp = result.mp;
      });
    },
    getModels() {
      query(
        "post",
        "getTechProcessLineData",
        {
          uuid_techprocess_line: "123",
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.models = result.model3d;
      });
    },
    treeHandleClick() {
      this.activeItem = null;
    },
    setActiveTab(val) {
      this.activeTab = val;
    },
    setEdit() {
      this.edit = !this.edit;
      if (!this.edit) {
        this.getMpLine(this.activeItem);
      }
    },
    setShowAddModal(val) {
      this.showAddModal = val;
    },
    updateTp() {
      this.updateMpLines();
      // this.getMpLine(activeItem);
      this.getDocumentations();
      // this.getTp();
    },
    setLeftScroll(e) {
      this.leftScroll = e.target.scrollTop;
    },
    updateTree(uuid) {
      if (!uuid) {
        this.getMpLines();
      } else {
        this.getMpLines().then(() => {
          const index = this.lines.findIndex((el) => el.uuid === uuid);
          this.getMpLine(this.lines[index]);
          this.activeIndex = index;
        });
      }
      this.getDocumentations();
    },
    getMPExportList() {
      query(
        "post",
        "getMPexportList",
        {
          uuid_mp: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.exportList = res;
      });
    },
    setUpdateTreeValues(func) {
      this.updateTreeValues = func;
    },
  },
  mounted() {
    this.getMpLines();
    // this.getTp();
    this.getDocumentations();
    this.getMPExportList();
    // this.getModels();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "modelV2/setModelReady") {
        if (this.activeItem && this.activeItem !== "tp") {
          query(
            "post",
            "getMPline",
            {
              uuid_mp_line: this.activeItem.uuid,
            },
            {
              token: localStorage.getItem("token"),
            },
          ).then((result) => {
            this.model3d = result.model3d.fileEnd;
            const faces = [];
            if (result.model3d.faces && result.model3d.faces.length > 0) {
              result.model3d.faces.forEach((el) => {
                faces.push(`Face${el}`);
              });
            }
            this.modelFaces = faces;
            const groups = [
              {
                surfaces: this.modelFaces,
                color: this.clickColor,
              },
            ];
            this.$store.state.modelV2.decorateSurfaces(groups);
          });
        } else if (this.activeItem && this.activeItem === "tp") {
          this.activeItem = this.mp;
        }
      }
    });
  },
  components: {
    ModelViewer,
    TechTree,
    Pdf,
    Button,
    AddTool,
    Tool,
    MachineTool,
    Tp,
    Blank,
    Operation,
    Fixture,
    Transition,
    Dropdown,
    ToolingList,
  },
};
</script>
