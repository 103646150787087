const state = () => ({
  name: null,
});

const mutations = {
  setName(state, name) {
    state.name = name;
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
