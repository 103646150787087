<template>
  <div>
    <form @submit="handleSubmit">
      <div class="block">
        <div class="component__100 component__margin-right">
          <Input :label="text.code[language]" name="code" />
          <Input :label="text.name[language]" name="name" />
        </div>
      </div>
      <Footer
        :ok-text="footerText.ok[language]"
        :cancel="cancel"
        :cancel-text="footerText.cancel[language]"
      />
    </form>
  </div>
</template>

<script>
import text from "@/assets/text/parts.json";
import Input from "@/components/input/Input.vue";
import Footer from "@/components/modalFooter/ModalFooter.vue";
import footerText from "@/assets/text/modalFooter.json";
import { query } from "@/utilities/axios";

export default {
  name: "AddPart",
  data() {
    return {
      text,
      footerText,
      columns: [
        {
          name: "code",
        },
        {
          name: "name",
        },
      ],
    };
  },
  props: {},
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.$parent.$emit("cancel");
    },
    handleSubmit(evt) {
      evt.preventDefault();
      const formData = new FormData(evt.target);
      const result = {};
      this.columns.forEach((col) => {
        result[col.name] = formData.get(col.name);
      });
      this.addPart(result);
    },
    addPart(formData) {
      query(
        "post",
        "addNewPartV2",
        {
          ...formData,
          ...{
            files: [],
          },
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("cancel");
      });
    },
  },
  components: {
    Input,
    Footer,
  },
  created() {},
};
</script>
