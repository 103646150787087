<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <a :href="`https://market.i5.solutions/material/${row.uuid}`" target="_blank">
        <Button block :text="text.view[language]" class="page__edit_margin" />
      </a>
      <div class="page__edit_margin" v-if="row.name">
        <div class="page__edit_title">{{ text.name[language] }}:</div>
        <div class="page__edit_value">
          {{ row.name }}
        </div>
      </div>
      <div class="page__edit_margin" v-if="row.standard">
        <div class="page__edit_title">{{ text.standard[language] }}:</div>
        <div class="page__edit_value">
          {{ row.standard }}
        </div>
      </div>
      <div class="page__edit_margin" v-if="row.sub_group">
        <div class="page__edit_title">{{ text.sub_group[language] }}:</div>
        <div class="page__edit_value">
          {{ row.sub_group }}
        </div>
      </div>
      <div class="page__edit_margin" v-if="row.group">
        <div class="page__edit_title">{{ text.group[language] }}:</div>
        <div class="page__edit_value">
          {{ row.group }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/all_materials.json";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  components: { Button },
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
