<template>
  <div>
    <div v-if="edit">
      <div class="block block__align-center">
        <Input
          style="width: 100%"
          :default-value="values.user ? values.user : ''"
          :label="text.create_cd[language]"
        />
        <Input
          style="width: 100%"
          class="component__margin-left"
          :default-value="moment(values.time_stamp).format('DD.MM.YYYY hh:mm:ss')"
          :label="text.date[language]"
        />
      </div>
      <div class="block block__align-center">
        <Input style="width: 100%" :label="text.stamp[language]" />
        <Input style="width: 100%" class="component__margin-left" :label="text.gost[language]" />
      </div>
      <div class="block block__align-center">
        <Input style="width: 100%" :label="text.name[language]" />
        <Input style="width: 100%" class="component__margin-left" :label="text.mass[language]" />
      </div>
      <div class="block block__align-center">
        <Input style="width: 100%" :label="text.norm[language]" />
        <Input style="width: 100%" class="component__margin-left" :label="text.company[language]" />
      </div>
      <Textarea style="width: 100%" :label="text.message[language]" />
    </div>
    <div v-else class="tech-proc__card" style="width: 100%">
      <div v-if="values.user" class="component__margin-bottom">
        <div class="tech-proc__card_param-title">
          {{ text.create_cd[language] }}
        </div>
        <div class="tech-proc__card_label">
          {{ values.user }}
        </div>
      </div>
      <div v-if="values.time_stamp" class="component__margin-bottom">
        <div class="tech-proc__card_param-title">
          {{ text.date[language] }}
        </div>
        <div class="tech-proc__card_label">
          {{ moment(values.time_stamp).format("DD.MM.YYYY hh:mm:ss") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/technological_processes.json";
import moment from "moment";
import Input from "@/components/input/Input.vue";
import Textarea from "@/components/textarea/Textarea.vue";

export default {
  name: "tp",
  data() {
    return {
      text,
      moment,
    };
  },
  props: {
    edit: {
      type: Boolean,
    },
    values: {},
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    Input,
    Textarea,
  },
};
</script>
