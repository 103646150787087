<template>
  <div class="login__wrapper">
    <img src="@/assets/images/login/IMG_9765.jpeg" class="login__image" />
    <div class="login__container">
      <div class="login__auth">
        <Auth />
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/auth/Auth.vue";

export default {
  name: "Login",
  components: {
    Auth,
  },
  methods: {
    pushToHome() {
      this.$router.push("/");
    },
  },
  created() {
    this.$store.commit("user/setAfterAuthorize", this.pushToHome);
  },
};
</script>
