<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <div v-if="modalType === 'sign-in'">
        <div v-if="error424" class="auth__error424">
          {{ auth.error_424[language] }}
        </div>
        <div v-if="error403" class="auth__error424">
          {{ auth["403_error"][language] }}
        </div>
        <a-form-item :label="auth.email_label[language]">
          <a-input
            @change="clearL"
            v-decorator="[
              'l',
              {
                rules: [
                  { required: true, message: auth.empty_error[language] },
                  { validator: check404 },
                ],
              },
            ]"
            placeholder="example@domain.com"
          />
        </a-form-item>
        <a-form-item :label="auth.pass_label[language]">
          <a-input
            @change="clearP"
            v-decorator="[
              'p',
              {
                rules: [
                  { required: true, message: auth.empty_error[language] },
                  { validator: check404 },
                ],
              },
            ]"
            type="password"
            :placeholder="auth.pass_label[language]"
          />
        </a-form-item>
        <div class="auth__margin">
          <I5Button
            type="primary"
            :text="auth.sign_in[language]"
            fontSize="15px"
            html-type="submit"
            minHeight="32px"
          />
        </div>
        <div class="auth__margin block block__center">
          <span class="auth__text">{{ auth.have_account[language] }}</span>
          <span
            class="auth__sign-up header__modal_pointer component__margin-left"
            @click="setModalType('sign-up')"
          >
            {{ auth.sign_up[language] }}
          </span>
        </div>
        <div
          class="block block__center header__modal_pointer header__modal_sign-up"
          @click="setModalType('recovery')"
        >
          {{ auth.forgot_password[language] }}
        </div>
      </div>
      <div v-if="modalType === 'sign-up'">
        <a-form-item :label="auth.email_label[language]" has-feedback>
          <a-input
            @change="clearEmail"
            v-decorator="[
              'email',
              {
                rules: [
                  { required: true, message: auth.empty_error[language] },
                  { type: 'email', message: auth.error_email_format[language] },
                  { validator: check409 },
                ],
              },
            ]"
            placeholder="example@domain.com"
          />
        </a-form-item>
        <a-form-item :label="auth.pass_label[language]" has-feedback>
          <a-input
            @blur="handleConfirmBlur"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: auth.empty_error[language] },
                  { validator: validateToNextPassword },
                  { min: 6, message: auth.error_pass_min[language] },
                  { max: 200, message: auth.error_pass_max[language] },
                  {
                    pattern: RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9])/),
                    message: auth.error_pass_contain[language],
                  },
                ],
              },
            ]"
            type="password"
            :placeholder="auth.pass_label[language]"
          />
        </a-form-item>
        <a-form-item :label="auth.pass_confirm[language]" has-feedback>
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  { required: true, message: auth.empty_error[language] },
                  { validator: compareToFirstPassword },
                ],
              },
            ]"
            type="password"
            :placeholder="auth.pass_confirm[language]"
          />
        </a-form-item>
        <div class="auth__margin">
          <I5Button
            type="primary"
            :text="auth.sign_up_button[language]"
            fontSize="15px"
            html-type="submit"
            minHeight="32px"
          />
        </div>
        <div class="auth__margin block block__center">
          <span class="auth__text">{{ auth.already_have_account[language] }}</span>
          <span
            class="auth__sign-up header__modal_pointer component__margin-left"
            @click="setModalType('sign-in')"
          >
            {{ auth.sign_in[language] }}
          </span>
        </div>
      </div>
      <div v-if="modalType === 'success'">
        <div class="auth__success block block__center auth__margin">
          {{ auth.modal_success_text[language] }}
        </div>
        <div class="block block__center auth__margin">
          {{ auth.success_recovery[language] }}
        </div>
        <div class="auth__margin">
          <I5Button
            type="primary"
            :text="auth.sign_in[language]"
            fontSize="15px"
            @click="setModalType('sign-in')"
            minHeight="32px"
          />
        </div>
      </div>
      <div v-if="modalType === 'recovery'">
        <a-form-item :label="auth.email_label[language]">
          <a-input
            @change="clearRecovery"
            v-decorator="[
              'email_recovery',
              {
                rules: [
                  { required: true, message: auth.empty_error[language] },
                  { validator: check404Recovery },
                ],
              },
            ]"
            placeholder="example@domain.com"
          />
        </a-form-item>
        <div class="auth__margin">
          <I5Button
            type="primary"
            :text="auth.modal_get_recovery[language]"
            fontSize="15px"
            html-type="submit"
            minHeight="32px"
          />
        </div>
        <div class="auth__margin block block__center">
          <span
            class="auth__sign-up header__modal_pointer component__margin-left"
            @click="setModalType('sign-in')"
          >
            {{ auth.sign_in[language] }}
          </span>
        </div>
      </div>
      <div v-if="modalType === 'recovery-success'">
        <div class="auth__success block block__center auth__margin">
          {{ auth.success_recovery[language] }}
        </div>
        <div class="auth__margin">
          <I5Button
            type="primary"
            :text="auth.sign_in[language]"
            fontSize="15px"
            @click="setModalType('sign-in')"
            minHeight="32px"
          />
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import { sha256 } from "js-sha256";
import { query } from "@/utilities/axios";
import auth from "@/assets/text/auth.json";

const I5Button = () => import("@/components/i5Button/I5Button.vue");

export default {
  name: "Auth",
  data() {
    return {
      auth,
      form: this.$form.createForm(this, { name: "auth" }),
      error404: false,
      error404Recovery: false,
      error401: false,
      error403: false,
      error409: false,
      error424: false,
      confirmDirty: false,
      modalType: "sign-in",
    };
  },
  methods: {
    setModalOk() {
      if (this.modalType === "sign-in") {
        return this.authorization;
      } else if (this.modalType === "sign-up") {
        return this.reg;
      } else if (this.modalType === "recovery") {
        return this.recovery;
      } else {
        return () => {};
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.modalType === "sign-in") {
            this.authorization(values, this.form);
          } else if (this.modalType === "sign-up") {
            this.reg(values, this.form);
          } else if (this.modalType === "recovery") {
            this.recovery(values, this.form);
          }
        }
      });
    },
    setModalForm(form) {
      this.form = form;
    },
    recovery(values, form) {
      return new Promise((resolve, reject) => {
        query(
          "post",
          "passwordRecoveryByMail",
          {
            mail: values.email_recovery.toLowerCase(),
          },
          {},
        )
          .then(() => {
            this.modalType = "recovery-success";
            resolve({
              notClose: true,
            });
          })
          .catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 404) {
                this.error404Recovery = true;
                form.validateFields(["email_recovery"], { force: true });
              }
            }
            reject();
          });
      });
    },
    reg(values, form) {
      return new Promise((resolve, reject) => {
        query(
          "put",
          "addUser",
          {
            email: values.email.toLowerCase(),
            pass: sha256(values.password),
          },
          {},
        )
          .then(() => {
            this.modalType = "success";
            resolve({
              notClose: true,
            });
          })
          .catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 409) {
                this.error409 = true;
                form.validateFields(["email"], { force: true });
              }
            }
            reject();
          });
      });
    },
    authorization(values, form) {
      return new Promise((resolve, reject) => {
        query(
          "post",
          "auth",
          {
            l: values.l.toLowerCase(),
            p: sha256(values.p),
            location: "app",
          },
          {},
        )
          .then((item) => {
            localStorage.setItem("token", item.token);
            this.$emit("ok", item.token);
            this.$store.dispatch("user/getUser").then(() => {
              if (this.$store.state.user.afterAuthorize) {
                this.$store.state.user.afterAuthorize();
              }
            });
            resolve({
              notClose: false,
            });
          })
          .catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 401) {
                this.error401 = true;
                form.validateFields(["p", "l"], { force: true }).then(() => {
                  this.error401 = false;
                });
              } else if (status === 404) {
                this.error404 = true;
                form.validateFields(["p", "l"], { force: true }).then(() => {
                  this.error404 = false;
                });
              } else if (status === 403) {
                this.error403 = true;
                setTimeout(() => {
                  this.error403 = false;
                }, 30000);
              } else if (status === 424) {
                this.error424 = true;
                setTimeout(() => {
                  this.error424 = false;
                }, 30000);
              }
            }
            reject();
          });
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    check403(rule, value, callback) {
      if (!this.error403) {
        callback();
        return;
      }
      callback(this.auth["403_error"][this.language]);
    },
    check409(rule, value, callback) {
      if (!this.error409) {
        callback();
        return;
      }
      callback(this.auth["409_error"][this.language]);
    },
    checkPass(rule, value, callback) {
      if (!this.error401) {
        callback();
        return;
      }
      callback(this.auth.pass_error[this.language]);
    },
    check404(rule, value, callback) {
      if (!this.error404) {
        callback();
        return;
      }
      callback(this.auth["404_error"][this.language]);
    },
    check404Recovery(rule, value, callback) {
      if (!this.error404Recovery) {
        callback();
        return;
      }
      callback(this.auth["404_error"][this.language]);
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    setModalTitle() {
      if (this.modalType === "sign-in") {
        return this.auth.sign_in;
      } else if (this.modalType === "sign-up") {
        return this.auth.sign_up_title;
      } else if (this.modalType === "success") {
        return this.auth.modal_success;
      } else if (this.modalType === "recovery") {
        return this.auth.modal_recovery_title;
      } else if (this.modalType === "recovery-success") {
        return this.auth.modal_recovery_title;
      } else {
        return {
          ru: "",
          en: "",
        };
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.auth.error_pass_match[this.language]);
      } else {
        callback();
      }
    },
    setModalType(type) {
      this.modalType = type;
    },
    clearL() {
      if (this.error404) {
        this.error404 = false;
        this.form.validateFields(["p", "l"], { force: true });
      }
      if (this.error424) {
        this.error424 = false;
      }
      if (this.error403) {
        this.error403 = false;
      }
    },
    clearP() {
      if (this.error401) {
        this.error401 = false;
        this.form.validateFields(["p", "l"], { force: true });
      }
    },
    clearEmail() {
      this.error409 = false;
      this.form.validateFields(["email"], { force: true });
    },
    clearRecovery() {
      this.error404Recovery = false;
      this.form.validateFields(["email_recovery"], { force: true });
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  mounted() {},
  beforeDestroy() {},
  components: {
    I5Button,
  },
};
</script>
