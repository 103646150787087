<template>
  <div>
    <EditModal
      :show="showEditModal"
      :info-data="editData"
      :parameters="editData.params"
      @close="closeEditModal()"
    />
    <Modal
      name="addMachineTool"
      @cancel="closeDeleteModal"
      @ok="deleteMachineTool"
      :okText="text.delete"
      :show="showDeleteModal"
      :title="text.delete_title[language]"
    >
      <template slot="body">
        <div class="component__margin">
          <span style="font-size: 18px"
            >{{ text.delete_text[language] }}
            <b>{{ row.serial_number }} {{ row.machine_tool_name }}</b
            >?
          </span>
        </div>
      </template>
    </Modal>
    <Modal
      name="addMachineTool"
      @cancel="setShowMaterialModal(false)"
      :okText="text.save"
      :show="showMaterialModal"
      :hide-footer="true"
      :title="text.set_machine_materials_title[language]"
    >
      <template slot="body">
        <div class="component__margin">
          <a-form
            :form="form"
            @submit="saveMaterialForMt"
            class="block block__column block__between"
          >
            <div style="height: 100%; overflow-y: auto">
              <a-form-item label="Материал">
                <a-tree-select
                  v-if="materialsData && mtData"
                  v-decorator="[
                    'materials',
                    {
                      initialValue: mtData,
                      rules: [
                        {
                          required: true,
                          message: 'Поле обязательное для заполнения',
                        },
                      ],
                    },
                  ]"
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  :style="{ width: '100%' }"
                  show-search
                  treeNodeFilterProp="title"
                  :tree-data="materialsData"
                />
              </a-form-item>
            </div>
            <div style="padding: 10px" class="block block__right">
              <Button
                type="default"
                :text="text.cancel[language]"
                @click="setShowMaterialModal(false)"
              />
              <Button
                type="primary"
                html-type="submit"
                class="component__margin-left"
                :text="text.save[language]"
              />
            </div>
          </a-form>
        </div>
      </template>
    </Modal>
    <ViewTableParameters :parameters="params" :width="width" @buttonClick="onHandleButtonClick" />
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";
import Modal from "@/components/modal/Modal.vue";
import Select from "@/components/select/Select.vue";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/machine_tools.json";
import { query } from "@/utilities/axios";
import { TreeSelect } from "ant-design-vue";
import ViewTableParameters from "@/components/viewTableParameters/ViewTableParameters.vue";
import EditModal from "../modals/EditModal.vue";

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  name: "MachineEdit",
  data() {
    return {
      text,
      SHOW_PARENT,
      form: this.$form.createForm(this, { name: "add_material" }),
      timer: null,
      showAddModal: false,
      showStandardModal: false,
      showEditModal: false,
      showDeleteModal: false,
      serialNumber: null,
      laborHourPrice: null,
      idShop: null,
      idAccClass: null,
      shops: [],
      accuracyClasses: [],
      showMaterialModal: false,
      mtData: null,
      materialsData: null,
      params: [],
      editData: null,
    };
  },
  props: {
    row: Object,
    width: {
      type: Number,
    },
  },
  watch: {
    row: {
      immediate: true,
      handler: function (val) {
        if (val && val.uuid) {
          this.getMTInfo(val.uuid);
        }
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    onHandleButtonClick(e) {
      console.log(e.button_type);
      switch (e.button_type) {
        case "edit_machine_tool_expert":
          this.openEditModal();
          break;
        case "delete_machine_tool_expert":
          this.openDeleteModal();
          break;
        case "edit_materials_machine_tool_expert":
          this.setShowMaterialModal(true);
          break;
        case "link":
          window.open(e.link, "_blank");
          break;
        default:
          console.log("default");
          break;
      }
    },
    openCalcModal() {
      this.showStandardModal = true;
    },
    openEditModal() {
      this.showEditModal = true;
      console.log(this.showEditModal);
    },
    closeEditModal() {
      this.showEditModal = false;
      this.$emit("update");
    },
    closeCalcModal() {
      this.showStandardModal = false;
      this.$emit("update");
    },
    openDeleteModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.mtData = null;
      this.form = this.$form.createForm(this, { name: "add_material" });
      this.form.resetFields();
      this.$emit("update");
    },
    changeStatus() {
      query(
        "post",
        "useMachineToolForOrders",
        {
          get_new_orders: !this.row.get_new_orders,
          id: this.row.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
    editMachineTool() {
      const data = this.$store.state.machineTools.hourPriceData;
      query("post", "changeCompanyMachineToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        // this.serialNumber = null;
        this.$store.commit("machineTools/clearHourPriceData");
        this.$emit("update");
        // this.closeEditModal();
      });
    },
    editMachineToolFromModal() {
      const data = {
        id: this.row.id,
      };
      if (this.serialNumber) {
        data.serial_number = this.serialNumber;
      }
      if (this.idShop) {
        data.id_shop = this.idShop;
      }
      if (this.laborHourPrice) {
        data.labor_hour_price = this.laborHourPrice;
      }
      if (this.idAccClass) {
        data.id_current_accuracy_class = this.idAccClass;
      }
      query("post", "changeCompanyMachineToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.serialNumber = null;
        this.$emit("update");
        this.closeEditModal();
      });
    },
    getShops() {
      query(
        "get",
        "getCurrentCompaniesShops",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((shops) => {
        this.shops = shops;
      });
    },
    saveEdit(value, name) {
      if (name === "serial_number") {
        this.serialNumber = value;
      } else if (name === "id_shop") {
        this.idShop = value;
      } else if (name === "id_current_accuracy_class") {
        this.idAccClass = value;
      }
    },
    setLaborHour(val) {
      this.laborHourPrice = val;
      this.resetTimer();
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.editMachineTool();
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer() {
      const data = {
        id: this.row.id,
      };
      if (this.serialNumber) {
        data.serial_number = this.serialNumber;
      }
      if (this.idShop) {
        data.id_shop = this.idShop;
      }
      if (this.laborHourPrice) {
        data.labor_hour_price = this.laborHourPrice;
      }
      this.$store.commit("machineTools/setHourPriceData", data);
      this.stopTimer();
      this.startTimer();
    },
    deleteMachineTool() {
      query(
        "delete",
        "deleteMachineToolFromCompany",
        {},
        {
          token: localStorage.getItem("token"),
          id: this.row.id,
        },
      ).then(() => {
        this.$emit("update");
        this.closeDeleteModal();
      });
    },
    getAccuracyClasses() {
      query(
        "get",
        "getAccuracyClasses",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.accuracyClasses = res;
      });
    },
    setShowMaterialModal(val) {
      this.showMaterialModal = val;
      if (val) {
        this.getToolData();
        this.getMaterialsList();
      }
    },
    getToolData() {
      query(
        "post",
        "getMaterialsForMachineTool",
        {
          id_machine_tool: this.row.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.mtData = res.materials;
      });
    },
    getMaterialsList() {
      query("get", "getMaterialTree", {}, {}).then((res) => {
        this.materialsData = res;
      });
    },
    saveMaterialForMt(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          query(
            "post",
            "addMaterialForMachineTool",
            {
              id_machine_tool: this.row.id,
              materials: values.materials,
            },
            {
              token: localStorage.getItem("token"),
            },
          ).then(() => {
            this.setShowMaterialModal(false);
            this.$emit("update");
          });
        }
      });
    },
    getMTInfo(uuid) {
      query(
        "post",
        "getMachineToolInfoExpert",
        { uuid },
        {
          token: localStorage.getItem("token"),
        },
      ).then((params) => {
        if (params && params.cards) {
          this.params = params.cards || [];
          this.editData = params.data_edit || null;
        }
      });
    },
  },
  created() {
    this.getAccuracyClasses();
  },
  components: {
    ViewTableParameters,
    Input,
    Button,
    Modal,
    Select,
    EditModal,
  },
};
</script>
