<template>
  <div class="err404__wrapper">
    <img src="@/assets/images/404/404.png" class="err404__img" />
  </div>
</template>

<script>
export default {
  name: "Page404",
};
</script>
