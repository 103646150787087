<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.file[language] }}</div>
    <div class="page__edit_title">{{ text.filename[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.filename }}
    </div>
    <div class="page__edit_title">{{ text.preview[language] }}:</div>
    <div v-if="row && row.preview">
      <img
        :src="row.preview"
        :style="{
          height: 'auto',
          width: '100%',
        }"
      />
    </div>
    <div class="page__edit_title">{{ text.size[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.size }}
    </div>
    <div class="page__edit_title">{{ text.ext[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.ext }}
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/files.json";

export default {
  name: "Edit",
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
