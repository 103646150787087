<template>
  <PageConstructor
    :query="query"
    show-filter
    :columns="columns"
    id-name="id"
    @setUpdate="setUpdate($event)"
    :page-name="text.page_name[language]"
  >
    <template slot="edit" slot-scope="{ row }">
      <Edit :row="row" @update="tableUpdate()" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/all_users.json";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "AllUsers",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getAllUsersAdmin",
        headers: {},
        data: {},
      },
      tableUpdate: null,
      columns: [
        {
          name: "id",
          label: text.id,
          options: {
            bold: true,
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "login",
          label: text.login,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "company_name",
          label: text.company_name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "position",
          label: text.role,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "manufacturing",
          label: text.manufacturing,
          type: "status",
          options: {
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "expert",
          label: text.expert,
          type: "status",
          options: {
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "admin",
          label: text.admin,
          type: "status",
          options: {
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "last_login",
          label: text.last_login,
          type: "date-time",
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setUpdate(func) {
      this.tableUpdate = func;
    },
  },
  components: {
    PageConstructor,
    Edit,
  },
};
</script>
