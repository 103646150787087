<template>
  <I5Modal :show="show" :title="text.edit_modal_name[language]" @close="onHandlerClose">
    <template slot="body">
      <div
        style="padding: 20px; overflow-y: scroll; height: calc(100% - 80px)"
        class="block block__column"
      >
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование на русском"
            name="name_rus"
            :default-value="editData.name_ru"
            @change="(e) => onHandleChange(e, 'name_ru')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Eng)"
            name="name_eng"
            :default-value="editData.name_en"
            @change="(e) => onHandleChange(e, 'name_en')"
            style="width: 100%"
          />
          <Input
            label="Наименование (De)"
            name="name_de"
            :default-value="editData.name_de"
            @change="(e) => onHandleChange(e, 'name_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Tr)"
            name="name_tr"
            :default-value="editData.name_tr"
            @change="(e) => onHandleChange(e, 'name_tr')"
            style="width: 100%"
          />
          <Input
            label="Наименование (Zh)"
            name="name_zh"
            :default-value="editData.name_zh"
            @change="(e) => onHandleChange(e, 'name_zh')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание на русском"
            name="description_ru"
            :default-value="editData.description_ru"
            @change="(e) => onHandleChange(e, 'description_ru')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание (Eng)"
            :default-value="editData.description_en"
            name="description_en"
            @change="(e) => onHandleChange(e, 'description_en')"
            style="width: 100%"
          />
          <Textarea
            label="Описание (De)"
            name="description_de"
            :default-value="editData.description_de"
            @change="(e) => onHandleChange(e, 'description_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание (Tr)"
            name="description_tr"
            :default-value="editData.description_tr"
            @change="(e) => onHandleChange(e, 'description_tr')"
            style="width: 100%"
          />
          <Textarea
            label="Описание (Zh)"
            name="description_zh"
            :default-value="editData.description_zh"
            @change="(e) => onHandleChange(e, 'description_zh')"
            style="width: 100%"
          />
        </div>
        <div style="margin-bottom: 20px">
          <h3>Параметры</h3>
          <Search
            @change="addParam($event)"
            :query="{
              method: 'post',
              url: 'getMachineToolTypeParameters',
              findName: 'find',
              label: 'name',
              value: 'id',
            }"
          />
          <div style="width: 100%; margin-bottom: 20px" v-if="params && params.length > 0">
            <div
              v-for="(el, index) in params"
              v-bind:key="`${index}_param`"
              class="block block__between block__align-center"
              style="
                margin-bottom: 10px;
                border: 2px solid #f1f1f1;
                border-radius: 8px;
                padding: 5px;
              "
            >
              <b>{{ el.name }}</b>
              <div class="tech-req__block_delete" @click="deleteParam(index)">
                <TrashIcon />
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px">
          <h3>Рабочие органы</h3>
          <Search
            @change="addWorkingBody($event)"
            :query="{
              method: 'post',
              url: 'getMachineToolTypeWB',
              findName: 'find',
              label: 'name',
              value: 'id',
            }"
          />
          <div style="width: 100%; margin-bottom: 20px" v-if="wb && wb.length > 0">
            <div
              v-for="(el, index) in wb"
              v-bind:key="`${index}_wb`"
              class="block block__between block__align-center"
              style="
                margin-bottom: 10px;
                border: 2px solid #f1f1f1;
                border-radius: 8px;
                padding: 5px;
              "
            >
              <b>{{ el.name }}</b>
              <div class="tech-req__block_delete" @click="deleteWorkingBody(index)">
                <TrashIcon />
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px">
          <h3>Технологии</h3>
          <Search
            @change="addProcessingMethod($event)"
            :query="{
              method: 'post',
              url: 'getMachineToolTypeProcessingMethods',
              findName: 'find',
              label: 'name',
              value: 'id',
            }"
          />
          <div
            style="width: 100%; margin-bottom: 20px"
            v-if="processing_methods && processing_methods.length > 0"
          >
            <div
              v-for="(el, index) in processing_methods"
              v-bind:key="`${index}_processing_methods`"
              class="block block__between block__align-center"
              style="
                margin-bottom: 10px;
                border: 2px solid #f1f1f1;
                border-radius: 8px;
                padding: 5px;
              "
            >
              <b>{{ el.name }}</b>
              <div class="tech-req__block_delete" @click="deleteProcessingMethod(index)">
                <TrashIcon />
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px">
          <h3>Схемы закрепления</h3>
          <Search
            @change="addFixtureSchema($event)"
            :query="{
              method: 'post',
              url: 'getMachineToolTypeFixtureSchemas',
              findName: 'find',
              label: 'name',
              value: 'id',
            }"
          />
          <div
            style="width: 100%; margin-bottom: 20px"
            v-if="fixture_schemas && fixture_schemas.length > 0"
          >
            <div
              v-for="(el, index) in fixture_schemas"
              v-bind:key="`${index}fixture_schemas`"
              class="block block__between block__align-center"
              style="
                margin-bottom: 10px;
                border: 2px solid #f1f1f1;
                border-radius: 8px;
                padding: 5px;
              "
            >
              <b>{{ el.name }}</b>
              <div class="tech-req__block_delete" @click="deleteFixtureSchema(index)">
                <TrashIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="height: 80px; gap: 12px; padding: 0 20px"
        class="block block__align-center block__right"
      >
        <Button type="default" @click="$emit('close')" text="Отмена" />
        <Button type="primary" text="Сохранить" @click="saveEdit" />
      </div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/tools_nomenclature.json";
import Input from "@/components/input/Input.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import TrashIcon from "@/assets/images/tech/trash.svg";
import Button from "@/components/button/Button.vue";
import Search from "@/components/search/Search.vue";
import Toggle from "@/components/toggle/Toggle.vue";
import { query } from "@/utilities/axios";

export default {
  name: "EditToolNomenclature",
  data() {
    return {
      text,
      editData: {
        name_ru: "",
        name_en: "",
        name_de: "",
        name_tr: "",
        name_zh: "",
        description_ru: "",
        description_en: "",
        description_de: "",
        description_tr: "",
        description_zh: "",
      },
      params: [],
      wb: [],
      processing_methods: [],
      fixture_schemas: [],
    };
  },
  components: {
    I5Modal,
    Input,
    TrashIcon,
    Button,
    Search,
    Textarea,
    Toggle,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    show: {
      type: Boolean,
    },
    parameters: {
      type: Array,
      default: () => [],
    },
    wb: {
      type: Array,
      default: () => [],
    },
    processing_methods: {
      type: Array,
      default: () => [],
    },
    fixture_schemas: {
      type: Array,
      default: () => [],
    },
    infoData: {
      type: Object,
    },
  },
  watch: {
    parameters: {
      immediate: true,
      handler: function (val) {
        this.params = val;
      },
    },
    wb: {
      immediate: true,
      handler: function (val) {
        this.wb = val;
      },
    },
    processing_methods: {
      immediate: true,
      handler: function (val) {
        this.processing_methods = val;
      },
    },
    fixture_schemas: {
      immediate: true,
      handler: function (val) {
        this.fixture_schemas = val;
      },
    },
    infoData: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.editData.name_ru = val.name_ru || "";
          this.editData.name_en = val.name_en || "";
          this.editData.name_de = val.name_de || "";
          this.editData.name_tr = val.name_tr || "";
          this.editData.name_zh = val.name_zh || "";
          this.editData.description_ru = val.description_ru || "";
          this.editData.description_en = val.description_en || "";
          this.editData.description_de = val.description_de || "";
          this.editData.description_tr = val.description_tr || "";
          this.editData.description_zh = val.description_zh || "";
        }
      },
    },
  },
  methods: {
    onHandlerClose() {
      this.$emit("close");
    },
    onHandleChange(e, name) {
      this.editData[name] = e;
    },
    deleteParam(index) {
      const params = [...this.params];
      params.splice(index, 1);
      this.params = params;
    },
    deleteWorkingBody(index) {
      const wb = [...this.wb];
      wb.splice(index, 1);
      this.wb = wb;
    },
    deleteFixtureSchema(index) {
      const fixture_schemas = [...this.fixture_schemas];
      fixture_schemas.splice(index, 1);
      this.fixture_schemas = fixture_schemas;
    },
    deleteProcessingMethod(index) {
      const processing_methods = [...this.processing_methods];
      processing_methods.splice(index, 1);
      this.processing_methods = processing_methods;
    },
    addParam(value) {
      this.params.unshift(value);
    },
    addWorkingBody(value) {
      this.wb.unshift(value);
    },
    addFixtureSchema(value) {
      this.fixture_schemas.unshift(value);
    },
    addProcessingMethod(value) {
      this.processing_methods.unshift(value);
    },
    saveEdit() {
      const editData = {
        uuid: this.infoData.uuid,
        ...this.editData,
        id_machine_tool_parameters: this.params.map((el) => el.id),
        id_machine_tool_working_bodies: this.wb.map((el) => el.id),
        id_processing_methods: this.processing_methods.map((el) => el.id),
        id_fixture_schemas: this.fixture_schemas.map((el) => el.id),
      };
      query("post", "updateMachineToolTypeInfo", editData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("update");
        this.$emit("close");
      });
    },
  },
};
</script>
