<template>
  <div>
    <span class="size-input__label">
      {{ label[language] }}
      <span class="size-input__sublabel">
        {{ sublabel[language] }}
      </span>
    </span>
    <div class="block">
      <Input
        :ti="tabIndexes[0]"
        :default-value="String(xSize)"
        :type="'number'"
        :text-position="'center'"
        name="x"
        @change="handleChange('x', $event)"
      />
      <div class="size-input_x">X</div>
      <Input
        :ti="tabIndexes[1]"
        :default-value="String(ySize)"
        :type="'number'"
        :text-position="'center'"
        name="y"
        @change="handleChange('y', $event)"
      />
      <div class="size-input_x">X</div>
      <Input
        :ti="tabIndexes[2]"
        :default-value="String(zSize)"
        name="z"
        :type="'number'"
        :text-position="'center'"
        @change="handleChange('z', $event)"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";

export default {
  name: "SizeInput",
  data() {
    return {
      x: "",
      y: "",
      z: "",
      label: {
        ru: "Габариты",
        en: "Size",
      },
      sublabel: {
        ru: "X Y Z, мм.",
        en: "X Y Z, mm.",
      },
    };
  },
  props: {
    xSize: {
      type: [Number, String],
    },
    ySize: {
      type: [Number, String],
    },
    zSize: {
      type: [Number, String],
    },
    tabIndexes: {
      type: Array,
      default: () => [0, 0, 0],
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleChange(name, value) {
      const result = {
        x: this.x,
        y: this.y,
        z: this.z,
      };
      this[name] = value;
      result[name] = value;
      this.$emit("change", result);
    },
  },
  components: {
    Input,
  },
};
</script>
