<template>
  <div>
    <Input label="Номер заказа" name="number" :disabled="true" :default-value="123123123" />
    <div>Вкладки деталей</div>
    <div class="block block__align-center">
      <div style="width: 100%">
        <Input label="Наименование детали *" name="name" />
      </div>
      <div style="width: 100%" class="component__margin-left">
        <Input label="Количество *" name="count" />
      </div>
      <div style="width: 100%" class="component__margin-left">
        <Input label="Цена за штуку *" name="size" />
      </div>
      <div class="component__margin-left">ИТОГО</div>
    </div>
    <h3>Дополнительные параметры</h3>
    <div class="block">
      <div style="width: 100%">
        <Input label="Материал" name="material" />
      </div>
      <div style="width: 100%" class="component__margin-left">
        <Input label="Габариты" name="size" />
      </div>
    </div>
    <div class="block">
      <div style="width: 100%">
        <Input label="ГОСТ на материал" name="material" />
      </div>
      <div style="width: 100%" class="component__margin-left">
        <Input label="Масса" name="mass" />
      </div>
    </div>
    <div class="block">
      <div>
        <a-checkbox />
      </div>
      <div class="component__margin-left">Материал заготовки предоставляется заказчиком</div>
    </div>
    <h3>Выбрать или загрузить документы</h3>
    <IconButton icon-name="plus" />
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";
import IconButton from "@/components/iconButton/IconButton.vue";

export default {
  name: "OrdersAdd",
  components: {
    Input,
    IconButton,
  },
};
</script>
