<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="id"
    :page-name="text.page_name[language]"
  >
    <template slot="edit" slot-scope="{ row }">
      <Edit :row="row" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/time_standards.json";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "TimeStandards",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getFixtureTimesV2",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "id",
          label: text.id,
          options: {
            bold: true,
            sort: true,
          },
          basis: 50,
          width: 50,
        },
        {
          name: "name",
          label: text.fixture,
          options: {
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "t_03",
          label: text.w0_3,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_1",
          label: text.w1,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_3",
          label: text.w3,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_5",
          label: text.w5,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_8",
          label: text.w8,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_12",
          label: text.w12,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_20",
          label: text.w20,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_30",
          label: text.w30,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_50",
          label: text.w50,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_100",
          label: text.w100,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
        {
          name: "t_200",
          label: text.w200,
          options: {
            sort: true,
          },
          basis: 80,
          width: 80,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
    Edit,
  },
};
</script>
