<template>
  <Modal @close="closeAddModal" :show="show" :title="text.add_tool_title[language]">
    <template slot="body">
      <div class="block block__column block__between" style="height: 100%; padding: 10px">
        <div style="height: 100%; overflow-y: auto">
          <div class="block">
            <Input
              :label="text.serial_number[language]"
              :error="snError"
              :default-value="sn"
              style="width: 50%"
              required
              @change="saveAdd($event, 'serial_number')"
            />
            <Input
              style="width: 100%"
              :label="text.tool_name[language]"
              :default-value="code"
              disabled
            />
          </div>
          <div class="block">
            <Input
              :label="text.minimal_amount[language]"
              :error="maError"
              type="number"
              :default-value="minimumAmount"
              style="width: 50%"
              required
              @change="saveAdd($event, 'minimal_amount')"
            />
            <Textarea
              @change="saveAdd($event, 'comment')"
              class="component__margin-left"
              :default-value="commentValue"
              style="width: 100%"
              :label="text.comment[language]"
            />
          </div>
        </div>
        <div style="padding: 10px" class="block block__right">
          <Button type="default" @click="closeAddModal" :text="text.cancel[language]" />
          <Button
            type="primary"
            :text="text.save[language]"
            @click="saveTool"
            style="margin-left: 10px"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import text from "@/assets/text/tools.json";
import { query } from "@/utilities/axios";

export default {
  name: "Add",
  data() {
    return {
      text,
      snError: null,
      maError: null,
      toolError: null,
      serialNumber: null,
      idTool: null,
      idShop: null,
      toolData: null,
      comment: null,
      minAmount: null,
      shops: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sn: {
      type: String,
    },
    code: {
      type: String,
    },
    commentValue: {
      type: String,
    },
    minimumAmount: {
      type: String,
    },
    uuid: {
      type: String,
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.getShops();
      }
    },
  },
  methods: {
    closeAddModal() {
      this.$emit("close");
      this.snError = null;
      this.maError = null;
      this.toolError = null;
      this.toolData = null;
      this.idTool = null;
      this.serialNumber = null;
      this.minAmount = null;
    },
    saveAdd(value, name) {
      if (name === "serial_number") {
        this.snError = null;
        this.serialNumber = value;
      } else if (name === "uuid_tool") {
        this.toolError = null;
        if (value.value !== -1) {
          this.toolData = value;
        }
        this.idTool = value.value;
      } else if (name === "minimal_amount") {
        this.maError = null;
        this.minAmount = value;
      } else if (name === "comment") {
        this.comment = value;
      }
    },
    saveTool() {
      const data = {
        uuid: this.uuid,
      };
      if (this.comment) {
        data.comment = this.comment;
      }
      if (this.minAmount) {
        data.minimum_amount = +this.minAmount;
      }
      if (this.serialNumber) {
        data.sn = this.serialNumber;
      }
      query("post", "changeCurrentCompaniesToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.closeAddModal();
      });
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    Modal,
    Input,
    Button,
    Textarea,
  },
};
</script>
