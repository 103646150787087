<template>
  <button class="small-button__wrapper" @click="handleClick">{{ text }}</button>
</template>

<script>
export default {
  name: "SmallButton",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>
