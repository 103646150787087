const state = () => ({
  tables: {},
});

const mutations = {
  setTable(state, data) {
    if (data.name && data.data) {
      state.tables[data.name] = data.data;
    }
  },
  delTable(state, data) {
    if (data.name && state.tables[data.name]) {
      state.tables[data.name] = null;
    }
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
