<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :hide-add-footer="true"
    show-filter
    :page-name="text.page_name[language]"
  >
    <template slot="edit" slot-scope="{ row, width }">
      <Edit :row="row" :width="width" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/wb_types.json";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "WBTypes",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getMachineToolWorkingBodiesTypes",
        headers: {},
        data: {},
      },
      queryFilter: {},
      columns: [
        {
          name: "id",
          label: text.id,
          options: {
            bold: true,
            sort: true,
          },
          basis: 100,
          width: 100,
        },
        {
          name: "img",
          label: {
            ru: "",
            en: "",
          },
          type: "image",
          options: {},
          basis: 80,
          width: 80,
        },
        {
          name: "name",
          label: text.name,
          options: {},
          basis: 0,
          width: 200,
        },
        {
          name: "amount_parameters",
          label: text.amount_parameters,
          options: {},
          basis: 0,
          width: 200,
        },
      ],
    };
  },
  methods: {},
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    Edit,
    PageConstructor,
  },
};
</script>
