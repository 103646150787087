<template>
  <div
    style="width: 100%"
    :class="`table__body_default ${options.bold ? 'table__body_bold' : ''} ${
      options.right ? 'table__body_right' : ''
    } ${options.center ? 'table__body_center' : ''} ${options.copy ? 'component__select' : ''}`"
  >
    <div v-if="type === 'money'" class="table__body_money mono-text">
      {{ item ? getMoney(item, user.currency || "RUB", language || "ru") : "" }}
    </div>
    <div v-if="type === 'number_with_spaces'" class="mono-text">
      {{ item ? getNumberWithSpaces(item) : " " }}
    </div>
    <div v-if="type === 'switch'">
      <a-switch :checked="item ? true : false" />
    </div>
    <div
      v-if="type === 'status'"
      style="height: 20px; padding-left: 6px"
      class="block block__align-center block__center"
    >
      <div :class="`table__status table__status_${item ? 'success' : 'danger'}`"></div>
    </div>
    <div
      v-if="type === 'status-reverse'"
      style="height: 20px; padding-left: 6px"
      class="block block__align-center block__center"
    >
      <div :class="`table__status table__status_${!item ? 'success' : 'danger'}`"></div>
    </div>
    <div v-if="type === 'size_mb'">
      <div class="mono-text">
        {{ item ? getMb(item) : " " }}
      </div>
    </div>
    <div v-if="type === 'image'" class="block__flex block__center">
      <img class="table__body_image" :src="item" @error="setError" v-if="item" />
      <div v-if="!item"></div>
    </div>
    <div v-if="type === 'approve'" class="block__flex block__center">
      <img class="table__body_image" src="@/assets/images/i5/approve.png" v-if="item" />
      <div v-if="!item"></div>
    </div>
    <div v-if="type === 'date'" class="mono-text">
      {{ item ? moment(item).format("DD.MM.YYYY") : " " }}
    </div>
    <div v-if="type === 'date-time'" class="mono-text">
      {{ item ? moment(item).format("DD.MM.YYYY HH:mm:ss") : " " }}
    </div>
    <div v-if="type === 'default'" class="mono-text">
      {{ item ? item : " " }}
    </div>
  </div>
</template>

<script>
import { getMoney, getNumberWithSpaces, getMb } from "@/utilities/functions/index";
import moment from "moment";
import Default from "@/assets/images/i5/default.png";

export default {
  name: "TableCell",
  data() {
    return {
      getMoney,
      getNumberWithSpaces,
      getMb,
      moment,
    };
  },
  props: {
    item: {
      type: [String, Number, Boolean],
      default: " ",
    },
    /*
     money - Ожидает число,в options название валюты currency RUB, EUR или USD, и регион locale
      ru или en, в результате получаем валюту в нужном виде
     numbers_with_spaces - Ожидает число, получаем число поделенное пробелами
     default - по умолчанию
     */
    type: {
      type: String,
      default: "default",
    },
    /*
     currency - RUB, USD, EUR нужен в money
     locale - ru, en нужен в money
     bold - Boolean, default - False делает жирный шрифт
     right - Boolean, default - False ячейка с текстом по правой грани
     */
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    setError(e) {
      e.target.src = Default;
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
