<template>
  <div style="margin-left: 8px; padding-bottom: 20px">
    <div
      class="page-header__name block block__align-center"
      style="height: 43px; margin-bottom: 20px"
    >
      {{ text.title[language] }}
    </div>
    <div style="margin-bottom: 15px">
      <div class="order__label component__margin-bottom">1. {{ text.title_1[language] }}</div>
      <div>{{ text.text_1[language] }}</div>
    </div>
    <div style="margin-bottom: 15px">
      <div class="order__label component__margin-bottom">2. {{ text.title_2[language] }}</div>
      <div class="block">
        <div style="height: 34px" class="block__flex block__align-center">
          {{ text.text_2[language] }}
        </div>
        <Input
          class="component__margin-left"
          :min="0"
          :step="1"
          :default-value="+overhead_cost"
          :error="overhead_cost_error"
          @change="saveData($event, 'overhead_cost')"
          :max="2000"
          type="number"
        />
        <div
          style="height: 34px; color: #bd3122"
          class="block__flex block__align-center component__margin-left"
        >
          %
        </div>
      </div>
    </div>
    <div style="margin-bottom: 15px">
      <div class="order__label component__margin-bottom">3. {{ text.title_3[language] }}</div>
      <div>{{ text.text_3[language] }}</div>
    </div>
    <div style="margin-bottom: 15px">
      <div class="order__label component__margin-bottom">4. {{ text.title_4[language] }}</div>
      <div class="block">
        <div style="height: 34px" class="block__flex block__align-center">
          {{ text.text_4[language] }}
        </div>
        <Input
          class="component__margin-left"
          :min="0"
          :step="1"
          :default-value="+profit"
          :error="profit_cost_error"
          @change="saveData($event, 'profit')"
          :max="500"
          type="number"
        />
        <div
          style="height: 34px; color: #bd3122"
          class="block__flex block__align-center component__margin-left"
        >
          %
        </div>
      </div>
    </div>
    <div style="margin-bottom: 15px">
      <div class="order__label component__margin-bottom">5. {{ text.title_5[language] }}</div>
      <div class="block">
        <div style="height: 34px" class="block__flex block__align-center">
          {{ text.text_5[language] }}
        </div>
        <div
          style="height: 34px; color: #bd3122"
          class="block__flex block__align-center component__margin-left"
        >
          {{ i5agency }}%
        </div>
      </div>
    </div>
    <Button :text="text.button[language]" @click="setFinParams" type="attention" />
  </div>
</template>

<script>
import text from "@/assets/text/cost_calc.json";
import { query } from "@/utilities/axios";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";

export default {
  name: "CostCalculation",
  data() {
    return {
      text,
      costs: null,
      profit: 0,
      overhead_cost: 0,
      i5agency: 0,
      overhead_cost_error: null,
      profit_cost_error: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getFinParams() {
      query(
        "get",
        "getFinancialParametersByCompany",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.costs = result[0];
        this.i5agency = result[0].i5agency;
        this.profit = result[0].profit;
        this.overhead_cost = result[0].overhead_costs;
      });
    },
    setFinParams() {
      query(
        "post",
        "addFinancialParametersByCompany",
        {
          overhead_costs: +this.overhead_cost,
          profit: +this.profit,
          discount: 0,
        },
        {
          token: localStorage.getItem("token"),
        },
      );
    },
    saveData(data, name) {
      this[name] = data;
    },
  },
  mounted() {
    this.getFinParams();
  },
  components: {
    Input,
    Button,
  },
};
</script>
