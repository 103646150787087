<template>
  <div style="margin-left: 8px; padding-bottom: 20px; width: 100%">
    <div
      class="page-header__name block block__align-center"
      style="height: 43px; margin-bottom: 20px"
    >
      {{ text.title[language] }}
    </div>
    <div class="block">
      <div class="home__card"></div>
      <div style="width: 100%"></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/admin_analytics.json";
import { query } from "@/utilities/axios";

export default {
  name: "Analytics",
  data() {
    return {
      text,
      showChart: false,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getAnalytics() {
      query(
        "post",
        "getOrdersAnalyticsAdmin",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        const colors = ["#1abc9c", "#9b59b6", "#e67e22", "#e74c3c", "#01a3a4", "#341f97"];
        for (const item of result.graph) {
          this.chartData.labels.push(item.name);
          for (const [index, el] of Object.keys(item)
            .filter((i) => i !== "name" && i !== "agency")
            .entries()) {
            if (!this.chartData.datasets[index]) {
              this.chartData.datasets.push({
                label: el,
                backgroundColor: colors[index],
                data: [],
              });
            }
            this.chartData.datasets[index].name = el;
            this.chartData.datasets[index].data.push(item[el]);
          }
        }
        this.showChart = true;
      });
    },
  },
  mounted() {
    this.getAnalytics();
  },
  components: {},
};
</script>
