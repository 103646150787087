import store from "@/store/index";
import jwtDecode from "jwt-decode";
import { query } from "@/utilities/axios/index";

// import router from '@/router';

const state = () => ({
  user: null,
  showAuthorizeModal: false,
  showCookies: false,
  afterAuthorizeFunction: () => {},
  access: null,
  closableModal: false,
});

const mutations = {
  setUser(state, data) {
    state.user = data;
  },
  removeUser(state) {
    state.user = null;
  },
  setShowAuthorizeModal(state, data) {
    state.showAuthorizeModal = data;
  },
  setShowCookies(state, data) {
    state.showCookies = data;
  },
  setAfterAuthorize(state, data) {
    state.afterAuthorizeFunction = data;
  },
  setAccess(state, data) {
    state.access = data;
  },
  removeAccess(state) {
    state.access = null;
  },
  setClosableModal(state, data) {
    state.closableModal = data;
  },
};

const getters = {};

const actions = {
  async getUser({ commit, state }) {
    return new Promise((resolve, reject) => {
      query(
        "get",
        "getUserInfo",
        {},
        {
          token: localStorage.getItem("token"),
        },
      )
        .then((item) => {
          state.afterAuthorizeFunction();
          if (item.lang) {
            store.commit("index/setLanguage", item.lang);
            localStorage.setItem("language", item.lang);
          }
          if (item.currency) {
            store.commit("index/setCurrency", item.currency);
          }
          commit("setUser", item);
          const acceptCookies = localStorage.getItem("accept_cookies");
          if (!item.accept_cookies && !acceptCookies) {
            commit("setShowCookies", true);
          }
          const getPayload = () => {
            const token = localStorage.getItem("token");
            if (token) {
              const decoded = jwtDecode(token);
              commit("setAccess", decoded);
            } else {
              commit("removeAccess");
            }
          };
          getPayload();
          resolve(item);
        })
        .catch(() => {
          commit("removeUser", null);
          commit("removeAccess");
          const secretKey = localStorage.getItem("token");
          if (secretKey) {
            localStorage.removeItem("token");
          }
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
