<template>
  <div style="width: 100%">
    <div class="breadcrumbs__wrapper" v-if="breadcrumbs">
      <div
        class="breadcrumbs__crumb"
        @click="handleClick(crumb)"
        v-for="(crumb, index) in breadcrumbs"
        v-bind:key="`${crumb[name]}_bc`"
      >
        <span>{{ crumb[name] }}</span>
        <span
          v-if="index < breadcrumbs.length - 1 && breadcrumbs.length > 1"
          class="component__margin-left component__margin-right"
        >
          /
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    breadcrumbs: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  methods: {
    handleClick(crumb) {
      this.$emit("click", crumb);
    },
  },
};
</script>
