<template>
  <div>
    <CalculatorPart
      @close="setCalculatorModal(false)"
      :values="calculatorCosts"
      :row="row"
      :part="part"
      :show="showCalculatorModal"
    />
    <FullSize
      :type="fsType"
      :link="fsLink"
      :preview-link="fsPreviewLink"
      :show="fsShow"
      :title="fsFileName"
      @close="closeFullSize"
    />
    <Modal
      :show="showBlankModal"
      :title="text.modal_blank_title[language]"
      @cancel="setShowBlankModal(false)"
    >
      <template slot="body">
        <div>
          <div class="block block__align-center">
            <Input
              style="width: 100%"
              :default-value="calcBlankPrice"
              :label="text.blank_price_for_one[language]"
            />
            <div class="order__x" v-if="part && part.amount">X {{ part.amount }} =</div>
            <Input
              style="width: 100%"
              :default-value="calcBlankTotalPrice"
              :label="text.blank_price_total[language]"
            />
          </div>
        </div>
      </template>
    </Modal>
    <div class="order__block order__label" v-if="row.code && row.name">
      {{ row.code }} - {{ row.name }}
    </div>
    <div class="order__files" v-if="part && part.files">
      <div :class="width < 600 ? 'order__files_container' : 'order__files_container-2'">
        <div class="order__preview" v-if="part.files && part.files[0]">
          <div :class="width < 600 ? 'order__preview' : 'order__preview_big'">
            <ModelViewer
              v-if="part.files[activeFile].preview_3d && show3dModel"
              style="height: 150px"
              :src="
                setLink(
                  part.files[activeFile].hash,
                  part.files[activeFile].time_stamp,
                  part.files[activeFile].file_name,
                )
              "
            />
            <div class="order__preview_buttons">
              <FullSizeLogo @click="setFullSize" class="order__preview_button" />
            </div>
            <div class="order__preview_img-container" v-if="!show3dModel">
              <img
                v-if="part.files[activeFile] && part.files[activeFile].preview_img !== 'default'"
                :src="part.files[activeFile].preview_img"
                class="order__preview_img"
                @error="setError"
              />
              <div
                style="height: 250px"
                class="block block__center block__align-center"
                v-if="!part.files[activeFile] || part.files[activeFile].preview_img === 'default'"
              >
                <DefaultSvg />
              </div>
              <div class="order__preview_360" v-if="part.files[activeFile].preview_3d">
                <Logo360 @click="setShow3dModel" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="part.files" :class="width <= 600 ? 'order__block' : 'order__files_block'">
          <div
            @click="setShowFiles"
            class="block block__between block__align-center order__files_label"
          >
            <div class="order__label">
              {{ text.total_files[language] }}: ({{ part.files.length }})
            </div>
            <div class="component__margin-right">
              <Arrow :class="!showFiles ? 'order__arrow' : ' order__arroworder__arrow_active'" />
            </div>
          </div>
          <div v-if="showFiles">
            <div
              v-for="(file, index) in part.files"
              @click="setActiveFile(index)"
              :class="`block__flex order__files_item ${
                activeFile === index ? 'order__files_active' : ''
              }`"
              v-bind:key="`file_${index}`"
            >
              <div style="width: 50px">
                <img :src="file.preview_img" v-if="file.preview_img" class="order__files_image" />
              </div>
              <div class="component__margin-left">{{ file.filename }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="order__block">
      <div class="order__label">
        Стоимость изготовления
      </div>
      <div class="order__label component__margin-top">
        Одной детали
      </div>
      <Input class="component__margin-top" />
      <div class="order__label component__margin-top">
        Партии деталей
      </div>
      <Input class="component__margin-top" />
    </div> -->
    <div class="order__block">
      <Button :text="text.viewing_tech_req[language]" @click="goToTechReq" block />
    </div>
    <div class="order__block">
      <Button :text="text.tech_button[language]" @click="goToTech" block :disabled="disableTech" />
    </div>
    <div class="order__block">
      <Button
        :text="text.tech_manual_button[language]"
        @click="addMP(row.uuid)"
        block
        :disabled="disableTech"
      />
    </div>
    <div class="order__block" v-if="calculatorCosts">
      <Button :text="text.open_calculator[language]" @click="setCalculatorModal(true)" block />
    </div>
    <div v-if="orderPriceType === 2" class="order__card">
      <div class="order__card_title">
        {{ text.part_price[language] }}
      </div>
      <Input
        v-if="!cantProduce"
        :disabled="cantProduce"
        type="number"
        :step="0.01"
        :sub-label="text.rub[language]"
        @change="savePartPrice(+$event, 'price_for_one')"
        :label="text.price_for_one[language]"
        :default-value="priceForOne"
      />
      <Input
        v-if="!cantProduce"
        :disabled="cantProduce"
        type="number"
        :step="0.01"
        :sub-label="text.rub[language]"
        @change="savePartPrice(+$event, 'price_all')"
        :default-value="priceAll"
        :label="text.total_price[language]"
      />
      <div
        v-if="
          manuf && manuf.price_all_with_agency && !this.cantProduce && orderData && !orderData.inner
        "
      >
        <span>
          {{ text.agency_i5[language] }}
          <span class="order__values_value">
            {{ getMoney(manuf.price_all_with_agency, "RUB", language) }}
          </span>
        </span>
      </div>
      <div
        v-if="
          recommendations &&
          recommendations.technology_analysis &&
          recommendations.technology_analysis.total_price
        "
      >
        <a-checkbox :disabled="cantProduce" @change="setI5Price($event)" :checked="i5PriceChecked">
          {{ text.i5_price_part[language] }} ({{
            getMoney(
              recommendations.technology_analysis.total_price,
              recommendations.currency,
              language,
            )
          }})
        </a-checkbox>
      </div>
      <div>
        <a-checkbox @change="setCommentChecked($event)" :checked="commentChecked">
          {{ text.set_part_comment[language] }}
        </a-checkbox>
      </div>
      <Textarea
        v-if="commentChecked"
        @change="savePartPrice($event, 'comment')"
        :default-value="partComment"
        :label="text.part_comment[language]"
        class="component__margin-bottom"
      />
      <div>
        <a-checkbox :checked="cantProduce" @change="setCantProduce">
          {{ text.cant_produce[language] }}
        </a-checkbox>
      </div>
    </div>
    <div class="block block__between">
      <div style="width: 100%" class="order__block">
        <div class="order__label">
          {{ text.part[language] }}
        </div>
        <div class="order__values" v-if="part">
          <div class="component__margin-bottom" v-if="part.code">
            <div class="order__values_label">{{ text.code[language] }}</div>
            <div class="order__values_value">{{ part.code }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.name">
            <div class="order__values_label">{{ text.name[language] }}</div>
            <div class="order__values_value">{{ part.name_order }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.amount">
            <div class="order__values_label">{{ text.amount[language] }}</div>
            <div class="order__values_value">{{ part.amount }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.material">
            <div class="order__values_label">{{ text.material[language] }}</div>
            <div class="order__values_value">{{ part.material }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.gost_material">
            <div class="order__values_label">{{ text.gost_material[language] }}</div>
            <div class="order__values_value">{{ part.gost_material }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.blank_type">
            <div class="order__values_label">{{ text.blank[language] }}</div>
            <div class="order__values_value">{{ part.blank_type }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.gost_blank">
            <div class="order__values_label">{{ text.gost_blank[language] }}</div>
            <div class="order__values_value">{{ part.gost_blank }}</div>
          </div>
          <div class="component__margin-bottom" v-if="part.x_size && part.y_size && part.z_size">
            <div class="order__values_label">{{ text.size[language] }}</div>
            <div class="order__values_value">
              {{ part.x_size }} x {{ part.y_size }} x {{ part.z_size }}
            </div>
          </div>
          <div class="component__margin-bottom" v-if="part.weight">
            <div class="order__values_label">{{ text.weight[language] }}</div>
            <div class="order__values_value">{{ getNumberWithSpaces(part.weight) }}</div>
          </div>
        </div>
      </div>
      <div style="width: 100%" class="order__block">
        <div>
          <div class="order__label">
            {{ text.recommend[language] }}
          </div>
          <div class="order__values component__margin-bottom" v-if="recommendations">
            <div v-if="recommendations.part_analysis">
              <div class="order__label component__margin-bottom">
                {{ text.recommend_tech_title[language] }}
              </div>
              <div class="block">
                <!--<WarningImage style="min-width: 20px;"/>-->
                <div>
                  {{ recommendations.part_analysis[0] }}
                </div>
              </div>
            </div>
            <div v-if="recommendations.manufacturing_analysis">
              <div class="order__label component__margin-bottom">
                {{ text.recommend_manuf_title[language] }}
              </div>
              <div>
                {{ recommendations.manufacturing_analysis[0] }}
              </div>
            </div>
            <div v-if="recommendations.technology_analysis">
              <div class="order__label">
                {{ text.recommend_price_title[language] }}
              </div>
              <div class="block__flex block__align-center component__margin-bottom">
                <div>
                  <div v-if="recommendations.technology_analysis.blank_cost">
                    <div class="order__values_label">{{ text.blank_cost[language] }}</div>
                    <div class="order__values_value">
                      {{
                        getMoney(
                          recommendations.technology_analysis.blank_cost,
                          recommendations.currency,
                          language,
                        )
                      }}
                    </div>
                  </div>
                </div>
                <!--<EditIcon
                  @click="setShowBlankModal(true)"
                  style="cursor: pointer;"
                  class="component__margin-left" />-->
              </div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.processing_cost"
              >
                <div class="order__values_label">{{ text.processing_cost[language] }}</div>
                <div class="order__values_value">
                  {{
                    getMoney(
                      recommendations.technology_analysis.processing_cost,
                      recommendations.currency,
                      language,
                    )
                  }}
                </div>
              </div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.overhead_cost"
              >
                <div class="order__values_label">{{ text.overhead_cost[language] }}</div>
                <div class="order__values_value">
                  {{
                    getMoney(
                      recommendations.technology_analysis.overhead_cost,
                      recommendations.currency,
                      language,
                    )
                  }}
                </div>
              </div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.profit"
              >
                <div class="order__values_label">{{ text.profit[language] }}</div>
                <div class="order__values_value">
                  {{
                    getMoney(
                      recommendations.technology_analysis.profit,
                      recommendations.currency,
                      language,
                    )
                  }}
                </div>
              </div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.discount"
              >
                <div class="order__values_label">{{ text.discount[language] }}</div>
                <div class="order__values_value">
                  {{ recommendations.technology_analysis.discount }} %
                </div>
              </div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.price_per_one"
              >
                <div class="order__values_label">{{ text.price_for_one[language] }}</div>
                <div class="order__values_value">
                  {{
                    getMoney(
                      recommendations.technology_analysis.price_per_one,
                      recommendations.currency,
                      language,
                    )
                  }}
                </div>
              </div>
              <div class="order__delimiter"></div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.agency && orderData && !orderData.inner"
              >
                <div class="order__values_label">{{ text.agency[language] }}</div>
                <div class="order__values_value">
                  {{
                    getMoney(
                      recommendations.technology_analysis.agency,
                      recommendations.currency,
                      language,
                    )
                  }}
                </div>
              </div>
              <div
                class="component__margin-bottom"
                v-if="recommendations.technology_analysis.total_price"
              >
                <div class="order__values_label">{{ text.total_price[language] }}</div>
                <div class="order__values_value">
                  {{
                    getMoney(
                      recommendations.technology_analysis.total_price,
                      recommendations.currency,
                      language,
                    )
                  }}
                </div>
              </div>
              <!--<Button
                size="small"
                text="Редактировать"
                block
                @click="setShowBlankModal(true)" />-->
            </div>
          </div>
          <div v-if="!recommendations && notRecText === 'no-auth'" class="order__values">
            {{ text.not_rec[language] }}
          </div>
          <div v-if="!recommendations && notRecText === 'auth'" class="order__values">
            {{ text.not_pay[language] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from "@/utilities/axios";
import { getMoney, getNumberWithSpaces } from "@/utilities/functions";
import text from "@/assets/text/order.json";
import packageJson from "@/../package.json";
import partText from "@/assets/text/parts.json";
import ModelViewer from "@/components/modelViewer/ModelViewer.vue";
import Modal from "@/components/modal/Modal.vue";
import Default from "@/assets/images/parts/edit-deafult.png";
import DefaultSvg from "@/assets/images/order/full_size_medium.svg";
import FullSizeLogo from "@/assets/images/order/full_size.svg";
import Logo360 from "@/assets/images/order/3d360.svg";
import FullSize from "@/components/fullSize/FullSize.vue";
import Arrow from "@/assets/images/order/Arrow.svg";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import CalculatorPart from "../calculator/Calculator.vue";

export default {
  data() {
    return {
      text,
      partText,
      getMoney,
      getNumberWithSpaces,
      specification: null,
      part: null,
      activeFile: 0,
      show3dModel: false,
      fsType: "image",
      fsLink: null,
      fsPreviewLink: null,
      fsFileName: "",
      fsShow: false,
      fsHash: null,
      fsTS: null,
      fsName: null,
      showFiles: true,
      loadingRec: false,
      recommendations: null,
      notRecText: "no-auth",
      disableTech: true,
      orderHas3d: false,
      manuf: null,
      priceForOne: null,
      priceAll: null,
      partComment: null,
      i5PriceChecked: false,
      commentChecked: false,
      cantProduce: false,
      timer: null,
      showBlankModal: false,
      calcBlankPrice: null,
      calcBlankTotalPrice: null,
      showCalculatorModal: false,
      editCalculator: {
        blank: false,
        treatment: false,
        profit: false,
        discount: false,
      },
      calculatorCosts: null,
    };
  },
  props: {
    row: Object,
    width: {
      type: [Number, String],
    },
    orderPriceType: {
      type: Number,
    },
    orderData: {
      type: Object,
    },
  },
  watch: {
    row: {
      deep: true,
      handler: function (val) {
        this.show3dModel = false;
        this.activeFile = 0;
        this.getPage(val.uuid);
        this.getRecommendations(val.uuid);
        this.i5PriceChecked = false;
        this.commentChecked = false;
        this.cantProduce = false;
        this.partComment = null;
        this.priceAll = null;
        this.priceForOne = null;
        this.manuf = null;
      },
    },
  },
  computed: {
    currency() {
      return this.$store.state.index.currency;
    },
    language() {
      return this.$store.state.index.language;
    },
    access() {
      return this.$store.state.user.access;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    getPage(uuid) {
      this.loading = false;
      const headers = {};
      const token = localStorage.getItem("token");
      if (token) {
        headers.token = token;
      }
      query(
        "post",
        "getPartOrderInfoV2",
        {
          uuid: uuid,
        },
        headers,
      ).then((orderPart) => {
        this.part = orderPart.part_order_info;
        this.manuf = orderPart.manuf;
        if (orderPart.manuf && orderPart.manuf.price_one) {
          this.priceForOne = orderPart.manuf.price_one;
        }
        if (orderPart.manuf && orderPart.manuf.price_all) {
          this.priceAll = orderPart.manuf.price_all;
        }
        if (orderPart.manuf && orderPart.manuf.comment) {
          this.partComment = orderPart.manuf.comment;
          this.commentChecked = true;
        }
        if (orderPart.manuf && orderPart.manuf.cant_produce) {
          this.priceForOne = null;
          this.priceAll = null;
          this.commentChecked = false;
          this.cantProduce = orderPart.manuf.cant_produce;
        }
        for (const file of orderPart.part_order_info.files) {
          if (file.preview_3d) {
            this.orderHas3d = true;
          }
        }
        this.setFS(0);
      });
    },
    setShowBlankModal(value) {
      this.showBlankModal = value;
      if (value && this.part && this.part.amount) {
        this.calcBlankPrice = this.recommendations.technology_analysis.blank_cost;
        this.calcBlankTotalPrice =
          +this.recommendations.technology_analysis.blank_cost * this.part.amount;
      }
    },
    getRecommendations(uuid) {
      this.loadingRec = false;
      const headers = {};
      const token = localStorage.getItem("token");
      if (token) {
        headers.token = token;
        query(
          "post",
          "getSystemRecommendations",
          {
            uuid: uuid,
          },
          headers,
        )
          .then((rec) => {
            this.disableTech = false;
            this.recommendations = rec;
            if (rec && rec.technology_analysis) {
              this.calculatorCosts = rec.technology_analysis;
            }
            this.loadingRec = true;
          })
          .catch((err) => {
            this.disableTech = true;
            this.loadingRec = true;
            if (err.response.status === 402) {
              this.notRecText = "auth";
            } else {
              this.notRecText = "no-auth";
            }
          });
      }
    },
    setFS(index) {
      if (this.part.files && this.part.files[index].preview_3d) {
        this.fsType = "3d";
        this.fsLink = this.part.files[index].preview_3d;
        this.fsFileName = this.part.files[index].filename;
      } else if (this.part.files && this.part.files[index].preview_img) {
        this.fsType = "image";
        this.fsLink = this.part.files[index].download;
        this.fsFileName = this.part.files[index].filename;
        this.fsPreviewLink = this.part.files[index].preview_img;
      } else {
        this.fsType = "none";
        this.fsFileName = this.part.files[index].filename;
      }
    },
    setError(e) {
      e.target.src = Default;
    },
    setActiveFile(index) {
      this.show3dModel = false;
      this.activeFile = index;
      this.setFS(this.activeFile);
    },
    setLink(hash, timeStamp, fileName) {
      return `${packageJson.url}getFile?time_stamp=${timeStamp}&hash=${hash}&file_name=${fileName}&ext=stl`;
    },
    setShow3dModel() {
      this.show3dModel = true;
    },
    setFullSize() {
      this.fsShow = true;
    },
    closeFullSize() {
      this.fsShow = false;
    },
    setShowFiles() {
      this.showFiles = !this.showFiles;
    },
    goToTech() {
      this.$router.push(`/recommendations/${this.row.uuid}`);
    },
    goToTechReq() {
      this.$router.push(`/order-tech-requirement/${this.row.uuid}/roughness`);
    },
    savePartPrice(value, type) {
      this.i5PriceChecked = false;
      if (type === "price_for_one") {
        if (value && this.part && this.part.amount) {
          this.priceForOne = value;
          this.priceAll = value * this.part.amount;
        } else {
          this.priceForOne = "";
          this.priceAll = "";
        }
      } else if (type === "price_all") {
        if (value) {
          if (value % this.part.amount === 0) {
            this.priceForOne = value / this.part.amount;
          } else {
            this.priceForOne = (value / this.part.amount).toFixed(2);
          }
          this.priceAll = value;
        } else {
          this.priceForOne = "";
          this.priceAll = "";
        }
      } else if (type === "comment") {
        this.partComment = value;
      }
      this.resetTimer();
    },
    setI5Price(val) {
      this.i5PriceChecked = val.target.checked;
      if (this.i5PriceChecked) {
        this.priceForOne = this.recommendations.technology_analysis.price_per_one;
        this.priceAll = this.recommendations.technology_analysis.total_price;
      } else {
        if (this.manuf && this.manuf.price_one) {
          this.priceForOne = this.manuf.price_one;
        } else {
          this.priceForOne = 0;
        }
        if (this.manuf && this.manuf.price_all) {
          this.priceAll = this.manuf.price_all;
        } else {
          this.priceAll = 0;
        }
      }
      this.estimatePartPrice();
    },
    setCommentChecked(val) {
      this.commentChecked = val.target.checked;
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.estimatePartPrice().then(() => {
          this.$emit("changePrice");
        });
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer() {
      this.stopTimer();
      this.startTimer();
    },
    estimatePartPrice() {
      return new Promise((resolve) => {
        query(
          "post",
          "estimateOrderPartByManuf",
          {
            uuid_part: this.row.uuid,
            price_all: this.priceAll ? +this.priceAll : 0,
            cant_produce: this.cantProduce,
            comment: this.partComment ? this.partComment : "",
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then(() => {
          this.getPage(this.row.uuid);
          resolve();
        });
      });
    },
    setCantProduce(val) {
      this.cantProduce = val.target.checked;
      if (this.cantProduce) {
        this.i5PriceChecked = false;
      } else {
        if (this.manuf && this.manuf.price_one) {
          this.priceForOne = this.manuf.price_one;
        } else {
          this.priceForOne = 0;
        }
        if (this.manuf && this.manuf.price_all) {
          this.priceAll = this.manuf.price_all;
        } else {
          this.priceAll = 0;
        }
        if (this.manuf && this.manuf.comment) {
          this.partComment = this.manuf.comment;
          this.commentChecked = true;
        } else {
          this.commentChecked = false;
        }
      }
      this.estimatePartPrice();
    },
    setCalculatorModal(val) {
      this.showCalculatorModal = val;
      if (!val) {
        this.getRecommendations(this.row.uuid);
      }
    },
    goToTechProcess(uuid) {
      this.$router.push(`/tech-processes/${uuid}`);
    },
    addMP(uuid) {
      query(
        "put",
        "addMP",
        {
          uuid_part_order: uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.goToTechProcess(res.uuid);
      });
    },
  },
  created() {
    this.getPage(this.row.uuid);
    this.getRecommendations(this.row.uuid);
  },
  components: {
    Button,
    DefaultSvg,
    ModelViewer,
    FullSizeLogo,
    FullSize,
    Arrow,
    Input,
    Textarea,
    // EditIcon,
    Modal,
    Logo360,
    CalculatorPart,
  },
};
</script>
