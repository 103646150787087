<template>
  <div style="width: 100%">
    <Modal
      :title="text.change_machine_tool_title[language]"
      @cancel="setShowSaveModal(false, null)"
      :ok-text="text.change"
      @ok="changeMachineTool()"
      :show="showSaveModal"
    >
      <template slot="body">
        <div
          style="margin: 20px 0"
          v-if="
            values && values.machine_tool && savedData && savedData.serial_number && savedData.name
          "
        >
          <span>{{ text.change_machine_tool_text1[language] }} </span>
          <b>{{ values.machine_tool }}</b>
          <span> {{ text.change_machine_tool_text2[language] }} </span>
          <b>{{ savedData.serial_number }} </b>
          <b>{{ savedData.name }}</b>
          <span> {{ text.change_machine_tool_text3[language] }} </span>
        </div>
      </template>
    </Modal>
    <div v-if="edit">
      <div style="width: 100%">
        <Search
          style="width: 100%"
          :label="text.operation_name[language]"
          :default-value="values.name"
          @change="changeLine($event.label, 'name')"
          :dropdown-props="{
            value: 'name',
          }"
          :query="{
            method: 'post',
            url: 'getOperationTypes',
            findName: 'find',
            label: 'name',
            value: 'uuid',
          }"
        />
      </div>
      <div style="width: 100%">
        <Search
          style="width: 100%"
          :label="text.machine_tool[language]"
          show-custom
          :default-value="values.machine_tool ? values.machine_tool : ''"
          :dropdown-props="{
            img: 'img',
            sublabel: 'manufacturing',
            secondLine: 'machine_tool_method',
            prelabel: 'serial_number',
          }"
          clearable
          @change="setShowSaveModal(true, $event)"
          @setTempValue="saveRestoreValue($event)"
          :query="{
            method: 'post',
            url: 'getSearchMachineToolsByCompany',
            findName: 'name',
            label: 'name',
            value: 'id',
          }"
        />
      </div>
      <div
        style="margin-bottom: 15px"
        v-for="(machineTool, index) in values.tools.filter((el) => el.type === 'machine_tool')[0]
          .data"
        v-bind:key="`info_${index}`"
      >
        <div class="block block__align-center">
          <div style="width: 100%">
            <Input
              disabled
              :default-value="machineTool.shop_name ? machineTool.shop_name : ''"
              :label="text.workshop[language]"
            />
          </div>
          <div style="width: 100%">
            <Input disabled class="component__margin-left" :label="text.iot[language]" />
          </div>
        </div>
      </div>
      <div class="tech-proc__label">Дополнительные станки (рабочие места):</div>
      <div v-for="tool in additionalTools" v-bind:key="`add_tool_${tool.id}`">
        <div style="width: 100%">
          <Search
            style="width: 100%"
            :label="text.machine_tool[language]"
            show-custom
            :default-value="tool.value ? tool.value : ''"
            :dropdown-props="{
              img: 'img',
              sublabel: 'manufacturing',
              secondLine: 'machine_tool_method',
              prelabel: 'serial_number',
            }"
            clearable
            :query="{
              method: 'post',
              url: 'getSearchMachineToolsByCompany',
              findName: 'name',
              label: 'name',
              value: 'id',
            }"
          />
        </div>
      </div>
      <div
        class="block block__align-center component__margin-bottom"
        @click="addTool"
        style="cursor: pointer"
      >
        <AddLogo class="component__margin-right" />
        <div class="tech-proc__params_plus">Добавить</div>
      </div>
    </div>
    <div v-else class="tech-proc__card" style="width: 100%">
      <div v-if="values.tools">
        <div
          v-for="(machineTool, index) in values.tools.filter((el) => el.type === 'machine_tool')[0]
            .data"
          v-bind:key="`mt_${index}`"
        >
          <div class="block block__between">
            <div>
              <div class="tech-proc__card_title component__margin-bottom">
                {{ text.machine_tool[language] }}
              </div>
              <div class="component__margin-bottom" v-if="machineTool.manufacturer">
                <div class="tech-proc__card_label">
                  {{ text.manufacturer_tool[language] }}
                </div>
                <div class="tech-proc__card_value-black">
                  {{ machineTool.manufacturer }}
                </div>
              </div>
              <div class="component__margin-bottom" v-if="machineTool.model">
                <div class="tech-proc__card_label">
                  {{ text.card_machine_model[language] }}
                </div>
                <div class="tech-proc__card_value-black">
                  {{ machineTool.model }}
                </div>
              </div>
              <div class="component__margin-bottom" v-if="machineTool.sn">
                <div class="tech-proc__card_label">
                  {{ text.sn[language] }}
                </div>
                <div class="tech-proc__card_value-black">
                  {{ machineTool.sn }}
                </div>
              </div>
              <div class="component__margin-bottom" v-if="machineTool.type">
                <div class="tech-proc__card_label">
                  {{ text.type[language] }}
                </div>
                <div class="tech-proc__card_value-black">
                  {{ machineTool.type }}
                </div>
              </div>
              <div class="component__margin-bottom" v-if="machineTool.shop_name">
                <div class="tech-proc__card_label">
                  {{ text.workshop[language] }}
                </div>
                <div class="tech-proc__card_value-black">
                  {{ machineTool.shop_name }}
                </div>
              </div>
            </div>
            <div class="block__flex block__column block__align-bottom">
              <div v-if="machineTool.logo">
                <img :src="machineTool.logo" class="tech-proc__machine-tool_logo" />
              </div>
              <div v-if="machineTool.img">
                <img :src="machineTool.img" class="tech-proc__machine-tool_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/technological_processes.json";
import Search from "@/components/search/Search.vue";
import Modal from "@/components/modal/Modal.vue";
import Input from "@/components/input/Input.vue";
import AddLogo from "@/assets/images/tech-proc/add.svg";
import { query } from "@/utilities/axios";

export default {
  name: "operation",
  data() {
    return {
      text,
      showSaveModal: false,
      savedData: null,
      restoreValue: () => {},
      additionalTools: [],
    };
  },
  props: {
    edit: {
      type: Boolean,
    },
    values: {},
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setShowSaveModal(val, data) {
      this.savedData = data;
      this.showSaveModal = val;
      this.restoreValue();
    },
    changeMachineTool() {
      if (this.savedData && this.savedData.id && this.values && this.values.uuid) {
        query(
          "post",
          "changeMPline",
          {
            uuid_mp_line: this.values.uuid,
            change_data: {
              id_machine_tool: this.savedData.id,
            },
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then(() => {
          this.$emit("update", this.values);
          this.setShowSaveModal(false, null);
        });
      }
    },
    changeLine(value, name) {
      const data = {};
      data[name] = value;
      query(
        "post",
        "changeMPline",
        {
          uuid_mp_line: this.values.uuid,
          change_data: data,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update", this.values);
      });
    },
    saveRestoreValue(func) {
      this.restoreValue = func;
    },
    addTool() {
      this.additionalTools.push({
        id: this.additionalTools.length + 1,
        value: null,
      });
    },
  },
  components: {
    Search,
    Modal,
    Input,
    AddLogo,
  },
};
</script>
