var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"show":_vm.show,"title":_vm.text.add_tool_title[_vm.language]},on:{"close":_vm.closeAddModal}},[_c('template',{slot:"body"},[_c('div',{staticClass:"block block__column block__between",staticStyle:{"height":"100%","padding":"10px"}},[_c('Breadcrumbs',{attrs:{"breadcrumbs":_vm.breadCrumbs,"name":"name"},on:{"click":function($event){return _vm.findToolNomenclatureType($event.tools || null)}}}),(_vm.pipelineType === 1)?_c('div',{staticStyle:{"height":"100%","padding":"10px","overflow-y":"auto"}},[_c('div',{staticStyle:{"height":"100%"}},[(_vm.nomenclatureTypes)?_c('div',{staticClass:"tools__add_type-container"},_vm._l((_vm.nomenclatureTypes),function(type){return _c('div',{key:`type_${type.uuid}`,staticClass:"tools__add_type-card",on:{"click":function($event){return _vm.setType(type)}}},[_c('div',{staticClass:"block block__center"},[(type.img)?_c('img',{staticClass:"tools__add_image",attrs:{"src":type.img}}):_vm._e()]),_c('div',{staticClass:"tools__add_name"},[_vm._v(" "+_vm._s(type.name)+" ")])])}),0):_vm._e()])]):_vm._e(),(_vm.pipelineType === 2)?_c('div',{staticClass:"block block__column block__between",staticStyle:{"height":"100%","padding":"10px"}},[_c('div',{staticStyle:{"height":"100%","overflow-y":"auto"}},[_c('div',{staticClass:"block"},[(_vm.resultType && _vm.resultType.name)?_c('Input',{staticStyle:{"width":"100%"},attrs:{"default-value":_vm.resultType.name,"label":_vm.text.tool_type[_vm.language],"disabled":""}}):_vm._e(),_c('Search',{staticClass:"component__margin-left",staticStyle:{"width":"100%"},attrs:{"position-type":'fixed',"label":_vm.text.manufacturer[_vm.language],"query":{
                method: 'post',
                url: 'getSearchCompaniesExpert',
                findName: 'find',
                label: 'name',
                value: 'id',
              }},on:{"change":function($event){return _vm.saveAdd($event.value, 'id_manufacturer')}}})],1),_c('div',{staticClass:"block"},[_c('div',{staticStyle:{"width":"100%"}},[(_vm.resultType && _vm.resultType.img)?_c('img',{staticStyle:{"max-height":"100px","width":"auto","max-width":"300px","object-fit":"scale-down"},attrs:{"src":_vm.resultType.img}}):_vm._e()]),_c('Input',{staticStyle:{"width":"100%"},attrs:{"label":_vm.text.vendor_code[_vm.language]},on:{"change":function($event){return _vm.saveAdd($event, 'vendor_code')}}})],1),_c('div',{staticClass:"block"},[_c('Textarea',{staticStyle:{"width":"100%"},attrs:{"label":_vm.text.comment[_vm.language]},on:{"change":function($event){return _vm.saveAdd($event, 'comment')}}}),_vm._v(" "),_c('Input',{staticClass:"component__margin-left",staticStyle:{"width":"100%"},attrs:{"label":_vm.text.tool_name[_vm.language]},on:{"change":function($event){return _vm.saveAdd($event, 'name')}}})],1),_c('div',{staticClass:"block",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.nomenclatureTypeParameters),function(item){return _c('div',{key:item.id,staticStyle:{"flex":"1 0 50%"}},[_c('div',{staticClass:"block block__align-center block__between"},[_c('div',[_vm._v(_vm._s(item.name_rus)+" "+_vm._s(item.iso302_01))]),_c('div',{staticClass:"block__flex"},[(item.i5_column_name)?_c('Input',{staticClass:"component__margin-right",attrs:{"type":"number"},on:{"change":function($event){return _vm.saveAdd(+$event, item.i5_column_name)}}}):_vm._e(),_c('div',{staticStyle:{"width":"30px"}},[_vm._v(_vm._s(item.dimension_rus))])],1)])])}),0)]),_c('div',{staticClass:"block block__right",staticStyle:{"padding":"10px"}},[_c('Button',{attrs:{"type":"default","text":_vm.text.cancel[_vm.language]},on:{"click":_vm.closeAddModal}}),_c('Button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","text":_vm.text.save[_vm.language]},on:{"click":_vm.addTool}})],1)]):_vm._e()],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }