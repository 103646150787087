var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"inherit"}},[_c('Modal',{attrs:{"show":_vm.showAddModal,"title":_vm.text.add_mt_title[_vm.language]},on:{"close":_vm.closeAddModal}},[_c('template',{slot:"body"},[_c('div',{staticClass:"block block__column block__between",staticStyle:{"height":"100%","padding":"10px"}},[_c('div',{staticStyle:{"padding":"10px","height":"100%","overflow-y":"auto"}},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"required":"","label":_vm.text.machine_tool_name[_vm.language]},on:{"change":function($event){return _vm.saveAdd('name', $event)}}}),_c('Search',{attrs:{"required":"","label":_vm.text.manufacturing[_vm.language],"query":{
              method: 'post',
              url: 'getSearchCompaniesExpert',
              findName: 'find',
              label: 'name',
              value: 'id',
            }},on:{"change":function($event){return _vm.saveAdd('id_company', $event.value)}}}),_c('Search',{attrs:{"required":"","label":_vm.text.machine_tool_method[_vm.language],"query":{
              method: 'post',
              url: 'getSearchMachineToolTypes',
              findName: 'find',
              label: 'machine_tool_methods',
              value: 'id',
            }},on:{"change":function($event){return _vm.saveAdd('id_machine_tool_method', $event.value)}}}),_c('Input',{staticStyle:{"width":"100%"},attrs:{"required":"","min":0,"type":"number","label":_vm.text.size_x[_vm.language]},on:{"change":function($event){return _vm.saveAdd('x_size', +$event)}}}),_c('Input',{staticStyle:{"width":"100%"},attrs:{"required":"","min":0,"type":"number","label":_vm.text.size_y[_vm.language]},on:{"change":function($event){return _vm.saveAdd('y_size', +$event)}}}),_c('Input',{staticStyle:{"width":"100%"},attrs:{"required":"","type":"number","min":0,"label":_vm.text.size_z[_vm.language]},on:{"change":function($event){return _vm.saveAdd('z_size', +$event)}}})],1),_c('div',{staticClass:"block block__right",staticStyle:{"padding":"10px"}},[_c('Button',{attrs:{"type":"default","text":_vm.text.cancel[_vm.language]},on:{"click":_vm.closeAddModal}}),_c('Button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","text":_vm.text.save[_vm.language]},on:{"click":_vm.addMachineTool}})],1)])])],2),_c('PageConstructor',{attrs:{"form-name":['serial_number', 'machine_tool_name'],"query":_vm.query,"buttons":_vm.buttons,"showFilter":true,"page-name":_vm.text.page_name[_vm.language],"columns":_vm.columns,"add-title":_vm.text.add_mt[_vm.language]},on:{"setUpdate":function($event){return _vm.setUpdate($event)},"headerClick":function($event){return _vm.headerClick($event)}},scopedSlots:_vm._u([{key:"edit",fn:function({ row, width }){return [_c('Edit',{attrs:{"row":row,"width":width},on:{"update":_vm.updateTable}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }