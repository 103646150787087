<template>
  <div class="order__wrapper">
    <Modal
      :show="showDeleteOrderModal"
      :ok-text="text.delete_order_ok"
      @cancel="
        () => {
          showDeleteOrderModal = false;
        }
      "
      @ok="deleteOrder"
      :title="text.delete_order_title[language]"
    >
      <template slot="body">
        <div class="create-order__del-modal" v-if="orderData">
          <span>
            {{ text.delete_order_text[language] }}: <b>№ {{ orderData.name }}</b> ?
          </span>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCancelOrderModal"
      :ok-text="text.cancel_order_ok"
      @cancel="closeCancelModal"
      @ok="cancelOrder"
      :title="text.cancel_order_title[language]"
    >
      <template slot="body">
        <div class="create-order__del-modal" v-if="orderData">
          <span>
            {{ text.cancel_order_text[language] }}: <b>№ {{ orderData.name }}</b> ?
          </span>
        </div>
      </template>
    </Modal>
    <div class="order__container_edit" v-if="orderData">
      <Button block @click="goToSpec" :text="text.spec[language]" />
      <Button
        class="component__margin-top"
        @click="goToEdit"
        v-if="orderData.edit"
        block
        :text="text.back_to_edit[language]"
      />
      <Button
        class="component__margin-top"
        @click="openDeleteModal"
        v-if="orderData.delete"
        block
        :text="text.delete_button[language]"
      />
      <Button
        class="component__margin-top"
        @click="openCancelModal"
        v-if="orderData.cancel_order"
        block
        :text="text.cancel_order_button[language]"
      />
      <div class="block block__align-center" style="margin: 20px 0">
        <div class="order__order">{{ text.order[language] }}</div>
        <div class="order__status">{{ orderData.status }}</div>
      </div>
      <div class="order__label order__number">№ {{ orderData.name }}</div>
      <div class="order__block">
        <div class="order__label">{{ text.timing[language] }}</div>
        <div class="component__margin-top">{{ text.execution_time[language] }}</div>
        <div class="block block__align-center component__margin-top">
          <CalendarImage />
          <div class="component__margin-left order__time">
            {{ orderData.date_execution }}
          </div>
        </div>
        <div class="component__margin-top">{{ text.deadline[language] }}</div>
        <div class="block block__align-center component__margin-top">
          <CalendarImage />
          <div class="component__margin-left order__time">
            {{ orderData.date_applications }}
          </div>
        </div>
      </div>
      <div class="order__block">
        <div class="order__label">{{ text.general_details[language] }}</div>
        <div class="order__general-details">
          <div class="block component__padding-bottom">
            <PartImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.type_of_parts[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.parts) }} </span>
              <span>{{ text.pcs[language] }}</span>
            </span>
          </div>
          <div class="block component__padding-bottom">
            <BoxImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.total_parts[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.total_parts) }} </span>
              <span>{{ text.pcs[language] }}</span>
            </span>
          </div>
          <div class="block component__padding-bottom">
            <FileImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.total_files[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.total_files) }} </span>
              <span>{{ text.pcs[language] }}</span>
            </span>
          </div>
          <div class="block component__padding-bottom" v-if="orderData.total_weight">
            <WeightImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.total_weight[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.total_weight) }} </span>
              <span>{{ text.kg[language] }}</span>
            </span>
          </div>
          <div
            class="block component__padding-bottom"
            v-if="orderData.max_price || orderData.max_price === null"
          >
            <CoinsImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.max_price[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span v-if="orderData.max_price"
                >{{ getNumberWithSpaces(orderData.max_price) }}
              </span>
              <span v-if="!orderData.max_price">{{ text.not_set[language] }} </span>
              <span v-if="orderData.max_price">{{ text.rub[language] }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="order__block">
        <div class="order__label">{{ text.comment[language] }}</div>
        <div class="order__general-details">
          {{ orderData.comment }}
        </div>
      </div>
      <Textarea
        class="component__margin-bottom"
        @change="changeOrder('admin_comment', $event)"
        :label="text.admin_comment[language]"
        :default-value="orderData.admin_comment"
      />
      <Button
        block
        type="attention"
        @click="seeOffers"
        :text="`${text.offers_on_order_button[language]} ( ${orderData.count_manuf} )`"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Modal from "@/components/modal/Modal.vue";
import text from "@/assets/text/order.json";
import Textarea from "@/components/textarea/Textarea.vue";
import CalendarImage from "@/assets/images/order/calendar.svg";
import PartImage from "@/assets/images/order/part.svg";
import FileImage from "@/assets/images/order/file.svg";
import WeightImage from "@/assets/images/order/weight.svg";
import BoxImage from "@/assets/images/order/box.svg";
import CoinsImage from "@/assets/images/order/coins.svg";
import { query } from "@/utilities/axios";
import { getNumberWithSpaces } from "@/utilities/functions/index";

export default {
  name: "Order",
  data() {
    return {
      text,
      getNumberWithSpaces,
      orderStatus: "",
      orderName: "",
      orderData: null,
      showDeleteOrderModal: false,
      showCancelOrderModal: false,
      timer: null,
      savedData: null,
    };
  },
  props: {
    row: Object,
  },
  watch: {
    row: {
      deep: true,
      handler: function (val) {
        this.activeFile = 0;
        this.getOrder(val.uuid);
        if (this.savedData) {
          this.saveData(this.savedData.data.name, this.savedData.data.value, this.savedData.uuid);
        }
        this.stopTimer();
      },
    },
  },
  computed: {
    type() {
      return this.$route.meta.type;
    },
    language() {
      return this.$store.state.index.language;
    },
    name() {
      return this.$store.state.order.name;
    },
    info() {
      return this.$store.state.order.info;
    },
  },
  methods: {
    getOrder(uuid) {
      query(
        "post",
        "getOrderInfoV2",
        {
          uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((order) => {
        this.orderStatus = order.order_info.status;
        this.orderData = order.order_info;
      });
    },
    goToSpec() {
      // this.$router.push(`/order/${this.row.uuid}`);
      const routeData = this.$router.resolve({ path: `/order/${this.row.uuid}` });
      window.open(routeData.href, "_blank");
    },
    goToEdit() {
      this.$router.push(`/create_order/${this.row.uuid}`);
    },
    deleteOrder() {
      query(
        "delete",
        "deleteOrderV2",
        {
          uuid: this.row.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
    openDeleteModal() {
      this.showDeleteOrderModal = true;
    },
    seeOffers() {
      this.$router.push(`/order_offers/${this.row.uuid}`);
    },
    changeOrder(name, value) {
      this.savedData = {
        uuid: this.row.uuid,
        data: {
          name,
          value,
        },
      };
      this.resetTimer(name, value);
    },
    startTimer(name, value) {
      this.timer = setTimeout(() => {
        this.saveData(name, value);
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(name, value) {
      this.stopTimer();
      this.startTimer(name, value);
    },
    saveData(name, value, uuid = null) {
      let resUuid = this.row.uuid;
      if (uuid) {
        resUuid = uuid;
      }
      const data = {
        uuid: resUuid,
      };
      data[name] = value;
      query("post", "changeOrderInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        if (!uuid || this.row.uuid === uuid) {
          this.getOrder(this.row.uuid);
        }
      });
      this.savedData = null;
    },
    openCancelModal() {
      this.showCancelOrderModal = true;
    },
    closeCancelModal() {
      this.showCancelOrderModal = false;
    },
    cancelOrder() {
      query(
        "post",
        "cancelOrderV2",
        {
          uuid: this.row.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.closeCancelModal();
        this.$emit("update");
      });
    },
  },
  mounted() {
    this.getOrder(this.row.uuid);
  },
  components: {
    Button,
    CalendarImage,
    PartImage,
    FileImage,
    WeightImage,
    BoxImage,
    Modal,
    Textarea,
    CoinsImage,
  },
};
</script>
