<template>
  <PageConstructor
    :query="query"
    :form-name="['filename']"
    :columns="columns"
    :page-name="text.page_name[language]"
    id-name="id"
    :hide-add-footer="true"
    :add-title="text.add[language]"
  >
    <template slot="add">
      <Upload />
    </template>
    <template slot="edit" slot-scope="{ row }">
      <Edit :row="row" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/files.json";
import Upload from "@/components/upload/Upload.vue";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "Files",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getFilesByCompanyV2",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "preview",
          label: {
            ru: "",
            en: "",
          },
          type: "image",
          options: {},
          basis: 96,
          width: 96,
        },
        {
          name: "filename",
          label: text.filename,
          options: {
            bold: true,
            sort: true,
          },
          basis: 0,
          width: 200,
        },
        {
          name: "ext",
          label: text.ext,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "size",
          label: text.size,
          type: "size_mb",
          options: {
            sort: true,
            right: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "user",
          label: text.user,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "date",
          label: text.date,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
    Upload,
    Edit,
  },
};
</script>
