<template>
  <div
    :class="`rec__card ${active ? 'rec__card_active' : ''}`"
    @mouseenter="setActiveCard(index, true)"
    @mouseleave="setActiveCard(index, false)"
    v-if="item"
  >
    <div class="rec__card_title" v-if="item.title">
      {{ item.title }}
    </div>
    <div class="rec__card_body">
      <div class="rec__card_block" v-if="item.blank">
        <div class="rec__card_label">
          {{ text.blank[language] }}
        </div>
        <div class="rec__card_value">
          {{ item.blank }}
        </div>
      </div>
      <div class="rec__card_block" v-if="item.machine_tools">
        <div class="rec__card_label">
          {{ text.machine_tools[language] }}
        </div>
        <div class="rec__card_value">
          {{ item.machine_tools }}
        </div>
      </div>
      <div class="rec__card_block" v-if="item.tools">
        <div class="rec__card_label">
          {{ text.tools[language] }}
        </div>
        <div class="rec__card_value">
          {{ item.tools }}
        </div>
      </div>
      <div class="rec__card_block" v-if="item.additional_tools">
        <div class="rec__card_label">
          {{ text.add_tools[language] }}
        </div>
        <div class="rec__card_value">
          {{ item.additional_tools }}
        </div>
      </div>
      <div class="rec__card_block">
        <div class="rec__card_operations">
          {{ text.operations[language] }}
        </div>
        <div class="rec__values" v-if="!showRoute">
          <div
            v-for="(i, index) in item.operations.slice(
              0,
              item.operations.length > 5 ? 5 : item.operations.length,
            )"
            v-bind:key="`cheap_${index}`"
          >
            <div class="block">
              <div class="rec__card_value">{{ i.number }}</div>
              <div class="component__margin-left rec__card_label">
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="rec__values" v-if="showRoute">
          <div v-for="(i, index) in item.route" v-bind:key="`cheap_${index}`">
            <div class="block">
              <div class="rec__card_value">{{ i.number }}</div>
              <div class="component__margin-left rec__card_label">
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="block block__align-center rec__show-operations"
          @click="setShowOperations(true)"
          v-if="item.operations.length > 5 && !showRoute"
        >
          <ArrowDown />
          <div class="component__margin-left rec__show-operations_text">
            {{ text.show_operations[language] }}
          </div>
        </div>
        <div
          class="block block__align-center rec__show-operations"
          @click="setShowOperations(false)"
          v-if="item.operations.length > 5 && showRoute"
        >
          <ArrowUp />
          <div class="component__margin-left rec__show-operations_text">
            {{ text.hide_operations[language] }}
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 20px">
      <div class="rec__card_block" style="margin-top: 10px" v-if="item.time.value">
        <div class="rec__card_big-title">
          {{ text.t_st[language] }}
        </div>
        <div class="rec__card_big-value block block__right">
          <span>{{ item.time.value }}</span>
          <span class="component__margin-left">{{ text.min[language] }}</span>
        </div>
      </div>
      <div class="rec__card_block" v-if="item.costs && item.costs.value">
        <div class="rec__card_big-title">
          {{ text.cost_price[language] }}
        </div>
        <div class="block block__align-center block__right">
          <div class="rec__card_big-value">
            {{ getMoney(item.costs.value, item.costs.currency, language) }}
          </div>
        </div>
      </div>
      <Button
        class="component__margin-top"
        :type="active ? 'attention' : 'default'"
        @click="handleClick"
        :text="text.choose[language]"
        block
      />
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/recommendations.json";
import Button from "@/components/button/Button.vue";
import ArrowDown from "@/assets/images/recommendations/arrow-down.svg";
import ArrowUp from "@/assets/images/recommendations/arrow-up.svg";
import { getMoney } from "@/utilities/functions";

export default {
  name: "Card",
  data() {
    return {
      text,
      getMoney,
      showRoute: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    title: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setShowOperations(val) {
      this.showRoute = val;
    },
    setActiveCard(index, val) {
      this.$emit("setActive", {
        index,
        val,
      });
    },
    handleClick() {
      this.$emit("click", this.item.uuid_mp);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    currency() {
      return this.$store.state.index.currency;
    },
  },
  components: {
    Button,
    ArrowDown,
    ArrowUp,
  },
};
</script>
