<template>
  <header class="header__container">
    <div class="block__flex block__left block__align-center">
      <Logo @click="goHome" style="cursor: pointer" />
    </div>
    <div class="block__flex">
      <Button
        @click="openLogin"
        :text="text.sign_in[language]"
        type="primary"
        size="small" />
      <Dropdown style="margin-left: 15px;" :active="activeFlag">
        <template slot="body">
           <div class="block__flex block__align-center">
             <Rus
               v-if="language === 'ru'"
               class="header__flag"
               height="36"/>
             <Eng
               v-if="language === 'en'"
               class="header__flag"
               height="36" />
           </div>
        </template>
        <template slot="dropdown">
          <div class="block block__column">
            <Rus
              @click="setLanguage('ru')"
              height="36"
              class="header__flag"/>
            <Eng
              @click="setLanguage('en')"
              height="36"
              class="component__margin-top header__flag" />
          </div>
        </template>
      </Dropdown>
    </div>
  </header>
</template>

<script>
import Logo from '@/assets/images/i5/i5.solutions.svg';
import Rus from '@/assets/images/flags/ru.svg';
import Eng from '@/assets/images/flags/gb.svg';
import Dropdown from '@/components/dropdown/Dropdown.vue';
import Button from '@/components/button/Button.vue';
import text from '@/assets/text/header.json';

export default {

  name: 'HeaderStandard',
  data() {
    return {
      text,
      activeFlag: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    setLanguage(lang) {
      this.$store.commit('index/setLanguage', lang);
      localStorage.setItem('language', lang);
      if (this.activeFlag !== lang) {
        this.activeFlag = lang;
      } else {
        this.activeFlag = null;
      }
    },
    openLogin() {
      this.$store.commit('user/setClosableModal', true);
      this.$store.commit('user/setShowAuthorizeModal', true);
    },
  },
  components: {
    Logo,
    Dropdown,
    Button,
    Rus,
    Eng,
  },
};
</script>
