<template>
  <div>
    <Modal
      :title="text.remove_elements[language]"
      :ok-text="text.delete"
      @cancel="
        () => {
          showDeleteModal = false;
        }
      "
      @ok="deleteMpLine"
      :show="showDeleteModal"
    >
      <template slot="body">
        <div style="padding: 20px">
          <div style="margin-bottom: 20px">{{ text.want_to_delete[language] }}:</div>
          <div v-if="deleteData && deleteData.list">
            <div v-for="(item, i) in deleteData.list" v-bind:key="`delete_list_${i}`">
              <div class="block__flex block__align-center" style="padding: 5px">
                <div
                  v-if="item.type === 2"
                  :style="setDeleteMargin(item.level)"
                  class="block block__align-center"
                >
                  <div class="ttv3__element_number" v-if="item.number">
                    {{ item.number }}
                  </div>
                  <div class="ttv3__element_text component__margin-left" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
                <div
                  v-else-if="item.type === 1"
                  :style="setDeleteMargin(item.level)"
                  class="block block__align-center"
                >
                  <div class="ttv3__dot_container">
                    <Dot />
                  </div>
                  <div class="ttv3__element_text" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
                <div
                  v-else-if="item.type === 4"
                  :style="setDeleteMargin(item.level)"
                  class="block block__align-center"
                >
                  <div class="ttv3__dot_container">
                    <Dot />
                  </div>
                  <div v-if="item.number" class="ttv3__element_number">
                    {{ item.number }}
                  </div>
                  <div class="ttv3__element_text component__margin-left" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
                <div :style="setDeleteMargin(item.level)" v-else>
                  <div class="ttv3__element_text" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :title="text.replace_elements[language]"
      :ok-text="text.replace"
      @cancel="
        () => {
          showReplaceModal = false;
        }
      "
      @ok="replaceItem()"
      :show="showReplaceModal"
    >
      <template slot="body">
        <div style="padding: 20px">
          <div style="margin-bottom: 20px">{{ text.want_to_replace[language] }}:</div>
          <div v-if="replaceData && replaceData.list" style="margin-bottom: 15px">
            <div v-for="(item, i) in replaceData.list" v-bind:key="`replace_list_${i}`">
              <div class="block__flex block__align-center" style="padding: 5px">
                <div
                  v-if="item.type === 2"
                  :style="setDeleteMargin(item.level)"
                  class="block block__align-center"
                >
                  <div class="ttv3__element_number" v-if="item.number">
                    {{ item.number }}
                  </div>
                  <div class="ttv3__element_text component__margin-left" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
                <div
                  v-else-if="item.type === 1"
                  :style="setDeleteMargin(item.level)"
                  class="block block__align-center"
                >
                  <div class="ttv3__dot_container">
                    <Dot />
                  </div>
                  <div class="ttv3__element_text" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
                <div
                  v-else-if="item.type === 4"
                  :style="setDeleteMargin(item.level)"
                  class="block block__align-center"
                >
                  <div class="ttv3__dot_container">
                    <Dot />
                  </div>
                  <div v-if="item.number" class="ttv3__element_number">
                    {{ item.number }}
                  </div>
                  <div class="ttv3__element_text component__margin-left" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
                <div :style="setDeleteMargin(item.level)" v-else>
                  <div class="ttv3__element_text" v-if="item.name">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="replaceData && replaceData.position" style="margin-bottom: 15px">
            <b>{{
              replaceData.position === "top" ? text.from_top[language] : text.from_bottom[language]
            }}</b>
          </div>
          <div v-if="replaceData && replaceData.whereReplace">
            {{ replaceData.whereReplace.name }}
          </div>
        </div>
      </template>
    </Modal>
    <div
      v-if="part"
      :class="activeIndex === 'tp' ? 'ttv3__element_active' : 'ttv3__element_not-active'"
      @click="handleClickV2('tp')"
    >
      <div class="block block__align-center">
        <div
          @click="setOpen(part, 'tp')"
          style="height: 34px"
          class="ttv3__arrow_container"
          v-if="values && values.length > 0"
        >
          <Arrow :class="`ttv3__arrow_svg ${part.open ? 'ttv3__arrow_svg_open' : ''}`" />
        </div>
        <Blank class="component__margin-right" />
        <div class="ttv3__element_text component__margin-left">{{ part.code }} {{ part.name }}</div>
      </div>
    </div>
    <div v-for="(item, index) in treeValues" v-bind:key="`tree_t_${index}`">
      <div
        @dragstart="startDrag($event, item.uuid, index)"
        @dragend="stopDrag"
        @dragover.prevent
        @dragenter.prevent
        @dragenter="onMouseEnter(index, $event)"
        @drop="onDrop($event)"
      >
        <div
          style="height: 34px"
          v-if="showDropElement(item, index, 'top')"
          :id="`tree_el_${index}_top`"
          class="ttv2__element_drop"
          @drop="dropEl($event, 'top', index)"
        ></div>
        <div :draggable="draggable" class="ttv3__element" v-if="item.show">
          <div
            v-for="line in Object.keys(item.treeImages)"
            v-bind:key="`tree_t_${index}_${line}`"
            style="height: 34px"
          >
            <div v-if="item.treeImages[line]" style="height: 34px">
              <div v-if="item.treeImages[line] === 'leaf'" style="height: 34px">
                <WithChildImage style="min-height: 34px" />
              </div>
              <div v-if="item.treeImages[line] === 'line'" style="height: 34px">
                <WithoutChildImage style="min-height: 34px" />
              </div>
              <div v-if="item.treeImages[line] === 'end'" style="height: 34px">
                <EndImage style="min-height: 34px" />
              </div>
              <div
                v-if="item.treeImages[line] === 'empty'"
                style="width: 16px; min-width: 16px"
              ></div>
            </div>
          </div>
          <div
            @click="setOpen(item, index)"
            style="height: 34px"
            class="ttv3__arrow_container"
            v-if="item.hasChild"
          >
            <Arrow :class="`ttv3__arrow_svg ${item.open ? 'ttv3__arrow_svg_open' : ''}`" />
          </div>
          <div style="height: 34px" class="ttv3__arrow_container" v-if="!item.hasChild">
            <Dot />
          </div>
          <div class="ttv3__element_container">
            <div
              @click="handleClickV2(index)"
              :class="index === activeIndex ? 'ttv3__element_active' : 'ttv3__element_not-active'"
            >
              <div v-if="item.type === 2" class="block block__align-center">
                <div class="ttv3__element_number" v-if="item.number">
                  {{ item.number }}
                </div>
                <div class="ttv3__element_text component__margin-left" v-if="item.name">
                  {{ item.name }}
                </div>
              </div>
              <div v-else-if="item.type === 1" class="block block__align-center">
                <div class="ttv3__element_text" v-if="item.name">
                  {{ item.name }}
                </div>
              </div>
              <div v-else-if="item.type === 4" class="block block__align-center">
                <div v-if="item.number" class="ttv3__element_number">
                  {{ item.number }}
                </div>
                <div class="ttv3__element_text component__margin-left" v-if="item.name">
                  {{ item.name }}
                </div>
              </div>
              <div
                v-else-if="item.type === 3"
                style="white-space: nowrap"
                class="block block__align-center"
              >
                <div v-if="item.number" class="ttv3__element_number">
                  {{ text.fix[language] }} {{ item.number }}
                </div>
                <div class="ttv3__element_text component__margin-left" v-if="item.name">
                  {{ item.name }}
                </div>
              </div>
              <div v-else>
                <div class="ttv3__element_text" v-if="item.name">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="block__flex">
            <div v-if="item.type !== 1 && edit" class="ttv3__button_container">
              <Move
                :class="index === dragIndex ? 'ttv2__move' : ''"
                @mousedown="setDraggable(true, index)"
                @mouseup="setDraggable(false)"
              />
            </div>
            <Dropdown
              v-if="item.type !== 1 && edit"
              @close="optionsClose"
              :component-scroll="parentScroll"
            >
              <template slot="toggle">
                <div class="ttv3__button_container">
                  <OptionsLogo />
                </div>
              </template>
              <template slot="expand">
                <div style="padding: 10px">
                  <div @click="expandAdd(item.flatLevel, 'top')" class="ttv3__options_el">
                    <AddTopLogo />
                    <div class="ttv3__element_text component__margin-left">
                      {{ text.add_top[language] }}
                    </div>
                  </div>
                  <div
                    style="padding-left: 25px"
                    v-if="editOpen.top && editOpen.top[item.flatLevel]"
                  >
                    <div class="ttv3__options_el" @click="addSpLine(item.uuid, 2, true)">
                      <AddOperationLogo />
                      <div class="ttv3__element_text component__margin-left">
                        {{ text.operation[language] }}
                      </div>
                    </div>
                    <div
                      class="ttv3__options_el"
                      v-if="item.level[0] > 2"
                      @click="addSpLine(item.uuid, 3, true)"
                    >
                      <AddInstallationLogo />
                      <div class="ttv3__element_text component__margin-left">
                        {{ text.installation[language] }}
                      </div>
                    </div>
                    <div
                      v-if="item.level[0] > 2"
                      class="ttv3__options_el"
                      @click="addSpLine(item.uuid, 4, true)"
                    >
                      <AddTransitionLogo />
                      <div class="ttv3__element_text component__margin-left">
                        {{ text.transition[language] }}
                      </div>
                    </div>
                  </div>
                  <div @click="expandAdd(item.flatLevel, 'bottom')" class="ttv3__options_el">
                    <AddBottomLogo />
                    <div class="ttv3__element_text component__margin-left">
                      {{ text.add_bottom[language] }}
                    </div>
                  </div>
                  <div
                    style="padding-left: 25px"
                    v-if="editOpen.bottom && editOpen.bottom[item.flatLevel]"
                  >
                    <div class="ttv3__options_el" @click="addSpLine(item.uuid, 2, false)">
                      <AddOperationLogo />
                      <div class="ttv3__element_text component__margin-left">
                        {{ text.operation[language] }}
                      </div>
                    </div>
                    <div class="ttv3__options_el" @click="addSpLine(item.uuid, 3, false)">
                      <AddInstallationLogo />
                      <div class="ttv3__element_text component__margin-left">
                        {{ text.installation[language] }}
                      </div>
                    </div>
                    <div class="ttv3__options_el" @click="addSpLine(item.uuid, 4, false)">
                      <AddTransitionLogo />
                      <div class="ttv3__element_text component__margin-left">
                        {{ text.transition[language] }}
                      </div>
                    </div>
                  </div>
                  <div @click="setShowDeleteModal(index, item.uuid)" class="ttv3__options_el">
                    <DeleteLogo />
                    <div class="ttv3__element_text component__margin-left">
                      {{ text.delete[language] }}
                    </div>
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
        <div
          style="height: 34px"
          v-if="showDropElement(item, index, 'bottom')"
          :id="`tree_el_${index}_bottom`"
          class="ttv2__element_drop"
          @drop="dropEl($event, 'bottom', index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import EndImage from "@/assets/images/tech-proc/end.svg";
import WithChildImage from "@/assets/images/tech-proc/with_child.svg";
import WithoutChildImage from "@/assets/images/tech-proc/without_child.svg";
import Arrow from "@/assets/images/tech-proc/ArrowV2.svg";
import Dot from "@/assets/images/tech-proc/dot.svg";
import Blank from "@/assets/images/tech-proc/blank.svg";
import OptionsLogo from "@/assets/images/tech-proc/options.svg";
import Dropdown from "@/components/dropdownV4/DropdownV4.vue";
import AddTopLogo from "@/assets/images/tech-proc/add_top.svg";
import AddBottomLogo from "@/assets/images/tech-proc/add_bottom.svg";
import AddOperationLogo from "@/assets/images/tech-proc/operation.svg";
import AddInstallationLogo from "@/assets/images/tech-proc/installation.svg";
import AddTransitionLogo from "@/assets/images/tech-proc/transition.svg";
import DeleteLogo from "@/assets/images/tech-proc/delete.svg";
import text from "@/assets/text/technological_processes.json";
import Modal from "@/components/modal/Modal.vue";
import { query } from "@/utilities/axios";
import Move from "@/assets/images/tech-proc/move.svg";

export default {
  name: "TreeTech",
  data() {
    return {
      text,
      treeValues: [],
      fullValues: [],
      draggable: false,
      dragData: null,
      dragPosition: null,
      dragIndex: null,
      activeIndex: "tp",
      hoveredIndex: null,
      timer: null,
      part: null,
      editOpen: {
        top: {},
        bottom: {},
      },
      showDeleteModal: false,
      showReplaceModal: false,
      deleteData: null,
      replaceData: null,
    };
  },
  props: {
    values: {
      type: [Array, Object],
      default: () => [],
    },
    mp: {
      type: Object,
    },
    activeLeaf: {
      type: [String, Number, Object],
    },
    parentScroll: {
      type: Number,
    },
    edit: {
      type: Boolean,
    },
  },
  watch: {
    activeLeaf: function (val) {
      this.activeIndex = val;
    },
    mp: function (val) {
      this.part = val;
    },
    values: function (val) {
      this.treeValues = this.setValuesV2(val);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    startDrag(evt, uuid, index) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("uuid", uuid);
      evt.dataTransfer.setData("index", index);
      this.dragData = {
        index: index,
        uuid,
      };
    },
    setDraggable(val, index = null) {
      this.draggable = val;
      this.dragIndex = index;
    },
    onMouseEnter(index, evt) {
      if (this.draggable) {
        // const top = document.getElementById(`tree_el_${index}_top`);
        // const bottom = document.getElementById(`tree_el_${index}_bottom`);
        if (evt.offsetY > 17) {
          this.dragPosition = "top";
        } else {
          this.dragPosition = "bottom";
        }
        this.hoveredIndex = index;
        /* if (!this.treeValues[index].open) {
          this.resetTimer(index);
        } */
      }
    },
    localOpen(index, layer) {
      const result = this.treeValues.slice();
      result[index].open = true;
      for (const [i, item] of result.entries()) {
        if (i > index && item.layer > layer) {
          item.open = true;
        } else {
          break;
        }
      }
      this.treeValues = result;
    },
    onDrop() {
      this.dragData = null;
      this.draggable = false;
      this.dragIndex = null;
      this.dragPosition = null;
    },
    replaceItem() {
      if (this.replaceData) {
        const uuidMpLine = this.replaceData.uuid;
        const uuidMpLineBefore =
          (this.replaceData &&
            this.replaceData.whereReplace &&
            this.replaceData.whereReplace.uuid) ||
          "";
        query(
          "post",
          "moveMPline",
          {
            uuid_mp_line: uuidMpLine,
            uuid_mp_line_before: uuidMpLineBefore,
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then(() => {
          this.$emit("update");
        });
      }
      this.showReplaceModal = false;
    },
    startTimer(index) {
      this.timer = setTimeout(() => {
        const result = this.treeValues.slice();
        if (this.dragData.item.layer > result[index].layer) {
          this.localOpen(index, this.dragData.item.layer);
        }
        // result.forEach((item) => {
        //   item.hovered = false;
        // });
        // result[index].hovered = true;
        // this.treeValues = result;
      }, 500);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(index) {
      this.stopTimer();
      this.startTimer(index);
    },
    stopDrag() {
      this.setDraggable(false);
      this.hoveredIndex = null;
      // const result = this.treeValues.slice();
      // result.forEach((item) => {
      //   item.hovered = false;
      // });
      // this.treeValues = result;
    },
    dropEl(evt, pos, index) {
      evt.preventDefault();
      const dropItem = evt.dataTransfer.getData("uuid");
      const dropIndex = evt.dataTransfer.getData("index");
      this.hoveredIndex = null;
      const item = this.values[dropIndex];
      let levelType = 0;
      if (item.level[2] > 0) {
        levelType = 2;
      } else if (item.level[1] > 0) {
        levelType = 1;
      } else if (item.level[0] > 0) {
        levelType = 0;
      }
      const list = [];
      for (const [i, value] of this.values.entries()) {
        if (levelType === 0 && i >= dropIndex) {
          if (value.level[0] === item.level[0]) {
            list.push(value);
          } else {
            break;
          }
        } else if (levelType === 1 && i >= dropIndex) {
          if (value.level[1] === item.level[1]) {
            list.push(value);
          } else {
            break;
          }
        } else if (levelType === 2 && i >= dropIndex) {
          if (value.level[2] === item.level[2]) {
            list.push(value);
          } else {
            break;
          }
        }
      }
      this.replaceData = {
        list,
        uuid: dropItem,
        index: dropIndex,
        position: pos,
        whereReplace: this.values[index],
      };
      this.showReplaceModal = true;
    },
    setValuesV2(values) {
      const result = this.remakeValues(values);
      this.handleClickV2(this.activeIndex);
      return result;
    },
    remakeValues(values) {
      let result = [];
      const firstList = values.filter((el) => el.level[1] === 0 && el.level[2] === 0);
      const secondList = values.filter((el) => el.level[1] !== 0 && el.level[2] === 0);
      const thirdList = values.filter((el) => el.level[1] !== 0 && el.level[2] !== 0);
      for (const [index, item] of values.entries()) {
        // распределяем по уровням
        if (item.level[2] === 0 && item.level[1] === 0) {
          item.treeLevel = 1;
          if (values[index + 1]) {
            if (values[index + 1].level[0] === item.level[0]) {
              item.hasChild = true;
              item.open = true;
            } else {
              item.hasChild = false;
              item.open = false;
            }
          }
        } else if (item.level[2] === 0 && item.level[1] !== 0) {
          item.treeLevel = 2;
          if (values[index + 1].level[1] === item.level[1]) {
            item.hasChild = true;
            item.open = true;
          } else {
            item.hasChild = false;
            item.open = false;
          }
        } else {
          item.treeLevel = 3;
          item.hasChild = false;
          item.open = false;
        }
        item.treeImages = {
          first: null,
          second: null,
          third: null,
        };
        item.flatLevel = `${item.level[0]}-${item.level[1]}-${item.level[2]}`;
        // выставляем открытие по умолчанию
        item.show = true;
        // ищем первую картинку
        if (item.treeLevel === 1) {
          const index = firstList.findIndex(
            (el) => item.flatLevel === `${el.level[0]}-${el.level[1]}-${el.level[2]}`,
          );
          if (index < firstList.length - 1) {
            item.treeImages.first = "leaf";
          } else {
            item.treeImages.first = "end";
          }
        } else {
          const index = firstList.findIndex((el) => el.level[0] > item.level[0]);
          if (index >= 0) {
            item.treeImages.first = "line";
          } else {
            item.treeImages.first = "empty";
          }
        }
        // ищем вторую картинку
        if (item.treeLevel === 2) {
          const list = secondList.filter((el) => el.level[0] === item.level[0]);
          const index = list.findIndex(
            (el) => item.flatLevel === `${el.level[0]}-${el.level[1]}-${el.level[2]}`,
          );
          if (index < list.length - 1) {
            item.treeImages.second = "leaf";
          } else {
            item.treeImages.second = "end";
          }
        } else if (item.treeLevel === 3) {
          const list = secondList.filter((el) => el.level[0] === item.level[0]);
          const index = list.findIndex(
            (el) => el.level[0] === item.level[0] && item.level[1] + 1 === el.level[1],
          );
          if (index >= 0) {
            item.treeImages.second = "line";
          } else {
            item.treeImages.second = "empty";
          }
        }
        // ищем третью картинку
        if (item.treeLevel === 3) {
          const list = thirdList.filter(
            (el) => el.level[0] === item.level[0] && el.level[1] === item.level[1],
          );
          const index = list.findIndex(
            (el) => item.flatLevel === `${el.level[0]}-${el.level[1]}-${el.level[2]}`,
          );
          if (index < list.length - 1) {
            item.treeImages.third = "leaf";
          } else {
            item.treeImages.third = "end";
          }
        }
      }
      result = values;
      return result;
    },
    updateTreeValues(values) {
      this.treeValues = this.remakeValues(values);
    },
    handleClickV2(index) {
      this.$emit("handleClick");
      this.activeIndex = index;
      if (index !== "tp") {
        this.$emit("click", {
          data: this.treeValues[index],
          index,
        });
      } else {
        this.$emit("click", {
          data: this.part,
          index,
        });
      }
    },
    setOpen(item, index) {
      const values = this.treeValues.slice();
      if (index !== "tp") {
        values[index].open = !values[index].open;
        for (const el of values) {
          if (item.treeLevel === 1) {
            if (el.level[0] === item.level[0] && el.treeLevel === 2) {
              el.show = !el.show;
              el.open = false;
            } else if (el.level[0] === item.level[0] && el.treeLevel === 3) {
              el.show = false;
              el.open = false;
            }
          } else if (item.treeLevel === 2) {
            if (
              el.level[0] === item.level[0] &&
              el.level[1] === item.level[1] &&
              el.treeLevel !== 1 &&
              el.treeLevel !== 2
            ) {
              el.show = !el.show;
              el.open = false;
            }
          }
        }
      } else {
        this.part.open = !this.part.open;
        for (const el of values) {
          el.show = this.part.open;
          if (this.part.open && (el.treeLevel === 1 || el.treeLevel === 2)) {
            el.open = true;
          } else if (this.part.open && el.treeLevel === 3) {
            el.open = false;
          } else if (!this.part.open) {
            el.open = false;
          }
        }
      }
      this.treeValues = values;
    },
    expandAdd(id, type) {
      const editOpen = { ...this.editOpen };
      if (!editOpen[type]) {
        editOpen[type] = {};
      }
      editOpen[type][id] = !editOpen[type][id];
      this.editOpen = editOpen;
    },
    optionsClose() {
      this.editOpen = {};
    },
    addSpLine(uuid, type, isUp) {
      query(
        "put",
        "addMPline",
        {
          uuid_mp_line: uuid,
          insert_up: isUp,
          type: type,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.$emit("update", res.uuid_new_line);
      });
    },
    setShowDeleteModal(index, uuid) {
      const item = this.values[index];
      let levelType = 0;
      if (item.level[2] > 0) {
        levelType = 2;
      } else if (item.level[1] > 0) {
        levelType = 1;
      } else if (item.level[0] > 0) {
        levelType = 0;
      }
      const list = [];
      for (const [i, value] of this.values.entries()) {
        if (levelType === 0 && i >= index) {
          if (value.level[0] === item.level[0]) {
            list.push(value);
          } else {
            break;
          }
        } else if (levelType === 1 && i >= index) {
          if (value.level[1] === item.level[1]) {
            list.push(value);
          } else {
            break;
          }
        } else if (levelType === 2 && i >= index) {
          if (value.level[2] === item.level[2]) {
            list.push(value);
          } else {
            break;
          }
        }
      }
      this.deleteData = {
        index,
        uuid,
        list,
      };
      this.showDeleteModal = true;
    },
    setDeleteMargin(level) {
      let result = 0;
      if (level[2] > 0) {
        result = 30;
      } else if (level[1] > 0) {
        result = 15;
      } else if (level[0] > 0) {
        result = 0;
      }
      return `margin-left: ${result}px; `;
    },
    deleteMpLine() {
      query(
        "delete",
        "deleteMPline",
        {
          uuid_mp_line: this.deleteData.uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.showDeleteModal = false;
        this.deleteData = null;
        this.$emit("update", null);
      });
    },
    showDropElement(item, index, position) {
      let result = false;
      if (this.dragIndex) {
        const type = this.values[this.dragIndex].type;
        if (
          index === this.hoveredIndex &&
          this.dragPosition === position &&
          index !== this.dragIndex &&
          ((type !== 5 && type === item.type) || type === 5) &&
          item.type !== 1
        ) {
          result = true;
        }
      }
      return result;
    },
  },
  mounted() {
    this.activeIndex = "tp";
    // this.setValues(this.values, 0);
    this.part = this.mp;
    this.part.open = true;
    this.treeValues = this.setValuesV2(this.values);
    this.$emit("updateTreeValues", this.updateTreeValues);
    /* const techData = localStorage.getItem('tech-proc');
    if (this.values && this.values[0] && this.values[0].length > 0) {
      this.setValues(this.values, 0);
      if (!techData) {
        localStorage.setItem('tech-proc', JSON.stringify(this.values));
      }
    } else {
      this.setValues(JSON.parse(techData), 0);
    } */
  },
  components: {
    EndImage,
    WithChildImage,
    WithoutChildImage,
    Arrow,
    Dot,
    Blank,
    OptionsLogo,
    Dropdown,
    AddBottomLogo,
    AddInstallationLogo,
    AddOperationLogo,
    AddTopLogo,
    AddTransitionLogo,
    DeleteLogo,
    Modal,
    Move,
    // Options,
  },
};
</script>
