<template>
  <div class="block__flex block__column" tabindex="0" @focus="handleFocus" @blur="handleFocusOut">
    <div :class="`dropdown ${setFocus()}`" @click="handleClick">
      <slot name="body"></slot>
    </div>
    <div
      :id="`${name}_dropdown`"
      :style="{
        maxWidth: 'fit-content',
        maxHeight: `${this.dropDownMaxHeight}px`,
        marginTop: `${this.elHeight + 10}px`,
      }"
      :class="`dropdown__container ${setOpen()} ${setDirection()}`"
    >
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  data() {
    return {
      focus: false,
      open: false,
      elWidth: null,
      elHeight: 0,
      dropDownMaxHeight: 100,
      dropDownDirection: "bottom", // top
      dropdownTransform: 0,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "dropdown",
    },
    placement: {
      type: String,
      validator: function (value) {
        return ["bottomLeft", "bottom", "bottomRight"].indexOf(value) !== -1;
      },
    },
    active: [Number, String, Object],
  },
  watch: {
    active: function () {
      this.open = false;
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    handleFocus() {
      if (!this.disabled) {
        this.dropdownToggle(this.$el);
        this.focus = true;
      }
    },
    handleFocusOut() {
      this.focus = false;
      this.open = false;
    },
    setFocus() {
      if (this.focus) {
        return "dropdown__focus";
      }
      return "";
    },
    setOpen() {
      let result = "";
      if (this.open) {
        result = "dropdown__open";
        this.$nextTick(() => {
          const dropdown = document.getElementById(`${this.name}_dropdown`);
          const dropdownWidth = dropdown.offsetWidth;
          const x = dropdown.getBoundingClientRect().x;
          const width = window.innerWidth;
          if (width / 2 < x && x + dropdownWidth > width) {
            this.dropdownTransform = `-${Math.round(x + dropdownWidth - width + 8)}px`;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          } else if (this.placement === "bottomLeft") {
            this.dropdownTransform = this.$el.getBoundingClientRect().x - x;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          }
        });
      }
      return result;
    },
    dropdownToggle(el) {
      const y = el.getBoundingClientRect().y;
      if (window.innerHeight - y > y) {
        this.dropDownDirection = "bottom";
        this.dropDownMaxHeight = window.innerHeight - y;
      } else {
        this.dropDownDirection = "top";
        this.dropDownMaxHeight = y - 105;
      }
    },
    setDirection() {
      return `dropdown_${this.dropDownDirection}`;
    },
  },
  mounted() {
    this.elWidth = this.$el.scrollWidth;
    this.elHeight = this.$el.scrollHeight;
  },
};
</script>
