<template>
  <div>
    <form @submit="handleSubmit">
      <div class="block block__between" style="width: 100%">
        <div class="block">
          <div class="component__100 component__margin-right">
            <Input :label="text.code[language]" name="code" />
            <Input :label="text.name[language]" name="name" />
          </div>
        </div>
        <div class="parts__add_files" style="width: 100%">
          <Input :label="text.search[language]" block @change="find($event)" />
          <div v-for="file in files" v-bind:key="file.id" class="parts__add_list">
            <div class="parts__add_file">
              <CheckBoxI5 :name="`file_${file.id}`" />
              <img :src="file.preview" class="parts__add_preview component__margin-left" />
              <div class="component__margin-left">{{ file.filename }}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        :ok-text="footerText.ok[language]"
        :cancel="cancel"
        :cancel-text="footerText.cancel[language]"
      />
    </form>
  </div>
</template>

<script>
import text from "@/assets/text/parts.json";
import Input from "@/components/input/Input.vue";
import Footer from "@/components/modalFooter/ModalFooter.vue";
import CheckBoxI5 from "@/components/checkbox/CheckBoxI5.vue";
import footerText from "@/assets/text/modalFooter.json";
import { query } from "@/utilities/axios";

export default {
  name: "AddPart",
  data() {
    return {
      text,
      footerText,
      timer: null,
      files: [],
      columns: [
        {
          name: "code",
        },
        {
          name: "name",
        },
      ],
    };
  },
  props: {},
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.$parent.$emit("cancel");
    },
    handleSubmit(evt) {
      evt.preventDefault();
      const formData = new FormData(evt.target);
      const result = {
        files: [],
      };
      for (const pair of formData.entries()) {
        const formItem = pair[0].split("_");
        if (formItem[0] === "file") {
          const fileIndex = this.files.findIndex((f) => +f.id === +formItem[1]);
          const file = this.files[fileIndex];
          result.files.push({
            hash: file.hash,
            time_stamp: file.time_stamp,
            file_name: file.file_name,
          });
        }
      }
      this.columns.forEach((col) => {
        result[col.name] = formData.get(col.name);
      });
      this.addPart(result);
    },
    addPart(formData) {
      query("post", "addNewPartV2", formData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("cancel");
      });
    },
    getFiles(find) {
      const data = {
        page: 1,
        line_per_page: 10,
      };
      if (find) {
        data.find = find;
      }
      query("post", "getFilesByCompanyV2", data, {
        token: localStorage.getItem("token"),
      }).then((files) => {
        this.files = files.table;
      });
    },
    find(val) {
      this.resetTimer(val);
    },
    startTimer(value) {
      this.timer = setTimeout(() => {
        this.getFiles(value);
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(value) {
      this.stopTimer();
      this.startTimer(value);
    },
  },
  components: {
    Input,
    Footer,
    CheckBoxI5,
  },
  created() {
    this.getFiles();
  },
};
</script>
