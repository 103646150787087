<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :add-title="text.add[language]"
    :page-name="text.page_name[language]"
    :hide-add-footer="true"
    @setFunctions="getFunctions($event)"
    :query-filter="queryFilter"
  >
    <template slot="add">
      <Add @cancel="cancelAdd" />
    </template>
    <template slot="edit" slot-scope="{ row }">
      <EditPart :row="row" />
    </template>
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/parts.json";
import Add from "./components/add/Add.vue";
import EditPart from "./components/edit/EditPart.vue";

export default {
  name: "Parts",
  data() {
    return {
      text,
      tableFunctions: null,
      query: {
        method: "post",
        url: "getPartsByCompanyV2",
        headers: {},
        data: {},
      },
      queryFilter: {},
      columns: [
        {
          name: "code",
          label: text.code,
          options: {
            bold: true,
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "user",
          label: text.user,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "files",
          label: text.files,
          options: {
            sort: true,
            right: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "date_created",
          label: text.date_created,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
        {
          name: "date_changed",
          label: text.date_changed,
          options: {
            sort: true,
          },
          basis: 200,
          width: 200,
        },
      ],
    };
  },
  methods: {
    cancelAdd() {
      this.tableFunctions.closeAdd();
    },
    getFunctions(func) {
      this.tableFunctions = func;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
    Add,
    EditPart,
  },
};
</script>
