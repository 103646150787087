<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <a :href="`https://market.i5.solutions/standard/${row.uuid}`" target="_blank">
        <Button block :text="text.view[language]" class="page__edit_margin" />
      </a>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/standards.json";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  components: { Button },
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
