<template>
  <div class="dd__wrapper" tabindex="0" @focus="handleFocus" @blur="handleFocusOut">
    <div @click="handleClick">
      <slot name="body"></slot>
    </div>
    <div
      v-show="open"
      class="dd__content"
      :style="{
        minWidth: `${minDdWidth}px`,
        top: `${top}px`,
        left: `${left}px`,
      }"
    >
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  data() {
    return {
      unsubscribe: () => {},
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      bodyWidth: 0,
      bodyHeight: 0,
      direction: "bottom",
      body: null,
      dropdown: null,
      open: false,
      maxDdHeight: 0,
      minDdWidth: 0,
      top: 0,
      left: 0,
    };
  },
  props: {
    name: {
      type: String,
      default: "dd",
    },
    margin: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPosition() {
      this.x = this.$el.getBoundingClientRect().x;
      this.y = this.$el.getBoundingClientRect().y;
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.bodyHeight = this.$el.clientHeight;
      this.bodyWidth = this.$el.clientWidth;
      this.minDdWidth = this.bodyWidth;
      this.top = this.bodyHeight + this.margin;
      if (this.y > this.height / 2) {
        this.direction = "top";
      } else {
        this.direction = "bottom";
      }
      if (this.direction === "bottom") {
        this.maxDdHeight = this.height - this.y - this.bodyHeight - this.margin;
      } else {
        this.maxDdHeight = this.y - this.margin;
      }
    },
    handleFocus() {
      // this.open = true;
      this.setOpen();
    },
    handleFocusOut() {
      this.open = false;
    },
    handleClick() {
      if (!this.disabled) {
        this.getPosition();
        this.open = !this.open;
      }
    },
    handleClose() {
      this.open = false;
    },
    setOpen() {
      this.$nextTick(() => {
        const dd = this.$el.querySelector(".dd__content");
        if (this.direction === "top") {
          if (dd.clientHeight < this.y) {
            this.direction = "bottom";
            this.maxDdHeight = dd.clientHeight;
          } else {
            this.top = -(dd.clientHeight + this.margin);
          }
        }
        if (dd.clientWidth > this.width - this.x) {
          this.left = -(this.width - this.x - dd.clientWidth + this.margin);
        }
      });
    },
  },
  mounted() {
    this.getPosition();
    this.$emit("setClose", this.handleClose);
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "index/setHeight") {
        this.getPosition();
      }
    });
  },
};
</script>
