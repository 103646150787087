<template>
  <div :class="`menu-v2__wrapper menu-v2__wrapper_${full ? 'open' : 'hide'}`">
    <div class="menu-v2__container">
      <div
        class="menu-v2__arrow_container"
        @mouseleave="arrowMouseLeave"
        @mouseenter="arrowMouseEnter"
      >
        <div
          @click="setFull"
          :class="`menu-v2__arrow_button ${arrowEnter ? 'menu-v2__arrow_button_enter' : ''}`"
        >
          <Left v-if="full" style="margin-right: 2px" />
          <Right v-if="!full" style="margin-right: -3px" />
        </div>
      </div>
      <div class="menu-v2__list">
        <div>
          <div @click="goTo('/')" :class="`menu-v2__company_${full ? 'open' : 'hide'}`">
            <div class="menu-v2__company_logo" v-if="user && user['company_logo']">
              <img :src="user['company_logo']" />
            </div>
            <transition name="component">
              <div
                class="menu-v2__company_nologo mono-text"
                v-if="user && !user['company_logo'] && user['company_name'] && full"
              >
                {{ (user["company_name"][0] + user["company_name"][1]).toUpperCase() }}
              </div>
            </transition>
            <transition name="component">
              <div class="menu-v2__company_name" v-if="user && user['company_name'] && full">
                {{ user["company_name"] }}
              </div>
            </transition>
          </div>
        </div>
        <div class="menu-v2__container menu-v2__padding" v-if="menuLoading">
          <div
            v-for="(menuItem, index) in userMenu"
            class="menu-v2__item"
            v-bind:key="`menu_${index}`"
          >
            <div
              @click="openMenu(index, !menuItem.open)"
              :class="`menu-v2__item_${setActiveCategory(menuItem, activeCategory, index)}`"
            >
              <order-and-parts
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'orderAndParts'"
              />
              <manufacture
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'manufacture'"
              />
              <warehouse
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'warehouse'"
              />
              <settings
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'settings'"
              />
              <library
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'library'"
              />
              <i5-kit
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'i5kit'"
              />
              <integration
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'integration'"
              />
              <experts
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'experts'"
              />
              <admin
                :class="`menu-v2__logo ${setActiveIcon(menuItem, activeCategory, index)}`"
                v-if="menuItem.icon === 'admin'"
              />
              <div
                v-if="full"
                :class="`menu-v2__category menu-v2__category_${setActiveCategory(
                  menuItem,
                  activeCategory,
                  index,
                )} mono-text`"
              >
                {{ menuItem.name[language] }}
              </div>
            </div>
            <div v-if="menuItem.children && full && menuItem.open">
              <div v-for="(child, index) in menuItem.children" v-bind:key="`child_${index}`">
                <div
                  :class="`menu-v2__children mono-text ${
                    child.link === $route.path ? 'menu-v2__children_active' : ''
                  }`"
                  @click="goTo(child.link)"
                >
                  <div
                    :class="`menu-v2__children_dot ${
                      child.link === $route.path ? 'menu-v2__children_dot_active' : ''
                    }`"
                  ></div>
                  <div class="menu-v2__children_text">
                    {{ child.name[language] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderAndParts from "@/assets/images/menu/123.svg";
import manufacture from "@/assets/images/menu/manufacture.svg";
import warehouse from "@/assets/images/menu/warehouse.svg";
import settings from "@/assets/images/menu/settings.svg";
import library from "@/assets/images/menu/library.svg";
import integration from "@/assets/images/menu/integration.svg";
import experts from "@/assets/images/menu/experts.svg";
import admin from "@/assets/images/menu/admin.svg";
import i5Kit from "@/assets/images/menu/i5kit.svg";
import Left from "@/assets/images/menu/close-arrow.svg";
import Right from "@/assets/images/menu/open-arrow.svg";

export default {
  name: "Menu",
  data() {
    return {
      unsubscribe: () => {},
      full: false,
      arrowEnter: false,
      activeCategory: null,
      userMenu: [],
      menuLoading: false,
      hasUser: false,
      hasAccess: false,
    };
  },
  watch: {
    "$route.path": {
      handler: function (val) {
        this.findCategory(val);
      },
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    language() {
      return this.$store.state.index.language;
    },
    access() {
      return this.$store.state.user.access;
    },
    menu() {
      return this.$store.state.index.menu;
    },
  },
  methods: {
    setFull() {
      this.full = !this.full;
      if (!this.full) {
        this.$store.commit("index/closeAllMenu");
      }
    },
    setActiveCategory(item, activeIndex, index) {
      let result = "";
      if (item.open || index === activeIndex) {
        result = "open";
      } else {
        result = "hide";
      }
      return result;
    },
    setActiveIcon(item, activeIndex, index) {
      let result = "";
      if (item.open || index === activeIndex) {
        result = "menu-v2__category_open";
      }
      return result;
    },
    findCategory(val) {
      for (const [index, item] of this.menu.entries()) {
        let result = null;
        for (const child of item.children) {
          if (child.link === val) {
            result = item;
            break;
          }
        }
        if (result) {
          this.activeCategory = index;
          break;
        }
      }
    },
    openMenu(index, value) {
      if (!this.full) {
        this.full = true;
      }
      this.closeAllMenu();
      this.setOpenMenu(index, value);
    },
    goTo(link) {
      if (link !== this.$route.path) {
        this.full = false;
        this.$router.push(link);
      } else {
        this.full = false;
      }
    },
    arrowMouseEnter() {
      this.arrowEnter = true;
    },
    arrowMouseLeave() {
      this.arrowEnter = false;
    },
    setMenu(user) {
      const acc = this.$store.state.user.access;
      const tempMenu = [];
      const check = (meta) => {
        let access = true;
        if (user.company !== 16 && meta.prod === false) {
          access = false;
        } else if (
          !(
            (meta.admin && acc.admin) ||
            (meta.expert && acc.expert) ||
            (meta.manufacturer && acc.manufacturing) ||
            (!acc.admin && !acc.expert && !acc.manufacturing && meta.user)
          )
        ) {
          access = false;
        }
        return access;
      };
      for (const parent of this.$store.state.index.menu) {
        let result = {};
        if (check(parent.meta)) {
          result = { ...parent };
          result.children = [];
          if (parent.children) {
            for (const child of parent.children) {
              if (check(child.meta)) {
                result.children.push(child);
              }
            }
          }
          tempMenu.push(result);
        }
      }
      this.userMenu = tempMenu;
      this.menuLoading = true;
    },
    setOpenMenu(index, value) {
      const result = this.userMenu.slice();
      result[index].open = value;
      this.userMenu = result;
    },
    closeAllMenu() {
      const result = this.userMenu.slice();
      for (const item of result) {
        item.open = false;
      }
      this.userMenu = result;
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "user/setUser") {
        this.hasUser = true;
        if (this.hasUser && this.hasAccess) {
          this.setMenu(mutation.payload);
        }
      }
      if (mutation.type === "user/setAccess") {
        this.hasAccess = true;
        if (this.hasUser && this.hasAccess) {
          this.setMenu(this.$store.state.user.user);
        }
      }
      if (mutation.type === "user/removeUser") {
        this.userMenu = [];
        this.hasAccess = false;
        this.hasUser = false;
      }
    });
  },
  mounted() {
    if (this.$store.state.user.user && this.$store.state.user.access) {
      this.setMenu(this.$store.state.user.user);
    }
    this.findCategory(this.$route.path);
  },
  beforeDestroy() {
    this.unsubscribe();
    this.hasAccess = false;
    this.hasUser = false;
  },
  components: {
    orderAndParts,
    manufacture,
    warehouse,
    settings,
    library,
    integration,
    experts,
    admin,
    i5Kit,
    Left,
    Right,
  },
};
</script>
