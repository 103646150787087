<template>
  <div class="slider" @mousedown="startDrag">
    <div class="slider-line"></div>
    <div class="slider-track" :style="{ left: `${trackLeft}%`, width: `${trackWidth}%` }"></div>
    <div class="slider-thumb" :style="{ left: `${thumbPosition}%` }">
      <div class="slider-tooltip" v-if="showingTooltip">{{ localValue }}</div>
    </div>
    <div v-if="isRange" class="slider-thumb" :style="{ left: `${thumbPosition2}%` }">
      <div class="slider-tooltip" v-if="showingTooltip2">{{ localValue2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 0,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    value2: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    step: {
      immediate: true,
      handler(val) {
        this.stepDecimalPlaces = this.getDecimalPlaces(val);
      },
    },
    value: function () {
      this.initValues();
    },
    value2: function () {
      this.initValues();
    },
  },
  data() {
    return {
      dragging: false,
      thumbPosition: 0,
      thumbPosition2: 100,
      trackWidth: 100,
      trackLeft: 0,
      showingTooltip: false,
      showingTooltip2: false,
      localValue: this.value,
      localValue2: this.value2,
      stepDecimalPlaces: null,
    };
  },
  created() {
    this.initValues();
  },
  methods: {
    startDrag(e) {
      e.preventDefault();
      this.dragging = true;
      this.startDragThumbIndex = this.getClosestThumbIndex(e);

      // Отображаем оба tooltip при начале перетаскивания
      this.showTooltip();
      this.showTooltip2();

      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.stopDrag);
    },
    stopDrag() {
      if (!this.dragging) return;
      this.dragging = false;
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.stopDrag);

      // Скрываем оба tooltip после завершения перетаскивания
      this.hideTooltip();
      this.hideTooltip2();

      this.$emit("mouseup", { value: this.localValue, value2: this.localValue2 });
      if (this.isRange) {
        this.$emit("change", {
          value: this.localValue,
          value2: this.localValue2,
        });
      } else {
        this.$emit("change", {
          value: this.localValue,
        });
      }
    },
    stopDragOnLeave() {
      if (!this.dragging) return;
      this.stopDrag();
    },
    onMouseMove(e) {
      if (!this.dragging) return;
      const rect = this.$el.getBoundingClientRect();
      const xPos = e.clientX - rect.left;
      const percentage = (xPos / rect.width) * 100;
      this.updateValue(percentage, this.startDragThumbIndex);
    },
    updateValue(percentage, thumbIndex) {
      // Ограничиваем процентное значение от 0 до 100
      percentage = Math.max(0, Math.min(100, percentage));

      if (thumbIndex === 1) {
        percentage = Math.min(percentage, this.thumbPosition2 - 1);
      } else {
        percentage = Math.max(this.thumbPosition + 1, percentage);
      }
      // Рассчитываем новое значение на основе процентного значения
      let newValue = ((this.max - this.min) * percentage) / 100 + this.min;

      // Учитываем step
      newValue = Math.round(newValue / this.step) * this.step;
      newValue = parseFloat(newValue.toFixed(this.stepDecimalPlaces));

      // Ограничиваем значение в пределах min и max
      newValue = Math.max(this.min, Math.min(this.max, newValue));

      if (thumbIndex === 1) {
        this.thumbPosition = percentage;
        this.trackLeft = percentage;
        this.trackWidth = this.isRange ? this.thumbPosition2 - this.thumbPosition : percentage;
        this.localValue = newValue;
      } else if (thumbIndex === 2) {
        this.thumbPosition2 = percentage - 1; // Учитываем смещение правого шарика
        // Учитываем смещение правого шарика
        this.trackWidth = this.thumbPosition2 - this.thumbPosition + 1;
        this.localValue2 = newValue;
      }
      if (this.isRange) {
        this.$emit("input", {
          value: this.localValue,
          value2: this.localValue2,
        });
      } else {
        this.$emit("input", {
          value: this.localValue,
        });
      }
    },
    getDecimalPlaces(num) {
      const match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
      if (!match) return 0;
      return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
    },
    getClosestThumbIndex(e) {
      if (!this.isRange) return 1;

      const rect = this.$el.getBoundingClientRect();
      const xPos = e.clientX - rect.left;
      const percentage = (xPos / rect.width) * 100;
      const distance1 = Math.abs(this.thumbPosition - percentage);
      const distance2 = Math.abs(this.thumbPosition2 - percentage);

      return distance1 < distance2 ? 1 : 2;
    },
    showTooltip() {
      this.showingTooltip = true;
    },
    hideTooltip() {
      this.showingTooltip = false;
    },
    showTooltip2() {
      this.showingTooltip2 = true;
    },
    hideTooltip2() {
      this.showingTooltip2 = false;
    },
    initValues() {
      const percentage1 = ((this.value - this.min) / (this.max - this.min)) * 100;
      const percentage2 = ((this.value2 - this.min) / (this.max - this.min)) * 100;
      this.updateValue(percentage1, 1);
      this.updateValue(percentage2, 2);
    },
  },
};
</script>
