<template>
  <div class="block block__column">
    <div
      v-for="(item, index) in list"
      v-bind:key="`item_${index}`"
      style="gap: 10px"
      class="block block__align-center"
    >
      <div style="height: 100%">
        <div class="tooling-list_card_line"></div>
        <div class="tooling-list_card_circle"></div>
        <div class="tooling-list_card_line"></div>
      </div>
      <div
        class="tooling-list_card block block__column"
        style="gap: 10px; width: 100%; margin-bottom: 20px"
      >
        <div class="tooling-list__title">{{ text.assembly[language] }} №{{ index + 1 }}</div>
        <div class="tooling-list__grid_container">
          <div v-for="(el, i) in item" v-bind:key="`el_${i}_${index}`">
            <div class="tooling-list_card">
              <img
                :src="el.img"
                v-if="el.img"
                class="tech-proc__model_card_icon component__none-select"
              />
              <div>
                <div class="component__margin-bottom">
                  <div class="tech-proc__model_card_label">
                    {{ text.sn[language] }}
                  </div>
                  <div class="tech-proc__model_card_value component__select">
                    {{ el.name }}
                  </div>
                </div>
                <div class="component__margin-bottom">
                  <div class="tech-proc__model_card_label">
                    {{ text.vendor_code[language] }}
                  </div>
                  <div class="tech-proc__model_card_value component__select">
                    {{ el.vendor_code }}
                  </div>
                </div>
                <div class="component__margin-bottom">
                  <div class="tech-proc__model_card_label">
                    {{ text.type[language] }}
                  </div>
                  <div class="tech-proc__model_card_value component__select">
                    {{ el.tool_nomenclature_type }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddTool from "@/assets/images/tech-proc/additional_tool.svg";
import Tool from "@/assets/images/tech-proc/tool.svg";
import MachineTool from "@/assets/images/tech-proc/machine_tool.svg";
import { query } from "@/utilities/axios/index";
import text from "@/assets/text/technological_processes.json";
export default {
  name: "ToolingList",
  data() {
    return {
      text,
      list: null,
    };
  },
  props: {
    uuid: {
      type: String,
    },
  },
  watch: {
    uuid: {
      immediate: true,
      handler: function (val) {
        this.getToolSheet(val);
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getToolSheet(uuid) {
      query(
        "post",
        "getToolSheet",
        {
          uuid_mp: uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.list = res;
      });
    },
  },
  comments: {
    AddTool,
    Tool,
    MachineTool,
  },
};
</script>
