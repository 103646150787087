<template>
  <div style="height: inherit">
    <AddModal
      :show="showAddBlankModal"
      :values="editData"
      :type="blankModalType"
      @close="setAddBlankModal(false)"
    />
    <PageConstructor
      :query="query"
      :columns="columns"
      id-name="uuid"
      @headerClick="headerClick($event)"
      :buttons="buttons"
      :page-name="text.page_name[language]"
    >
      <template slot="edit" slot-scope="{ row }">
        <Edit :row="row" @edit="setEdit($event)" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/blanks.json";
import AddModal from "./components/addModal/AddModal.vue";
import Edit from "./components/edit/Edit.vue";

export default {
  name: "Blanks",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getCurrentCompaniesBlanksV2",
        headers: {},
        data: {},
      },
      editData: null,
      showAddBlankModal: false,
      blankModalType: "add",
      buttons: [
        {
          type: "main",
          text: text.add_button[this.$store.state.index.language],
        },
      ],
      columns: [
        {
          name: "blank_type",
          label: text.blank_type,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "material",
          label: text.material,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "size",
          label: text.size,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "standard",
          label: text.standard,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "amount",
          label: text.amount,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "workshop_name",
          label: text.workshop_name,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "blank_price",
          label: text.blank_price,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "sale_cost",
          label: text.sale_cost,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "export",
          label: text.export,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.editData = null;
        this.blankModalType = "add";
        this.setAddBlankModal(true);
      }
    },
    setAddBlankModal(val) {
      this.showAddBlankModal = val;
    },
    setEdit(values) {
      this.editData = values;
      this.blankModalType = "edit";
      this.setAddBlankModal(true);
    },
  },
  components: {
    PageConstructor,
    AddModal,
    Edit,
  },
};
</script>
