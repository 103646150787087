var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-profile__wrapper"},[_c('div',{staticClass:"user-profile__header"},[_c('div',{staticClass:"user-profile__header_title"},[_vm._v(_vm._s(_vm.text.user_profile[_vm.language]))])]),_c('div',{staticClass:"user-profile__body"},[_c('div',{staticClass:"user-profile__block"},[_c('img',{staticClass:"component__margin-right user-profile__logo",attrs:{"src":_vm.user.company_logo}}),_c('div',{staticClass:"user-profile__value component__margin-top"},[_vm._v(_vm._s(_vm.user.company_name))])]),_c('div',{staticClass:"user-profile__block"},[_c('div',{staticClass:"user-profile__title"},[_vm._v(_vm._s(_vm.text.first_name[_vm.language])+":")]),(_vm.user.first_name)?_c('div',{staticClass:"user-profile__value"},[_vm._v(_vm._s(_vm.user.first_name))]):_vm._e()]),_c('div',{staticClass:"user-profile__block"},[_c('div',{staticClass:"user-profile__title"},[_vm._v(_vm._s(_vm.text.last_name[_vm.language])+":")]),(_vm.user.last_name)?_c('div',{staticClass:"user-profile__value"},[_vm._v(_vm._s(_vm.user.last_name))]):_vm._e()]),_c('div',{staticClass:"user-profile__block"},[_c('div',{staticClass:"user-profile__title"},[_vm._v(_vm._s(_vm.text.email[_vm.language])+":")]),(_vm.user.email)?_c('div',{staticClass:"user-profile__value"},[_vm._v(_vm._s(_vm.user.email))]):_vm._e()]),_c('div',{staticClass:"user-profile__block"},[_c('div',{staticClass:"user-profile__title"},[_vm._v(_vm._s(_vm.text.phone[_vm.language])+":")]),(_vm.user.mobile)?_c('div',{staticClass:"user-profile__value"},[_vm._v(_vm._s(_vm.user.mobile))]):_vm._e()]),_c('div',{staticClass:"user-profile__block"},[_c('div',[_c('Select',{staticStyle:{"max-width":"200px"},attrs:{"label":_vm.text.currency[_vm.language],"values":_vm.currencyList,"default-value":_vm.user && _vm.user.currency ? _vm.user.currency : '',"dropdown-props":{
            label: 'name',
            value: 'id',
          }},on:{"change":function($event){return _vm.setCurrency($event)}}})],1)]),_c('div',{staticClass:"user-profile__block"},[_c('div',[_c('Select',{staticStyle:{"max-width":"200px"},attrs:{"label":_vm.text.language[_vm.language],"values":_vm.languages,"default-value":_vm.user && _vm.user.lang ? _vm.user.lang : '',"dropdown-props":{
            label: 'name',
            value: 'id',
          }},on:{"change":function($event){return _vm.setLanguage($event)}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }