<template>
  <div v-if="parameters && parameters.length > 0">
    <div v-for="(item, index) in parameters" v-bind:key="`${index}`">
      <div v-if="item.type === 'title'" class="page__edit_margin page__edit_large-title">
        {{ item.name }}
      </div>
      <div v-else-if="item.type === 'button'" class="page__edit_margin">
        <Button
          block
          :text="item.name"
          @click="
            () => {
              $emit('buttonClick', item);
            }
          "
        />
      </div>
      <div v-else-if="item.type === 'name'" class="page__edit_margin">
        <div class="page__edit_title">{{ item.title }}:</div>
        <div class="page__edit_value component__select">
          {{ item.name }}
        </div>
      </div>
      <div v-else-if="item.type === 'data_list'" class="page__edit_margin">
        <div class="page__edit_title">{{ item.title }}</div>
        <div class="page__edit_line">
          <div v-for="(value, i) in item.data" v-bind:key="`${index}_${i}`">
            <div class="page__edit_line_label">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="item.type === 'data_value_list'" class="page__edit_margin">
        <div class="page__edit_title">{{ item.title }}</div>
        <div class="page__edit_line">
          <div v-for="(el, i) in item.data" v-bind:key="`${index}_${i}`">
            <div class="page__edit_line_label">
              <span
                ><span class="component__select" v-if="el.name">{{ el.name }}</span> {{ " " }}
                <span style="font-weight: 600" v-if="el.value">{{ el.value }}</span> {{ " " }}
                <span v-if="el.dimension">{{ el.dimension }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="item.type === 'models'">
        <FilePreview :width="width" :files="item.models" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import FilePreview from "@/components/filePreview/FilePreview.vue";
export default {
  name: "ViewTableParameters",
  data() {
    return {};
  },
  props: {
    parameters: {
      type: Array,
    },
    width: {
      type: [Number, String],
    },
  },
  components: {
    FilePreview,
    Button,
  },
};
</script>
