<template>
  <PageWithCards
    show-filter
    :query="query"
    :card="card"
    id-name="uuid"
    :page-name="text.page_name[language]"
  >
  </PageWithCards>
</template>

<script>
import PageWithCards from "@/components/pageWithCards/PageWithCards.vue";
import text from "@/assets/text/tools_catalogue.json";

export default {
  name: "ToolsCatalogue",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getToolsByUser",
        headers: {},
        data: {},
      },
      card: {
        title: {
          value: "name",
        },
        values: [
          {
            type: "value",
            label: text.manufacturer,
            value: "manufacturer",
            logo: "logo",
          },
          {
            type: "image",
            value: "img",
          },
          {
            type: "html-list",
            label: text.options,
            value: "parameters",
          },
          {
            type: "html-list",
            label: text.processing_modes,
            value: "cutting_conditions",
          },
          {
            type: "html-list",
            label: text.instrument_test_reports,
            value: "tool_tests",
          },
          {
            type: "html-list",
            label: text.suppliers,
            value: "suppliers",
          },
        ],
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageWithCards,
  },
};
</script>
