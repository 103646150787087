<template>
  <div>
    <PageHeader name="Оперативное планирование" :buttons="buttons" />
    <timeline ref="timeline" :items="items" :groups="groups" :options="options"> </timeline>
    <a-modal
      v-model="showDeleteModal"
      cancelText="Отмена"
      okType="danger"
      okText="Удалить"
      @ok="deleteItem"
      @cancel="closeDeleteModal"
      title="Удаление события"
    >
      <p v-if="deletedItem.item">
        Вы действительно хотите удалить
        <b>{{ deletedItem.item.content }}</b>
      </p>
    </a-modal>
    <a-modal
      v-model="showAddModal"
      v-if="Object.keys(addItem).length > 0"
      cancelText="Отмена"
      okText="Добавить"
      :footer="null"
      title="Добавление события"
    >
      <a-form :form="form" @submit="addNewItem">
        <a-form-item label="Наименование">
          <a-input
            placeholder="Введите наименование"
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Поле обязательно к заполнению' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Станок">
          <a-select
            v-decorator="[
              'machine-tool',
              {
                rules: [{ required: true, message: 'Выберите станок' }],
                initialValue: addItem.item ? addItem.item.group : 0,
              },
            ]"
            placeholder="Please select a country"
          >
            <a-select-option v-for="tool in groups" v-bind:key="tool.id" :value="tool.id">
              {{ tool.content }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Дата начала">
          <div :style="{ display: 'flex' }">
            <a-time-picker
              v-decorator="[
                'start-time',
                {
                  initialValue: moment(this.addItem.item.start, 'HH:mm'),
                },
              ]"
              format="HH:mm"
            />
            <a-date-picker
              :style="{ marginLeft: '8px' }"
              v-decorator="[
                'start-date',
                {
                  initialValue: moment(this.addItem.item.start, 'DD.MM.YYYY'),
                },
              ]"
              :format="'DD.MM.YYYY'"
            />
          </div>
        </a-form-item>
        <a-form-item label="Дата начала">
          <div :style="{ display: 'flex' }">
            <a-time-picker
              v-decorator="[
                'end-time',
                {
                  initialValue: moment(this.addItem.item.start, 'HH:mm').add(1, 'h'),
                },
              ]"
              format="HH:mm"
            />
            <a-date-picker
              v-decorator="[
                'end-date',
                {
                  initialValue: moment(this.addItem.item.start, 'DD.MM.YYYY'),
                },
              ]"
              :style="{ marginLeft: '8px' }"
              :format="'DD.MM.YYYY'"
            />
          </div>
        </a-form-item>
        <div :style="{ display: 'flex', justifyContent: 'flex-end' }">
          <a-button key="back" @click="closeAddModal"> Отмена </a-button>
          <a-button :style="{ marginLeft: '8px' }" key="submit" type="primary" html-type="submit">
            Добавить
          </a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { query } from "@/utilities/axios";
import PageHeader from "@/components/pageHeader/PageHeader.vue";

export default {
  name: "OpPlanning",
  data() {
    return {
      query,
      moment,
      form: this.$form.createForm(this, { name: "addItemForm" }),
      showDeleteModal: false,
      showAddModal: false,
      deletedItem: {},
      addItem: {},
      groups: [],
      items: [
        {
          start: new Date(2021, 5, 29, 12, 0),
          end: new Date(2021, 5, 29, 16, 40),
          content: "Деталь 1",
          group: 1,
          // Optional: fields 'id', 'type', 'group', 'className', 'style'
        },
        {
          start: new Date(2021, 5, 29, 9, 0),
          end: new Date(2021, 5, 29, 12, 35),
          content: "Деталь 2",
          group: 3,
          // Optional: fields 'id', 'type', 'group', 'className', 'style'
        },
        {
          start: new Date(2021, 5, 29, 16, 0),
          end: new Date(2021, 5, 29, 18, 20),
          content: "Деталь 3",
          group: 0,
          // Optional: fields 'id', 'type', 'group', 'className', 'style'
        },
        {
          start: new Date(2021, 5, 29, 10, 0),
          end: new Date(2021, 5, 29, 14, 30),
          content: "Деталь 4",
          group: 2,
        },
      ],
      options: {
        editable: true,
        orientation: "top",
        onRemove: (item, callback) => {
          this.deletedItem = {
            item,
            callback,
          };
          this.showDeleteModal = true;
        },
      },
      buttons: [
        {
          type: "main",
          text: "Создать заказ",
        },
      ],
    };
  },
  computed: {},
  methods: {
    getData() {
      query(
        "post",
        "getCurrentCompaniesMachineToolsV2",
        {
          page: 1,
          line_per_page: 10,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((values) => {
        this.groups = values.table;
      });
    },
    deleteItem() {
      this.deletedItem.callback(this.deletedItem.item);
      this.deletedItem = {};
      this.showDeleteModal = false;
    },
    closeDeleteModal() {
      this.deletedItem = {};
      this.showDeleteModal = false;
    },
    closeAddModal() {
      this.addItem = {};
      this.showAddModal = false;
    },
    addNewItem(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const result = {};
          result.id = this.addItem.item.id;
          result.content = values.name;
          result.group = values["machine-tool"];
          const startYear = moment(values["start-date"]).format("YYYY");
          const startMonth = moment(values["start-date"]).add(-1, "M").format("MM");
          const startDay = moment(values["start-date"]).format("DD");
          const startHour = moment(values["start-time"]).format("HH");
          const startMin = moment(values["start-time"]).format("mm");
          const endYear = moment(values["end-date"]).format("YYYY");
          const endMonth = moment(values["end-date"]).add(-1, "M").format("MM");
          const endDay = moment(values["end-date"]).format("DD");
          const endHour = moment(values["end-time"]).format("HH");
          const endMin = moment(values["end-time"]).format("mm");
          result.start = new Date(startYear, startMonth, startDay, startHour, startMin);
          result.end = new Date(endYear, endMonth, endDay, endHour, endMin);
          this.addItem.callback(result);
          this.addItem = {};
          this.showAddModal = false;
        }
      });
    },
  },
  components: {
    PageHeader,
  },
  created() {
    this.getData();
  },
};
</script>
