<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :page-name="text.page_name[language]"
  >
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/measuring_tools.json";

export default {
  name: "MeasuringTools",
  data() {
    return {
      text,
      query: {
        method: "post",
        url: "getCurrentCompanyMeasureTools",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "sn",
          label: text.number,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "name",
          label: text.name,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "measure_tool_type",
          label: text.measure_tool_type,
          options: {
            sort: true,
          },
          basis: 0,
          width: 150,
        },
        {
          name: "manufacturer",
          label: text.manufacturer,
          options: {},
          basis: 200,
          width: 200,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
